import { DatePicker } from 'antd'
import useOrders from '../../hooks/useOrders'

export default function DateHandler({ setDate, defaultValue, margin }) {
    const { addDay } = useOrders()

    const onChangeHandler = (_, dateString) => {
        dateString = Array.isArray(dateString) ? dateString.map(i => new Date(i).toString() === 'Invalid Date' ? addDay(Date.now(), -1): i): [addDay(Date.now(), -1), Date.now()]
        setDate(dateString)
        console.log(dateString)
    }

    return (
        <div style={{margin: margin ? margin: 0}}>
            <DatePicker.RangePicker
                style={{borderRadius: '10px', minWidth: '260px'}}
                format="YYYY-MM-DD"
                onChange={onChangeHandler}
                size="large"
                defaultValue={defaultValue}
            />
        </div>
    )
}


DateHandler.defaultProps = {
    margin: '16px 0'
}