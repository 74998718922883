import BoxLayout from "./BoxLayout";
import { useState, useContext, useEffect, useCallback } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import { Popover, Space, Tag, message, Switch, Dropdown, Menu } from "antd";
import styled from "styled-components";
import ProductLinks from "../../Common/ProductLinks";
import Parse from "parse";
import UpdateIssue from "./UpdateIssue";

const RefundStatus = ({ refund_status, object }) => {
  const {
    order: {
      data: { timeline, ref: orderObject },
    },
  } = useContext(DetailsContext);
  const user = Parse.User.current() || {};
  const [status, setStatus] = useState(refund_status);

  const statuses = [
    { title: "Initiate", value: "initiated" },
    { title: "Complete", value: "completed" },
  ];

  const statusUpdate = async (refundStatus) => {
    try {
      if (status !== refundStatus && object instanceof Parse.Object) {
        setStatus(refundStatus);
        if (orderObject) {
          timeline.push({
            type: "issue",
            msg: `${user.get("name")}(${user.get(
              "type"
            )}) Issue status updated to ${status}`,
            time: new Date().toDateString(),
          });
          orderObject.set("timeline", timeline);
          await orderObject.save(null, {
            sessionToken: user.getSessionToken(),
          });
        }

        object.set("refund_status", refundStatus);
        await object.save();
        message.success("Refund Status Updated");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          {statuses.map((item) => {
            return (
              <Menu.Item
                key={item.value}
                onClick={() => statusUpdate(item.value)}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <div className={`status ${status}`}>{status}</div>
    </Dropdown>
  );
};

const Status = ({ status, object }) => {
  const {
    order: {
      data: { timeline, ref: orderObject },
    },
  } = useContext(DetailsContext);
  const user = Parse.User.current() || {};
  const [value, setValue] = useState(status);

  const statusUpdate = useCallback(
    async (issueStatus) => {
      try {
        if (status !== issueStatus && object instanceof Parse.Object) {
          setValue(issueStatus);
          if (orderObject) {
            timeline.push({
              type: "issue",
              msg: `${user.get("name")}(${user.get(
                "type"
              )}) Issue status updated to ${issueStatus}`,
              time: new Date().toDateString(),
            });
            orderObject.set("timeline", timeline);
            await orderObject.save();
          }
          object.set("status", issueStatus);
          await object.save();
          message.success(
            `Issue ${issueStatus === "close" ? "closed" : "opened"}!`
          );
        }
      } catch (err) {
        message.error(err.message);
      }
    },
    [timeline]
  );

  return (
    <Dropdown
      overlay={
        <Menu>
          {[value === "open" ? "close" : "open"].map((item) => {
            return (
              <Menu.Item key={item} onClick={() => statusUpdate(item)}>
                {item}
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <div className={`status ${value}`}>{value}</div>
    </Dropdown>
  );
};

const IssueApproval = ({ approved, issueId }) => {
  const [approval, setApproval] = useState(approved);
  const [loading, setLoading] = useState(false);

  const approvalHandler = async (approved) => {
    try {
      setLoading(true);
      await Parse.Cloud.run("issueApprovalUpdate", {
        issueId,
        approved,
      });
      message.success("Issue Approval Updated");
      setApproval(approved);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Switch
        loading={loading}
        onChange={approvalHandler}
        size="small"
        checked={approval}
      />
    </div>
  );
};

export default function IssueDetails() {
  const {
    order: {
      data: { issue: orderIssue, rider },
    },
  } = useContext(DetailsContext);
  const [issue, setIssue] = useState(null);

  const {
    restaurant_penalty,
    rider_penalty,
    refund_status,
    refund_items,
    accountable,
    type,
    details,
    status,
    refund_amount,
    added_by,
    createdAt,
    approved,
  } = issue || {};

  const fetchIssue = async (id) => {
    try {
      const issue = await new Parse.Query("order_issue")
        .equalTo("objectId", id)
        .select([
          "restaurant_penalty",
          "rider_penalty",
          "refund_status",
          "refund_items",
          "accountable",
          "type",
          "details",
          "status",
          "refund_amount",
          "added_by.name",
        ])
        .first();

      if (issue) {
        setIssue({ ...issue.toJSON(), object: issue });
      } else {
        message.error("Issue not found");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (orderIssue) fetchIssue(orderIssue.id);
  }, [orderIssue]);

  const { name: riderName, phone: riderPhone } = rider?.toJSON() || {};

  return (
    issue && (
      <Wrapper
        style={{
          minHeight: "300px",
          border: "2px solid #ff9292",
        }}
      >
        <div className="top">
          <div>
            <Space>
              <div>Approval</div>
              <IssueApproval approved={approved} issueId={issue.objectId} />
              <UpdateIssue issue={issue} />
            </Space>
          </div>
          <Status status={status} object={issue.object} />
        </div>
        <div className="content">
          <Space className="type">
            <Popover content="Issue type">
              <div className="title">{type}</div>
            </Popover>
            {accountable && (
              <Popover content="Accountable">
                <div className="accountable">{accountable}</div>
              </Popover>
            )}
          </Space>
          {details && <div className="details">{details}</div>}

          {(restaurant_penalty || rider_penalty) && (
            <div className="penalty">
              <div>
                {restaurant_penalty?.map((rest) => {
                  return (
                    <div key={rest.id} className="penalty-item">
                      <Space>
                        <div className="image">
                          <img src={rest.image} alt="" />
                        </div>
                        <div>
                          <div className="title">Penalty</div>
                          <Space>
                            <Popover content="Restaurant">
                              <div className="restaurant">
                                {rest.name || rest.id}
                              </div>
                            </Popover>
                            <div className="amount">{rest.amount} tk</div>
                          </Space>
                        </div>
                      </Space>
                    </div>
                  );
                })}
              </div>

              {rider_penalty && rider && (
                <Popover content="Rider Penalty">
                  <Space className="rider">
                    <div className="name">{riderName || riderPhone}</div>
                    <Tag>{rider_penalty} tk</Tag>
                  </Space>
                </Popover>
              )}
            </div>
          )}

          <div className="refund">
            <Space className="title">
              <Space>
                <span>Refund </span>
                {refund_amount && (
                  <div className="amount">{refund_amount} tk</div>
                )}
              </Space>
              {refund_status && <RefundStatus {...issue} />}
            </Space>

            {refund_items && (
              <div className="refund-items">
                {refund_items.map((item) => {
                  const vTotal = item.variant?.reduce((acc, cur) => {
                    cur.items?.forEach((i) => {
                      acc += i.price;
                    });
                    return acc;
                  }, 0);

                  const price = item.sale_unit + (vTotal || 0);

                  return (
                    <div key={item.id} className="refund-item">
                      <Space>
                        <div className="image">
                          <img src={item.image} alt="" width="50px" />
                        </div>
                        <div>
                          <div className="name">
                            <ProductLinks
                              id={item.id}
                              restaurantId={item.restaurant?.id}
                            >
                              {item.name}
                            </ProductLinks>
                          </div>
                          {item.restaurant && (
                            <div className="restaurant">
                              @{item.restaurant?.name}
                            </div>
                          )}
                          <div className="qty">
                            Quantity: {item.quantity} X {price} ={" "}
                            {item.quantity * price} tk
                          </div>
                        </div>
                      </Space>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div title="Issue raised by" className="added-by">
            <div className="name">@{added_by?.name}</div>
            <div className="date">{new Date(createdAt).toLocaleString()}</div>
          </div>
        </div>
      </Wrapper>
    )
  );
}

const Wrapper = styled(BoxLayout)`
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0px 10px;

    .status {
      font-size: 14px;
      font-weight: 500;
      padding: 1px 10px;
      text-transform: capitalize;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;

      &.open {
        background: #409f40;
        color: #fff;
      }

      &.close {
        background: #ff4d4f;
        color: #fff;
      }
    }
  }

  .content {
    .type {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 10px;
      display: flex;

      .title {
        color: crimson;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .accountable {
        font-size: 12px;
        font-weight: 500;
        background: #f6d365;
        padding: 1px 10px;
        border-radius: 5px;
      }
    }

    .details {
      margin-bottom: 10px;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      border-left: 3px solid lightgray;
      padding-left: 10px;
      background: #f5f5f5;
    }

    .penalty {
      margin-bottom: 10px;
      background: #f7f7f7;
      padding: 10px;
      border-radius: 10px;

      .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #f6d365;
      }

      .title {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .penalty-item {
        font-size: 14px;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .rider {
        font-size: 14px;
        font-weight: 500;
        color: #666;
      }
    }

    .refund {
      background: #f7f7f7;
      padding: 7px 18px;
      border-radius: 10px;

      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .amount {
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 1px;
          background: #ffab03;
          padding: 1px 10px;
          border-radius: 5px;
        }

        .status {
          font-size: 13px;
          font-weight: 500;
          padding: 1px 10px;
          border-radius: 5px;
          display: inline-block;
          letter-spacing: 1px;
          text-transform: capitalize;
          cursor: pointer;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

          &.initiated {
            background: #fda085;
          }

          &.completed {
            background: #409f40;
            color: #fff;
          }
        }
      }

      .refund-items {
        padding: 10px 0px;
        .refund-item {
          padding: 10px 0;
          .image {
            width: 50px;
            height: 50px;
            background: lightgray;
            border-radius: 100%;

            img {
              border-radius: 100%;
              width: 100%;
              height: 100%;
            }
          }

          .name {
            font-size: 14px;
            font-weight: 500;
          }
          .restaurant {
            font-size: 12px;
            font-weight: 500;
            color: #8c8c8c;
          }
          .qty {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }

    .bkash-refund {
      background: #f7f7f7;
      margin-top: 10px;
      padding: 10px 20px;
      border-radius: 10px;

      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        color: #eb2f96;
        letter-spacing: 1px;
      }

      .info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .added-by {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #8c8c8c;
      text-align: right;
    }
  }
`;
