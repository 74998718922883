import { useContext, useState } from "react"
import { UserListContext } from "../../pages/User/UserList";
import { Space, Button, Popconfirm, Affix } from "antd"
import styled from "styled-components"
import GoBack from "../Common/GoBack"
import SentNotification from "./SentNotification";

function DeleteUsers(){
    const { selectedRows, deleteUsers } = useContext(UserListContext)
    const [loading, setLoading] = useState(false)

    return (
        <div>
            <Popconfirm
                title="Are you sure you want to delete selected users?"
                onConfirm={() => {
                    setLoading(true)
                    deleteUsers({
                        ids: selectedRows.map(user => user.id)
                    }, () => {
                        setLoading(false)
                    })
                }}
                okText="Yes"
                confirmLoading={loading}
            >
                <Button size="large" danger>Delete</Button>
            </Popconfirm>
        </div>
    )
}


export default function Header() {
    const { selectedRows } = useContext(UserListContext)

    return (
        <Affix offsetTop={0}>
            <HeaderContainer>
                <Space style={{marginBottom: '10px'}}>
                    <GoBack />
                    <h2 className="title">User list</h2>
                </Space>
                {selectedRows.length > 0 && <div className="selection-box">
                    <Space>
                        <SentNotification />
                        <DeleteUsers />
                    </Space>
                </div>}
            </HeaderContainer>
        </Affix>
    )
}

const HeaderContainer = styled.div`
    margin-bottom: 20px;
    
    .title {
        margin-bottom: 0;
        font-size: 20px;
    }
    
    .selection-box {
        padding: 20px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
`