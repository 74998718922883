import { useReducer, createContext, useEffect, useCallback } from "react";
import { message } from "antd";
import useOrders from "../../hooks/useOrders";
import { parser } from "../../utils";
import { useParams } from "react-router-dom";
import Components from "../../components/order/Details";
import Parse from "parse";
import { client } from "../../AuthProvider";

const Types = {
  ORDER_REQUEST: "ORDER_REQUEST",
  ORDER_SUCCESS: "ORDER_SUCCESS",
  ORDER_FAILURE: "ORDER_FAILURE",
  ORDER_CLEAR: "ORDER_CLEAR",
  EDIT_MODE_ON: "EDIT_MODE_ON",
  EDIT_MODE_OFF: "EDIT_MODE_OFF",
  GET_RIDERS_REQUEST: "GET_RIDERS_REQUEST",
  GET_RIDERS_SUCCESS: "GET_RIDERS_SUCCESS",
  GET_RIDERS_FAILURE: "GET_RIDERS_FAILURE",
  SET_DELIVERY_ZONE: "SET_DELIVERY_ZONE",
  SET_USER_ORDER_COUNT: "SET_USER_ORDER_COUNT",
};

export const DetailsContext = createContext();

const initialState = {
  order: {
    loading: false,
    error: null,
    data: null,
  },
  editMode: false,
  riders: {
    loading: true,
    data: null,
  },
  zone: null,
  userOrderCount: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.ORDER_REQUEST:
      state.order.loading = true;
      return { ...state };
    case Types.ORDER_SUCCESS:
      state.order.loading = false;
      state.order.data = action.payload;
      return { ...state };
    case Types.ORDER_FAILURE:
      state.order.loading = false;
      state.order.error = action.payload;
      return { ...state };
    case Types.EDIT_MODE_ON:
      state.editMode = true;
      return { ...state };
    case Types.EDIT_MODE_OFF:
      state.editMode = false;
      return { ...state };
    case Types.GET_RIDERS_REQUEST:
      state.riders.loading = true;
      return { ...state };
    case Types.GET_RIDERS_SUCCESS:
      state.riders.loading = false;
      state.riders.data = action.payload;
      return { ...state };
    case Types.GET_RIDERS_FAILURE:
      state.riders.loading = false;
      state.riders.data = null;
      state.riders.error = action.payload;
      return { ...state };
    case Types.SET_DELIVERY_ZONE:
      state.zone = action.payload;
      return { ...state };
    case Types.SET_USER_ORDER_COUNT:
      state.userOrderCount = action.payload;
      return { ...state };
    case Types.ORDER_CLEAR:
      state.order.data = null;
      return { ...state };
    default:
      return state;
  }
};

export default function OrderDetails({ orderId }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getById, assignRider, updateOrderItems } = useOrders();
  const { id = orderId } = useParams();

  const ordersQuery = new Parse.Query("order");
  ordersQuery.equalTo("objectId", id);

  let ordersLiveSubscription = null;

  const initLiveQuery = async () => {
    ordersLiveSubscription = await client.subscribe(
      ordersQuery,
      Parse.User.current()?.getSessionToken()
    );

    if (ordersLiveSubscription) {
      ordersLiveSubscription.on("update", (object) => {
        if (object.id === state.order.data?.id) {
          dispatch({
            type: Types.ORDER_SUCCESS,
            payload: parser(object),
          });
        }
      });
    }
  };

  const clearSubs = useCallback(() => {
    if (ordersLiveSubscription) ordersLiveSubscription.unsubscribe();
  }, [ordersLiveSubscription]);

  useEffect(() => {
    initLiveQuery();

    return () => {
      clearSubs();
    };
  }, []);

  useEffect(() => {
    if (state.order.data?.id && id !== state.order.data.id) {
      dispatch({
        type: Types.ORDER_CLEAR,
      });
    }
  }, [id]);

  const fetchOrder = async (id) => {
    dispatch({ type: Types.ORDER_REQUEST });
    message.loading("Loading...", 0);
    getById(id, (err, order) => {
      message.destroy();
      if (order) {
        dispatch({
          type: Types.ORDER_SUCCESS,
          payload: parser(order),
        });

        fetchUserOrderCount(order.get("user")?.id, order.createdAt);
      }

      if (err) {
        message.error(err);
        dispatch({
          type: Types.ORDER_FAILURE,
          payload: err,
        });
      }
    });
  };

  const fetchUserOrderCount = async (id, createdAt) => {
    try {
      const count = await new Parse.Query("order")
        .equalTo("user", {
          __type: "Pointer",
          className: "_User",
          objectId: id,
        })
        .lessThanOrEqualTo("createdAt", new Date(createdAt))
        .count();
      dispatch({
        type: Types.SET_USER_ORDER_COUNT,
        payload: count,
      });
    } catch (err) {
      message.error(err);
    }
  };

  const editModeHandler = (arg) => {
    if (arg === "on") {
      dispatch({ type: Types.EDIT_MODE_ON });
    } else {
      dispatch({ type: Types.EDIT_MODE_OFF });
    }
  };

  const getRiders = async () => {
    dispatch({ type: Types.GET_RIDERS_REQUEST });
    try {
      const query = new Parse.Query("User");
      query.equalTo("type", "rider");
      query.select(
        "name",
        "collection",
        "active",
        "deactive_permission",
        "rider_availability"
      );
      let riders = await query.find(null, {
        sessionToken: Parse.User.current()?.getSessionToken(),
      });
      riders = riders.filter((rider) => rider.get("active"));
      dispatch({ type: Types.GET_RIDERS_SUCCESS, payload: parser(riders) });
    } catch (err) {
      dispatch({ type: Types.GET_RIDERS_FAILURE });
      message.error(err.message);
    }
  };

  const assignRiderFunc = (orderId, riderId, callback) => {
    assignRider({ order_id: orderId, rider_id: riderId }, (err, res) => {
      if (res) {
        callback(null, res);
      } else {
        callback(err, null);
      }
    });
  };

  const getOrderCharges = async (order, callback = () => {}) => {
    const order_items = order.data.order_items.map((item) => {
      const newObj = {
        id: item.id,
        quantity: item.quantity,
      };
      if (
        item.variant &&
        Array.isArray(item.variant) &&
        item.variant.length > 0
      ) {
        // const itemVariants = item.variant.filter(i => i?.items?.length > 0)
        newObj["variant"] = item.variant.map((i) => ({
          variantId: i.variantId,
          items:
            typeof i.items === "string"
              ? [{ id: i.items }]
              : i.items.map((i) => ({
                  id: i.id,
                })),
        }));
      }
      if (item.addons && Array.isArray(item.addons) && item.addons.length > 0) {
        newObj["addons"] = item.addons.map((i) => ({
          id: i.id,
        }));
      }

      return newObj;
    });

    const object = {
      order_items,
      customer_area: order.data.customer_area,
      update: true,
    };

    const { promo } = order.data;
    if (promo && promo.promo_code && promo.promo_auth) {
      object["promo"] = promo.promo_code;
      object["promoAuth"] = promo.promo_auth;
    }

    try {
      const get = await Parse.Cloud.run("getOrderCharges", object);
      if (get) {
        const {
          discount,
          items_discount,
          items_total,
          promo_discount,
          total,
          vat,
          order_items,
        } = get;
        const charge = order.data.charge;
        charge.total = total;
        charge.discount = discount;
        charge.items_discount = items_discount;
        charge.items_total = items_total;
        charge.promo_discount = promo_discount;
        charge.vat = vat;
        order.data.order_items = order_items;
        dispatch({ type: Types.ORDER_SUCCESS, payload: order.data });
        callback();
      }
    } catch (err) {
      message.error(err.message);
      callback();
    }
  };

  const updateCustomerInfo = async (
    {
      customer_area,
      customer_name,
      customer_phone,
      customer_address,
      note,
      rider_note,
      geo,
    },
    callback
  ) => {
    getById(id, async (err, order) => {
      if (order) {
        order.set("customer_name", customer_name);
        order.set("customer_phone", customer_phone);
        order.set("customer_address", customer_address);
        order.set("customer_area", customer_area);
        order.set("note", note);
        order.set("rider_note", rider_note);

        if (geo) {
          order.set("geo", [geo.latitude, geo.longitude]);
          order.set(
            "geoPoint",
            new Parse.GeoPoint(Number(geo.latitude), Number(geo.longitude))
          );
        }

        try {
          const saved = await order.save();
          if (saved) {
            dispatch({ type: Types.ORDER_SUCCESS, payload: parser(order) });
            message.success("Customer info updated");
            callback(true);
          } else {
            callback(false);
            message.error("Failed to update customer info!");
          }
        } catch (err) {
          message.error(err.message);
          callback(false);
        }
      } else {
        callback(false);
        message.error("Failed to update customer info!");
      }
    });
  };

  const updateOrderItemsFunc = (params, callback) => {
    updateOrderItems(params, (err, order) => {
      if (order) {
        dispatch({ type: Types.ORDER_SUCCESS, payload: parser(order) });
        message.success("Order items updated");
        callback(true);
      } else if (err) {
        callback(false);
        message.error(err);
      }
    });
  };

  const necessaryOrderItems = (order_items) => {
    return order_items.map(({ id, quantity, variant, addons }) => {
      const obj = { id, quantity };
      if (variant && Array.isArray(variant)) {
        obj["variant"] = variant.map(({ variantId, items }) => {
          return {
            variantId,
            items: items?.map(({ id, name }) => ({ id, name })),
          };
        });
      }
      if (addons && Array.isArray(addons)) {
        obj["addons"] = addons.map(({ id }) => ({ id }));
      }
      return obj;
    });
  };

  const fetchDeliveryZones = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name", "areas").find();

      if (Array.isArray(hubs)) {
        let areas = hubs.reduce((acc, hub) => {
          const { areas, name } = hub.toJSON();
          if (Array.isArray(areas)) {
            areas.forEach((area) => {
              acc.push({ ...area, hub: name });
            });
          }

          return acc;
        }, []);

        dispatch({
          type: Types.SET_DELIVERY_ZONE,
          payload: areas,
        });
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchOrder(id);
    getRiders();
    fetchDeliveryZones();
  }, []);

  return (
    <DetailsContext.Provider
      value={{
        ...state,
        dispatch,
        editModeHandler,
        Types,
        getOrderCharges,
        updateCustomerInfo,
        assignRider: assignRiderFunc,
        updateOrderItems: updateOrderItemsFunc,
        necessaryOrderItems,
      }}
    >
      {state.order.data && <Components />}
    </DetailsContext.Provider>
  );
}
