import { useEffect, useState } from "react";
import { Form, Button, Tag } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchHubs } from "../../store/hub/hubSlices";
import PrimaryItems from "../../components/rider/registration/PrimaryItems";
import {
  getRider,
  updateRiderHandler,
} from "../../store/rider/riderCrudSlices";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";
import moment from "moment";
import Boxer from "../../components/Common/Boxer";
import BehavioralItems from "../../components/rider/registration/BehavioralItems";

export default function RiderUpdate() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    { fetchHubs, getRider, updateRiderHandler },
    dispatch
  );
  const { id } = useParams();
  const [updatePassword, setUpdatePassword] = useState(false);
  const hubs = useSelector((state) => state.hub.hubs.data.results);
  const [jobType, setJobType] = useState("");

  const {
    updateRider: { loading, file },
    rider: { data, loading: riderLoading },
  } = useSelector((state) => state.riderCrud);

  const onFinish = (values) => {
    values.file = file;
    if (values.date_of_birth) {
      values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    }
    values.cb = () => form.resetFields();
    values.id = id;

    actions.updateRiderHandler(values);
  };

  useEffect(() => {
    if (hubs?.length === 0) {
      actions.fetchHubs({ select: ["name"] });
    }

    actions.getRider({
      id,
      cb: (query) => {
        query.select([
          "name",
          "username",
          "phone",
          "email",
          "date_of_birth",
          "image",
          "riderHub.name",
          "nidNumber",
          "jobType",
          "vehicleType",
          "employeeType",
          "riderDutyHour",
          "riderBasic",
        ]);
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      let {
        name,
        username,
        phone,
        email,
        image,
        riderHub,
        nidNumber,
        jobType,
        vehicleType,
        employeeType,
        date_of_birth,
        riderDutyHour,
        riderBasic,
      } = data;

      if (date_of_birth) {
        date_of_birth = moment(
          date_of_birth.iso ? new Date(date_of_birth.iso) : date_of_birth,
          "YYYY-MM-DD"
        );
      }

      form.setFieldsValue({
        name,
        username,
        phone,
        email,
        image,
        riderHub: riderHub?.objectId,
        nidNumber,
        jobType,
        vehicleType,
        employeeType,
        date_of_birth,
        riderDutyHour,
        riderBasic,
      });

      setJobType(jobType);
    }
  }, [data]);

  if (riderLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Tag color="green">Loading...</Tag>
      </div>
    );
  }

  return (
    <Wrapper md={18} lg={16} xl={12}>
      <div className="header">
        <div className="left-section">
          <h3 className="title">Update Rider</h3>
        </div>
      </div>
      <Form
        form={form}
        size="large"
        layout="vertical"
        name="addUser"
        onFinish={onFinish}
      >
        <Boxer open={true}>
          <PrimaryItems isUpdate={true} updatePassword={updatePassword}>
            <Button
              style={{ marginTop: "10px" }}
              onClick={() => setUpdatePassword(!updatePassword)}
              danger={updatePassword}
            >
              {updatePassword ? "Close" : "Show"} Update Password
            </Button>
          </PrimaryItems>
          {data && (
            <BehavioralItems
              jobType={jobType}
              setJobType={setJobType}
              update={true}
              image={data.image}
            />
          )}
        </Boxer>
        <div className="footer">
          <Button
            loading={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            Submit
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .section {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    min-height: 450px;
    border-radius: 10px;
  }

  .header {
    padding: 20px 0;
    .left-section {
      display: flex;
      align-items: center;

      .title {
        font-size: 22px;
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    line-height: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .profile-picture {
    position: relative;

    img {
      height: 150px;
      width: 150px;
      border-radius: 10px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }

  .footer {
    padding: 20px 0;
    margin-top: 10px;
  }
`;
