import { createContext, useReducer, useEffect } from "react";
import Components from "../../components/product/Sales";
import { useParams } from "react-router";
import { message } from "antd";
import Parse from "parse";

export const ProductSalesContext = createContext();

const Types = {
  GET_ITEM_REQUEST: "GET_ITEM_REQUEST",
  GET_ITEM_SUCCESS: "GET_ITEM_SUCCESS",
  GET_ITEM_FAILURE: "GET_ITEM_FAILURE",
  GET_REPORT_REQUEST: "GET_REPORT_REQUEST",
  GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
  GET_REPORT_FAILURE: "GET_REPORT_FAILURE",
};

const initialState = {
  loading: false,
  data: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_REPORT_REQUEST:
      state.loading = true;
      return { ...state };
    case Types.GET_REPORT_SUCCESS:
      state.loading = false;
      state.data = action.payload;
      return { ...state };
    case Types.GET_REPORT_FAILURE:
      state.loading = false;
      state.data = [];
      return { ...state };
    default:
      return state;
  }
};

export default function ProductSalesProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams();

  const fetchData = async (params) => {
    try {
      dispatch({ type: Types.GET_REPORT_REQUEST });
      const res = await Parse.Cloud.run("productSalesHistory", params);
      dispatch({ type: Types.GET_REPORT_SUCCESS, payload: res });
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.GET_REPORT_FAILURE });
    }
  };

  return (
    <div>
      <Components id={id} fetchData={fetchData} {...state} />
    </div>
  );
}
