import { useContext, useState } from "react"
import { UserListContext } from "../../pages/User/UserList";
import { Modal, Form, Input, Button, Space } from 'antd'
import Parse from 'parse'

const { Item } = Form

export default function SentNotification() {
    const { selectedRows } = useContext(UserListContext)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onSubmit = async (values) => {
        try{
            setLoading(true)
            const res = await Parse.Cloud.run('sentNotification', {
                userIds: selectedRows.map(item => item.id),
                notification: values 
            })
            if(res){
                setVisible(false)
                form.resetFields()
            }
        }catch (err){
            setLoading(false)
        }
    }

    return (
        <>
            <Button onClick={() => setVisible(true)} size="large" type="primary">Sent Notification</Button>
            <Modal
                title="Sent Notification"
                visible={visible}
                onOk={onSubmit}
                confirmLoading={loading}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <Form 
                    form={form}
                    layout="vertical"
                    name="sent-notification"
                    onFinish={onSubmit}
                >
                    <Item name="title" label="Title" rules={[{
                        required: true,
                    }]}>
                        <Input size="large" placeholder="Title" />
                    </Item>
                    <Item name="body" label="Body" rules={[{
                        required: true,
                    }]}>
                        <Input.TextArea placeholder="Message" rows={4} />
                    </Item>
                    <Item>
                        <Space>
                            <Button onClick={() => setVisible(false)} danger>Cancel</Button>
                            <Button type="primary" htmlType="submit">Send</Button>
                        </Space>
                    </Item>
                </Form>
            </Modal>
        </>
    )
}