import { useContext } from "react";
import { OrderListContext } from "../../../pages/Order/OrderList";
import { AuthContext } from "../../../AuthProvider";
import { Button, message, Popconfirm, Space, Tag } from "antd";
import styled from "styled-components";

export default function TopBar() {
  const {
    login: { data },
  } = useContext(AuthContext);
  const { deleteOrders, selectedItems } = useContext(OrderListContext);

  const deleteHandler = () => {
    if (data.type !== "admin") {
      message.info("Only admin can delete orders");
    } else {
      const sensitiveItem = selectedItems.find((item) =>
        [
          "delivered",
          "rejected",
          "preparing",
          "picked",
          "ready",
          "created",
        ].includes(item.status)
      );
      if (sensitiveItem) {
        message.info(
          `You can't delete created, delivered, preparing, rejected, picked or ready order`
        );
      } else {
        deleteOrders({
          ids: selectedItems
            ?.filter((item) => item.status === "cancelled")
            .map((item) => item.id),
        });
      }
    }
  };

  return selectedItems.length ? (
    <Wrapper>
      <Space>
        <Space style={{ borderRight: "1px solid #000", paddingRight: 10 }}>
          <strong>Selected</strong>
          <Tag>{selectedItems.length}</Tag>
        </Space>
        <Popconfirm
          title="Are you sure you want to delete selected orders?"
          onConfirm={deleteHandler}
          okText="Yes"
        >
          <Button danger>Delete selected items</Button>
        </Popconfirm>
      </Space>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  background: #fff;
  padding: 10px 20px;
`;
