import { Table } from "antd";

export default function RestaurantsReportTable({ data }) {
  const columns = [
    {
      title: "Restaurant name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order count",
      dataIndex: "order",
      key: "order",
    },
    {
      title: "Confirmation count",
      dataIndex: "confirmed",
      key: "confirmed",
    },
    {
      title: "Average Confirmation Time",
      dataIndex: "avg_confirm_time",
      key: "confirmation",
      sorter: (a, b) => a.avg_confirm_time - b.avg_confirm_time,
    },
    {
      title: "Prep count",
      dataIndex: "prefCount",
      key: "prepCount",
    },
    {
      title: "Average Prep Time",
      dataIndex: "avg_pref_time",
      key: "pref",
      sorter: (a, b) => a.avg_pref_time - b.avg_pref_time,
    },
  ];

  return (
    <section style={{ padding: "20px", marginTop: "20px" }}>
      <div>last 1000 orders</div>
      <h1>Restaurants Report</h1>
      <Table columns={columns} dataSource={data} pagination={false} />
    </section>
  );
}
