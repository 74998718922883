import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { uploadFile, getSignedUrl } from "../../api/media";
import Parse from "parse";

const initialState = {
  addRider: {
    loading: false,
    data: null,
    file: null,
  },
  updateRider: {
    loading: false,
    data: null,
    file: null,
  },
  rider: {
    loading: false,
    data: null,
  },
};

export const addRiderHandler = createAsyncThunk(
  "riderCrud/addRider",
  async (values) => {
    const { file, cb, ...params } = values;
    if (file) {
      const { url } = await getSignedUrl(file.type);
      await uploadFile({ url, file });
      params.image = url.split("?")[0];
    }
    const res = await Parse.Cloud.run("newRider", params);
    if (typeof cb === "function") {
      cb(res);
    }
    return res;
  }
);

export const updateRiderHandler = createAsyncThunk(
  "riderCrud/updateRider",
  async (values) => {
    const { file, cb, ...params } = values;

    if (file) {
      const { url } = await getSignedUrl(file.type);
      await uploadFile({ url, file });
      params.image = url.split("?")[0];
    }

    const res = await Parse.Cloud.run("updateRider", params);
    if (typeof cb === "function") {
      cb(res);
    }
    return res;
  }
);

export const getRider = createAsyncThunk(
  "riderCrud/getRider",
  async (params) => {
    const { id, cb } = params || {};
    const query = new Parse.Query("User").equalTo("objectId", id);
    if (typeof cb === "function") {
      cb(query);
    }
    return await query.first();
  }
);

const riderCrudSlices = createSlice({
  name: "riderCrud",
  initialState,
  reducers: {
    saveFile: (state, action) => {
      state.addRider.file = action.payload;
    },
    saveFileUpdate: (state, action) => {
      state.updateRider.file = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(addRiderHandler.pending, (state) => {
      state.addRider.loading = true;
    });
    builder.addCase(addRiderHandler.fulfilled, (state) => {
      state.addRider.loading = false;
      state.addRider.file = null;
      message.success("Rider added successfully");
    });
    builder.addCase(addRiderHandler.rejected, (state, action) => {
      state.addRider.loading = false;
      message.error(action.error.message);
    });

    // Get rider
    builder.addCase(getRider.pending, (state) => {
      state.rider.loading = true;
    });
    builder.addCase(getRider.fulfilled, (state, action) => {
      state.rider.loading = false;
      if (!action.payload) {
        message.error("Rider not found");
        return;
      }
      state.rider.data = action.payload.toJSON();
    });
    builder.addCase(getRider.rejected, (state, action) => {
      state.rider.loading = false;
      message.error(action.error.message);
    });

    // Update rider
    builder.addCase(updateRiderHandler.pending, (state) => {
      state.updateRider.loading = true;
    });
    builder.addCase(updateRiderHandler.fulfilled, (state, action) => {
      state.updateRider.loading = false;
      state.rider.data = action.payload.toJSON();
      message.success("Rider updated successfully");
    });
    builder.addCase(updateRiderHandler.rejected, (state, action) => {
      state.updateRider.loading = false;
      message.error(action.error.message);
    });
  },
});

export default riderCrudSlices.reducer;

export const { saveFile, saveFileUpdate } = riderCrudSlices.actions;
