import { List, Col, Tag, Row, Space } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

const variantTotal = (variant) => {
  if (Array.isArray(variant) && variant.length > 0) {
    return variant.reduce((acc, current) => {
      current.items.forEach((item) => {
        acc += item.price;
      });
      return acc;
    }, 0);
  } else {
    return 0;
  }
};

export default function InvoiceOrders({ orders }) {
  return (
    <Wrapper span={24}>
      <List
        size="large"
        dataSource={orders}
        bordered
        itemLayout="vertical"
        renderItem={({
          orderId,
          time,
          total,
          orderNumber,
          promo,
          items = [],
          refund,
          penalty,
          issue,
        }) => {
          const getDiscount = (items) => {
            let discount = 0;
            items.forEach((item) => {
              discount += item.discount ?? 0;
              if (item.promoDiscount && promo) {
                discount += item.promoDiscount;
              }
            });
            return discount;
          };

          return (
            <List.Item className="list-item">
              <div className="order-id">
                #{" "}
                <Link
                  style={{ color: "black" }}
                  target="_blank"
                  to={`/order/list/${orderId}`}
                >
                  {orderId} ({orderNumber})
                </Link>{" "}
                {promo ? <Tag color="gold">{promo}</Tag> : ""}
              </div>
              <div className="time">{new Date(time).toLocaleString()}</div>
              {penalty && (
                <Space className="penalty">
                  <strong>Penalty:</strong>
                  <Tag color="red">{penalty} tk</Tag>
                </Space>
              )}
              {issue && (
                <div className="issue">
                  <Space className="title">
                    <strong>Issue:</strong>
                    <Tag color="red">{issue.type}</Tag>
                  </Space>
                  <div className="details">{issue.details}</div>
                </div>
              )}
              <div className="total">{total - getDiscount(items)} tk</div>
              <div style={{ marginTop: "10px" }}>
                {items.map((item, i) => {
                  return (
                    <div key={i}>
                      <div className="item">
                        <div className="counter">{i + 1}</div>
                        <Row style={{ width: "100%" }}>
                          <Col
                            lg={10}
                            md={16}
                            sm={20}
                            span={24}
                            className="item-name"
                          >
                            <div>
                              {item.name}
                              {item.variant && item.variant.length > 0 && (
                                <span>
                                  {item.variant
                                    .map((i) =>
                                      i.items.map((a) => a.name).join(" ")
                                    )
                                    .join("-")}
                                </span>
                              )}
                            </div>
                            {item.refund?.quantity && (
                              <div>
                                Refund:{" "}
                                <Tag color="green">
                                  {item.refund.quantity ?? 0}
                                </Tag>{" "}
                                item {item.refund.quantity > 1 ? "s" : ""}
                                <Tag color="red">{item.refund.total}</Tag> tk
                              </div>
                            )}
                            {item.refund?.addons?.length > 0 && (
                              <div>
                                <strong>Refund addons: </strong>
                                <div>
                                  {item.refund.addons.map((addon) => (
                                    <Space>
                                      <strong>{addon.name} - </strong>
                                      <div>
                                        {addon.quantity} X {addon.price} ={" "}
                                      </div>
                                      <Tag color="green">{addon.total} tk</Tag>
                                    </Space>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Col>
                          <Col className="item-price">
                            {item.quantity} x{" "}
                            {item.sale_unit + variantTotal(item.variant)} tk
                            {item.discount || (promo && item.promoDiscount) ? (
                              <span style={{ paddingLeft: "10px" }}>
                                - {item.discount + (item.promoDiscount ?? 0)} tk
                              </span>
                            ) : null}
                            - {item.revenue} tk
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
              </div>
            </List.Item>
          );
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Col)`
  .list-item {
    background-color: #fafafa;
    position: relative;
    min-height: 100px;

    .order-id {
      font-size: 14px;
      font-weight: bold;
    }

    .total {
      position: absolute;
      right: 0;
      top: 45px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      height: 30px;
      margin-right: 10px;
      background: #cfd8dc;
      border-radius: 30px;
    }

    .time {
      position: absolute;
      top: 0;
      right: 0;
      margin: 16px;
      font-size: 14px;
      font-weight: 500;
    }

    .item {
      margin-bottom: 5px;
      display: flex;

      .counter {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #cfd8dc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        margin-right: 10px;
      }

      .item-name {
        font-weight: 500;
      }
    }
  }
`;
