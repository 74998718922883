import { useState, useEffect } from "react";
import {
  Table,
  Space,
  message,
  Menu,
  Dropdown,
  Tag,
  Switch,
  Drawer,
  Button,
} from "antd";
import useRestaurants from "../../hooks/useRestaurants";
import { parser } from "../../utils";
import {
  ExpandOutlined,
  SettingOutlined,
  StarFilled,
  UserOutlined,
} from "@ant-design/icons";
import useSearch from "../../components/inventory/utils/useSearch";
import { compressedImage } from "../../common/utils";
import RestaurantLinks, {
  MenuItems,
} from "../../components/Common/RestaurantLinks";
import styled from "styled-components";
import Parse from "parse";
import { useHistory } from "react-router-dom";
import { getParams } from "../../common/utils";
import { Link } from "react-router-dom";
import AddNew from "./AddNew";
import axios from "axios";

const Availability = ({ object }) => {
  const [availability, setAvailability] = useState(object?.get("availability"));
  const [loading, setLoading] = useState(false);
  return (
    <Switch
      checked={availability}
      disabled={loading}
      onChange={async (e) => {
        try {
          if (object instanceof Parse.Object) {
            setAvailability(e);
            setLoading(true);
            object.set("availability", e);
            const res = await object.save(null, {
              sessionToken: Parse.User.current()?.getSessionToken(),
            });

            if (res) {
              message.success(
                `Availability updated to ${e ? "Available" : "Unavailable"}`
              );
            }

            setLoading(false);
            if (!res) {
              setAvailability(!e);
            }
          }
        } catch (err) {
          setLoading(false);
          setAvailability(!e);
          message.error(err.message);
        }
      }}
    />
  );
};

function Review({ resId }) {
  const [reviews, setReviews] = useState({
    data: [],
    loading: false,
    rating: {},
  });

  const fetchData = async (id) => {
    try {
      setReviews((prev) => ({ ...prev, loading: true }));
      const { data } = await axios.get(
        `https://apiv2.munchies.com.bd/api/rating/restaurant/${id}`
      );
      setReviews(data);
    } catch (err) {
      message.error(err.message);
      setReviews((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    fetchData(resId);
  }, [resId]);

  return (
    <div>
      {reviews.loading && <p>Loading...</p>}
      {reviews.data?.map((review, idx) => {
        return (
          <ReviewItem key={idx}>
            <div className="wrap">
              <h3 className="name">
                <div className="icon">
                  <UserOutlined />
                </div>
                <div>{review.userName}</div>
                {/* <div style={{ marginLeft: "auto" }}>
                  <Switch checked={review.isActive} />
                </div> */}
              </h3>
              {review.review && <div>{review.review}</div>}
              <div>
                {[1, 2, 3, 4, 5].map((n) => (
                  <StarFilled
                    key={n}
                    style={{ color: review.rating >= n ? "#faad14" : "gray" }}
                  />
                ))}
              </div>
              <div className="time">
                {new Date(review.createdAt).toLocaleString()}
              </div>
            </div>
          </ReviewItem>
        );
      })}
    </div>
  );
}

const ReviewItem = styled.div`
  padding: 5px 10px;
  .wrap {
    background: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px;

    .name {
      display: flex;
      align-items: center;
      .icon {
        background: #bfbfbf;
        color: black;
        padding: 5px;
        border-radius: 50%;
        margin-right: 10px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .time {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export default function List() {
  const { getRestaurants } = useRestaurants();
  const [restaurants, setRestaurants] = useState({
    count: 0,
    results: [],
  });
  const [, getColumnsSearchProps] = useSearch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = getParams(window.location.search);
  const [restaurant, setRestaurant] = useState(null);
  const [review, setReview] = useState(null);
  const [hubs, setHubs] = useState([]);
  let [reqPayloads, setReqPayloads] = useState({
    limit: 100,
    skip: 0,
    select: [
      "name",
      "commission_type",
      "type",
      "sorting_order",
      "banner_image",
      "cuisines",
      "availability",
      "commission",
      "counter",
      "phone",
      "hub.name",
      "rating",
    ],
    sortBy: "sorting_order",
    ...params,
  });

  const fetchRestaurants = (params) => {
    setLoading(true);
    getRestaurants(params, (err, data) => {
      if (data) {
        setRestaurants({
          count: data.count,
          results: parser(data.results),
        });
      }

      if (err) {
        message.error(err);
      }
      setLoading(false);
    });
  };

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubs(parser(hubs));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  useEffect(() => {
    fetchRestaurants(reqPayloads);
    const searchParams = {};

    const { select, ...params } = reqPayloads;

    for (let i in params) {
      if (params[i] !== undefined && params[i] !== null) {
        if (!Array.isArray(params[i])) {
          searchParams[i] = params[i];
        } else if (Array.isArray(params[i]) && params[i].length > 0) {
          searchParams[i] = params[i];
        }
      }
    }

    for (let i in searchParams) {
      if (Array.isArray(searchParams[i])) {
        searchParams[i] = JSON.stringify(searchParams[i]);
      }
    }
    history.push(
      `/restaurant/list?${new URLSearchParams(searchParams).toString()}`
    );
  }, [reqPayloads]);

  const Text = ({ children }) => (
    <span style={{ fontSize: "16px", fontWeight: "500", color: "black" }}>
      {children}
    </span>
  );

  const filterFormate = (value) => {
    return Array.isArray(value) ? value : value ? [value] : value;
  };

  const columns = [
    {
      title: "Banner",
      dataIndex: "banner_image",
      key: "banner_image",
      width: "150px",
      render: (image, { id }) => (
        <div className="b-image">
          <img src={compressedImage(image)} width={100} height={75} alt="" />
          <div className="icon" onClick={() => setRestaurant(id)}>
            <ExpandOutlined className="ex" />
          </div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      defaultFilteredValue: filterFormate(reqPayloads.name),
      className: "drag-visible",
      ...getColumnsSearchProps("name"),
      render: (text, { id }) => {
        return (
          <>
            <RestaurantLinks id={id}>
              <Text>{text}</Text>
            </RestaurantLinks>
          </>
        );
      },
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      width: "160px",
      filters: hubs.map((hub) => ({
        text: hub.name,
        value: hub.id,
      })),
      render: (hub) => (
        <Link to={`/hub/create-new?id=${hub?.id}`}>
          <div style={{ fontSize: "14px", color: "gray" }}>
            {hub?.get("name")}
          </div>
        </Link>
      ),
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
      width: "150px",
      defaultFilteredValue: filterFormate(reqPayloads.availability),
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.availability === value,
      render: (availability, { ref }) => <Availability object={ref} />,
    },
    {
      title: "Commission",
      dataIndex: "commission",
      key: "commission",
      width: "150px",
      sorter: (a, b) => a.commission - b.commission,
      render: (text, { commission_type: cty }) => (
        <Space>
          {text}{" "}
          <Tag color={cty === "flat" ? "green" : "orange"}>
            {cty === "percentage" ? "%" : cty}
          </Tag>
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "150px",
      filters: [
        { text: "Restaurant", value: "restaurant" },
        { text: "Store", value: "store" },
        { text: "Sub store", value: "sub_store" },
      ],
      onFilter: (value, record) => record.type === value,
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "200px",
      defaultFilteredValue: filterFormate(reqPayloads.phone),
      ...getColumnsSearchProps("phone"),
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      width: "150px",
      render: (rating, { id }) =>
        rating?.value > 0 ? (
          <Space direction="vertical">
            <div>
              Rating: {(rating.value / rating.count).toFixed(2)} /{" "}
              {rating.count}
            </div>
            <Button onClick={() => setReview(id)}>Review</Button>
          </Space>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Action",
      key: "action",
      className: "drag-visible",
      width: "100px",
      render: (_, { id }) => (
        <div>
          <Dropdown
            arrow
            placement="bottom"
            trigger={["click", "contextMenu"]}
            overlay={() => (
              <Menu
                theme="dark"
                style={{ width: "160px", textAlign: "center" }}
              >
                <MenuItems id={id} />
              </Menu>
            )}
          >
            <SettingOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <Table
        dataSource={Array.from(restaurants.results)}
        columns={columns}
        loading={loading}
        pagination={{
          defaultPageSize: reqPayloads.limit,
          current: reqPayloads.skip / reqPayloads.limit + 1,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: restaurants.count,
          pageSizeOptions: ["10", "20", "50", "100", "200"],
          position: ["topLeft", "topRight"],
        }}
        rowKey="sorting_order"
        scroll={{ x: 1200 }}
        onChange={(pagination, filters) => {
          const params = {
            ...reqPayloads,
            limit: pagination.pageSize,
            skip: pagination.pageSize * (pagination.current - 1),
            name: filters.name ? filters.name[0] : undefined,
            phone: filters.phone ? filters.phone[0] : undefined,
            availability: filters.availability
              ? filters.availability
              : undefined,
            cuisines: filters.cuisines ? filters.cuisines[0] : undefined,
            hub: filters.hub,
          };
          setReqPayloads(params);
        }}
      />

      <Drawer
        title="Restaurant"
        placement="right"
        visible={restaurant}
        onClose={() => setRestaurant(null)}
        width={1200}
      >
        {restaurant && <AddNew resId={restaurant} />}
      </Drawer>
      <Drawer
        title="Review"
        placement="right"
        visible={review}
        onClose={() => setReview(null)}
        width={500}
      >
        {review && <Review resId={review} />}
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .b-image {
    position: relative;
    width: 100px;
    height: 75px;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      .ex {
        top: 0;
        left: 0;
        font-size: 30px;
      }
      color: #fff;
    }

    &:hover {
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
