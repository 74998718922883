import { useContext } from "react";
// import { AnimateSharedLayout } from 'framer-motion';
import { Switch } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout";
import AdminLayout from "./components/layout";

import { AuthContext } from "./AuthProvider";

import AuthMiddleware from "./routes/middleware/Authmiddleware";

const permissableRoutes = (routes, pages, type) => {
  return routes.filter((route) => {
    return type === "admin" || pages.includes(route.key);
  });
};

const App = () => {
  const {
    login: { data, loading },
    role,
  } = useContext(AuthContext);

  return (
    !loading && (
      <div>
        <Switch>
          {!data && (
            <NonAuthLayout>
              {authRoutes.map((props, idx) => {
                return <AuthMiddleware key={idx} {...props} />;
              })}
            </NonAuthLayout>
          )}

          {["admin", "manager", "csr", "product manager"].includes(
            data?.type
          ) && (
            <AdminLayout>
              {permissableRoutes(
                userRoutes,
                role?.access?.pages ?? [],
                data.type
              ).map((props, idx) => {
                return (
                  <AuthMiddleware
                    key={idx}
                    data={data}
                    isAuthProtected={true}
                    {...props}
                  />
                );
              })}
            </AdminLayout>
          )}
        </Switch>
      </div>
    )
  );
};

export default App;
