import { useContext, useState, useEffect, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Space,
  Button,
  Skeleton,
  Dropdown,
  Menu,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { HubsContext } from "./HubsProvider";
import Styled from "styled-components";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

function AddNewBrand({ brands, id, object, setAdd }) {
  const [form] = Form.useForm();
  const {
    restaurants,
    hubs: { data },
    updateHubs,
  } = useContext(HubsContext);

  const onFinish = async ({ brands }) => {
    const hub = data.results.find((h) => h.id === id);
    const items = restaurants.filter((r) => brands.includes(r.objectId));
    hub.brands.push(...brands);
    hub.brandsObj.push(...items);
    updateHubs(data);
    form.resetFields();
    setAdd(false);
  };

  const filterAvailableItems = (data) => {
    if (!Array.isArray(data)) return [];
    return data.filter((item) => !brands.includes(item.objectId));
  };

  return (
    <Form
      form={form}
      style={{ width: "100%" }}
      layout="vertical"
      className="add-new-brand"
      onFinish={onFinish}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={[16]}>
        <Col span={18}>
          <Form.Item
            name="brands"
            rules={[{ required: true, message: "Please select a restaurant" }]}
            style={{ marginBottom: "10px", width: "100%" }}
          >
            <Select
              showSearch
              placeholder="Select a restaurant"
              style={{ width: "100%" }}
              optionFilterProp="children"
              size="large"
              autoFocus
              allowClear
              mode="multiple"
            >
              {restaurants &&
                filterAvailableItems(restaurants).map(
                  ({ name, objectId, hub }, i) => (
                    <Select.Option key={i} value={objectId}>
                      {name} ({hub?.name})
                    </Select.Option>
                  )
                )}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button
            size="large"
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Add
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function BrandItem({ objectId, name, banner_image, hub, hubId, idx }) {
  const { deleteBrandItem } = useContext(HubsContext);

  return (
    <Draggable draggableId={`${hubId}-${objectId}`} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="droppable-item"
        >
          <div className="body">
            <img className="image" src={banner_image} alt={name} />
            <div>
              <h3 className="name">{name}</h3>
              <p className="hub-name">{hub.name}</p>
            </div>
            <Button
              className="close"
              icon={<CloseOutlined />}
              onClick={() => deleteBrandItem(hubId, idx)}
              shape="circle"
              danger
              size="small"
            ></Button>
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

function BrandsSkeleton() {
  const ref = useRef();

  const Item = ({ width }) => (
    <Skeleton.Input
      style={{
        width,
        height: "60px",
        borderRadius: "10px",
        marginBottom: "10px",
      }}
      active
      size="large"
    />
  );

  return (
    <div ref={ref} style={{ marginTop: "20px", overflow: "hidden" }}>
      <Item
        width={ref?.current?.clientWidth ? ref?.current?.clientWidth - 10 : 300}
      />
      <Item
        width={ref?.current?.clientWidth ? ref?.current?.clientWidth - 10 : 300}
      />
      <Item
        width={ref?.current?.clientWidth ? ref?.current?.clientWidth - 10 : 300}
      />
      <Item
        width={ref?.current?.clientWidth ? ref?.current?.clientWidth - 10 : 300}
      />
      <Item
        width={ref?.current?.clientWidth ? ref?.current?.clientWidth - 10 : 300}
      />
    </div>
  );
}

function Hub(props) {
  const { id, name, object, brands, brandsObj, idx } = props;
  const {
    updateHub,
    hubs: { data },
    restaurants,
    updateHubs,
  } = useContext(HubsContext);
  const [add, setAdd] = useState(false);

  const countResInHub = useCallback((res, hubId, list) => {
    let total = 0;
    let exists = [];
    let notExists = [];

    res.forEach((r) => {
      if (r.hub.objectId === hubId) {
        total++;
        if (list.includes(r.objectId)) {
          exists.push(r);
        } else {
          notExists.push(r);
        }
      }
    });

    return { total, notExists, exists };
  }, []);

  return (
    <Col span={24} md={12} xl={8}>
      <div className="hub">
        <div className="hub-header">
          <h4 className="name">{name}</h4>
          <Space>
            <Button
              shape="circle"
              icon={
                <Link
                  target="__blank"
                  style={{ padding: "0 5px" }}
                  to={`/hub/create-new?id=${id}`}
                >
                  <EditOutlined />
                </Link>
              }
            ></Button>
            <Dropdown
              placement="bottom"
              trigger={["click"]}
              overlay={() => (
                <Menu>
                  {data.results.map((hub, i) => {
                    const { total, exists, notExists } = countResInHub(
                      restaurants,
                      hub.id,
                      brands
                    );
                    return (
                      <Menu.Item key={i}>
                        <Space>
                          {total && notExists.length ? (
                            <Button
                              onClick={() => {
                                brandsObj.push(...notExists);
                                brands.push(
                                  ...notExists.map(({ objectId }) => objectId)
                                );
                                if (data.results[idx]) {
                                  data.results[idx].brands = brands;
                                  data.results[idx].brandsObj = brandsObj;
                                  updateHubs(data);
                                }
                              }}
                              size="small"
                              icon={<PlusOutlined />}
                              shape="circle"
                            />
                          ) : null}
                          {total && exists.length ? (
                            <Button
                              onClick={() => {
                                const ids = exists.map(
                                  ({ objectId }) => objectId
                                );
                                if (data.results[idx]) {
                                  data.results[idx].brands = brands.filter(
                                    (id) => !ids.includes(id)
                                  );
                                  data.results[idx].brandsObj =
                                    brandsObj.filter(
                                      ({ objectId }) => !ids.includes(objectId)
                                    );
                                  updateHubs(data);
                                }
                              }}
                              size="small"
                              icon={<MinusOutlined />}
                              shape="circle"
                            />
                          ) : null}
                          <span>{hub.name}</span>
                        </Space>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              )}
            >
              <Button icon={"H"} shape="circle"></Button>
            </Dropdown>
            <Button
              onClick={() => setAdd(!add)}
              icon={<PlusOutlined />}
              shape="circle"
            ></Button>
            <Button
              onClick={() => updateHub({ ...props, ref: object })}
              icon={<CheckOutlined />}
              shape="circle"
              title="save"
            ></Button>
          </Space>
        </div>
        {add && <AddNewBrand {...props} setAdd={setAdd} />}
        <div>
          <Droppable droppableId={id}>
            {(provided) => {
              return (
                <div
                  className="droppable customScroll"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {brandsObj?.map((brand, i) => (
                    <BrandItem
                      key={brand.objectId}
                      idx={i}
                      hubId={id}
                      {...brand}
                    />
                  ))}
                  {!brandsObj && <BrandsSkeleton />}
                </div>
              );
            }}
          </Droppable>
        </div>
      </div>
    </Col>
  );
}

export default function Hubs() {
  const {
    hubs: { loading, data },
    updateHubs,
    fetchHubs,
  } = useContext(HubsContext);

  useEffect(() => {
    if (data.results?.length === 0) {
      fetchHubs({}, true);
    }
  }, []);

  const onDragEnd = (props) => {
    const { destination, source } = props;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    )
      return;

    const hub = data.results.find((hub) => hub.id === source.droppableId);
    if (hub) {
      const item = hub.brandsObj[source.index];
      hub.brandsObj.splice(source.index, 1);
      hub.brandsObj.splice(destination.index, 0, item);
      updateHubs(data);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper span={24}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row gutter={[16, 24]}>
          {data.results?.map((hub, i) => (
            <Hub key={hub.id} idx={i} {...hub} object={hub.ref} />
          ))}
        </Row>
      </DragDropContext>
    </Wrapper>
  );
}

const Wrapper = Styled(Col)`

    @keyframes fadeIn {
        from {
            opacity: 0;
            margin-top: -15px;
        }
        to {
            opacity: 1;
            margin-top: 0;
        }
    }

    .hub{
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;

        &-header{
            padding: 5px 0;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .add-new-brand{
            animation: fadeIn .4s ease;
        }
    }

    .droppable{
        height: 400px;
        padding: 0 5px;
        &-item{
            padding: 5px 0px;
            .body{
                background-color: #fff;
                padding: 12px 20px;
                border-radius: 5px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                transition: all 0.3s cubic-bezier(.25,.8,.25,1);
                display: flex;
                align-items: center;
                position: relative;

                .close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

                    &:hover{
                        transform: scale(1.1);
                    }
                }

                .image {
                    width: 60px;
                    height: 40px;
                    border-radius: 10px;
                    margin-right: 10px;
                }

                .name, .hub-name{
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 500;
                }

                .hub-name{
                    font-size: 13px;
                    color: #999;
                }

                &:hover {
                    box-shadow:  0 5px 10px rgba(0,0,0,0.22);
                }
            }
        }
    }
`;
