import { Form, InputNumber, Button, Space, Input } from "antd";
import Layout from "./Layout";
import { DeleteOutlined } from "@ant-design/icons";
import { Box } from "../../Common/Boxer";
import Basic from "./Basic";

const { Item } = Form;

export default function PartTimeRidersCommission() {
  return (
    <Box title="Part-time Riders Commission">
      <Basic employeeType="partTime" />
      <Layout title="Per Order Commission by distance (km)">
        <Item
          label="Cycle"
          name={["earning", "commission", "fullTime", "orderMilage", "cycle"]}
        >
          <InputNumber />
        </Item>
        <h3>Bike</h3>
        <Form.List
          name={["earning", "commission", "fullTime", "orderMilage", "bike"]}
        >
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key}>
                    <Item {...restField} label="Km" name={[name, "range"]}>
                      <Input placeholder="km range (0-5)" />
                    </Item>
                    <Item {...restField} label="Tk" name={[name, "amount"]}>
                      <InputNumber placeholder="amount" />
                    </Item>
                    <Item>
                      <Button
                        onClick={() => {
                          remove(name);
                        }}
                        shape="circle"
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Item>
                  </Space>
                ))}

                <div>
                  <Button
                    onClick={() => {
                      add();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>
            );
          }}
        </Form.List>
      </Layout>
    </Box>
  );
}
