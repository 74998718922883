import { Form, Space, Checkbox, Button, message, Drawer } from "antd";
import { useState, useEffect, useCallback } from "react";
import Parse from "parse";
import { parser } from "../utils";
import styled from "styled-components";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import Boxer, { Box } from "../components/Common/Boxer";
import CreateRole from "./Config/Roles";

const pages = {
  dashboard: {
    title: "Dashboard",
    key: "dashboard",
    components: [
      { title: "Statistics", key: "statistics" },
      { title: "Sold Items", key: "sold_items" },
      { title: "Restaurants", key: "restaurants" },
    ],
  },
  role_management: {
    title: "Role Management",
    key: "role_management",
    components: [],
  },
  download_payments: {
    title: "Download Payments",
    key: "download_payments",
    components: [],
  },
  rider_restaurant_report: {
    title: "Rider & Restaurant Reports",
    key: "rider_restaurant_report",
    components: [
      { title: "Rider Reports", key: "rider_reports" },
      { title: "Restaurant Reports", key: "restaurant_reports" },
      { title: "Area wise Delivery Time", key: "area_wise_delivery_time" },
    ],
  },
  searches: {
    title: "Searches",
    key: "searches",
    components: [
      { title: "Search Query By Customer", key: "search_query_table" },
    ],
  },
  order_dashboard: {
    title: "Order Dashboard",
    key: "order_dashboard",
    components: [{ title: "Order Dashboard", key: "order_report" }],
  },
  order_issues: {
    title: "Order Issues",
    key: "order_issues",
    components: [],
  },
  order_processor: {
    title: "Order Processor",
    key: "order_processor",
    components: [
      { title: "Orders", key: "orders" },
      { title: "Status Update", key: "status_update" },
      { title: "Rider Assign", key: "rider_assign" },
      { title: "Order Capacity Update", key: "order_capacity_update" },
    ],
  },
  order_list: {
    title: "Order List",
    key: "order_list",
    components: [{ title: "Order Table", key: "order_table" }],
  },
  order_details: {
    title: "Order Details",
    key: "order_details",
    components: [
      { title: "Order Status Update", key: "status_update" },
      { title: "Order Item Update", key: "item_update" },
      { title: "Rider Assign", key: "rider_assign" },
      { title: "Order Summery", key: "order_summery" },
      { title: "Payment Method Update", key: "payment_method_update" },
      { title: "Show Customer Info", key: "customer_info" },
      { title: "Customer Info Update", key: "customer_info_update" },
      { title: "Order Timeline", key: "timeline" },
      { title: "Raise Issue", key: "raise_issue" },
    ],
  },
  product_list: {
    title: "Product List",
    key: "product_list",
    components: [
      { title: "Availability Update", key: "availability_update" },
      { title: "Add Discount", key: "add_discount" },
      { title: "Delete Product", key: "delete_product" },
      { title: "Show Inventory", key: "show_inventory" },
    ],
  },
  product_details: {
    title: "Product Details",
    key: "product_details",
    components: [
      { title: "Show Image Section", key: "image" },
      { title: "Show Pricing Section", key: "pricing" },
      { title: "Show Meta Info", key: "meta" },
      { title: "Update", key: "update" },
    ],
  },
  add_product: { title: "Add Product", key: "add_product", components: [] },
  pending_product_approval: {
    title: "Pending Product Approval",
    key: "pending_product_approval",
    components: [],
  },
  product_change_request: {
    title: "Product Update Requests",
    key: "product_change_request",
    components: [],
  },
  categories: {
    title: "Categories",
    key: "categories",
    components: [
      { title: "Create New Category", key: "create_category" },
      { title: "Update Category", key: "update_category" },
      { title: "Delete Category", key: "delete_category" },
      { title: "Update Restriction", key: "update_restriction" },
    ],
  },
  inventory_records: {
    title: "Inventory Records",
    key: "inventory_records",
    components: [],
  },
  product_sales: {
    title: "Product Sales",
    key: "product_sales",
    components: [],
  },
  rider_registration: {
    title: "Rider Registration",
    key: "rider_registration",
    components: [],
  },
  rider_update: {
    title: "Rider Update",
    key: "rider_update",
    components: [],
  },
  rider_profile: {
    title: "Rider Profile",
    key: "rider_profile",
    components: [],
  },
  rider_list: {
    title: "Rider List",
    key: "rider_list",
    components: [],
  },
  rider_attendance: {
    title: "Rider Attendance",
    key: "rider_attendance",
    components: [],
  },
  rider_dashboard: {
    title: "Rider Dashboard",
    key: "rider_dashboard",
    components: [],
  },
  rider_settings: {
    title: "Rider Settings",
    key: "rider_settings",
    components: [],
  },
  rider_dashboard_orders: {
    title: "Single Rider Dashboard Orders",
    key: "rider_dashboard_orders",
    components: [],
  },
  add_user: {
    title: "Add New User",
    key: "add_user",
    components: [],
  },
  otp_store: {
    title: "OTP Store",
    key: "otp_store",
    components: [],
  },
  user_list: {
    title: "User List",
    key: "user_list",
    components: [],
  },
  update_user: {
    title: "Update User",
    key: "update_user",
    components: [],
  },
  sections: {
    title: "Sections",
    key: "sections",
    components: [],
  },
  cuisines: {
    title: "Cuisines",
    key: "cuisines",
    components: [],
  },
  payments: {
    title: "Payments",
    key: "payments",
    components: [],
  },
  invoice_history: {
    title: "Invoice History",
    key: "invoice_history",
    components: [],
  },
  invoice_details: {
    title: "Invoice Details",
    key: "invoice_details",
    components: [],
  },
  restaurant_list: {
    title: "Restaurant List",
    key: "restaurant_list",
    components: [],
  },
  restaurant_sales: {
    title: "Restaurant Sales",
    key: "restaurant_sales",
    components: [],
  },
  add_restaurant: {
    title: "Add New Restaurant",
    key: "add_restaurant",
    components: [],
  },
  update_restaurant: {
    title: "Update Restaurant",
    key: "update_restaurant",
    components: [],
  },
  restaurant_categories: {
    title: "Restaurant Categories",
    key: "restaurant_categories",
    components: [],
  },
  restaurant_payments: {
    title: "Restaurant Payments",
    key: "restaurant_payments",
    components: [],
  },
  restaurant_payment_details: {
    title: "Restaurant Payments Details",
    key: "restaurant_payment_details",
    components: [],
  },
  restaurant_product_upload: {
    title: "Restaurant Product Upload",
    key: "restaurant_product_upload",
    components: [],
  },
  add_inventory: {
    title: "Add Inventory",
    key: "add_inventory",
    components: [],
  },
  update_inventory: {
    title: "Update Inventory",
    key: "update_inventory",
    components: [],
  },
  inventory_history: {
    title: "Inventory History",
    key: "inventory_history",
    components: [],
  },
  add_promo: {
    title: "Add Promo",
    key: "add_promo",
    components: [],
  },
  promo_list: {
    title: "Promo List",
    key: "promo_list",
    components: [],
  },
  carousel: {
    title: "Carousel",
    key: "carousel",
    components: [],
  },
  promotional_banner: {
    title: "Promotional Banner",
    key: "promotional_banner",
    components: [],
  },
  hub_list: {
    title: "Hub List",
    key: "hub_list",
    components: [],
  },
  add_hub: {
    title: "Add Hub",
    key: "add_hub",
    components: [],
  },
  update_hub: {
    title: "Update Hub",
    key: "update_hub",
    components: [],
  },
  area_list: {
    title: "Area List",
    key: "area_list",
    components: [],
  },
  add_survey: {
    title: "Add Survey",
    key: "add_survey",
    components: [],
  },
  survey_list: {
    title: "Survey List",
    key: "survey_list",
    components: [],
  },
  general_config: {
    title: "General Config",
    key: "general_config",
    components: [],
  },
  profile: {
    title: "Profile",
    key: "profile",
    components: [],
  },
  logs: {
    title: "Logs",
    key: "logs",
    components: [],
  },
};

function Role({ data }) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    pages: [],
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (data.access) {
      form.setFieldsValue(data.access);
      setFormData(data.access);
    }
  }, []);

  const onFinish = async (values) => {
    try {
      if (data.ref instanceof Parse.Object) {
        data.ref.set("access", values);
        await data.ref.save(null, {
          sessionToken: Parse.User.current().sessionToken,
        });
        message.success("Role Updated");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    const newFormData = form.getFieldsValue();
    for (let i in newFormData) {
      if (newFormData[i] === undefined) {
        newFormData[i] = [];
      }
    }
    setFormData(newFormData);
  };

  const allSelect = useCallback(
    (page, key) => {
      if (
        page.components?.length &&
        formData[key]?.length !== page.components.length
      ) {
        const newData = {
          ...formData,
          [key]: page.components.map((i) => i.key),
        };
        form.setFieldsValue(newData);
        setFormData(newData);
      } else if (
        page.components?.length &&
        formData[key]?.length === page.components.length
      ) {
        const newData = {
          ...formData,
          [key]: [],
        };
        form.setFieldsValue(newData);
        setFormData(newData);
      }
    },
    [formData]
  );

  return (
    <Box title={data.name} metaTitle="role">
      <div className="role">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div style={{ textAlign: "right", marginBottom: "20px" }}>
            <Space>
              <Button
                title="Add New Page"
                onClick={showDrawer}
                icon={<PlusOutlined />}
                shape="circle"
                size="small"
              />
              <Button
                htmlType="submit"
                icon={<CheckOutlined />}
                shape="circle"
                style={{ color: "green" }}
                size="small"
              />
            </Space>
          </div>
          <div className="role-content customScroll">
            <Drawer
              title={
                <span style={{ textTransform: "capitalize" }}>
                  {data.name} Pages
                </span>
              }
              placement="right"
              onClose={onClose}
              visible={visible}
              getContainer={false}
            >
              <Form.Item name="pages">
                <Checkbox.Group>
                  <Space direction="vertical">
                    {Object.entries(pages).map(([key, value]) => {
                      return (
                        <Checkbox key={key} value={key}>
                          {value.title}
                        </Checkbox>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Drawer>
            {formData.pages?.map((key, i) => {
              const page = pages[key];

              return (
                <Form.Item
                  key={i}
                  name={key}
                  label={
                    <span
                      onClick={() => {
                        allSelect(page, key);
                      }}
                    >
                      {page.title}
                    </span>
                  }
                >
                  {page.components && (
                    <Checkbox.Group>
                      <Space
                        direction="vertical"
                        style={{ marginLeft: "16px" }}
                      >
                        {page.components.map(({ title, key }, i) => (
                          <Checkbox key={i} value={key}>
                            {title}
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              );
            })}
          </div>
        </Form>
      </div>
    </Box>
  );
}

export default function RoleAccess() {
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    try {
      const roles = await new Parse.Query("_Role")
        .exists("access")
        .select(["name", "access"])
        .notEqualTo("name", "admin")
        .find({
          sessionToken: Parse.User.current().getSessionToken(),
        });
      setRoles(parser(roles));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <Wrapper>
      <CreateRole />
      <Boxer>
        {roles.map((role, i) => (
          <Role key={i} data={role} />
        ))}
      </Boxer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .role {
    &-content {
      padding: 20px;
      height: 400px;
    }
  }

  .customScroll::-webkit-scrollbar-track {
    background: #fff;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }
`;
