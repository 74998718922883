import { useContext, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { DashboardContext } from "../../pages/Dashboard/DashboardContext";
import Skeleton from "./Skeleton";
import DateHandler from "./DateHandler";
import moment from "moment";
import useOrders from "../../hooks/useOrders";
import Statistics from "./Statistics";
import ProgressReport from "./ProgressReport";
import { Space, Tag } from "antd";

function NotFound() {
  return (
    <div style={{ textAlign: "center", padding: "30px 0" }}>
      <InboxOutlined
        style={{ color: "#c7c3c3", fontSize: "50px", marginBottom: "20px" }}
      />
      <h1 style={{ fontSize: "22px", color: "gray" }}>No sales found</h1>
    </div>
  );
}

export default function DashboardComponents() {
  const {
    salesAnalytics: { loading, error, data },
    getSalesAnalytics,
  } = useContext(DashboardContext);
  const { addDay, startTime } = useOrders();
  const [date, setDate] = useState([
    moment(addDay(startTime(new Date()), -30), "yyyy-MM-DD"),
    moment(startTime(new Date()), "yyyy-MM-DD"),
  ]);

  useEffect(() => {
    getSalesAnalytics(date);
  }, [date]);

  return (
    <div style={{ padding: "30px 0" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DateHandler setDate={setDate} defaultValue={date} />
        {data?.date?.length > 1 && (
          <Space>
            <Tag>{new Date(data.date[0]).toDateString()}</Tag>
            <div>to</div>
            <Tag>
              {new Date(data.date[data.date?.length - 1]).toDateString()}
            </Tag>
          </Space>
        )}
      </div>
      {data && <Statistics />}
      {data && <ProgressReport {...data} />}
      {!data && loading && <Skeleton />}
      {error && <NotFound />}
    </div>
  );
}
