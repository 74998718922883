import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "toastr/build/toastr.min.css";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./AuthProvider";
import store from "./store";
import { Provider } from "react-redux";
import "antd/dist/antd.css";
import "./styles.scss";

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
