import { DeleteOutlined } from "@ant-design/icons";
import { Button, Space, Tag } from "antd";
import { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { SectionsContext } from "./SectionsContext";

export default function SectionItem({
  id,
  index,
  name,
  banner_image,
  section,
  availability,
  hub,
}) {
  const { deleteSectionItem } = useContext(SectionsContext);
  return (
    <Draggable key={id} draggableId={`${section}-${id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="droppable-item"
        >
          <div className="body">
            <Space>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  background: "lightgray",
                }}
              >
                {banner_image && (
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                    src={banner_image}
                    alt=" "
                  />
                )}
              </div>
              <div className="contents">
                <div className="title">
                  {name}{" "}
                  <Tag color={availability ? "green" : "red"}>
                    {availability ? "Available" : "Unavailable"}
                  </Tag>
                </div>
                {hub && <p className="hub">{hub.get("name")}</p>}
              </div>
            </Space>
            <Button
              icon={<DeleteOutlined />}
              shape="circle"
              onClick={() => deleteSectionItem(section, id)}
              danger
            />
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}
