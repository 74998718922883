import { Col } from "antd"



export default function OrderItemCharge(props){
    const { quantity, sale_unit, discount, total, variant, promoDiscount } = props

    const getTotalVariantPrice = (variant) => {
        if(!variant || !Array.isArray(variant)) return 0

        return variant.reduce((acc, item) => {
            item.items.forEach(i => {
                acc += i.price
            })
            return acc 
        },0)
    }

    const Item = ({ name, value, borderTop }) => (
        <div className="item" style={{borderTop: borderTop? '1px solid #d9d9d9': 'none'}}>
            <div className="name">{name}</div>
            <div className="value">{value}</div>
        </div>
    )

    return (
        <Col span={8}>
            <div className="charges">
                <Item name="Sale unit" value={sale_unit} />
                {variant && <Item name="Variant total" value={getTotalVariantPrice(variant)} />}
                <Item name="Quantity" value={quantity} />
                <Item name="Total" value={total} borderTop={true} />
                <Item name="Discount" value={discount} borderTop={true} />
                {promoDiscount && <Item name="Promo Discount" value={promoDiscount} />}
            </div>
        </Col>
    )
}