import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useReducer,
} from "react";
import UploadImages from "./UploadImages";
import ProductItem from "./ProductItem";
import { Row, message, Affix } from "antd";
import styled from "styled-components";
import { BulkProductUploadContext } from "../../../pages/Restaurant/BulkProductUpload";
import useMedia from "../../../hooks/useMedia";
import Parse from "parse";
import TopBar from "./TopBar";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "set-item":
      state[payload.id] = payload.data;
      return { ...state };
    case "set":
      return payload;
    default:
      return state;
  }
};

export default function ProductUpload() {
  const [forms, dispatch] = useReducer(reducer, {});
  const { restaurant, saveData, deleteSavedItem, restaurantId } = useContext(
    BulkProductUploadContext
  );
  const { deleteFile } = useMedia();
  const [loading, setLoading] = useState(false);

  const reStoreData = () => {
    let store = localStorage.getItem(`${restaurantId}:product-upload`) || "{}";
    if (store) {
      store = JSON.parse(store);
      dispatch({ type: "set", payload: store });
    }
  };

  useEffect(() => {
    reStoreData();
  }, []);

  const setImages = (images) => {
    dispatch({ type: "set", payload: images });
  };

  const setForms = (forms) => {
    dispatch({ type: "set", payload: forms });

    Object.values(forms).forEach((form) => {
      saveData(form);
    });
  };

  const deleteItem = useCallback(
    (id) => {
      const newForms = { ...forms };
      const { file } = newForms[id];

      deleteFile(file?.key ?? forms[id]?.key);
      delete newForms[id];
      setForms(newForms);

      deleteSavedItem(id);
    },
    [forms]
  );

  const setFormData = (id) => (data) => {
    saveData(data);
    dispatch({ type: "set-item", payload: { id, data } });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      const products = await Promise.all(
        Object.values(forms).map(async ({ form, images }) => {
          await form.validateFields();
          const data = form.getFieldsValue();
          data.images = images;

          if (!data.category) {
            throw new Error("Please select category for all products!");
          }

          if (!data.images[0]) {
            return message.error(
              `Image upload isn't complete yet of (${data.name}) !`
            );
          }

          if (!data["price"]?.amount) {
            return message.error(
              `Please enter price for product (${data.name})`
            );
          }

          if (data.price?.variants?.length > 0) {
            data.price["type"] = "variant";
            data.price.variants.forEach((variant) => {
              variant.items?.forEach((item) => {
                if (item.total && !item.price) {
                  item.price = item.total - data.price.amount;
                }
              });
            });
          } else {
            data.price["type"] = "flat";
            delete data.price.variants;
          }

          if (data.addons?.items?.length === 0 && !data.addons.title) {
            delete data.addons;
          }

          delete data.checked;

          data["price"]["discount"] = {
            type: "fixed",
            amount: 0,
          };

          return data;
        })
      );

      const result = await Parse.Cloud.run("bulkProductUpload", {
        restaurant: restaurant.data.id,
        products,
      });

      message.success(`${result.length} products uploaded successfully!`);
      setForms({});
      setLoading(false);

      // clear local storage
      localStorage.removeItem(`${restaurantId}:product-upload`);
    } catch (e) {
      if (e.message) message.error(e.message);
      setLoading(false);
    }
  };

  return restaurant.data ? (
    <Wrapper>
      {Object.keys(forms).length === 0 && (
        <UploadImages setImages={setImages} />
      )}
      {Object.keys(forms).length > 0 && (
        <>
          <Affix offsetTop={64}>
            <TopBar
              forms={forms}
              setForms={setForms}
              submitHandler={submitHandler}
              loading={loading}
              deleteItem={deleteItem}
            />
          </Affix>
          <Row gutter={[24, 24]}>
            {Object.values(forms).map((form) => {
              return (
                <ProductItem
                  key={form.id}
                  data={form}
                  deleteItem={() => deleteItem(form.id)}
                  setData={(data) => {
                    setFormData(form.id)(data);
                  }}
                  forms={forms}
                />
              );
            })}
          </Row>
        </>
      )}
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  .seperate {
    border-right: 1px solid #ccc;
    padding-right: 10px;
  }

  .topbar {
    background: #fff;
    margin-bottom: 10px;
    padding: 10px 20px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upload-images {
  }

  .submit {
    position: fixed;
    bottom: 0;
    width: 150px;
    height: 45px;
    background: #263238;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 24px 4px rgb(0 0 0 / 40%);
    border: none;
    color: rgb(29, 217, 237);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: calc(50% - 75px - 100px);
    margin-bottom: 10px;
    transition: all 0.3s ease;
    border-radius: 30px;
    z-index: 100;
    &:hover {
      opacity: 0.9;
    }
  }
`;
