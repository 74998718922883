import {
  useEffect,
  useState,
  useReducer,
  useCallback,
  createContext,
} from "react";
import { message, DatePicker, Drawer, Space } from "antd";
import Parse from "parse";
import { parser } from "../../utils";
import GridView from "../../components/rider/list/GridView";
import RiderOrders from "./RiderOrders";
import ArchivedRiders from "../../components/rider/list/ArchivedRiders";

export const RidersContext = createContext();

const Types = {
  GET_RIDERS_REQUEST: "GET_RIDERS_REQUEST",
  GET_RIDERS_SUCCESS: "GET_RIDERS_SUCCESS",
  GET_RIDERS_FAILURE: "GET_RIDERS_FAILURE",
  UPDATE_RIDER: "UPDATE_RIDER",
  DELETE_RIDER: "DELETE_RIDER",
  ARCHIVED_RIDERS: "ARCHIVED_RIDERS",
};

const initialState = {
  riders: {
    loading: false,
    results: [],
  },
  archivedRiders: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_RIDERS_REQUEST:
      state.riders.loading = true;
      return { ...state };
    case Types.GET_RIDERS_SUCCESS:
      state.riders.loading = false;
      state.riders.results = action.payload;
      return { ...state };
    case Types.GET_RIDERS_FAILURE:
      state.riders.loading = false;
      return { ...state };
    case Types.UPDATE_RIDER:
      const riderIndex = state.riders.results.findIndex(
        (rider) => rider.id === action.payload.id
      );
      if (riderIndex !== -1) {
        state.riders.results.splice(riderIndex, 1, parser(action.payload));
      }
      return { ...state };
    case Types.DELETE_RIDER:
      const index = state.riders.results.findIndex(
        (i) => i.objectId === action.payload.id
      );
      if (index > -1 && !action.payload.restore) {
        state.riders.results.splice(index, 1);
      } else if (action.payload.restore) {
        const archivedIndex = state.archivedRiders.findIndex(
          (i) => i.objectId === action.payload.id
        );
        if (archivedIndex > -1) {
          state.archivedRiders.splice(archivedIndex, 1);
          message.success(
            "Rider restored successfully. Reload page to show rider into list!"
          );
        }
      }
      return { ...state };
    case Types.ARCHIVED_RIDERS:
      state.archivedRiders = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default function Riders() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [date, setDate] = useState(null);
  const [riderId, setRiderId] = useState(null);

  const updateRiders = useCallback(
    (payload) => {
      dispatch({
        type: Types.GET_RIDERS_SUCCESS,
        payload:
          typeof payload === "function"
            ? payload(state.riders.results)
            : payload,
      });
    },
    [state.riders]
  );

  const fetchRiders = async (params) => {
    dispatch({ type: Types.GET_RIDERS_REQUEST });
    try {
      const data = await Parse.Cloud.run("riderListInDetails", params);
      updateRiders(data);
    } catch (err) {
      dispatch({ type: Types.GET_RIDERS_FAILURE });
      message.error(err.message);
    }
  };

  const deleteRider = async (params) => {
    try {
      await Parse.Cloud.run("deleteRider", params);
      dispatch({
        type: Types.DELETE_RIDER,
        payload: params,
      });
      if (!params.restore) {
        message.success("Rider Archived!");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchArchivedRiders = async () => {
    try {
      const data = await new Parse.Query(Parse.User)
        .equalTo("delete", true)
        .select(["name", "username", "phone", "delete"])
        .find();

      dispatch({
        type: Types.ARCHIVED_RIDERS,
        payload: data.map((i) => i.toJSON()),
      });
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchRiders({
      date,
    });
  }, [date]);

  return (
    <RidersContext.Provider
      value={{
        ...state,
        updateRiders,
        setRiderId,
        riderId,
        deleteRider,
        fetchArchivedRiders,
      }}
    >
      <Space style={{ marginBottom: "20px" }}>
        <DatePicker.RangePicker
          size="large"
          onChange={(date, dateString) => {
            setDate(dateString);
          }}
          format="YYYY-MM-DD"
        />
        <ArchivedRiders />
      </Space>

      <GridView />
      <Drawer
        title="Orders"
        width={1200}
        onClose={() => setRiderId(null)}
        visible={riderId}
      >
        {riderId && <RiderOrders riderId={riderId} />}
      </Drawer>
    </RidersContext.Provider>
  );
}
