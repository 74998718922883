import { Table, message, Row, Timeline, Space, DatePicker, Button } from "antd";
import { Result, Order } from "./riderSingle.dto";
import { useState, useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import useOrders from "../../hooks/useOrders";
import StatisticsItem from "../../components/Common/StatisticsItem";
import Parse from "parse";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";

const { Item } = Timeline;

const columns = [
  {
    title: "Order ID",
    dataIndex: "id",
    key: "id",
    render: (id: string) => (
      <Link target="_blank" to={`/order/list/${id}`}>
        {id}
      </Link>
    ),
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    sorter: (a: any, b: any) => a.total - b.total,
  },
  {
    title: "Delivery Charge",
    dataIndex: "delivery_charge",
    key: "delivery_charge",
  },
  {
    title: "Customer Area",
    dataIndex: "customer_area",
    key: "customer_area",
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <div
        style={{
          color: ["rejected", "cancelled"].includes(status) ? "red" : "green",
        }}
      >
        {status}
      </div>
    ),
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Completed At",
    dataIndex: "completedTime",
    key: "completed_at",
    render: (date: any) => new Date(date).toLocaleString(),
  },
];

const Statistics: FC<Result> = ({
  cod,
  delivered,
  online,
  bkash,
  total,
  bkash_on_delivery,
  assigned,
  rejected,
  rider,
}) => {
  return (
    <Row gutter={[16, 16]} className="statistics">
      <StatisticsItem
        title="Riders name"
        value={rider?.name}
        isAmount={false}
      />
      <StatisticsItem title="Total sales" value={total} />
      <StatisticsItem
        title="Online"
        value={online}
        value2={
          <Space direction="vertical" style={{ textAlign: "center" }} size={0}>
            <div>{bkash} tk</div>
            <div className="title">Bkash</div>
          </Space>
        }
      />
      <StatisticsItem title="Cash on delivery" value={cod} />
      <StatisticsItem title="Bkash on delivery" value={bkash_on_delivery} />
      <StatisticsItem title="Assigned" value={assigned} isAmount={false} />
      <StatisticsItem
        title="Delivered"
        value={delivered}
        isAmount={false}
        value2={
          <Space>
            <Space
              direction="vertical"
              style={{ textAlign: "center" }}
              size={0}
            >
              <div>{rejected}</div>
              <div className="title">Rejected</div>
            </Space>
          </Space>
        }
      />
      <StatisticsItem
        title="Success Rate"
        value={
          Math.round((delivered / (!assigned ? 1 : assigned)) * 100) + " %"
        }
        isAmount={false}
      />
    </Row>
  );
};

const getSplitDate = (date: String) => {
  const dateRange = date.split(",");
  return dateRange.map((d: string) => moment(new Date(d)).format("YYYY-MM-DD"));
};

const RiderOrders = (params: { riderId: string; date: Array<string> }) => {
  const search: any = new URLSearchParams(window.location.search);
  const { startTime } = useOrders();
  const [date, setDate] = useState<any>(
    params.date || search.get("date")
      ? getSplitDate(search.get("date"))
      : [
          moment(startTime(new Date())).format("YYYY-MM-DD"),
          moment(startTime(new Date())).format("YYYY-MM-DD"),
        ]
  );
  const [data, setData] = useState<Result | null>(null);
  const [loading, setLoading] = useState(false);
  const { riderId = params.riderId } = useParams<{ riderId: string }>();

  const fetchRidersData = async (params: any) => {
    message.loading("loading...", 0);
    setLoading(true);
    try {
      const data: Result = await Parse.Cloud.run("getOneRider", params);
      setData(data);
      message.destroy();
      setLoading(false);
    } catch (err: any) {
      message.destroy();
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRidersData({
      date,
      rider: riderId,
    });
  }, [date]);

  const mapOrders = (orders: Order[]) => {
    if (!orders || !Array.isArray(orders)) return [];
    return orders.map((order, i) => {
      return {
        key: i,
        ...order,
        total: order.charge?.total,
        delivery_charge: order.charge?.delivery_charge,
        discount: order.charge?.discount,
      };
    });
  };

  return (
    <div>
      <Space style={{ marginBottom: "20px" }}>
        <DatePicker.RangePicker
          size="large"
          defaultValue={[
            moment(new Date(date[0]), "yyyy-MM-DD"),
            moment(new Date(date[1]), "yyyy-MM-DD"),
          ]}
          onChange={(date, dateString) => {
            setDate(dateString);
          }}
          format="YYYY-MM-DD"
        />
        <Button
          size="large"
          type="primary"
          style={{ display: "flex", alignItems: "center" }}
          icon={<DownloadOutlined />}
        >
          {" "}
          Download CSV
        </Button>
      </Space>
      {data && <Statistics {...data} />}
      <Table
        style={{ marginTop: "20px" }}
        loading={loading}
        columns={columns}
        dataSource={mapOrders(data ? data.orders : [])}
        pagination={false}
      />
      {data?.rider?.timeline && (
        <StyledTimeline>
          <Timeline mode="left" style={{ marginTop: "20px" }}>
            {data?.rider?.timeline?.map(
              ({ msg, time, current, createdAt, type, orderId }, i, arr) => {
                return (
                  <Item
                    label={
                      <div style={{ fontSize: "14px" }}>
                        <Space>
                          {current && (
                            <span>
                              {" "}
                              {current} <small>at</small>
                            </span>
                          )}
                          {new Date(time).toLocaleString()}
                        </Space>
                      </div>
                    }
                    key={i}
                    style={{ fontSize: "16px", minHeight: "100px" }}
                  >
                    <div>
                      {msg}{" "}
                      {type === "rider_assign" ? (
                        <Link target="_blank" to={`/order/list/${orderId}`}>
                          ({orderId})
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </Item>
                );
              }
            )}
          </Timeline>
        </StyledTimeline>
      )}
    </div>
  );
};

const StyledTimeline = styled.div`
  background: #fff;
  padding: 30px;

  .ant-timeline-item-label {
    width: calc(30% - 12px) !important;
  }

  .ant-timeline-item-head {
    left: 30% !important;
  }

  .ant-timeline-item-tail {
    left: 30% !important;
  }

  .ant-timeline-item-content {
    left: calc(30% - 4px) !important;
    width: calc(70% - 14px) !important;
  }
`;

export default RiderOrders;
