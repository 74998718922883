import { useContext } from "react";
import { OrderListContext } from "../../../pages/Order/OrderList";
import { Table, Space, DatePicker } from "antd";
import { formatRelative } from "date-fns";
import useSearch from "../../inventory/utils/useSearch";
import { Link } from "react-router-dom";
import { CalendarOutlined, ExpandOutlined } from "@ant-design/icons";
import { statusColor } from "../utils";
import Header from "./Header";
import TopBar from "./TopBar";
import useSelect from "../../inventory/utils/useSelect";

export default function OrderList(props) {
  const {
    hubs,
    orders,
    getOrdersList,
    userId,
    riderId,
    selectedRowKeys,
    onSelectChange,
    setOrderId,
    restaurants,
    fetchRestaurants,
  } = useContext(OrderListContext);
  const [, getColumnSearchProps] = useSearch();
  const [, getSelectProps] = useSelect({
    items: restaurants,
    onSearch: (v) =>
      fetchRestaurants({ limit: 5, search: v, select: ["name", "hub.name"] }),
    getName: (v) => `${v.name} - ${v.hub?.get("name")}`,
    getValue: (v) => v.id,
  });

  const status = [
    "created",
    "confirmed",
    "preparing",
    "ready",
    "picked",
    "delivered",
    "cancelled",
    "rejected",
  ];

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      width: "150px",
      ...getColumnSearchProps("id"),
      render: (id) => (
        <Space>
          <Link
            style={{
              color: "black",
              fontWeight: "bold",
              letterSpacing: "1px",
              fontSize: "12px",
            }}
            to={`/order/list/${id}`}
          >
            #{id}
          </Link>
          <div style={{ cursor: "pointer" }}>
            <ExpandOutlined onClick={() => setOrderId(id)} />
          </div>
        </Space>
      ),
    },
    {
      title: "Timestamp",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      ...dateSearchProps("createdAt"),
      render: (date) => {
        return (
          <span title={new Date(date).toLocaleString()}>
            {formatRelative(new Date(date), Date.now())}
          </span>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 180,
      ...getColumnSearchProps("customer_name"),
      render: (name, record) => {
        return (
          <Link
            style={{ color: "black" }}
            to={`/user/add?id=${record.user?.id}`}
            title={name}
          >
            {name !== "null" ? name : record.customer_phone}
          </Link>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
      width: 150,
      ...getColumnSearchProps("customer_phone"),
    },
    {
      title: "Area",
      dataIndex: "customer_area",
      key: "customer_area",
      width: 150,
      ...getColumnSearchProps("customer_area"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      filters: status.map((i) => ({ text: i, value: i })),
      onFilter: (value, record) => {
        return record.status === value;
      },
      render: (status) => {
        return (
          <div
            style={{
              background: "#fff",
              padding: "5px 10px",
              borderRadius: "5px",
              textTransform: "capitalize",
              letterSpacing: "1px",
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      title: "Total Charge",
      dataIndex: "total",
      key: "total",
      width: 150,
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Rider",
      dataIndex: "rider",
      key: "rider",
      width: 150,
      render: (rider) => (
        <Link style={{ color: "black" }} to={`/user/add?id=${rider?.id}`}>
          {rider?.get("name")}
        </Link>
      ),
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: 150,
      filters: [
        { text: "Cash On Delivery", value: "cod" },
        { text: "Online", value: "online" },
        { text: "Bkash", value: "bkash" },
      ],
      onFilter: (value, record) => {
        return record.payment_method === value;
      },
    },
    {
      title: "Issue",
      dataIndex: "issue",
      key: "issue",
      width: 180,
      render: (issue) => {
        return (
          issue && (
            <div
              style={{
                background: "#fff",
                padding: "5px 10px",
                color: "#d9363e",
                textTransform: "capitalize",
                borderRadius: "7px",
                border: `2px solid ${
                  issue.get("status") !== "close" ? "#fa7d83" : "green"
                }`,
              }}
            >
              <div>{issue.get("type")}</div>
              {issue.get("refund_status") && (
                <small>Refund: {issue.get("refund_status")}</small>
              )}
            </div>
          )
        );
      },
    },
    {
      title: "Hub",
      dataIndex: "hub",
      key: "hub",
      width: 150,
      filters: hubs.map((hub) => {
        return {
          text: hub.name,
          value: hub.id,
        };
      }),
      onFilter: (value, record) => {
        return record.hubId === value;
      },
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      width: "120px",
      filters: [
        { text: "App", value: "app" },
        { text: "web", value: "web" },
      ],
      onFilter: (value, record) => {
        return record.platform === value;
      },
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Promo",
      dataIndex: "promo",
      key: "promo",
      width: "150px",
      ...getColumnSearchProps("promo"),
    },
    {
      title: "Restaurant Type Filter",
      key: "restaurantType",
      filters: [
        { text: "Restaurant", value: "restaurant" },
        { text: "Store", value: "store" },
      ],
    },
    {
      title: "Restaurants",
      key: "pickupIds",
      ...getSelectProps("pickupIds"),
    },
  ];

  const mapData = (data) => {
    return data.map(({ id, charge, promo, hub, ...rest }, index) => ({
      key: index,
      id,
      total: charge?.total,
      promo: promo?.promo_code,
      discount: charge?.discount,
      hub: hub.get("name"),
      hubId: hub.id,
      ...rest,
    }));
  };

  const tableOnChange = (pagination, filters, sorter) => {
    console.log(sorter, filters);
    const params = {
      limit: pagination.pageSize,
      select: [
        "status",
        "customer_phone",
        "customer_name",
        "payment_method",
        "platform",
        "charge",
        "user.name",
        "promo.promo_code",
        "customer_area",
        "rider.name",
        "hub.name",
        "issue.type",
        "issue.refund_status",
        "issue.status",
      ],
      exclude: ["order_items", "pickups"],
      skip: (pagination.current - 1) * pagination.pageSize,
      createdAt:
        filters.createdAt && filters.createdAt[0]?.filter((i) => i).length > 0
          ? filters.createdAt[0]
          : undefined,
      customer_name: filters.customer_name ? filters.customer_name[0] : "",
      customer_phone: filters.customer_phone ? filters.customer_phone[0] : "",
      customer_area: filters.customer_area ? filters.customer_area[0] : "",
      status: filters.status,
      platform: filters.platform,
      payment_method: filters.payment_method,
      promo: filters.promo ? filters.promo[0] : "",
      ids: filters.id ? filters.id : "",
      hub: filters.hub ? filters.hub[0] : "",
      sortBy: sorter.field,
      sortOrder: sorter.order,
      restaurantType: filters.restaurantType,
      pickupIds: filters.pickupIds,
    };
    if (userId) {
      params["user"] = userId;
    }
    if (riderId) {
      params["rider"] = riderId;
    }
    getOrdersList(params);
  };

  return (
    <div>
      <Header />
      <TopBar />
      <Table
        onRow={(record) => {
          return {
            style: {
              background: statusColor(record.status),
              fontSize: "16px",
              fontWeight: 500,
            },
          };
        }}
        rowSelection={rowSelection}
        columns={columns}
        loading={orders.loading}
        dataSource={mapData(orders.data.results)}
        pagination={{
          total: orders.data.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        onChange={tableOnChange}
        scroll={{
          x: 1000,
        }}
      />
    </div>
  );
}
