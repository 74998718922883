import { useState, useEffect } from "react";
import { message, Table } from "antd";
import Parse from "parse";
import { Link } from "react-router-dom";

export default function ArchivedOrders() {
  const [orders, setOrders] = useState({
    count: 0,
    loading: true,
    results: [],
  });

  const fetchOrders = async ({ limit = 100, skip = 0 } = {}) => {
    try {
      const data = await new Parse.Query("order")
        .select(["status", "customer_name", "customer_phone", "delete"])
        .equalTo("delete", true)
        .limit(limit)
        .skip(skip)
        .withCount()
        .find();

      setOrders((orders) => ({
        ...orders,
        count: data.count,
        results: data.results.map((order) => order.toJSON()),
        loading: false,
      }));
    } catch (err) {
      message.error(err.message);
      setOrders((orders) => ({ ...orders, loading: false }));
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "objectId",
      render: (id) => (
        <Link target="__blank" to={`/order/list/${id}`}>
          #{id}
        </Link>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div>
      <Table
        loading={orders.loading}
        columns={columns}
        dataSource={orders.results}
        pagination={{
          total: orders.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          onChange: (page, pageSize) =>
            fetchOrders({ limit: pageSize, skip: (page - 1) * pageSize }),
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
      />
    </div>
  );
}
