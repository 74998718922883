import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Chart } from "react-chartjs-2";

const getChartsData = ({ labels = [], data = [] }) => {
  return {
    labels,
    datasets: [
      {
        label: `Orders In Areas (%)`,
        data,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        tension: 0.7,
        fill: true,
        // showLine: true,
      },
    ],
  };
};

export default function OrdersInArea() {
  const { data } = useContext(SalesReportContext);

  return (
    <div id="orders-in-areas">
      <h2>Orders In Areas</h2>
      <Chart
        type="bar"
        datasetIdKey="label"
        data={getChartsData({
          labels: data?.ordersInArea?.map((item) => item.area),
          data: data?.ordersInArea?.map((item, i) =>
            Number(item.percentage.replace("%", ""))
          ),
        })}
      />
    </div>
  );
}
