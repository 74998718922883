import { Row, Col } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getGroupRestaurants } from "../../../store/restaurant/restaurantDetails";
import { Box } from "../../Common/Boxer";
import styled from "styled-components";

export default function Groups() {
  const dispatch = useDispatch();
  const {
    restaurant: {
      data: rData,
      group: { loading, data },
    },
  } = useSelector((state) => state.restaurantDetails);

  useEffect(() => {
    if (rData?.group && !data.length) {
      dispatch(
        getGroupRestaurants({
          limit: rData.group.length,
          select: ["name", "banner_image"],
          type: ["store", "restaurant"],
        })
      );
    }
  }, [rData]);

  return (
    <Box
      maxHeight="500px"
      title={loading ? "Group loading..." : "Group Restaurants"}
    >
      <Wrapper>
        {data.map((res, i) => {
          return (
            <div className="item">
              <div className="bdy">
                <img src={res.banner_image} alt="" />
                <div className="title">{res.name}</div>
              </div>
            </div>
          );
        })}
      </Wrapper>
    </Box>
  );
}

const Wrapper = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    padding: 10px;

    .bdy {
      background: #f2f2f2;
      width: 150px;
      min-height: 140px;
      padding: 10px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 130px;
        height: 90px;
        border-radius: 10px;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        padding-top: 10px;
      }
    }
  }
`;
