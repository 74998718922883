import { Form, Modal, Radio, Button, Input, Space, message } from "antd";
import { useState } from "react";
import Parse from "parse";
import useOrders from "../../hooks/useOrders";

export default function OrderReject({ visible, setVisible, orderId }) {
  const { Item } = Form;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { updateStatus } = useOrders();

  const onFinish = async (values) => {
    setLoading(true);
    updateStatus(
      {
        orderId,
        status: "rejected",
        ...values,
      },
      (err, res) => {
        if (res) {
          message.success("Order rejected!");
          setLoading(false);
          setVisible(false);
        } else {
          message.error(err.message);
          setLoading(false);
        }
      }
    );
  };

  return (
    <Modal
      title="Reject Order"
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      style={{ zIndex: "9999" }}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Item
          name="rejected_for"
          label="Who is responsible for this rejection?"
          rules={[
            {
              required: true,
              message: "Please select who is responsible for this rejection!",
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="restaurant">Restaurant</Radio>
              <Radio value="csr">CSR</Radio>
              <Radio value="customer">Customer</Radio>
              <Radio value="rider">Rider</Radio>
            </Space>
          </Radio.Group>
        </Item>

        <Item name="rejection_reason" label="Reason">
          <Input.TextArea rows={3} />
        </Item>

        <Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Item>
      </Form>
    </Modal>
  );
}
