import { Button, message } from "antd";
import Parse from "parse";
import { useState, useEffect } from "react";
import DateHandler from "../components/dashboard/DateHandler";
import { downloadDetails } from "./Payments/Invoice";

export default function DownloadPayments() {
  const [restaurants, setRestaurants] = useState([]);
  const [date, setDate] = useState([]);

  const fetchRestaurants = async () => {
    try {
      const data = await new Parse.Query("restaurant")
        .select(["name", "banner_image"])
        .ascending("sorting_order")
        .limit(1000)
        .find({ sessionToken: Parse.User.current().getSessionToken() });
      if (data) {
        setRestaurants(data.map((r) => r.toJSON()));
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchInvoiceDetails = async ({
    paymentIds,
    note,
    vat_included,
    restaurant,
  }) => {
    try {
      const res = await Parse.Cloud.run("invoiceDetails", {
        paymentIds,
        restaurant,
        note,
        vat_included,
      });

      return res;
    } catch (err) {
      message.error(err.message);
    }
  };

  const downloadHandler = async () => {
    await Promise.all(
      restaurants.map(async (restaurant) => {
        const query = new Parse.Query("restaurant_payment");
        query.equalTo("restaurant", {
          __type: "Pointer",
          className: "restaurant",
          objectId: restaurant.objectId,
        });

        const startDate = new Date(date[0]);
        const endDate = new Date(date[1]);
        startDate.setHours(9);
        endDate.setHours(9);

        query.select(["payment_date", "total_collected"]);
        query.ascending("payment_date");

        query.greaterThanOrEqualTo("payment_date", startDate);
        query.lessThanOrEqualTo("payment_date", endDate);

        const payments = await query.find({
          sessionToken: Parse.User.current().getSessionToken(),
        });

        if (payments) {
          const paymentIds = payments.map((p) => p.id);
          const invoice = await fetchInvoiceDetails({
            paymentIds,
            restaurant: restaurant.objectId,
            note: "",
            vat_included: false,
          });

          if (invoice) {
            downloadDetails(invoice);
          }
        }
      })
    );
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  return (
    <div>
      <DateHandler setDate={setDate} defaultDate={date} />
      <Button onClick={downloadHandler}>Download</Button>
    </div>
  );
}
