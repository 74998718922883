import {
  Button,
  Modal,
  Form,
  Input,
  Upload,
  Space,
  Popconfirm,
  Row,
  Col,
  Select,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  fetchList,
  createNew,
  updateList,
  updateListAsync,
  setActiveBanner,
  searchBannerItems,
  addBannerItem,
  updateActiveItems,
  deleteActiveItems,
  updateBanner,
  deleteBanner,
} from "../../store/promotional_banner";
import {
  InboxOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  ExpandOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { compressedImage } from "../../common/utils";
import Parse from "parse";
import { parser } from "../../utils";

function AddNew() {
  const { loading } = useSelector((state) => state.promotionalBanner.createNew);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;
  const [file, saveFile] = useState(null);
  const [hubs, setHubs] = useState([]);

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubs(parser(hubs));
    } catch (err) {
      message.error(err.message);
    }
  };

  const uploadOnChange = async ({ fileList }) => {
    const file = fileList[fileList.length - 1];
    if (file?.originFileObj) {
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

      file.thumbUrl = src;
      dispatch(saveFile(file));
    }
  };

  const onSubmit = async ({ title, platform, content_type, hubs }) => {
    dispatch(
      createNew({
        title,
        platform,
        content_type,
        file,
        hubs,
        done: () => {
          form.resetFields();
          setOpen(false);
          saveFile(null);
        },
      })
    );
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
      >
        Add New
      </Button>
      <Modal
        title="Add New Cuisine"
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        footer={null}
        width={700}
      >
        <AddNewStyles>
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
            size="large"
            initialValues={{
              platform: "all",
              content_type: "Restaurant",
            }}
          >
            <div className="image-upload">
              <Upload.Dragger
                beforeUpload={() => false}
                style={{
                  marginBottom: "10px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  background: "#fff",
                  display: file ? "none" : "block",
                  width: "300px",
                }}
                listType="picture"
                multiple={false}
                type="file"
                showUploadList={false}
                onChange={uploadOnChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Upload.Dragger>
              {file && (
                <div className="picture">
                  <img src={file.thumbUrl} alt="" className="image" />
                  <Button
                    icon={<CloseOutlined />}
                    shape="circle"
                    className="close-btn"
                    onClick={() => {
                      saveFile(null);
                    }}
                    type="danger"
                  ></Button>
                </div>
              )}
            </div>

            <Row gutter={[16, 0]}>
              <Col span={12}>
                <Item name="title" label="Banner Title">
                  <Input placeholder="Title" />
                </Item>
              </Col>
              <Col span={12}>
                <Item name="platform" label="Platform">
                  <Select style={{ minWidth: "120px" }}>
                    <Select.Option value="all">All</Select.Option>
                    <Select.Option value="web">Web</Select.Option>
                    <Select.Option value="app">App</Select.Option>
                  </Select>
                </Item>
              </Col>
              <Col span={12}>
                <Item name="content_type" label="Content Type">
                  <Select style={{ minWidth: "120px" }}>
                    <Select.Option value="Restaurant">Restaurant</Select.Option>
                    <Select.Option value="Product">Product</Select.Option>
                    <Select.Option value="Category">Category</Select.Option>
                  </Select>
                </Item>
              </Col>
              <Col span={12}>
                <Item name="hubs" label="Hubs">
                  <Select
                    placeholder="Select hubs"
                    mode="multiple"
                    style={{ minWidth: "120px" }}
                  >
                    {hubs.map((hub) => (
                      <Select.Option key={hub.id} value={hub.id}>
                        {hub.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>

            <Item>
              <Space>
                <Button loading={loading} htmlType="submit" type="primary">
                  Submit
                </Button>
              </Space>
            </Item>
          </Form>
        </AddNewStyles>
      </Modal>
    </div>
  );
}

const AddNewStyles = styled.div`
  .image-upload {
    display: flex;
    justify-content: center;

    .picture {
      position: relative;
      margin-bottom: 20px;
      width: 300px;

      .image {
        width: 100%;
        max-height: 260px;
        border-radius: 10px;
      }

      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
`;

function AddBannerItem({ content_type }) {
  const dispatch = useDispatch();
  const {
    searchingResult: result,
    activeBanner: { object, items },
  } = useSelector((state) => state.promotionalBanner);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);

  const addItemHandler = (id) => {
    dispatch(addBannerItem(id));
    setId(null);
  };

  const updateBannerHandler = useCallback(() => {
    object.set("items", items);
    dispatch(updateBanner(object));
  }, [object, items]);

  return (
    <>
      <Space>
        <div className="plus-icon" onClick={() => setOpen(!open)}>
          <PlusOutlined />
        </div>
        <Button
          onClick={updateBannerHandler}
          type="primary"
          icon={<CheckOutlined />}
          shape="circle"
        ></Button>
      </Space>

      {open && (
        <div className="add-banner-item">
          <Space>
            <Select
              showSearch
              style={{ width: 200 }}
              onSearch={(search) => {
                dispatch(searchBannerItems({ content_type, search }));
              }}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              onChange={(id) => {
                setId(id);
              }}
              id={id}
            >
              {result.map((item, i) => {
                return (
                  <Select.Option
                    key={i}
                    value={item.objectId}
                  >{`${item.name}  @${item.hub?.name}`}</Select.Option>
                );
              })}
            </Select>
            <Space>
              <Button
                onClick={() => {
                  addItemHandler(id);
                }}
              >
                Add
              </Button>
              <Button danger onClick={() => setOpen(false)}>
                Close
              </Button>
            </Space>
          </Space>
        </div>
      )}
    </>
  );
}

function EditBanner({ title, hubs, platform, object }) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [hubList, setHubList] = useState([]);
  const dispatch = useDispatch();

  const { Item } = Form;

  const fetchHubs = async () => {
    try {
      const hubs = await new Parse.Query("hub").select("name").find();
      setHubList(parser(hubs));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  const onSubmit = (values) => {
    if (object) {
      object.set("title", values.title);
      object.set("platform", values.platform);
      object.set("hubs", values.hubs);
      dispatch(updateBanner(object));
      setOpen(false);
    }
  };

  return (
    <div className="edit-banner">
      <Button
        onClick={() => setOpen(true)}
        shape="circle"
        icon={<EditOutlined />}
      ></Button>
      <Modal
        title="Edit Banner"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          size="large"
          initialValues={{
            platform,
            title,
            hubs,
          }}
        >
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Item name="title" label="Banner Title">
                <Input placeholder="Title" />
              </Item>
            </Col>
            <Col span={12}>
              <Item name="platform" label="Platform">
                <Select style={{ minWidth: "120px" }}>
                  <Select.Option value="all">All</Select.Option>
                  <Select.Option value="web">Web</Select.Option>
                  <Select.Option value="app">App</Select.Option>
                </Select>
              </Item>
            </Col>
            <Col span={12}>
              <Item name="hubs" label="Hubs">
                <Select
                  placeholder="Select hubs"
                  mode="multiple"
                  style={{ minWidth: "120px" }}
                >
                  {hubList.map((hub) => (
                    <Select.Option key={hub.id} value={hub.id}>
                      {hub.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>

          <Item>
            <Space>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Space>
          </Item>
        </Form>
      </Modal>
    </div>
  );
}

function Banner() {
  const banner = useSelector((state) => state.promotionalBanner.activeBanner);
  const dispatch = useDispatch();

  if (!banner) {
    return null;
  }

  const { image, title, platform, content_type, items } = banner;

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const { index: startIndex } = source;
    const { index: endIndex } = destination;

    const newOrder = [...items];
    const [removed] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, removed);

    dispatch(updateActiveItems(newOrder));
  };

  return (
    <Col lg={16}>
      <div className="banner">
        <Row gutter={[16, 16]}>
          <Col lg={14}>
            <div className="body">
              <div className="body-header">
                <div className="left">
                  <h2 className="title">{title}</h2>
                  <div>
                    <span>{content_type}</span> for{" "}
                    <span>{platform} platform.</span>
                  </div>
                </div>
                <div className="right">
                  <Space>
                    <EditBanner {...banner} />
                    <AddBannerItem {...banner} />
                  </Space>
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-items">
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="items customScroll"
                      >
                        {items?.map((item, i) => {
                          return (
                            <div>
                              <Draggable
                                draggableId={`${item.id}-${i}`}
                                index={i}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={item.id}
                                    className="item"
                                  >
                                    <div className="name">
                                      <div>{item.name}</div>
                                      <div className="hub">
                                        @{item.hub?.name}
                                      </div>
                                    </div>
                                    <Button
                                      icon={<DeleteOutlined />}
                                      shape="circle"
                                      danger
                                      onClick={() => {
                                        dispatch(deleteActiveItems(item.id));
                                      }}
                                    ></Button>

                                    {provided.placeholder}
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          );
                        })}

                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </div>
          </Col>
          <Col lg={10}>
            <div className="image">
              <img src={image} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}

function BannersItem(props) {
  const { title, platform, content_type, image, objectId, idx, object } = props;
  const dispatch = useDispatch();

  return (
    <Draggable draggableId={objectId} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="banners-item"
        >
          <Space>
            <img className="image" src={compressedImage(image)} alt="" />
            <div>
              <h3 className="title">{title}</h3>
              <div>
                <span>{content_type}</span> for{" "}
                <span>{platform} platform.</span>
              </div>
            </div>
          </Space>
          <Space>
            <Button
              onClick={() => dispatch(setActiveBanner(props))}
              shape="circle"
              icon={<ExpandOutlined />}
            ></Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(deleteBanner(object));
              }}
            >
              <Button shape="circle" icon={<DeleteOutlined />} danger></Button>
            </Popconfirm>
          </Space>

          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

export default function PromotionalBanner() {
  const { loading, data } = useSelector(
    (state) => state.promotionalBanner.list
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const { index: startIndex } = source;
    const { index: endIndex } = destination;

    const newOrder = [...data];
    const [removed] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, removed);

    newOrder.forEach((item, index) => {
      if (item.object) {
        item.object.set("sort_order", index);
      }
    });

    dispatch(updateList(newOrder));
    dispatch(updateListAsync(newOrder.map((item) => item.object)));
  };

  return (
    <Wrapper>
      <AddNew />
      {loading && <div className="loader">Loading...</div>}

      {!loading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <h1>Promotional Banners</h1>
          <Row gutter={[24, 24]}>
            <Col lg={8}>
              <Droppable droppableId="droppable">
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="banners customScroll"
                    >
                      {data.map((banner, i) => (
                        <BannersItem
                          key={banner.objectId}
                          idx={i}
                          {...banner}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </Col>
            <Banner />
          </Row>
        </DragDropContext>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .banners {
    height: calc(100vh - 230px);
    padding: 5px 10px;

    &-item {
      height: 80px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightgray;
      padding: 10px;
      cursor: grab;
      margin-bottom: 10px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .image {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }

      .title {
        margin-bottom: 0;
      }
    }
  }

  .banner {
    background: #fff;
    height: calc(100vh - 230px);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid lightgray;

    .image {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        max-height: 250px;
        border-radius: 5px;
      }
    }

    .body {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid lightgray;
        padding: 10px;

        .title {
          margin-bottom: 0;
        }

        .plus-icon {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background: #f7f7f7;
          color: green;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          transition: 0.3s ease;
          cursor: pointer;

          &:hover {
            background: #e5e4e4;
          }
        }

        .add-banner-item {
          width: 360px;
          padding: 10px;
          background: #fff;
          border-radius: 5px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          position: absolute;
          top: 60px;
          right: 60px;
        }
      }

      .items {
        padding: 10px;
        height: 360px;
        background: #fff;

        .item {
          padding: 10px;
          margin-bottom: 5px;
          background: #f8f8f8;
          border: 1px solid lightgray;
          font-size: 16px;
          letter-spacing: 0.5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: grab;
          transition: 0.3s ease;

          &:hover {
            background: #f2f2f2;
          }

          .name {
            .hub {
              font-size: 12px;
              color: gray;
            }
          }
        }
      }
    }
  }
`;
