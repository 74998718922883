import { Form, InputNumber, Space, Button, Input } from "antd";
import Layout from "./Layout";
import { Box } from "../../Common/Boxer";
import { MinusOutlined } from "@ant-design/icons";

const { Item, List } = Form;

function Milestone({ name }) {
  return (
    <List name={["bonus", name]}>
      {(fields, { add, remove }) => (
        <div>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space key={key}>
              <Item {...restField} label="Level" name={[name, "name"]}>
                <Input />
              </Item>
              <Item
                {...restField}
                label="Per Month Order"
                name={[name, "perMonth"]}
              >
                <InputNumber />
              </Item>
              <Item {...restField} label="Bonus Amount" name={[name, "amount"]}>
                <InputNumber />
              </Item>
              <Button
                onClick={() => remove(name)}
                shape="circle"
                icon={<MinusOutlined />}
              ></Button>
            </Space>
          ))}
          <div>
            <Button onClick={() => add()}>Add New</Button>
          </div>
        </div>
      )}
    </List>
  );
}

export default function Bonus() {
  return (
    <Box title="Bonus & Milestone">
      <Layout title="Cycle Riders Milestone" style={{ marginBottom: "20px" }}>
        <Milestone name="cycle" />
      </Layout>
      <Layout title="Bike Riders Milestone">
        <Milestone name="bike" />
      </Layout>
    </Box>
  );
}
