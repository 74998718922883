import { useContext } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import { Row, Col } from "antd";
import styled from "styled-components";
import OrderItems from "./orderItems";
import RiderInfo from "./RiderInfo";
import OrderSummery from "./OrderSummery";
import CustomerInfo from "./CustomerInfo";
import OrderTimeline from "./Timeline";
import OrderHeader from "./OrderHeader";
import IssueDetails from "./IssueDetails";

export default function Details() {
  const { order } = useContext(DetailsContext);
  const { data } = order;

  return (
    <DetailsWrapper gutter={[16, 16]}>
      <Col xs={24} md={24} xl={16}>
        <section className="left-section">
          <OrderHeader />
          <OrderItems />
        </section>
      </Col>
      <Col xs={24} md={24} xl={8}>
        <section className="right-section">
          {order.data && <IssueDetails />}
          <RiderInfo rider={data.rider} />
          <OrderSummery />
          <CustomerInfo />
        </section>
      </Col>
      <OrderTimeline {...data} />
    </DetailsWrapper>
  );
}

const DetailsWrapper = styled(Row)`
  min-height: 100%;
  position: relative;
  min-width: 500px;
  overflow-x: scroll;

  .left-section {
    height: 100%;

    .order-header {
      padding: 20px 0;
      position: relative;

      .id {
      }

      .status {
        font-size: 20px;
        font-weight: 500;
      }

      .edit-mode {
        position: absolute;
        right: 0;
        top: 0;
        .title {
          font-size: 14px;
          font-weight: 500;
        }

        .issue {
          margin-top: 10px;
        }
      }
    }
  }

  .right-section {
    height: 100%;
  }
`;
