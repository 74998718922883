import {
  createContext,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from "react";
import useProduct from "../../hooks/useProduct";
import { parser } from "../../utils";
import { Drawer, message } from "antd";
import ProductListTable from "../../components/product/List";
import Parse from "parse";
import useRestaurants from "../../hooks/useRestaurants";
import useCategories from "../../hooks/useCategories";
import useMedia from "../../hooks/useMedia";
import InventoriesByProduct from "../Inventory/InventoriesByProduct";
import ProductDetails from "./Create";

export const ProductListContext = createContext();

const Types = {
  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
  ADD_NEW_PRODUCT: "ADD_NEW_PRODUCT",
  GET_RESTAURANTS_SUCCESS: "GET_RESTAURANTS_SUCCESS",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_RESTAURANT_REQUEST: "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS: "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_ERROR: "GET_RESTAURANT_ERROR",
};

const initialState = {
  products: { loading: false, data: { count: 0, resulst: [] } },
  restaurants: { loading: false, data: [] },
  categories: { loading: false, data: [] },
  restaurant: { loading: false, data: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_PRODUCTS_REQUEST:
      state.products.loading = true;
      return { ...state };
    case Types.GET_PRODUCTS_SUCCESS:
      state.products.loading = false;
      state.products.data = action.payload;
      return { ...state };
    case Types.GET_PRODUCTS_ERROR:
      state.products.loading = false;
      return { ...state };
    case Types.ADD_NEW_PRODUCT:
      state.products.data.results.unshift(action.payload);
      state.products.data.count++;
      return { ...state };
    case Types.GET_RESTAURANTS_SUCCESS:
      state.restaurants.data = action.payload;
      return { ...state };
    case Types.GET_CATEGORIES_SUCCESS:
      state.categories.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANT_REQUEST:
      state.restaurant.loading = true;
      return { ...state };
    case Types.GET_RESTAURANT_SUCCESS:
      state.restaurant.loading = false;
      state.restaurant.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANT_ERROR:
      state.restaurant.loading = false;
      state.restaurant.data = null;
      return { ...state };
    default:
      return state;
  }
};

export default function ProductListContextProvider({ restaurantId: rId }) {
  const { getProducts, updateProduct, getByIds } = useProduct();
  const { getRestaurants, getById: getRestaurantById } = useRestaurants();
  const { getMany: getCategories } = useCategories();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { deleteFile } = useMedia();
  const [view, setView] = useState("list"); // list || grid
  const url = new URL(window.location);
  const restaurantId = rId || url.searchParams.get("restaurant");
  const [drawer, setDrawer] = useState({
    visible: false,
    type: "",
    productId: null,
  });
  const [filter, setFilter] = useState({
    restaurants: [],
    category: [],
    search: "",
    limit: 100,
  });
  const [productId, setProductId] = useState(null);
  const properties = [
    "images",
    "name",
    "stock",
    "restaurant.name",
    "restaurant.hub",
    "restaurant.banner_image",
    "category.name",
    "price",
    "availability",
    "is_inv",
    "sorting_order",
    "sold",
  ];

  const getRestaurantFunc = (id) => {
    dispatch({ type: Types.GET_RESTAURANT_REQUEST });
    getRestaurantById(
      {
        id,
        select: ["objectId", "name", "banner_image", "hub.name"],
        exclude: [
          "group",
          "sub_stores",
          "managedBy",
          "meta_tags",
          "operating_hours",
          "hub",
        ],
      },
      async (err, res) => {
        if (res) {
          dispatch({
            type: Types.GET_RESTAURANT_SUCCESS,
            payload: { ...res.toJSON(), id: res.id },
          });
        } else {
          dispatch({ type: Types.GET_RESTAURANT_ERROR });
          message.error(err);
        }
      }
    );
  };

  const getProductsFunc = async (params) => {
    let hubs = await new Parse.Query("hub").select(["name"]).find();
    hubs = hubs.reduce((acc, hub) => {
      acc[hub.id] = hub.get("name");
      return acc;
    }, []);

    dispatch({ type: Types.GET_PRODUCTS_REQUEST });
    getProducts(params, (err, products) => {
      if (products) {
        dispatch({
          type: Types.GET_PRODUCTS_SUCCESS,
          payload: {
            count: products.count,
            results: products.results.map((p) => {
              const data = { ...p.toJSON(), ref: p };
              data.hub = hubs[data.restaurant?.hub?.objectId];
              return data;
            }),
          },
        });
      }

      if (err) {
        message.error(err);
        dispatch({ type: Types.GET_PRODUCTS_ERROR });
      }
    });
  };

  const updateProducts = useCallback(
    (payload) => {
      dispatch({
        type: Types.GET_PRODUCTS_SUCCESS,
        payload:
          typeof payload === "function"
            ? payload(state.products.data)
            : payload,
      });
    },
    [state.products]
  );

  const updateAvailability = (id, availability) => {
    updateProduct({ id, data: { availability } }, (err, res) => {
      if (err) {
        message.error(err);
      }

      if (res) {
        updateProducts((products) => {
          const product = products.data.results.find((p) => p.objectId === id);

          if (product) {
            product.availability = availability;
          }

          return products;
        });

        message.success("Availability updated!");
      }
    });
  };

  const getRestaurantsFunc = () => {
    getRestaurants(
      { select: ["objectId", "name", "hub.name"], sortBy: "name" },
      (err, res) => {
        if (res)
          dispatch({
            type: Types.GET_RESTAURANTS_SUCCESS,
            payload: parser(res.results),
          });
      }
    );
  };

  const getCategoriesFunc = useCallback(
    async (payloads) => {
      if (!restaurantId) {
        getCategories(payloads, (err, res) => {
          if (res) {
            dispatch({
              type: Types.GET_CATEGORIES_SUCCESS,
              payload: parser(res.results),
            });
          }
        });
      } else {
        const cats = await new Parse.Query("restaurant_categories")
          .equalTo("restaurant", {
            __type: "Pointer",
            className: "restaurant",
            objectId: restaurantId,
          })
          .select(["category.name"])
          .find();

        dispatch({
          type: Types.GET_CATEGORIES_SUCCESS,
          payload: parser(cats.map((cat) => cat.get("category"))),
        });
      }
    },
    [restaurantId]
  );

  const deleteProducts = (ids, callback) => {
    getByIds({ ids, select: ["objectId", "name"] }, async (err, products) => {
      if (err) return message.error(err);

      if (products) {
        const deletedItems = await Promise.all(
          products.map(async (product) => {
            const { images } = product.toJSON();
            const res = await product.destroy();

            if (res && images && images.length > 0) {
              images.forEach((image) => {
                const key = image.split("/").pop();
                deleteFile(key);
              });
            }

            return res;
          })
        );

        if (deletedItems) {
          callback(true);
          state.products.data.results = state.products.data.results.filter(
            (item) => !ids.includes(item.id)
          );
          state.products.data.count = state.products.data.count - ids.length;
          dispatch({
            type: Types.GET_PRODUCTS_SUCCESS,
            payload: state.products.data,
          });
        } else {
          callback(false);
        }
      }
    });
  };

  useEffect(() => {
    if (restaurantId) {
      getRestaurantFunc(restaurantId);
    } else {
      getRestaurantsFunc();
    }

    return () => {
      dispatch({ type: Types.GET_RESTAURANT_ERROR });
      dispatch({
        type: Types.GET_PRODUCTS_SUCCESS,
        payload: initialState.products.data,
      });
    };
  }, [restaurantId]);

  const setProducts = (products) => {
    dispatch({ type: Types.GET_PRODUCTS_SUCCESS, payload: products });
  };

  const openInventory = (productId) => {
    setDrawer({ ...drawer, visible: true, type: "inventory", productId });
  };

  const openSales = (productId) => {
    setDrawer({ ...drawer, visible: true, type: "sales", productId });
  };

  return (
    <ProductListContext.Provider
      value={{
        ...state,
        getProducts: getProductsFunc,
        updateProducts,
        updateAvailability,
        getCategories: getCategoriesFunc,
        setProducts,
        deleteProducts,
        restaurantId,
        drawer,
        openInventory,
        openSales,
        properties,
        view,
        setView,
        filter,
        setFilter,
        setProductId,
      }}
    >
      <ProductListTable />
      <Drawer
        title="Inventories"
        placement="right"
        visible={drawer.visible}
        onClose={() => setDrawer({ ...drawer, visible: false })}
        width={window.innerWidth > 600 ? "50%" : "100%"}
      >
        {drawer.type === "inventory" && drawer.productId && (
          <InventoriesByProduct product={drawer.productId} />
        )}
      </Drawer>
      <Drawer
        title="Product Details"
        placement="right"
        width={1000}
        visible={productId}
        onClose={() => setProductId(null)}
      >
        <ProductDetails productId={productId} />
      </Drawer>
    </ProductListContext.Provider>
  );
}
