import { createContext, useReducer, useEffect, useState } from "react";
import { message } from "antd";
import Parse from "parse";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";

export const riderMsSocket = io(process.env.REACT_APP_WEB_SOCKET_URL);

riderMsSocket.on("connect", () => {
  console.log("Rider ms socket connected: ", riderMsSocket.id);
});

Parse.serverURL = process.env.REACT_APP_PARSE_URL;
Parse.initialize(process.env.REACT_APP_PARSE_ID);

let LiveQueryClient = Parse.LiveQueryClient;
export let client = new LiveQueryClient({
  applicationId: "food",
  serverURL: process.env.REACT_APP_WEBSOCKET_URL,
});

client.open();

export const AuthContext = createContext();

const Types = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SET_ROLE: "SET_ROLE",
};

const initialState = {
  login: { loading: true, data: null, error: null },
  role: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      state.login.loading = true;
      return { ...state };
    case Types.LOGIN_SUCCESS:
      state.login.loading = false;
      state.login.data = action.payload;
      return { ...state };
    case Types.LOGIN_FAILURE:
      state.login.loading = false;
      state.login.error = action.payload;
      return { ...state };
    case Types.SET_ROLE:
      state.role = action.payload;
      return { ...state };
    case Types.SET_ROLE_FAILED:
      state.role = null;
      state.login.data = null;
      localStorage.removeItem("Parse/food/currentUser");
      return { ...state };
    default:
      return state;
  }
};

export default function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  const logoutHandler = async () => {
    try {
      await Parse.User.logOut();
      dispatch({ type: "LOGIN_SUCCESS", payload: null });
      history.push("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.message });
      message.error(err.message);
    }
  };

  const loginHandler = async (username, password) => {
    dispatch({ type: Types.LOGIN_REQUEST });
    try {
      const res = await Parse.User.logIn(username, password);
      if (res) {
        if (
          ["admin", "manager", "csr", "product manager"].includes(
            res.get("type")
          )
        ) {
          fetchRole(res.get("type"), () => {
            dispatch({ type: "LOGIN_SUCCESS", payload: res.toJSON() });
            history.push("/");
          });
        } else {
          message.error("You are not authorized to access this page");
          logoutHandler();
        }
      }
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.message });
      message.error(err.message);
    }
  };

  const fetchRole = async (type, done) => {
    try {
      const role = await new Parse.Query("_Role")
        .select("name", "access")
        .equalTo("name", type)
        .first();
      if (role) {
        dispatch({ type: Types.SET_ROLE, payload: role.toJSON() });
      }
      if (typeof done === "function") {
        done();
      }
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.SET_ROLE_FAILED });
    }
  };

  useEffect(() => {
    message.loading("Loading...");
    if (Parse.User.current()) {
      const data = Parse.User.current().toJSON();
      fetchRole(data.type);
      if (["admin", "manager", "csr", "product manager"].includes(data.type)) {
        dispatch({ type: "LOGIN_SUCCESS", payload: data });
      } else {
        message.error("You are not authorized to access this page");
        logoutHandler();
      }
    } else {
      dispatch({ type: "LOGIN_SUCCESS", payload: null });
    }
    message.destroy();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginHandler,
        dispatch,
        logoutHandler,
        refresh,
        setRefresh,
        setLoginData: (data) =>
          dispatch({ type: "LOGIN_SUCCESS", payload: data }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
