import Parse from 'parse'


export default function useConfig() {
    const repoName = 'config'
    const defaultFunc = (err, data) => {}

    const getConfigs = async ({}, callback=defaultFunc) => {
        try{
            const configs = await new Parse.Query(repoName).descending('createdAt').find()
            callback(null, configs)
        }catch (err) {
            callback(err.message)
        }
    }

    const createNew = async ({ key, value }, callback=defaultFunc) => {
        try{
            const exists = await new Parse.Query(repoName).equalTo('key', key).first()
            if(exists) callback('Key already exists')

            const Config = Parse.Object.extend(repoName)
            const config = new Config()
            config.set('key', key)
            config.set('value', value)
            const res = await config.save()
            callback(null, res)
        }catch (err){
            callback(err.message)
        }
    }

    const updateItem = async (ref, { value }, callback=defaultFunc) => {
        try{
            if(ref){
                ref.set('value', value) 
                const res = await ref.save()
                callback(null, res)
            }else{
                callback("Reference not found!")
            }
        }catch (err) {
            callback(err.message)
        } 
    }


    return {
        getConfigs,
        createNew,
        updateItem
    }
}