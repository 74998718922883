import { Form, Input, Button, Divider, Select } from "antd";
import { memo, useState } from "react";
import { BiPlus } from "react-icons/bi";

const { Item } = Form;

const PlusIcon = (props) => <BiPlus {...props} style={{ fontSize: "18px" }} />;

function MetaInfo() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {open && (
        <div className="slowup">
          <Divider style={{ margin: "7px 0" }} orientation="left">
            Meta Info
          </Divider>
          <Item name="meta_title">
            <Input type="text" placeholder="Meta title" />
          </Item>
          <Item name="meta_tags">
            <Select
              placeholder="tag one, tag two..."
              mode="tags"
              style={{ width: "100%" }}
              tokenSeparators={[","]}
            ></Select>
          </Item>
          <Item name="meta_description">
            <Input.TextArea placeholder="Description" />
          </Item>
        </div>
      )}
      <Item>
        <Button
          onClick={() => {
            setOpen(!open);
          }}
          icon={<PlusIcon />}
          className="center add-btn"
        >
          Meta Info
        </Button>
      </Item>
    </div>
  );
}

export default memo(MetaInfo);
