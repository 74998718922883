import styled from "styled-components";
import { Row, Col, Space, Button, Tag, Table } from "antd";
import { DownloadOutlined, SendOutlined } from "@ant-design/icons";
import Parse from "parse";
import InvoiceOrders from "./InvoiceOrders";
import { InvoiceContext } from "../../pages/Payments/Invoice";
import { useContext } from "react";

const Item = ({ name, value, suffix }) => {
  return (
    <div className="item">
      <div className="name">{name}</div>
      <div className="value">
        {value} {suffix ?? " "}
      </div>
    </div>
  );
};

export default function InvoiceDetails() {
  const { details, invoiceId, downloadDetails, setVisible } =
    useContext(InvoiceContext);

  const {
    payment_period,
    success_order,
    failed_order,
    vat_collection,
    vat_included,
    revenue,
    commission,
    product_discount,
    promo_discount,
    munchies_promo_discount,
    failed_order_amount,
    penalty,
    refund,
    payable,
    items_total,
    total_sales,
    note,
    orders,
    appendix,
  } = details;

  let restaurant =
    details.restaurant instanceof Parse.Object
      ? details.restaurant.toJSON()
      : details.restaurant;

  return (
    <Wrapper>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space>
            <Button onClick={downloadDetails} icon={<DownloadOutlined />}>
              Download
            </Button>
            <Button
              onClick={() => {
                window.print();
              }}
              icon={<DownloadOutlined />}
            >
              PDF
            </Button>
            {!details.objectId && (
              <>
                <Button
                  onClick={() => setVisible(true)}
                  icon={<SendOutlined />}
                  type="primary"
                >
                  Payment
                </Button>
              </>
            )}
            {details.objectId && invoiceId === "new" && (
              <div style={{ color: "green", fontSize: "18px" }}>
                Payment Successful
              </div>
            )}
          </Space>
        </Col>
        <Col span={24}>
          <Row justify="end" gutter={[24, 24]}>
            <Col span={24} className="topbar">
              <Row justify="space-between">
                <Col>
                  <h3 className="title">
                    Invoice
                    <span style={{ color: "GrayText" }}>
                      #{details.objectId ?? ""}
                    </span>
                  </h3>
                  <Space>
                    {payment_period[0] && (
                      <Tag>
                        {new Date(
                          payment_period[0].iso ?? payment_period[0]
                        ).toLocaleDateString()}
                      </Tag>
                    )}
                    <div>to</div>
                    {payment_period[1] && (
                      <Tag>
                        {" "}
                        {new Date(
                          payment_period[1].iso ?? payment_period[1]
                        ).toLocaleDateString()}
                      </Tag>
                    )}
                  </Space>
                </Col>
                <Col>
                  <Space className="rs">
                    <img
                      className="rs-image"
                      src={restaurant.banner_image}
                      alt={restaurant.name}
                    />
                    <h3 className="rs-name">{restaurant.name}</h3>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24} lg={16} xl={14} className="calc">
              <h3 className="title">Payment details</h3>
              <Item name="Success order" value={success_order} />
              <Item name="Failed order" value={failed_order} />
              <Item name="Items value" value={items_total} suffix="tk" />
              <Item
                name="Product discount"
                value={"-" + product_discount}
                suffix="tk"
              />
              <Item
                name="Munchies Promo discount"
                value={munchies_promo_discount}
                suffix="tk"
              />
              <Item
                name="Promo discount"
                value={"-" + promo_discount}
                suffix="tk"
              />
              <Item name="Total sales" value={total_sales} suffix="tk" />
              <Item
                name={`Vat collection (${restaurant?.vat} %)`}
                value={`${!vat_included ? "-" : ""}` + vat_collection}
                suffix="tk"
              />
              {(revenue || restaurant?.type === "store") && (
                <Item name={`Revenue`} value={revenue} suffix="tk" />
              )}
              <Item
                name={`Commission (${restaurant?.commission} %)`}
                value={"-" + commission}
                suffix="tk"
              />
              <Item
                name="Failed order amount"
                value={"+" + failed_order_amount}
                suffix="tk"
              />
              <Item name="Penalty" value={"-" + penalty} suffix="tk" />
              <Item name="Refund" value={"-" + refund} suffix="tk" />
              {vat_included && <Item name=" " value="Vat included" />}
              {appendix?.length > 0 &&
                appendix.map(({ title, amount }) => {
                  return <Item name={title} value={amount} suffix="tk" />;
                })}
              <Item name="Payable" value={payable} suffix="tk" />
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <Row justify="space-between" gutter={[24, 24]}>
                {note && (
                  <Col span={24} md={14}>
                    <div className="note-box">
                      <h3 className="title">Note</h3>
                      <p className="value">{note}</p>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <InvoiceOrders orders={orders} />
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @print {
    page-size: A4;
    margin: 0px;
  }

  .topbar {
    margin-bottom: 20px;
    padding: 20px;

    .rs {
      &-image {
        width: 70px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      }
      &-name {
        font-size: 20px;
      }
    }
  }

  .payment-info,
  .note-box {
    padding: 20px;
    background: #fff;
    p {
      font-weight: 500;
      color: gray;
    }
  }

  .calc {
    .title {
      font-size: 16px;
      text-align: right;
      font-weight: 700;
      padding: 0 20px;
    }
    .item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 16px;
      border-bottom: 1px solid #e8e8e8;
      padding: 0 30px;

      .value {
        font-weight: 500;
      }
    }

    .item:last-child {
      border-bottom: none;
      font-weight: 700;
    }
  }
`;
