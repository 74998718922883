import Parse from "parse";

export default function useProduct(product) {
  const repoName = "product";
  const defaultFunc = () => {};

  const getProducts = async (
    {
      limit = 30,
      skip = 0,
      select,
      approval_status,
      is_inv,
      is_restricted,
      search,
      name,
      vat,
      availability,
      discount,
      restaurant,
      restaurants,
      category,
      exclude,
      order,
      orderBy,
      customQuery,
    } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      query.limit(limit);
      query.skip(skip);
      query.includeAll();
      query.withCount();
      if (restaurant) {
        query.equalTo("restaurant", {
          __type: "Pointer",
          className: "restaurant",
          objectId: restaurant,
        });
      }
      if (Array.isArray(exclude)) {
        query.exclude(...exclude);
      }
      if (approval_status) {
        query.equalTo("approval_status", approval_status);
      }
      if (select) query.select(select);
      if (is_inv !== undefined) query.equalTo("is_inv", is_inv);
      if (Array.isArray(is_restricted)) {
        query.containedIn("is_restricted", is_restricted);
      }

      if (availability) {
        if (Array.isArray(availability) && availability.length > 0)
          query.containedIn("availability", availability);
        else if (availability && typeof availability === "string") {
          query.equalTo("availability", availability);
        }
      }
      if (Array.isArray(restaurants) && restaurants.length > 0) {
        query.containedIn(
          "restaurant",
          restaurants.map((i) => ({
            __type: "Pointer",
            className: "restaurant",
            objectId: i,
          }))
        );
      }

      if (Array.isArray(category) && category.length > 0) {
        query.containedIn(
          "category",
          category.map((i) => ({
            __type: "Pointer",
            className: "category",
            objectId: i,
          }))
        );
      } else if (category && typeof category === "string") {
        query.equalTo("category", {
          __type: "Pointer",
          className: "category",
          objectId: category,
        });
      }
      if (search) query.matches("name", search, "i");
      if (name) query.matches("name", name, "i");
      if (vat) query.equalTo("price.vat", vat);
      if (discount) query.equalTo("price.discount.amount", discount);

      if (orderBy) {
        if (order === "asc") query.ascending(orderBy);
        else query.descending(orderBy);
      } else if (!orderBy && (restaurant || restaurants?.length > 0)) {
        query.ascending("sorting_order");
      } else {
        if (order === "asc") query.ascending("createdAt");
        else query.descending("createdAt");
      }

      if (typeof customQuery === "function") {
        customQuery(query);
      }

      const products = await query.find();
      callback(null, products);
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getById = async ({ id, select } = {}, callback = defaultFunc) => {
    if (!id) {
      callback("Id can't be empty!", null);
      return;
    }

    try {
      const query = new Parse.Query(repoName);
      query.equalTo("objectId", id);
      query.includeAll();
      if (select) {
        query.select(select);
      }
      const product = await query.first();
      if (product) {
        callback(null, product);
      } else {
        callback("Product not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getByIds = async ({ ids, select }, callback = defaultFunc) => {
    if (!ids || !Array.isArray(ids)) {
      callback("Ids can't be empty!", null);
      return;
    }

    try {
      const query = new Parse.Query(repoName);
      query.containedIn("objectId", ids);
      if (select) {
        query.select(select);
      }
      const products = await query.find();
      callback(null, products);
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getByName = async (name, callback = defaultFunc) => {
    if (!name) {
      callback("Name can't be empty!", null);
      return;
    }

    try {
      const product = await new Parse.Query(repoName)
        .equalTo("name", name)
        .first();
      if (product) {
        callback(null, product);
      } else {
        callback("Product not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getBySlug = async (slug, callback = defaultFunc) => {
    if (!slug) {
      callback("Slug can't be empty!", null);
      return;
    }

    try {
      const product = await new Parse.Query(repoName)
        .equalTo("slug", slug)
        .first();
      if (product) {
        callback(null, product);
      } else {
        callback("Product not found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const addProduct = async (data = {}, callback = defaultFunc) => {
    try {
      let {
        name,
        restaurant,
        images,
        category,
        price,
        addons,
        details,
        meta_title,
        meta_description,
        meta_tags,
      } = data;

      if (addons) {
        if (addons.items?.length === 0) {
          addons = {};
        }
      }

      const productObj = {
        name,
        restaurant,
        images,
        category,
        price,
        addons,
        details,
        meta_title,
        meta_description,
        meta_tags,
        availability: "available",
      };
      const product = await Parse.Cloud.run("addProduct", productObj);
      if (product) {
        callback(null, product);
      }
    } catch (err) {
      callback(err.message ?? err.error, null);
    }
  };

  const updateProduct = async ({ id, data } = {}, callback = () => {}) => {
    getById({ id }, async (err, product) => {
      if (err) {
        callback(err, null);
        return;
      }

      let {
        name,
        category,
        price,
        addons,
        details,
        images,
        meta_title,
        meta_description,
        meta_tags,
        availability,
        restaurant,
        approval_status,
      } = data;

      try {
        if (name) product.set("name", name);
        if (category)
          product.set("category", {
            __type: "Pointer",
            className: "category",
            objectId: category,
          });
        if (restaurant && product.get("restaurant").id !== restaurant) {
          product.set("restaurant", {
            __type: "Pointer",
            className: "restaurant",
            objectId: restaurant,
          });
        }
        if (approval_status) product.set("approval_status", approval_status);
        if (price) {
          if (price.discount?.validity) {
            price.discount.validity = new Date(
              price.discount.validity
            ).toISOString();
          }
          product.set("price", price);
        }
        if (addons) product.set("addons", addons);
        if (details) product.set("details", details);
        if (images && Array.isArray(images)) product.set("images", images);
        if (meta_title) product.set("meta_title", meta_title);
        if (meta_description) product.set("meta_description", meta_description);
        if (meta_tags) product.set("meta_tags", meta_tags);
        if (availability) product.set("availability", availability);
        const result = await product.save();

        if (result) {
          callback(null, product);
          return;
        }
      } catch (err) {
        console.log(err);
        callback(err.message, null);
      }
    });
  };

  const getAttributes = async (
    { limit = 20, skip = 0, search } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query("attribute");
      query.limit(limit);
      query.skip(skip);
      if (search) query.matches("name", search, "i");
      query.withCount();
      const attributes = await query.find();
      if (attributes) {
        callback(null, attributes);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const updateProductsDiscount = async (
    payloads = {},
    callback = defaultFunc
  ) => {
    try {
      const result = new Parse.Cloud.run("updateProductsDiscount", payloads);
      if (result) {
        callback(null, result);
      }
    } catch (err) {
      callback(err.error, null);
    }
  };

  return {
    getProducts,
    getById,
    getByName,
    getBySlug,
    addProduct,
    updateProduct,
    getAttributes,
    updateProductsDiscount,
    getByIds,
  };
}
