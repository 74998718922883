import { useEffect, useState, useContext, useCallback } from "react";
import { Row, Col, Space, Tag, Tooltip, Switch } from "antd";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import { intervalToDuration } from "date-fns";

function Returning({ completedAt, lastArea }) {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const { hours, minutes, seconds } = time;

  const setTimer = useCallback(() => {
    return setInterval(() => {
      const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(completedAt),
        end: new Date(),
      });

      setTime({ hours, minutes, seconds });
    }, 2000);
  }, [completedAt]);

  useEffect(() => {
    const interval = setTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="returning">
      <div>
        Last Delivered: <strong>{lastArea || ""} </strong>{" "}
      </div>
      <div>
        {hours ? hours + "h :" : ""} {minutes || "0"}m : {seconds || "0"}s ago
      </div>
    </div>
  );
}

function Rider({ data }) {
  const {
    name,
    phone,
    rider_availability,
    orders = [],
    completedAt,
    lastArea,
    delivered,
  } = data;
  return (
    <div className="rider">
      <Space>
        <div
          className={`avail ${rider_availability ? "active" : "deActive"}`}
        ></div>
        <div>
          <div className="name" style={{ fontSize: "16px", fontWeight: 500 }}>
            {name} <Tag>{delivered}</Tag>
          </div>
          <div>{phone}</div>
        </div>
      </Space>
      <div style={{ marginTop: "10px" }}>
        {orders.length > 0 && (
          <Row className="orders" gutter={[4, 8]}>
            {orders.map((order, i) => (
              <Col key={i}>
                <Tooltip title={order.status}>
                  <Tag className="orders-item">{order.customer_area}</Tag>
                </Tooltip>
              </Col>
            ))}
          </Row>
        )}
        {orders.length === 0 && completedAt ? (
          <Returning completedAt={completedAt} lastArea={lastArea} />
        ) : null}
      </div>
    </div>
  );
}

export default function Riders({ fold, setFold }) {
  const {
    riders: { data },
  } = useContext(OrdersContext);

  return (
    <div className={`riders-panel customScroll ${fold ? "fold" : "unfold"}`}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {!fold && <h2 style={{ marginBottom: "0" }}>Riders</h2>}
        <Switch
          unCheckedChildren="Riders"
          checkedChildren="Fold"
          checked={!fold}
          onClick={(value) => setFold(!value)}
        />
      </div>
      <div className="riders" style={{ display: fold ? "none" : "block" }}>
        {data?.map((rider) => (
          <Rider key={rider.id} data={rider}></Rider>
        ))}
      </div>
    </div>
  );
}
