

    export const statusColor = (status) => {
        switch(status){
            case 'created':
                return '#ff9800'
            case 'confirmed':
                return '#87ceeb96'
            case 'preparing':
                return '#ff980063'
            case 'ready':
                return '#ffd7002e'
            case 'picked':
                return '#f4c2c2'
            case 'delivered':
                return '#0080003d'
            case 'cancelled':
                return '#607d8b69'
            default:
                return '#ff000047'
        }
    }