import { useContext, useEffect, useState } from "react";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import { Tabs, Row, Col, Empty, Space } from "antd";
import OrderCard from "./OrderCard";
import { useHistory } from "react-router-dom";
import Skeleton from "./Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const { TabPane } = Tabs;

const tabs = [
  { title: "Created", key: "created" },
  { title: "Confirmed", key: "confirmed" },
  { title: "Preparing", key: "preparing" },
  { title: "Ready", key: "ready" },
  { title: "Picked", key: "picked" },
  { title: "Delivered", key: "delivered" },
  { title: "Cancelled", key: "cancelled" },
  { title: "Rejected", key: "rejected" },
  { title: "Pending", key: "pending" },
  { title: "Restaurant Reject", key: "restaurant_reject" },
];

export default function OrdersTab({ date }) {
  const { todaysOrders, setOrderId, fetchOrders } = useContext(OrdersContext);
  const { data } = todaysOrders;
  const { pathname } = window.location;
  const [activeKey, setActiveKey] = useState(
    new URL(window.location).searchParams.get("key")
  );
  const history = useHistory();

  const onChange = (key) => {
    history.push(`${pathname}?key=${key}`);
    setActiveKey(key);

    if (
      (data[key].count > 0 && data[key].orders.length < data[key].count) ||
      (data[key].lastFetched &&
        new Date(data[key].lastFetched).getTime() <
          new Date().getTime() - 1000 * 60 * 5)
    ) {
      fetchOrders({
        completedAt: date,
        limit: 20,
        skip: 0,
        status: [key],
      });
    }
  };

  useEffect(() => {
    fetchOrders({
      completedAt: date,
      limit: 20,
      skip: 0,
      status: [activeKey || "created"],
    });

    if (!activeKey) {
      history.push(`${pathname}?key=created`);
      setActiveKey("created");
    }
  }, [date]);

  return (
    <Tabs onChange={onChange} defaultActiveKey={activeKey}>
      {tabs.map((tab) => {
        const { key, title } = tab;
        const {
          loading,
          status,
          count,
          orders = [],
          limit,
          skip = 0,
        } = data[tab.key] || {};

        return (
          <TabPane
            tab={
              <Space>
                <div>{title}</div>
                <strong>{count}</strong>
              </Space>
            }
            key={key}
            style={{ overflow: "hidden" }}
          >
            <InfiniteScroll
              dataLength={orders.length} //This is important field to render the next data
              next={() => {
                fetchOrders({
                  completedAt: date,
                  limit,
                  skip: skip + limit,
                  status: [status],
                });
              }}
              refreshFunction={() => {
                fetchOrders({
                  completedAt: date,
                  limit: 20,
                  skip: 0,
                  status: [key],
                });
              }}
              hasMore={count > orders.length}
              loader={loading ? <Skeleton /> : null}
              endMessage={
                count > 0 && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <b>Yay! You have seen it all</b>
                  </div>
                )
              }
            >
              <Row gutter={[16, 24]} style={{ width: "99%" }}>
                {orders?.map((order) => {
                  return (
                    <OrderCard
                      key={order.id}
                      {...order}
                      status={status}
                      setOrderId={setOrderId}
                    />
                  );
                })}

                {!loading && orders.length === 0 && (
                  <Col
                    span={24}
                    style={{
                      minHeight: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Empty />
                  </Col>
                )}
              </Row>
            </InfiniteScroll>
          </TabPane>
        );
      })}
    </Tabs>
  );
}
