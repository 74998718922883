import { message } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import RidersReportTable from "../../components/reports/RidersTable";
import RestaurantsReportTable from "../../components/reports/RestaurantsTable";
import AreaWiseDeliveryTime from "../../components/reports/AreaWiseDeliveryTime";

export default function Reports() {
  const [state, setState] = useState({});

  const fetchTimeReports = async () => {
    try {
      message.loading("Loading...", 0);
      const data = await Parse.Cloud.run("timeReport", {});
      if (data) {
        setState(data);
      }
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchTimeReports();
  }, []);

  return (
    <div>
      {state.riders && <RidersReportTable data={state.riders} />}
      {state.restaurant && <RestaurantsReportTable data={state.restaurant} />}
      {state.areaWise && <AreaWiseDeliveryTime {...state} />}
    </div>
  );
}
