import { ExpandOutlined } from "@ant-design/icons";
import { Space, Table, Row, Col, message, Drawer, Button } from "antd";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useProduct from "../../hooks/useProduct";
import { parser } from "../../utils";
import ProductDetails from "./Create";

export default function ChangeRequests() {
  const { getProducts } = useProduct();
  const [products, setProducts] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [productId, setProductId] = useState(null);

  useEffect(() => {
    getProducts(
      {
        limit: 100,
        skip: 0,
        select: ["name", "image", "change_request"],
        customQuery: (query) => {
          if (query) query.equalTo("change_request.isData", true);
        },
      },
      (err, data) => {
        if (err) {
          message.error(err);
        } else {
          setProducts({
            loading: false,
            data: parser(data.results),
            count: data.count,
          });
        }
      }
    );
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "images",
      key: "image",
      width: "160px",
      render: (image) => {
        return <img src={image[0]} alt="product" width="70" />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
    },
    {
      title: "Action",
      key: "action",
      width: "160px",
      render: (text) => (
        <Space>
          <Link to={`/product/add?id=${text.id}`}>Details</Link>
        </Space>
      ),
    },
    {
      title: "Expand",
      key: "expand",
      width: "160px",
      render: ({ id }) => (
        <Button onClick={() => setProductId(id)} icon={<ExpandOutlined />}>
          Open
        </Button>
      ),
    },
  ];

  const mapDataSource = (products) => {
    return products.map((product, i) => ({
      key: i,
      ...product,
      category: product?.category?.get("name"),
      restaurant: product.restaurant?.get("name"),
      price: product?.price?.amount,
    }));
  };

  return (
    <div>
      <Row justify="center">
        <Col span={24} lg={20} xl={16}>
          <Table
            columns={columns}
            dataSource={mapDataSource(products.data)}
            loading={products.loading}
            pagination={{
              total: products.count,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSize: 100,
            }}
          />
        </Col>
        <Drawer
          title="Product Details"
          placement="right"
          width={1000}
          visible={productId}
          onClose={() => setProductId(null)}
        >
          <ProductDetails productId={productId} />
        </Drawer>
      </Row>
    </div>
  );
}
