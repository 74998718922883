import { Col, Space, Tag } from "antd";
import Styled from "styled-components";
import CardHeader from "./CardHeader";
import OrderItem from "./OrderItem";
import FooterActions from "./FooterActions";
import { Link } from "react-router-dom";

export default function OrderCard(props) {
  const { pickups } = props;

  return (
    <Col sm={24} md={12} lg={12} xl={8}>
      <OrderCardWrapper className="order-card">
        <CardHeader {...props} />
        <div className="pickup-points customScroll">
          {pickups.map((restaurant, i) => {
            const {
              name,
              id,
              picked,
              items,
              ready,
              confirmed,
              order_number,
              status,
              rejectReason,
            } = restaurant;
            return (
              <div className="pickup-points-item" key={i}>
                <div className="header">
                  <h3 className="title">
                    <Link target="_blank" to={`/restaurant/add?id=${id}`}>
                      {name}{" "}
                      <small style={{ color: "gray" }}> #{order_number}</small>
                    </Link>
                  </h3>
                  {status && <Tag color="volcano">{status}</Tag>}
                  {!status && (
                    <Space>
                      <Tag color={confirmed ? "volcano" : ""}>Confirmed</Tag>
                      <Tag color={ready ? "volcano" : ""}>Ready</Tag>
                      <Tag color={picked ? "volcano" : ""}>Picked</Tag>
                    </Space>
                  )}
                </div>
                {rejectReason && (
                  <div className="reject-reason">{rejectReason}</div>
                )}
                {items.map((item, i) => (
                  <OrderItem key={i} {...item} />
                ))}
              </div>
            );
          })}
        </div>
        <FooterActions {...props} />
      </OrderCardWrapper>
    </Col>
  );
}

const OrderCardWrapper = Styled.div`
    background: #001529fa;
    min-height: 300px;
    padding: 10px;
    border-radius: 10px;
    animation: fadeIn .7s ease-in-out;
    position: relative;

    .pickup-points{
        height: 250px;
        overflow-y: auto;
        padding: 5px;
        
        &-item{
            background: #fff;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;

            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .reject-reason{
              background: pink;
              padding: 5px 10px;
              border-radius: 4px;
              font-weight: 500;
              margin-top: 5px;
            }

            .title{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            .order-number{
                font-weight: 600;
                font-size: 12px;
            }
        }
    }

`;
