import { Timeline, Col, Space } from "antd";
import { formatRelative, intervalToDuration } from "date-fns";
import BoxLayout from "./BoxLayout";
import styled from "styled-components";

const { Item } = Timeline;

const StyledTimeline = styled(Timeline)`
  .ant-timeline-item-label {
    width: calc(30% - 12px) !important;
  }

  .ant-timeline-item-head {
    left: 30% !important;
  }

  .ant-timeline-item-tail {
    left: 30% !important;
  }

  .ant-timeline-item-content {
    left: calc(30% - 4px) !important;
    width: calc(70% - 14px) !important;
  }
`;

const TimeDiff = ({ time, prev }) => {
  const { hours, minutes, seconds, days, months } = intervalToDuration({
    start: new Date(prev),
    end: new Date(time),
  });

  return (
    <div>
      {`${days > 0 ? `${days}d ` : ""}${hours > 0 ? `${hours}h ` : ""}${
        minutes > 0 ? `${minutes}m ` : ""
      }${seconds > 0 ? `${seconds}s ` : ""}`}
    </div>
  );
};

export default function OrderTimeline({ timeline, createdAt }) {
  return (
    <Col span={24}>
      <BoxLayout title="Order Timeline">
        <StyledTimeline
          reverse={true}
          mode="left"
          style={{ marginTop: "20px" }}
        >
          {timeline?.map(({ msg, time, previous, current, status }, i, arr) => {
            return (
              <Item
                label={
                  time ? (
                    <div style={{ fontSize: "14px" }}>
                      <Space>
                        {current && (
                          <span style={{ fontWeight: "500" }}>
                            {" "}
                            {current} <small>at</small>
                          </span>
                        )}
                        {formatRelative(new Date(time), Date.now())}
                      </Space>
                      <div>
                        {time && (arr[i - 1]?.time || i === 0) && (
                          <TimeDiff
                            time={time}
                            prev={arr[i - 1]?.time || createdAt}
                          />
                        )}
                      </div>
                    </div>
                  ) : null
                }
                key={i}
                style={{ fontSize: "16px", minHeight: "100px" }}
              >
                <div>{msg}</div>
              </Item>
            );
          })}
        </StyledTimeline>
      </BoxLayout>
    </Col>
  );
}
