import { Table, Tag, DatePicker, Space } from "antd";
import { useContext } from "react";
import { PaymentsContext } from "../../../pages/Restaurant/Payments";
import { isSameDay, isBefore, isAfter } from "date-fns";
import { CalendarOutlined, ExpandOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function PaymentsTable() {
  const {
    payments,
    getPayments,
    setSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
    restaurantId,
    setSalesId,
  } = useContext(PaymentsContext);

  const inBetween = (date, start, end) => {
    date = new Date(date);
    start = new Date(start);
    end = new Date(end);
    return (
      isSameDay(date, start) ||
      isSameDay(date, end) ||
      (isAfter(date, start) && isBefore(date, end))
    );
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
          allowClear
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (value?.filter((i) => i).length > 0) {
          return inBetween(record[dataIndex], value[0], value[1]);
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      width: "150px",
      ...dateSearchProps("date"),
      render: (date, { id }) => (
        <Space>
          {new Date(date).toLocaleDateString()}
          <ExpandOutlined onClick={() => setSalesId(id)} />
        </Space>
      ),
    },
    {
      title: "Total Sale",
      dataIndex: "total_collected",
      key: "total_collected",
      sorter: (a, b) => a.total_collected - b.total_collected,
    },
    {
      title: "Total Vat",
      dataIndex: "total_vat",
      key: "vat",
      render: (total_vat, { vat }) => (
        <span title={`${vat}%`}>{total_vat}</span>
      ),
    },
    {
      title: "Commission",
      dataIndex: "total_commission",
      key: "commission",
      render: (com, { commission_rate }) => (
        <span title={`${commission_rate} %`}>{com}</span>
      ),
    },
    {
      title: "Penalty",
      dataIndex: "penalty",
      key: "penalty",
    },
    {
      title: "Refund",
      dataIndex: "refund",
      key: "refund",
    },
    {
      title: "Failed order amount",
      dataIndex: "failed_order_amount",
      key: "failed_order_amount",
    },
    {
      title: "Payable",
      dataIndex: "payable",
      key: "payable",
      sorter: (a, b) => a.payable - b.payable,
    },
    {
      title: "Payment Status",
      dataIndex: "paid",
      key: "paid",
      filters: [
        { text: "Paid", value: "paid" },
        { text: "Unpaid", value: "unpaid" },
      ],
      onFilter: (value, record) => {
        if (value === "paid") return record.paid;
        else return !record.paid;
      },
      render: (paid) =>
        paid ? (
          <Tag color="success">Paid</Tag>
        ) : (
          <Tag color="red">Not Paid</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, { id }) => {
        return (
          <Space>
            <Link to={`/restaurant/${restaurantId}/payments/${id}`}>
              Details
            </Link>
          </Space>
        );
      },
    },
  ];

  if (payments.data?.results) {
    const hasRevenue = payments.data?.results?.find((i) => i.revenue);
    if (hasRevenue) {
      columns.splice(4, 0, {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        sorter: (a, b) => a.revenue - b.revenue,
      });
    }
  }

  const tableOnChange = (pagination, filters) => {
    getPayments({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      restaurantId,
      date: filters.date?.length > 0 ? filters.date[0] : undefined,
    });
  };

  const mapPaymnets = (payments) => {
    if (!Array.isArray(payments)) return [];

    return payments.map((payment, index) => {
      let restaurant = payment.restaurant?.toJSON();

      const total_vat = Math.round(
        (payment.total_collected * payment.vat) / (100 + payment.vat)
      );
      let total_commission = 0;

      if (restaurant?.commission_type === "percentage") {
        total_commission = Math.round(
          ((payment.total_collected - total_vat) * payment.commission_rate) /
            100
        );
      } else if (restaurant?.commission_type === "flat") {
        total_commission = Math.round(
          (payment.revenue * payment.commission_rate) / 100
        );

        payment.revenue = Math.round(payment.revenue);
      }

      const payable =
        payment.total_collected -
        (total_vat + total_commission) +
        (payment.penalty ?? 0) +
        (payment.failed_order_amount ?? 0);

      return {
        key: index,
        ...payment,
        date: payment.payment_date,
        total_vat,
        total_commission,
        payable,
      };
    });
  };

  const onSelectChange = (keys, record) => {
    setSelectedItems(record);
    setSelectedRowKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    getCheckboxProps: (record) => ({
      // disabled: record.paid,
    }),
  };

  return (
    <div>
      <Table
        columns={columns}
        loading={payments.loading}
        rowSelection={rowSelection}
        dataSource={mapPaymnets(payments.data.results)}
        pagination={{
          defaultPageSize: 100,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: payments.data.count,
          position: ["topLeft", "topRight"],
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        scroll={{ x: 1000 }}
        onChange={tableOnChange}
      />
    </div>
  );
}
