import { useReducer, useEffect, useState, FC } from "react";
import {
  message,
  DatePicker,
  Row,
  Col,
  Table,
  Space,
  Button,
  Drawer,
  Checkbox,
} from "antd";
import Parse from "parse";
import moment from "moment";
import useOrders from "../../hooks/useOrders";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import StatisticsItem from "../../components/Common/StatisticsItem";
import { DownloadOutlined, ExpandOutlined } from "@ant-design/icons";
import { downloadCsv } from "../../utils";
import RiderOrders from "./RiderOrders";

export interface RiderDashboardDTO {
  result: Result;
}

export interface Result {
  cod: number;
  bkash: number;
  bkash_on_delivery: number;
  online: number;
  total: number;
  orderCount: number;
  delivered: number;
  rejected: number;
  riders: Rider[];
  date: string;
}

export interface Rider {
  id: string;
  name: string;
  assigned: number;
  delivered: number;
  total: number;
  cod: number;
  online: number;
  bkash: number;
  bkash_on_delivery: number;
  collected: number;
}

const Types = {
  FETCH_DATA_REQUEST: "FETCH_DATA_REQUEST",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case Types.FETCH_DATA_REQUEST:
      state.loading = true;
      return { ...state };
    case Types.FETCH_DATA_SUCCESS:
      state.loading = false;
      state.data = action.payload;
      return { ...state };
    case Types.FETCH_DATA_FAILURE:
      state.loading = false;
      state.error = action.payload;
      return { ...state };
    default:
      return state;
  }
};

const Statistics: FC<Result> = ({
  cod,
  delivered,
  rejected,
  online,
  bkash,
  bkash_on_delivery = 0,
  total,
  orderCount,
}) => {
  return (
    <Row gutter={[16, 16]} className="statistics">
      <StatisticsItem title="Total sales" value={total} />
      <StatisticsItem
        title="Total orders"
        value={orderCount}
        isAmount={false}
        value2={
          <Space>
            <Space
              direction="vertical"
              style={{ textAlign: "center" }}
              size={0}
            >
              <div>{rejected}</div>
              <div className="title">Rejected</div>
            </Space>
            <Space
              direction="vertical"
              style={{ textAlign: "center" }}
              size={0}
            >
              <div>{delivered}</div>
              <div className="title">Delivered</div>
            </Space>
          </Space>
        }
      />
      <StatisticsItem
        title="Online"
        value={online}
        value2={
          <Space direction="vertical" style={{ textAlign: "center" }} size={0}>
            <div>{bkash} tk</div>
            <div className="title">Bkash</div>
          </Space>
        }
      />
      <StatisticsItem
        title="Cash on delivery"
        value={cod}
        value2={
          <Space direction="vertical" style={{ textAlign: "center" }} size={0}>
            <div>{bkash_on_delivery} tk</div>
            <div className="title">Bkash On Delivery</div>
          </Space>
        }
      />
    </Row>
  );
};

const getSplitDate = (date: string) => {
  const dateRange = date.split(",");
  return dateRange.map((d: string) => moment(new Date(d)).format("YYYY-MM-DD"));
};

export default function RiderDashboard() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { startTime } = useOrders();
  const search: any = new URLSearchParams(window.location.search);
  const [date, setDate] = useState<any>(
    search.get("date")
      ? getSplitDate(search.get("date"))
      : [startTime(new Date()), startTime(new Date())]
  );
  const history = useHistory();
  const [riderData, setRiderData] = useState<{
    id: string;
    date: Array<string>;
  } | null>(null);
  const [selectRow, setSelectRow] = useState<string | null>(null);

  const fetchRidersData = async (params: any) => {
    dispatch({ type: Types.FETCH_DATA_REQUEST });
    message.loading("loading...", 0);

    try {
      const data: RiderDashboardDTO = await Parse.Cloud.run(
        "getRidersDashboardData",
        params
      );
      dispatch({ type: Types.FETCH_DATA_SUCCESS, payload: data });
      message.destroy();
    } catch (err: any) {
      message.destroy();
      message.error(err.message);
      dispatch({ type: Types.FETCH_DATA_FAILURE, payload: err });
    }
  };

  const downloadData = (data: any) => {
    if (!data.riders || !Array.isArray(data.riders)) return;

    downloadCsv(
      [
        {
          header: [
            {
              title: "ID",
              key: "id",
            },
            {
              title: "Name",
              key: "name",
            },
            {
              title: "Assigned",
              key: "assigned",
            },
            {
              title: "Rejected",
              key: "rejected",
            },
            {
              title: "Delivered",
              key: "delivered",
            },
            {
              title: "Total",
              key: "total",
            },
            {
              title: "COD",
              key: "cod",
            },
            {
              title: "Online",
              key: "online",
            },
            {
              title: "Bkash",
              key: "bkash",
            },
          ],
          data: data.riders,
        },
      ],
      `${date}-riders-data.csv`
    );
  };

  useEffect(() => {
    fetchRidersData({ date });
    history.push(
      `/rider/dashboard?date=${date.map((d: any) =>
        moment(d).format("YYYY-MM-DD")
      )}`
    );
  }, [date]);

  const columns = [
    {
      title: "Details",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (
        <ExpandOutlined
          style={{ fontSize: "20px" }}
          onClick={() => setRiderData({ id, date })}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "120px",
      render: (name: string, { id }: any) => {
        return (
          <div
            style={{ padding: "20px", cursor: "pointer" }}
            onClick={() => setSelectRow(id)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      sorter: (a: any, b: any) => a.assigned - b.assigned,
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
      sorter: (a: any, b: any) => a.delivered - b.delivered,
    },
    {
      title: "Rejected",
      dataIndex: "rejected",
      key: "rejected",
      sorter: (a: any, b: any) => a.rejected - b.rejected,
    },
    {
      title: "COD",
      dataIndex: "cod",
      key: "cod",
      sorter: (a: Rider, b: Rider) => a.cod - b.cod,
    },
    {
      title: "Aamarpay",
      dataIndex: "online",
      key: "online",
      sorter: (a: Rider, b: Rider) => a.online - b.online,
    },
    {
      title: "Bkash",
      dataIndex: "bkash",
      key: "bkash",
      sorter: (a: Rider, b: Rider) => a.bkash - b.bkash,
    },
    {
      title: "Bkash On Delivery",
      dataIndex: "bkash_on_delivery",
      key: "bkash_on_delivery",
      sorter: (a: Rider, b: Rider) => a.bkash_on_delivery - b.bkash_on_delivery,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a: any, b: any) => a.total - b.total,
    },
  ];

  return (
    <Wrapper>
      <Space>
        <DatePicker.RangePicker
          size="large"
          defaultValue={[
            moment(new Date(date[0]), "yyyy-MM-DD"),
            moment(new Date(date[1]), "yyyy-MM-DD"),
          ]}
          onChange={(date, dateString) => {
            setDate(dateString);
          }}
          format="YYYY-MM-DD"
        />
        <Button
          size="large"
          onClick={() => downloadData(state.data)}
          type="primary"
          style={{ display: "flex", alignItems: "center" }}
          icon={<DownloadOutlined />}
        >
          {" "}
          Download CSV
        </Button>
      </Space>

      {state.data && <Statistics {...state.data} />}

      <Row justify="center" style={{ marginTop: "30px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            loading={state.loading}
            dataSource={state.data?.riders ?? []}
            pagination={{
              pageSize: 50,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            rowClassName={(record, index) =>
              `table-row ${selectRow === record.id ? "selected" : ""}`
            }
          />
        </Col>
      </Row>
      <Drawer
        title="Rider Orders"
        placement="right"
        width={1200}
        onClose={() => setRiderData(null)}
        visible={riderData !== null}
      >
        {riderData && (
          <RiderOrders riderId={riderData.id} date={riderData.date} />
        )}
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .statistics {
    margin-top: 16px;
  }

  .table-row:hover {
    .ant-table-cell {
      background-color: #2d2d2d;
      color: #fff;
    }
  }

  .selected {
    background-color: #2d2d2d;
    color: #fff;
  }
`;
