import { useState, useContext } from "react";
import BoxLayout from "./BoxLayout";
import { Button, message, Modal, Select } from "antd";
import styled from "styled-components";
import { DetailsContext } from "../../../pages/Order/Details";
import { BiCycling } from "react-icons/bi";

function UpdateRider({ visible, setVisible, rider }) {
  const {
    riders,
    assignRider,
    order: { data },
  } = useContext(DetailsContext);
  const [riderId, setRiderId] = useState(rider?.id);
  const [loading, setLoading] = useState(false);

  const updateHandler = () => {
    if (riderId) {
      if (["delivered", "rejected", "cancelled"].includes(data.status)) {
        message.error("Order is already delivered, cancelled or rejected");
        return;
      }

      setLoading(true);
      assignRider(data.id, riderId, (err, res) => {
        if (res) {
          setVisible(false);
          message.success("Rider assigned!");
        } else if (err) {
          message.error(err);
        }
        setLoading(false);
      });
    } else {
      message.error("Please select a rider");
    }
  };

  return (
    <Modal
      title="Assign Rider"
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div>
        <Select
          placeholder="Select rider"
          size="large"
          style={{ width: "200px" }}
          onChange={(value) => setRiderId(value)}
          loading={riders.loading}
          defaultValue={rider ? rider.id : ""}
        >
          {riders.data &&
            riders.data.map((rider, key) => {
              return (
                <Select.Option
                  style={{ color: rider.rider_availability ? "green" : "red" }}
                  key={key}
                  value={rider.id}
                >
                  {rider.name}
                </Select.Option>
              );
            })}
        </Select>
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{ marginRight: "10px" }}
            danger
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={updateHandler}>
            Assign
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default function RiderInfo({ rider }) {
  const [visible, setVisible] = useState(false);
  const {
    order: { data },
  } = useContext(DetailsContext);

  return (
    <BoxLayout
      style={{
        position: "relative",
        background: "linear-gradient(45deg, #e89200, #daa449)",
      }}
    >
      <Wrapper>
        {!rider && <div className="not-assigned">Not assigned yet!</div>}
        {rider && (
          <div className="info">
            <div className="user">
              <BiCycling />
              <h4 className="name">{rider.get("name")}</h4>
            </div>
            <p className="username">
              <strong>Username:</strong> {rider.get("username")}
            </p>
            <p className="phone">
              <strong>Phone:</strong> {rider.get("phone")}
            </p>
          </div>
        )}
        <div className="assign">
          <Button
            onClick={() => setVisible(true)}
            disabled={["delivered", "rejected", "cancelled"].includes(
              data.status
            )}
            className="custom-btn"
            type="primary"
          >
            Assign rider
          </Button>
          <UpdateRider
            visible={visible}
            setVisible={setVisible}
            rider={rider}
          />
        </div>
      </Wrapper>
    </BoxLayout>
  );
}

const Wrapper = styled.div`
  .not-assigned {
    font-weight: bold;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
  }

  .info {
    .user {
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // background-color: #dedede;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        margin-right: 10px;
        color: #fff;
      }
      .name {
        margin-bottom: 0;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 700;
      }
    }
    .username,
    .phone {
      font-size: 16px;
      margin-bottom: 0px;
      font-weight: 500;
    }
  }

  .assign {
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;

    .custom-btn {
      color: #ffa100;
      background: #000000;
      border: none;
      font-weight: 600;
      letter-spacing: 0.5px;
      border-radius: 30px;
      transition: all 0.3s;

      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
