import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import Parse from "parse";

const initialState = {
  settings: {
    loading: false,
    data: null,
    updateLoading: false,
  },
  travelLogs: {
    loading: false,
    data: {
      count: 0,
      results: [],
    },
  },
};

export const fetchTravelLogs = createAsyncThunk(
  "rider/fetchTravelLogs",
  async (params) => {
    const { riderId, limit = 50, skip = 0, cb } = params || {};
    const query = new Parse.Query("rider_travel");
    query.equalTo("rider", {
      __type: "Pointer",
      className: "_User",
      objectId: riderId,
    });
    query.limit(limit);
    query.skip(skip);
    query.withCount();
    query.descending("createdAt");
    if (typeof cb === "function") {
      cb(query);
    }
    return await query.find();
  }
);

export const fetchSettings = createAsyncThunk(
  "rider/fetchSettings",
  async () => {
    return await new Parse.Query("config")
      .equalTo("key", "rider_settings")
      .first();
  }
);

export const updateSettings = createAsyncThunk(
  "rider/updateSettings",
  async (params) => {
    const { object, ...values } = params;
    if (object instanceof Parse.Object) {
      object.set("value", values);
      return await object.save();
    }
    return null;
  }
);

const riderSlices = createSlice({
  name: "rider",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSettings.pending, (state) => {
      state.settings.loading = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings.loading = false;
      if (!action.payload) {
        message.error("Settings not found");
        return;
      }
      state.settings.data = {
        ...action.payload.toJSON(),
        refs: action.payload,
      };
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.settings.loading = false;
      message.error(action.error.message);
    });

    builder.addCase(updateSettings.pending, (state) => {
      state.settings.updateLoading = true;
    });
    builder.addCase(updateSettings.fulfilled, (state, action) => {
      state.settings.updateLoading = false;
      if (action.payload) {
        message.success("Settings updated");
      }
    });
    builder.addCase(updateSettings.rejected, (state, action) => {
      state.settings.updateLoading = false;
      message.error(action.error.message);
    });

    // Travel Logs
    builder.addCase(fetchTravelLogs.pending, (state) => {
      state.travelLogs.loading = true;
    });
    builder.addCase(fetchTravelLogs.fulfilled, (state, action) => {
      state.travelLogs.loading = false;
      action.payload.results = action.payload.results.map((item) =>
        item.toJSON()
      );
      state.travelLogs.data = action.payload;
    });
    builder.addCase(fetchTravelLogs.rejected, (state, action) => {
      state.travelLogs.loading = false;
      message.error(action.error.message);
    });
  },
});

export default riderSlices.reducer;
