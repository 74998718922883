import { useState } from "react";
import { Button, message, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Parse from "parse";

export default function Delete({ restaurant, setRestaurant }) {
  const { object, delete: isDeleted } = restaurant;
  const [loading, setLoading] = useState(false);

  const deleteHandler = async () => {
    try {
      if (object instanceof Parse.Object && !isDeleted) {
        setLoading(true);
        object.set("delete", true);
        restaurant.delete = true;
        await object.save();
        setRestaurant({ ...restaurant, object });
        setLoading(false);
      }
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="get-delete">
      <Popconfirm
        title="Are you sure delete this item?"
        onConfirm={deleteHandler}
        disabled={isDeleted}
      >
        <Button loading={loading} danger={!isDeleted} icon={<DeleteOutlined />}>
          {!isDeleted ? "Delete" : "Deleted"}
        </Button>
      </Popconfirm>
    </div>
  );
}
