import { createContext, useContext } from "react";
import PaymentHistoryDashboard from "../../components/payments/PaymentHistoryDashboard";
import { useEffect, useState } from "react";
import moment from "moment";
import FullPaymentHistoryTable from "../../components/payments/FullPaymentHistoryTable";

export const PaymentHistoryContext = createContext();

export default function PaymentHistoryPage() {
    const [date, setDate] = useState([
        moment().subtract(30, 'days'),
        moment()
    ]);

    const { dashboardData, setDashboardData } = useState({
        failedOrderAmount: 0,
        penaltyAmount: 0,
        rejectedOrders: 0,
        successfullyDelivered: 0,
        totalNetPayable: 0,
        totalPaid: 0,
        totalPlacedOrder: 0,
        totalSales: 0,
        vendorDiscount: 0,
    })
    return(
        <PaymentHistoryContext.Provider value = {{date,setDate,dashboardData,setDashboardData}}>
            <PaymentHistoryDashboard />
            <br/>
            <FullPaymentHistoryTable />
        </PaymentHistoryContext.Provider>
    )
}