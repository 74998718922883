import { useContext, useState } from "react";
import BoxLayout from "./BoxLayout";
import styled from "styled-components";
import { Button, Dropdown, Menu, message } from "antd";
import { EditOutlined, FileOutlined, InfoOutlined } from "@ant-design/icons";
import { DetailsContext } from "../../../pages/Order/Details";
import Parse from "parse";
import OnlinePaymentDetails from "./OnlinePaymentDetails";
import BkashPaymentDetails from "./BkashPaymentDetails";

function Item({ title, value, borderTop }) {
  return (
    (value !== undefined || value !== null) && (
      <div
        className="item"
        style={{ borderTop: borderTop ? "1px solid #c9c9c9" : "none" }}
      >
        <div>{title}: </div>
        <div>{value}</div>
      </div>
    )
  );
}

function PaymentMethod() {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const { payment_method, payments, ref } = data;
  const [loading, setLoading] = useState(false);

  async function updateToCOD() {
    if (ref instanceof Parse.Object) {
      try {
        setLoading(true);
        ref.set("payment_method", "cod");

        const user = Parse.User.current();

        const timeline = ref.get("timeline") || [];
        timeline.push({
          type: "payment_method",
          current: "cod",
          previous: payment_method,
          time: new Date().toISOString(),
          msg: `${user?.get(
            "name"
          )} updated Payment method ${payment_method} to COD`,
        });
        ref.set("timeline", timeline);
        await ref.save(null, {
          sessionToken: Parse.User.current().getSessionToken(),
        });
        setLoading(false);
      } catch (err) {
        message.error(err.message);
      }
    }
  }
  return (
    <div className="method">
      <div className="title">Payment method </div>
      <div className="value">{payment_method}</div>
      <div className="info">
        <Dropdown
          trigger={["click"]}
          placement="bottomRight"
          overlay={() => (
            <Menu style={{ minWidth: "100px" }}>
              {["bkash", "online"].includes(payment_method) &&
                payments.length === 0 && (
                  <Menu.Item onClick={updateToCOD} icon={<EditOutlined />}>
                    Update To COD
                  </Menu.Item>
                )}
              <Menu.Item icon={<FileOutlined />}>Details</Menu.Item>
            </Menu>
          )}
        >
          <Button
            loading={loading}
            size="small"
            icon={<InfoOutlined />}
            shape="circle"
          />
        </Dropdown>
      </div>
    </div>
  );
}

export default function OrderSummery() {
  const {
    order: { data },
  } = useContext(DetailsContext);
  const { charge, promo, payment_status } = data;

  return (
    <BoxLayout title="Order summery">
      <Wrapper>
        <div className="payment">
          <PaymentMethod />
          <div className="status">
            <div>{payment_status}</div>
          </div>
        </div>
        <Item title="Items total" value={`${charge.items_total} tk`} />
        <Item
          title="Items discount"
          value={`${charge.items_discount} tk`}
          borderTop={true}
        />
        <Item
          title={`Promo discount ${promo ? `(${promo.promo_code})` : ""}`}
          value={`${charge.promo_discount} tk`}
        />
        <Item
          title="Total discount"
          value={`-${charge.discount} tk`}
          borderTop={true}
        />
        <Item title="Service charge" value={`+${charge.vat} tk`} />
        <Item title="Delivery charge" value={`+${charge.delivery_charge} tk`} />
        <Item title="Total" value={`${charge.total} tk`} borderTop={true} />

        {data.payment_method !== "cod" && (
          <div className="payment-details">
            <h3>Payment Details</h3>
            {data.payment_method === "online" && (
              <OnlinePaymentDetails orderId={data.id} />
            )}
            {data.payment_method === "bkash" && (
              <BkashPaymentDetails
                orderId={data.id}
                issue={data.issue?.toJSON()}
              />
            )}
          </div>
        )}
      </Wrapper>
    </BoxLayout>
  );
}

const Wrapper = styled.div`
  .payment {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 7px 0;
    margin-bottom: 10px;

    .method {
      width: 58%;
      background: #f5f5f5;
      padding: 10px;
      font-weight: 500;
      text-align: center;
      border-radius: 5px;
      position: relative;

      .info {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #999;
      }

      .value {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    .status {
      width: 38%;
      padding: 3px;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-size: 16px;
      border-radius: 5px;
    }
  }
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #535353;
  }
`;
