import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { createContext } from "react";

export default function Roles() {
  return (
    <div style={{ marginBottom: "20px"
     }}>
      <Button icon={<PlusOutlined />}>Add New Role</Button>
    </div>
  );
}
