import { Form, Input, InputNumber, Space, Button, Divider } from "antd";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { memo } from "react";
import { BiPlus } from "react-icons/bi";

const { Item, List } = Form;

const PlusIcon = (props) => <BiPlus {...props} style={{ fontSize: "18px" }} />;

function VariantItem({
  name: variantName,
  fieldKey,
  remove: removeVariant,
  ...rest
}) {
  return (
    <div className="variant-item">
      <Space>
        <Item
          {...rest}
          name={[variantName, "title"]}
          rules={[
            {
              required: true,
              message: `Title can't be empty!`,
            },
          ]}
        >
          <Input placeholder="Title" />
        </Item>
        <Item
          {...rest}
          name={[variantName, "min"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="Min" min={0} />
        </Item>
        <Item
          {...rest}
          name={[variantName, "max"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="Max" min={0} />
        </Item>
      </Space>
      <List name={[variantName, "items"]}>
        {(fields, { add, remove }, i) => (
          <div key={i}>
            {fields.map(({ key, name, fieldKey, ...rest }) => (
              <Space key={key}>
                <Item
                  {...rest}
                  name={[name, "name"]}
                  rules={[
                    {
                      required: true,
                      message: `Can't be empty!`,
                    },
                  ]}
                >
                  <Input placeholder="Item Name" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "price"]}
                  style={{ display: "none" }}
                >
                  <InputNumber placeholder="+Price" />
                </Item>
                <Item
                  {...rest}
                  name={[name, "total"]}
                  rules={[
                    {
                      required: true,
                      message: `Can't be empty!`,
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Total Price"
                    style={{ width: "110px", border: "2px solid #52c41a" }}
                    min={0}
                  />
                </Item>
                <Item className="center">
                  <CloseCircleOutlined
                    style={{ color: "red", fontSize: "16px" }}
                    onClick={() => remove(name)}
                  />
                </Item>
              </Space>
            ))}

            <div>
              <Space>
                <Button
                  type="dashed"
                  onClick={() => removeVariant(variantName)}
                  className="center"
                  danger
                  icon={<DeleteOutlined />}
                >
                  delete
                </Button>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusIcon />}
                  className="center"
                >
                  Item
                </Button>
              </Space>
            </div>
          </div>
        )}
      </List>
    </div>
  );
}

function Variants() {
  return (
    <List name={["price", "variants"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.length > 0 && (
            <Divider style={{ margin: "7px 0" }} orientation="left">
              Variants
            </Divider>
          )}
          {fields.map(({ key, ...rest }) => (
            <VariantItem key={key} {...rest} remove={remove} />
          ))}
          <Item>
            <Button
              onClick={() => add()}
              icon={<PlusIcon />}
              className="center add-btn"
            >
              Variant
            </Button>
          </Item>
        </>
      )}
    </List>
  );
}

export default memo(Variants);
