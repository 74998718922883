import { Form, InputNumber, Space } from "antd";
import Layout from "./Layout";
import { Box } from "../../Common/Boxer";

const { Item } = Form;

function SalaryItem({ type }) {
  return (
    <>
      <Space>
        <Item
          label="Fuel Cost Km"
          name={["earning", "salary", type, "fuelCost", "km"]}
        >
          <InputNumber />
        </Item>
        <Item
          label="Fuel Cost Amount (tk)"
          name={["earning", "salary", type, "fuelCost", "amount"]}
        >
          <InputNumber />
        </Item>
      </Space>
      <Space>
        <Item
          label="Mobil Cost Order"
          name={["earning", "salary", type, "mobilCost", "order"]}
        >
          <InputNumber />
        </Item>
        <Item
          label="Mobil Cost Amount (tk)"
          name={["earning", "salary", type, "mobilCost", "amount"]}
        >
          <InputNumber />
        </Item>
      </Space>
      <Item
        label="Mobile Cost"
        name={["earning", "salary", type, "mobileBill"]}
      >
        <InputNumber />
      </Item>
    </>
  );
}

export default function Salary() {
  return (
    <Box title="Salary">
      <Layout title="Part time rider">
        <SalaryItem type="partTime" />
      </Layout>
      <Layout title="Fulltime rider">
        <SalaryItem type="fullTime" />
      </Layout>
    </Box>
  );
}
