import {
  message,
  Space,
  Row,
  Col,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import { useState, useEffect, useCallback, createContext } from "react";
import Parse from "parse";
import { useParams } from "react-router-dom";
import Attendance from "../../components/rider/activity/Attendance";
import Orders from "../../components/rider/activity/Orders";
import styled from "styled-components";
import { isSameDay } from "date-fns";

const inBetween = (start, end, now) => {
  if (start < end) {
    return start <= now && end >= now;
  } else {
    return start <= now || end >= now;
  }
};

export const RidersActivity = createContext();

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function days(date) {
  const [year, month] = date
    ? date.split("-").map((i) => parseInt(i))
    : [new Date().getFullYear(), new Date().getMonth() + 1];
  date = new Date(year, month - 1, 1);

  const start = new Date(date);
  const last = new Date(date);
  last.setDate(daysInMonth(month, year));

  return [start.toLocaleString(), last.toLocaleString()];
}

export default function RiderActivityProvider() {
  const [data, setData] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const { id } = useParams();
  const [date, setDate] = useState([days()[0], days()[1]]);
  const [lastHour, setLastHour] = useState(20);
  const [lastMinute, setLastMinute] = useState(10);

  const fetchRiderActivity = async (params) => {
    try {
      message.loading("Loading...", 0);
      const data = await Parse.Cloud.run("riderActivity", params);
      if (data) {
        setData(data);
      }
      message.destroy(0);
    } catch (err) {
      message.destroy(0);
      message.error(err.message);
    }
  };

  const dayWiseOrders = useCallback(
    (date) => {
      if (!data) return [];
      return data.orders.filter((order) => {
        const completedAt = new Date(order.get("completedAt"));
        const hours = completedAt.getHours();
        if (inBetween(0, 12, hours)) {
          completedAt.setDate(completedAt.getDate() - 1, 0, 0, 0, 0);
        }
        return isSameDay(completedAt, new Date(date));
      });
    },
    [data]
  );

  useEffect(() => {
    fetchRiderActivity({ date, id });
  }, [id, date]);

  const GetLateInfo = ({ dates }) => {
    if (!dates) return null;

    const start = new Date(dates[0]).toLocaleDateString();
    const last = new Date(dates[dates.length - 1]).toLocaleDateString();
    const late = dates.filter((date) => isLate(date));

    return (
      <Space>
        <strong>{start}</strong>
        to
        <strong>{last}</strong>
        total <strong>{late.length}</strong> late
      </Space>
    );
  };

  const isLate = useCallback(
    (date) => {
      const time = new Date(date);
      const hour = time.getHours();
      const minute = time.getMinutes();

      if (
        hour > lastHour ||
        (hour >= 0 && hour <= 6) ||
        (hour === lastHour && minute > lastMinute)
      ) {
        return true;
      }
      return false;
    },
    [lastHour, lastMinute]
  );

  return (
    <RidersActivity.Provider
      value={{
        data,
        isLate,
        activeDate,
        setActiveDate,
        dayWiseOrders,
      }}
    >
      <Wrapper>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Space size={20} direction="vertical">
              <DatePicker.RangePicker
                size="large"
                onChange={(date, dateString) => {
                  setDate(dateString);
                }}
              />

              <Space>
                <Space>
                  <label>Last Hour</label>
                  <Select value={lastHour} onChange={(e) => setLastHour(e)}>
                    {[20, 21, 22, 23, 24].map((hour) => (
                      <Select.Option key={hour} value={hour}>
                        {hour % 12 || 12}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                <Space>
                  <label>Last Minute</label>
                  <InputNumber
                    placeholder="Last minute"
                    min={0}
                    max={59}
                    value={lastMinute}
                    onChange={(e) => {
                      if (e < 60 && e > 0) {
                        setLastMinute(e);
                      }
                    }}
                  />
                </Space>
              </Space>
            </Space>
          </Col>
          {data && (
            <Col span={12} style={{ textAlign: "right" }}>
              <h1>
                Rider <strong>{data.name}</strong>
              </h1>
              {data.attendance.length && (
                <GetLateInfo dates={data.attendance.map((i) => i.createdAt)} />
              )}
              <div>
                <Space>
                  <h4>Delivered</h4>
                  <div>{data.orders.length}</div>
                </Space>
              </div>
            </Col>
          )}
          <Attendance />
          <Orders />
        </Row>
      </Wrapper>
    </RidersActivity.Provider>
  );
}

const Wrapper = styled.div`
  .late {
    background-color: #ffc0cb;
  }
`;
