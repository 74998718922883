import React, { useState, useContext, memo, useCallback } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  message,
  InputNumber,
  Button,
  Row,
  Col,
  Modal,
  Select,
  Space,
} from "antd";
import { SectionsContext } from "./SectionsContext";

function CreateNewSection() {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const {
    createSection,
    newSection: { loading },
    hubs,
  } = useContext(SectionsContext);

  const onFinish = useCallback(
    (values) => {
      const resetForm = () => {
        form.resetFields();
        setOpen(false);
        message.success("New section created successfully");
      };
      createSection({ ...values, resetForm });
    },
    [form, createSection]
  );

  return (
    <div className="create-new-section-wrap">
      {!open && (
        <div onClick={(e) => setOpen(true)} className="create-new">
          <h3 className="title">Create New</h3>
          <div className="icon">
            <PlusOutlined />
          </div>
        </div>
      )}

      {open && (
        <Modal
          title="Create New Section"
          visible={open}
          onCancel={(e) => setOpen(false)}
          footer={null}
          style={{ paddingBottom: "10px" }}
        >
          <Form
            onFinish={onFinish}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            form={form}
            size="large"
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col span={16}>
                <Form.Item
                  name="name"
                  label="Section Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input section name!",
                    },
                  ]}
                  style={{ marginBottom: "0" }}
                >
                  <Input
                    size="large"
                    autoFocus
                    style={{
                      width: "100%",
                    }}
                    placeholder="Section name"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Select Hub (Optional)"
                  name="hubs"
                  style={{ marginBottom: "0px" }}
                >
                  <Select placeholder="Select hub" mode="multiple">
                    {hubs.data.map((hub) => {
                      return (
                        <Select.Option key={hub.id} value={hub.id}>
                          {hub.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
              <Space
                className="modal-footer"
                style={{ borderTop: "0", paddingBottom: "0" }}
              >
                <Button size="middle" onClick={(e) => setOpen(false)} danger>
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  size="middle"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default CreateNewSection;
