import { Descriptions } from "antd";
import ordersReport from "../../hooks/ordersReport";
import { useMemo } from "react";
import LineReport from "./LineReport";

export default function OrdersReport({ orders }) {
  const {
    length,
    confirm,
    ready,
    delivery,
    delivered,
    cancelled,
    rejected,
    hours,
    ordersPerDate,
  } = ordersReport(orders);

  const { Item } = Descriptions;

  return (
    <div style={{ margin: "20px 0" }}>
      {/* <LineReport ordersPerDate={ordersPerDate} /> */}
      <Descriptions
        title="Order Report"
        bordered={true}
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        style={{ background: "#fff", padding: "20px" }}
        layout="vertical"
      >
        {length > 0 && (
          <>
            <Item label="Total Orders">{length}</Item>
            <Item label="Average order confirmation time">
              {Math.round(confirm.time / confirm.count)} Minutes
            </Item>
            <Item label="Average order ready time">
              {Math.round(ready.time / ready.count)} Minutes
            </Item>
            <Item label="Average order delivery time">
              {Math.round(delivery.time / delivery.count)} Minutes
            </Item>
            <Item label="Success ratio">
              {Math.round(
                (delivered * 100) / (delivered + cancelled + rejected)
              )}
              %
            </Item>
            <Item label="Cancelled ratio">
              {Math.round(
                (cancelled * 100) / (delivered + cancelled + rejected)
              )}
              %
            </Item>
            <Item label="Rejected ratio" span={4}>
              {Math.round(
                (rejected * 100) / (delivered + cancelled + rejected)
              )}
              %
            </Item>
            {Object.keys(hours).length > 0 && (
              <Item label="Orders per hour" span={4}>
                <Descriptions
                  bordered={true}
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  {Object.keys(hours).map((hour, i) => {
                    return (
                      <Item
                        key={i}
                        label={`${hour === "0" ? 12 : hour % 12} ${
                          hour > 12 ? "PM" : "AM"
                        }`}
                      >
                        {hours[hour]}
                      </Item>
                    );
                  })}
                </Descriptions>
              </Item>
            )}
          </>
        )}
      </Descriptions>
    </div>
  );
}
