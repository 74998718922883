import React from 'react'
import { Form, Col, Button } from 'antd'

const { Item } = Form

export default function Footer({ resetForm, isNew, loading }) {
    return (
        <Col span={24}>
            <Item style={{marginBottom: '20px', textAlign: 'right'}}>
                {isNew && <Button onClick={e => resetForm(true)} size="large" type="dashed" htmlType="button" style={{marginRight: '16px', width: '100px'}} danger>Reset</Button>}
                <Button loading={loading} disabled={loading} size="large" type="primary" htmlType="submit" style={{width: '100px'}}>{isNew? 'Submit': 'Update'}</Button>
            </Item>
        </Col>
    )
}
