import "chart.js/auto";
import { Line } from "@ant-design/plots";

// const getChartsData = ({ date, sales }) => {
//   return {
//     labels: date,
//     datasets: [
//       {
//         label: `Sales`,
//         data: sales,
//         backgroundColor: "rgba(255, 99, 132, 0.2)",
//         borderColor: "rgba(255, 99, 132, 1)",
//         borderWidth: 2,
//         tension: 0.7,
//         fill: true,
//         showLine: false,
//       },
//     ],
//   };
// };

// export default function ProgressReport({ sales, date }) {
//   return (
//     <div style={{ marginTop: "20px" }}>
//       <Chart
//         type="line"
//         datasetIdKey="label"
//         data={getChartsData({ date, sales })}
//       />
//     </div>
//   );
// }
export default function ProgressReport({ sales, ordersValue }) {
  const config = (yField, data = sales) => ({
    data,
    padding: "auto",
    xField: "date",
    yField,
    xAxis: {
      tickCount: 20,
    },
    yAxis: {
      label: {
        formatter: (v) => `${v} tk`,
      },
    },
    smooth: true,
    // slider: {
    //   start: 0,
    //   end: 1,
    // },
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "median"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "line",
        start: ["min", "median"],
        end: ["max", "median"],
        style: {
          stroke: "#F4664A",
          lineDash: [2, 2],
        },
      },
    ],
  });

  return (
    <div style={{ marginTop: "50px" }}>
      <h2>Progression Report</h2>
      <div style={{ margin: "50px 0" }}>
        <h3 style={{ textAlign: "right" }}>Sales Report</h3>
        <Line {...config("total_sales")} />
      </div>
      <div style={{ margin: "50px 0" }}>
        <h3 style={{ textAlign: "right" }}>Order Delivered</h3>
        <Line {...config("order_delivered")} />
      </div>
      <div style={{ margin: "50px 0" }}>
        <h3 style={{ textAlign: "right" }}>Commission</h3>
        <Line {...config("commission")} />
      </div>
      {/* <div style={{ margin: "50px 0" }}>
        <h3 style={{ textAlign: "right" }}>Orders Value</h3>
        <Line
          {...config(
            "value",
            ordersValue.map((value, i) => ({
              value,
              date: i,
            }))
          )}
        />
      </div> */}
    </div>
  );
}
