import { createContext, useReducer } from "react";
import { message } from "antd";
import useCategories from "../../hooks/useCategories";
import { parser } from "../../utils";
import useMedia from "../../hooks/useMedia";

const Types = {
  CATEGORIES_REQUEST: "CATEGORIES_REQUEST",
  CATEGORIES_SUCCESS: "CATEGORIES_SUCCESS",
  CATEGORIES_FAILURE: "CATEGORIES_FAILURE",
};

export const CategoriesContext = createContext();

const initialState = {
  categories: { loading: false, data: { count: 0, results: [] }, error: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.CATEGORIES_REQUEST:
      state.categories.loading = true;
      return { ...state };
    case Types.CATEGORIES_SUCCESS:
      state.categories.loading = false;
      state.categories.data = action.payload;
      state.categories.error = null;
      return { ...state };
    case Types.CATEGORIES_FAILURE:
      state.categories.loading = false;
      state.categories.error = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default function CategoriesContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getMany, addNew, updateItem, deleteItem } = useCategories();
  const { deleteFile } = useMedia();

  // Get categories ...
  const getCategories = async (payloads) => {
    dispatch({ type: Types.CATEGORIES_REQUEST });

    getMany(payloads, (err, categories) => {
      if (categories) {
        dispatch({
          type: Types.CATEGORIES_SUCCESS,
          payload: {
            ...categories,
            results: parser(categories.results),
          },
        });
      }

      if (err) {
        dispatch({ type: Types.CATEGORIES_FAILURE, payload: err.message });
        message.error(err);
      }
    });
  };

  // create new category handler
  const createCategoryHandler = async (props, successCallback) => {
    addNew(props, (err, category) => {
      if (category) {
        state.categories.data.results.unshift({
          ...category.attributes,
          id: category.id,
        });
        state.categories.data.count++;

        dispatch({
          type: Types.CATEGORIES_SUCCESS,
          payload: state.categories.data,
        });
        successCallback();
      }

      if (err) {
        message.error(err);
      }
    });
  };

  // update category handler
  const updateCategoryHandler = async (
    { id, name, image, prep_time },
    successCallback
  ) => {
    updateItem({ id, data: { name, image, prep_time } }, (err, category) => {
      if (err) {
        message.error(err);
        return;
      }

      const cat = state.categories.data.results.find(
        (i) => i.id === category.id
      );
      if (cat) {
        cat.name = category.get("name");
        cat.image = category.get("image");
        cat.prep_time = category.get("prep_time");
        dispatch({
          type: Types.CATEGORIES_SUCCESS,
          payload: state.categories.data,
        });
      }
      successCallback();
      message.success("Category updated!");
    });
  };

  // delete category
  const deleteCategory = async (id, successCallback) => {
    deleteItem(id, (err, res) => {
      if (err) {
        message.error(err);
        return;
      }

      const catIndex = state.categories.data.results.findIndex(
        (i) => i.id === id
      );
      if (catIndex > -1) {
        const category = state.categories.data.results[catIndex];
        if (category) {
          deleteFile(category.image);
        }
        state.categories.data.results.splice(catIndex, 1);
        dispatch({
          type: Types.CATEGORIES_SUCCESS,
          payload: state.categories.data,
        });
        successCallback();
        message.success("Category deleted!");
      }
    });
  };

  const restrictionHandler = async (ref, v, cb) => {
    try {
      const res = await ref.set("is_restricted", v);
      cb(true);
      message.success("Category updated!");
    } catch (err) {
      message.error(err.message);
      cb(false);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        ...state,
        getCategories,
        updateCategoryHandler,
        createCategoryHandler,
        deleteCategory,
        restrictionHandler,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
}
