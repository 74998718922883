import { message } from "antd";
import Parse from "parse";

export default function useHub() {
  const repoName = "hub";
  const defaultFunc = (err, data) => {};

  const getHubs = async (
    { limit = 100, skip = 0, select } = {},
    cb = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      query.limit(limit);
      query.skip(skip);
      query.withCount();
      if (select) query.select(select);

      const hubs = await query.find({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      cb(null, hubs);
    } catch (err) {
      cb(err.message);
    }
  };

  const getById = async ({ id, select } = {}, cb = defaultFunc) => {
    if (!id) return cb("id is required");
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("objectId", id);
      if (select) query.select(select);

      const hub = await query.first({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      if (!hub) {
        cb("Hub not found");
      } else {
        cb(null, hub);
      }
    } catch (err) {
      cb(err.message);
    }
  };

  const createNew = async (
    { name, region, areas, address },
    cb = defaultFunc
  ) => {
    try {
      const hub = new Parse.Object(repoName);
      hub.set("name", name);
      hub.set("region", region);
      hub.set("areas", areas);
      hub.set("address", address);
      const res = await hub.save(null, {
        sessionToken: Parse.User.current().getSessionToken(),
      });
      cb(null, res);
    } catch (err) {
      cb(err.message);
    }
  };

  const updateItem = (
    { id, name, region, areas, address },
    cb = defaultFunc
  ) => {
    try {
      getById({ id }, async (err, ref) => {
        if (err) {
          cb(err);
        } else {
          if (name) ref.set("name", name);
          if (region) ref.set("region", region);
          if (areas) ref.set("areas", areas);
          if (address) ref.set("address", address);
          const res = await ref.save(null, {
            sessionToken: Parse.User.current().getSessionToken(),
          });
          cb(null, res);
        }
      });
    } catch (err) {
      cb(err.message);
    }
  };

  return {
    getHubs,
    getById,
    createNew,
    updateItem,
  };
}
