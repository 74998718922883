import { Table, Tooltip } from "antd";

export default function AreaWiseDeliveryTime({ riders, areaWise }) {
  const columns = [
    {
      title: "Area name",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Order count",
      dataIndex: "count",
      key: "order",
    },
    {
      title: "Average Delivery Time",
      dataIndex: "average",
      key: "average",
      width: "120px",
    },
  ];

  if (Array.isArray(riders)) {
    riders.forEach((rider) => {
      columns.push({
        title: rider.name,
        dataIndex: rider.id,
        key: rider.id,
        width: "70px",
        render: (value, { average }) =>
          value ? (
            <Tooltip title={rider.name}>
              <div
                style={{
                  background: value.average > average ? "#f35c8f" : "#00780099",
                  color: "white",
                  textAlign: "center",
                  minWidth: "60px",
                  padding: "5px",
                }}
              >
                <div>{value.average}</div>
                <div style={{ color: "black" }}>by {value.count}</div>
              </div>
            </Tooltip>
          ) : null,
      });
    });
  }

  const dataSource = (areaWise = [], riders = []) => {
    const ridersByArea = riders.reduce((acc, rider) => {
      const { id, areaWise } = rider;
      areaWise.forEach(({ area, average, count }) => {
        if (!acc[area]) {
          acc[area] = {};
        }
        acc[area][id] = {
          average,
          count,
        };
      });

      return acc;
    }, {});

    return areaWise.map((area) => {
      let data = area;
      if (ridersByArea[area.area]) {
        data = {
          ...data,
          ...ridersByArea[area.area],
        };
      }
      return data;
    });
  };

  return (
    <section style={{ padding: "20px", marginTop: "20px" }}>
      <div>last 1000 orders</div>
      <h1>Area Wise Delivery Time</h1>
      <Table
        columns={columns}
        dataSource={dataSource(areaWise, riders)}
        pagination={false}
        scroll={{
          x: true,
        }}
      />
    </section>
  );
}
