import { Table } from "antd";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchTravelLogs } from "../../../store/rider/riderSlices";

export default function TravelLogs() {
  const { data, loading } = useSelector((state) => state.rider.travelLogs);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (data.results.length === 0) {
      dispatch(
        fetchTravelLogs({
          riderId: id,
          cb: (query) => {
            query.select(["subject", "traveled", "resultedFrom"]);
          },
        })
      );
    }
  }, []);

  const columns = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "date",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Traveled",
      dataIndex: "traveled",
      key: "traveled",
      render: (traveled) => (traveled ? `${traveled} km` : "N/A"),
    },
    {
      title: "Resulted From",
      dataIndex: "resultedFrom",
      key: "resultedFrom",
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Table
        dataSource={data.results || []}
        columns={columns}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          total: data.count,
        }}
        scroll={{
          y: "500px",
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 400px;
  max-height: 600px;

  .title {
    font-size: 16px;
  }

  .ant-table {
    box-shadow: none;
  }
`;
