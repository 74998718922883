import { useContext, useEffect, useState, useCallback } from "react";
import { ProductListContext } from "../../../pages/Product/ProductList";
import {
  Row,
  Col,
  Space,
  Layout,
  Checkbox,
  Button,
  Input,
  Radio,
  Divider,
  Form,
} from "antd";
import styled from "styled-components";
import { compressedImage } from "../../../common/utils";
import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import RestaurantLinks from "../../Common/RestaurantLinks";

function ProductItem({
  images,
  name,
  category,
  price,
  restaurant,
  id,
  availability,
  refs,
}) {
  return (
    <Col span={24} sm={12} md={8} lg={8} xl={6}>
      <div className="product-item">
        <img className="image" alt="product" src={compressedImage(images[0])} />
        <div className="price">{price.amount} tk</div>
        <div className="res-thumb">
          <RestaurantLinks id={restaurant.id}>
            <img alt="" src={compressedImage(restaurant.get("banner_image"))} />
          </RestaurantLinks>
        </div>
        <div className="category">{category.get("name")}</div>
        <div className="body">
          <h3 className="name">{name}</h3>
        </div>
      </div>
    </Col>
  );
}

function Filter({ groupBy, setGroupBy }) {
  const { restaurants, categories, filter, setFilter, getProducts } =
    useContext(ProductListContext);
  const [toggle, setToggle] = useState({
    restaurant: false,
    category: false,
  });
  const [form] = Form.useForm();

  const toggler = (key) => {
    setToggle({ ...toggle, [key]: !toggle[key] });
  };

  const sortedRestaurant = (res) => {
    if (!Array.isArray(res)) return [];
    res.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return res;
  };

  const onSubmit = (value) => {
    getProducts({ ...value, limit: 100 });
  };

  const { Item } = Form;

  return (
    <Layout.Sider className="sidebar">
      <Form form={form} onFinish={onSubmit}>
        <div className="header">
          <Button htmlType="submit">Filter</Button>
        </div>
        <div>
          <Item name="name" style={{ marginBottom: "0" }}>
            <Input placeholder="Search" />
          </Item>
        </div>
        <div className="group-by">
          <h3 className="title">Group By</h3>
          <Radio.Group
            onChange={(e) => {
              setGroupBy(e.target.value);
            }}
          >
            <Radio value="restaurant">Restaurant</Radio>
            <Radio value="category">Category</Radio>
          </Radio.Group>
        </div>
        <div className="restaurants customScroll">
          <h3 onClick={() => toggler("restaurant")} className="title">
            Restaurants{" "}
            {!toggle.restaurant ? (
              <CaretRightOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </h3>
          <Item
            name="restaurants"
            style={{ display: toggle.restaurant ? "block" : "none" }}
          >
            <Checkbox.Group
              onChange={(e) => {
                setFilter({ ...filter, restaurants: e });
              }}
              className="items"
            >
              <Space direction="vertical">
                {sortedRestaurant(restaurants.data)?.map((res) => {
                  return <Checkbox value={res.id}>{res.name}</Checkbox>;
                })}
              </Space>
            </Checkbox.Group>
          </Item>
        </div>
        <div className="restaurants customScroll">
          <h3 onClick={() => toggler("category")} className="title">
            Categories{" "}
            {!toggle.category ? <CaretRightOutlined /> : <CaretDownOutlined />}
          </h3>
          <Item
            name="category"
            style={{ display: toggle.category ? "block" : "none" }}
          >
            <Checkbox.Group
              onChange={(e) => {
                setFilter({ ...filter, category: e });
              }}
              className="items"
            >
              <Space direction="vertical">
                {categories.data?.map((res) => {
                  return <Checkbox value={res.id}>{res.name}</Checkbox>;
                })}
              </Space>
            </Checkbox.Group>
          </Item>
        </div>
      </Form>
    </Layout.Sider>
  );
}

export default function ProductsGridView() {
  const { products, getProducts, filter } = useContext(ProductListContext);
  const [groupBy, setGroupBy] = useState("restaurant");

  const groupProducts = useCallback(
    (data) => {
      if (!Array.isArray(data)) return [];

      const res = data.reduce((acc, item) => {
        if (groupBy === "restaurant") {
          if (!acc[item.restaurant.id]) {
            acc[item.restaurant.id] = {
              id: item.restaurant.id,
              name: item.restaurant.get("name"),
              image: item.restaurant.get("banner_image"),
              items: [],
            };
          }

          acc[item.restaurant.id].items.push(item);
        } else if (groupBy === "category") {
          if (!acc[item.category.id]) {
            acc[item.category.id] = {
              id: item.category.id,
              name: item.category.get("name"),
              image: item.category.get("image"),
              items: [],
            };
          }

          acc[item.category.id].items.push(item);
        }

        return acc;
      }, {});

      return Object.values(res);
    },
    [groupBy]
  );

  useEffect(() => {
    getProducts({
      ...filter,
    });
  }, []);

  return (
    <Wrapper>
      <Layout>
        <div className="products" style={{ width: "100%" }}>
          <Row gutter={[16, 24]}>
            {groupProducts(products.data?.results)?.map(
              ({ id, name, image, items }, idx) => {
                return (
                  <Col span={24} key={idx}>
                    <Divider>
                      <Space>
                        <img
                          src={compressedImage(image)}
                          alt=""
                          style={{ width: "70px", borderRadius: "10px" }}
                        />
                        {name}
                      </Space>
                    </Divider>
                    <Row gutter={[16, 24]}>
                      {items.map((product) => (
                        <ProductItem key={product.id} {...product} />
                      ))}
                    </Row>
                  </Col>
                );
              }
            )}
          </Row>
        </div>
        <Filter groupBy={groupBy} setGroupBy={setGroupBy} />
      </Layout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  .products {
    padding: 0 20px;
    margin-right: 200px;
  }

  .sidebar {
    .header {
      font-size: 16px;
      padding: 10px 20px;
      border-bottom: 1px solid #e8e8e8;

      &:hover {
      }
    }
    position: fixed;
    top: 64px;
    right: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: #cccccc;
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 7px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .group-by {
      padding: 10px 20px;

      .title {
        margin-bottom: 0;
      }
    }

    .restaurants {
      .title {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        font-size: 20px;
        background: #f5f5f5;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 0;
      }

      .items {
        padding: 5px 5px 20px 20px;
        animation: fadeIn 0.5s;
        height: 300px;
        overflow-y: auto;
      }
    }
  }

  .product-item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;

    .price {
      width: 70px;
      height: 30px;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      margin: 10px;
      display: flex;
      padding: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
      font-weight: 500;
    }

    .category {
      position: absolute;
      min-width: 100px;
      height: 30px;
      background: #fff;
      left: 10px;
      top: 50px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .res-thumb {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 100%;
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
    }

    .image {
      width: 100%;
      height: 150px;
    }

    .body {
      padding: 10px 10px;
      min-height: 120px;

      .name {
        margin-bottom: 0;
      }
    }
  }
`;
