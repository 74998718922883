import { useEffect, useState } from "react";
import {
  Form,
  InputNumber,
  Button,
  Row,
  Col,
  message,
  DatePicker,
  Select,
  Space,
} from "antd";
import Styled from "styled-components";
import usePurchaseRecord from "../../hooks/usePurchaseRecord";
import { parser } from "../../utils";
import moment from "moment";
import { useParams } from "react-router-dom";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

export default function UpdatePurchaseRecord() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { updatePurchaseRecord, getById } = usePurchaseRecord();
  const { id } = useParams();
  const [purchaseRecord, setPurchaseRecord] = useState({
    loading: false,
    data: null,
  });
  const [data, setData] = useState(null);

  const onFinish = (values) => {
    if (values.unit_price > values.selling_price) {
      const ok = window.confirm(
        "Unit price is higher than selling price. Are you sure?"
      );
      if (!ok) return;
    }

    if (values.expired_date) {
      values.expired_date = new Date(values.expired_date).toISOString();
    }

    setLoading(true);
    updatePurchaseRecord({ id, ...values }, (err, res) => {
      setLoading(false);
      if (err) {
        message.error(err);
      } else if (res) {
        message.success("Purchase record updated successfully!");
      }
    });
  };

  useEffect(() => {
    setPurchaseRecord({ loading: true, data: null });
    getById(id, (err, res) => {
      if (err) {
        message.error(err);
        setPurchaseRecord({ loading: false, data: null });
      }

      if (res) {
        res = parser(res);
        setData(res);
        setPurchaseRecord({ loading: false, data: res });
        form.setFieldsValue({
          quantity: res.quantity,
          unit_price: res.unit_price,
          selling_price: res.selling_price,
          product: res.product?.id,
          expired_date: res.expired_date ? moment(res.expired_date) : null,
        });
      }
    });

    return () => {
      form.resetFields();
    };
  }, [id]);

  const getVariants = (variants, filter = []) => {
    if (!Array.isArray(variants)) return [];
    let arr = [];
    variants.forEach(({ items }) => {
      items.forEach((item) => {
        if (!filter.find((f) => f?.id === item.id)) {
          arr.push(item);
        }
      });
    });
    return arr;
  };

  return (
    <section>
      <Row justify="center" gutter={[24, 24]}>
        <Col md={18} lg={14} xl={12}>
          <FormStyled
            form={form}
            layout="vertical"
            name="addNewRecord"
            onFinish={onFinish}
            size="large"
          >
            <div className="header">
              <div className="left-section">
                <h3 className="title">Edit purchase record</h3>
              </div>
            </div>
            {!(id && !purchaseRecord.data) && (
              <Row gutter={[16, 16]}>
                {purchaseRecord.data && (
                  <Col span={12}>
                    <Form.Item label="Product name">
                      <h3 className="product-name">
                        {purchaseRecord.data.product?.get("name")}
                      </h3>
                    </Form.Item>
                  </Col>
                )}

                <Col span={12}>
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    rules={[
                      {
                        type: "number",
                        required: true,
                        min: 1,
                        message: "Please input quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={data && data.variants}
                      placeholder="Quantity"
                    />
                  </Form.Item>
                </Col>

                {data && !data.variants ? (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="unit_price"
                        label="Unit Price"
                        rules={[
                          {
                            required: true,
                            message: "Please input purchase price!",
                          },
                        ]}
                      >
                        <InputNumber min={0} placeholder="Unit Price" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="selling_price"
                        label="Selling Price"
                        rules={[
                          {
                            required: true,
                            message: "Please input selling price!",
                          },
                        ]}
                      >
                        <InputNumber min={0} placeholder="Selling Price" />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item name="expired_date" label="Expires On">
                        <DatePicker
                          disabledDate={(current) => {
                            return current.isBefore(Date.now(), current);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  data && (
                    <Col span={24}>
                      <h3>Variants</h3>
                      <div className="variants">
                        <div className="variant-item hr">
                          <div className="name">Name</div>
                          <div className="quantity">Quantity</div>
                          <div className="stock">Stock</div>
                        </div>
                        {data.variants.map((variant, index) => (
                          <div key={index} className="variant-item">
                            <div className="name">{variant.name}</div>
                            <div className="quantity">{variant.quantity}</div>
                            <div className="stock">{variant.stock}</div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  )
                )}
                <Col span={24}>
                  <Form.Item>
                    <Button
                      style={{ width: "100%" }}
                      loading={loading}
                      htmlType="submit"
                      type="primary"
                    >
                      {purchaseRecord.data ? "Update" : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </FormStyled>
        </Col>
      </Row>
    </section>
  );
}

const FormStyled = Styled(Form)`
    background: #fff;
    padding: 20px;
    margin-top: 30px;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        margin-bottom: 24px;

        .left-section{
            display: flex;
            align-items: center;
            justify-content: center;

            .title{
                font-size: 24px;
                margin-bottom: 0;
                letter-spacing: 1px;
                font-weight: 500;
            }
        }
    }

    .ant-input-number{
        width: 100%;
    }

    .ant-form-item{
        margin-bottom: 0px;
    }

    .product-name{
        font-size: 18px;
        background: #001529;
        min-height: 40px;
        padding: 10px 20px;
        color: #fff;
        letter-spacing: 1px;
        border-radius: 2px;
    }

    .variants {
      .variant-item{
        padding: 10px 20px;
        display: flex;
        background: #e3e3e3;
        margin-bottom: 10px;
        border-radius: 2px;

        &.hr {
          font-weight: 500;
          background: #5b5a5a;
          color: #fff;
        }
        
        .name {
          width: 180px;
          font-size: 14px;
          font-weight: 500;
        }
        .quantity {
          width: 100px;
        }
        .stock {
          width: 100px;
        }
      }
    }
`;
