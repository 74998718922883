import { permission } from "../../common/utils";
import {
  BiHome,
  BiCart,
  BiBox,
  BiCycling,
  BiUser,
  BiCreditCard,
  BiCollection,
  BiStore,
  BiRocket,
  BiCarousel,
  BiCog,
} from "react-icons/bi";
import { FaWarehouse } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { GiCook } from "react-icons/gi";
import { ApartmentOutlined } from "@ant-design/icons";

export const menu = [
  {
    title: "Dashboard",
    icon: <BiHome />,
    link: "/dashboard",
    key: "dashboard",
  },
  {
    title: "Campaign",
    icon: <ApartmentOutlined />,
    base: "/campaign",
    items: [
      {
        title: "World Cup",
        icon: "",
        link: "/world-cup-leader-board",
        key: "world_cup_leader_board",
      },
    ],
  },
  {
    title: "Reports",
    icon: <IoMdAnalytics />,
    base: "/report",
    items: [
      {
        title: "Rider & Restaurant",
        icon: "",
        link: "/rider-restaurant",
        key: "rider_restaurant_report",
      },
      {
        title: "Searches",
        icon: "",
        link: "/searches",
        key: "searches",
      },
    ],
  },
  {
    title: "Orders",
    icon: <BiCart />,
    base: "/order",
    items: [
      {
        title: "Dashboard",
        icon: "",
        link: "/",
        key: "order_dashboard",
      },
      {
        title: "Order Processor",
        icon: "",
        link: "/processor",
        key: "order_processor",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "order_list",
      },
      {
        title: "Issues",
        icon: "",
        link: "/issues",
        key: "order_issues",
      },
      {
        title: "Archived Orders",
        icon: "",
        link: "/archived",
        key: "archived_orders",
      },
    ],
  },
  {
    title: "Product",
    icon: <BiBox />,
    base: "/product",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "product_list",
      },
      {
        title: "Pending Approval",
        icon: "",
        link: "/approval",
        key: "pending_product_approval",
      },
      {
        title: "Update Requests",
        icon: "",
        link: "/change-requests",
        key: "product_change_request",
      },
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_product",
      },
      {
        title: "Categories",
        icon: "",
        link: "/categories",
        key: "categories",
      },
      {
        title: "Inventory Records",
        icon: "",
        link: "/inventory-records",
        key: "inventory_records",
      },
    ],
  },
  {
    title: "Restaurants",
    icon: <BiStore />,
    base: "/restaurant",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "restaurant_list",
      },
      {
        title: "Sales",
        icon: "",
        link: "/sales",
        key: "restaurant_sales",
      },
      {
        title: "Add",
        icon: "",
        link: "/add",
        key: "add_restaurant",
      },
    ],
  },
  {
    title: "Rider",
    icon: <BiCycling />,
    base: "/rider",
    items: [
      {
        title: "Registration",
        icon: "",
        link: "/registration",
        key: "rider_registration",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "rider_list",
      },
      {
        title: "Dashboard",
        icon: "",
        link: "/dashboard",
        key: "rider_dashboard",
      },
      {
        title: "Leader Board",
        icon: "",
        link: "/leaderboard",
        key: "rider_leaderboard",
      },
      {
        title: "Settings",
        icon: "",
        link: "/settings",
        key: "rider_settings",
      },
    ],
  },
  {
    title: "User",
    icon: <BiUser />,
    base: "/user",
    items: [
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_user",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "user_list",
      },
      {
        title: "OTP",
        icon: "",
        link: "/otp",
        key: "otp_store",
      },
    ],
  },
  {
    title: "Sections",
    icon: <BiCollection />,
    base: "/sections",
    key: "sections",
    items: [
      {
        title: "Dashboard",
        icon: "",
        link: "/dashboard",
        key: "sections_dashboard",
      },
      {
        title: "Scheduling",
        icon: "",
        link: "/scheduling",
        key: "sections_scheduling",
      },
      {
        title: "Slots Map",
        icon: "",
        link: "/slots-map",
        key: "section_slots_map",
      },
    ],
  },
  {
    title: "Cuisines",
    icon: <GiCook />,
    link: "/cuisines",
    key: "cuisines",
  },
  {
    title: "Payments",
    icon: <BiCreditCard />,
    base: "/payments",
    items: [
      {
        title: "Payments",
        icon: "",
        link: "/",
        key: "payments",
      },
      {
        title: "Invoice History",
        icon: "",
        link: "/history",
        user: permission("100"),
        key: "invoice_history",
      },
    ],
  },
  {
    title: "Inventory",
    icon: <FaWarehouse />,
    base: "/inventory",
    items: [
      {
        title: "Add",
        icon: "",
        link: "/add",
        user: permission("11"),
        key: "add_inventory",
      },
      {
        title: "History",
        icon: "",
        link: "/list",
        user: permission("11"),
        key: "inventory_history",
      },
    ],
    user: permission("11"),
  },
  {
    title: "Promo",
    icon: <BiRocket />,
    base: "/promo",
    items: [
      {
        title: "List",
        icon: "",
        link: "/list",
        key: "promo_list",
      },
      {
        title: "Add New",
        icon: "",
        link: "/add",
        key: "add_promo",
      },
    ],
  },
  {
    title: "Carousel",
    icon: <BiCarousel />,
    link: "/carousel",
    key: "carousel",
  },
  {
    title: "Promotional Banner",
    icon: <BiCarousel />,
    link: "/promotional-banner",
    key: "promotional_banner",
  },
  {
    title: "Hub",
    icon: <BiCog />,
    base: "/hub",
    items: [
      {
        title: "Create New",
        icon: "",
        link: "/create-new",
        user: permission("1"),
        key: "add_hub",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        user: permission("1"),
        key: "hub_list",
      },
      {
        title: "Area list",
        icon: "",
        link: "/areas",
        user: permission("1"),
        key: "area_list",
      },
    ],
    user: permission("1"),
  },
  {
    title: "Survey",
    icon: <IoMdAnalytics />,
    base: "/survey",
    items: [
      {
        title: "Create New",
        icon: "",
        link: "/create",
        user: permission("1"),
        key: "add_survey",
      },
      {
        title: "List",
        icon: "",
        link: "/list",
        user: permission("1"),
        key: "survey_list",
      },
    ],
    user: permission("1"),
  },
  {
    title: "Config",
    icon: <BiCog />,
    base: "/config",
    items: [
      {
        title: "General",
        icon: "",
        link: "/",
        user: permission("1"),
        key: "config_general",
      },
      {
        title: "Role Permissions",
        icon: "",
        link: "/roles",
        user: permission("1"),
        key: "role_management",
      },
      {
        title: "Profile",
        icon: "",
        link: "/admin/me",
        user: permission("1"),
        key: "profile",
      },
      {
        title: "Logs",
        icon: "",
        link: "/logs",
        user: permission("1"),
        key: "logs",
      },
    ],
    user: permission("1"),
  },
];

export default menu;
