import { Skeleton, Row, Col } from "antd";

const Item = (props) => (
  <Col sm={24} md={12} lg={8}>
    <Skeleton.Input
      style={{ width: "330px", height: "200px", borderRadius: "10px" }}
      active
      size="large"
    />
  </Col>
);

export default function OrdersSkeleton() {
  return (
    <Row gutter={[24, 24]} style={{ padding: "20px 0", width: "99%" }}>
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </Row>
  );
}
