import menu from "./menu";
import { Layout, Menu } from "antd";
import Styled from "styled-components";
import { Link } from "react-router-dom";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function MenuTop({ collapsed }) {
  return (
    <MenuTopWrapper>
      <p className="name">{collapsed ? "M" : "Munchies"}</p>
      <p className="v">v{process.env.REACT_APP_APP_VERSION}</p>
    </MenuTopWrapper>
  );
}

const MenuTopWrapper = Styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;

    p{
        margin-bottom: 0;
    }
    .v{
        font-size: 12px;
        margin-right: 10px;
        color: gray;
    }

`;

const accessControl = (menu, pages = [], type) => {
  if (!Array.isArray(menu)) return [];

  const pagesObj = {};

  pages.forEach((key) => {
    pagesObj[key] = true;
  });

  const newItems = [];

  menu.forEach((item) => {
    const { key, items: subMenu } = item;

    if (type === "admin") {
      newItems.push(item);
      return;
    }

    if (key && pagesObj[key]) {
      newItems.push(item);
    } else if (!key && Array.isArray(subMenu)) {
      const newSubMenu = [];

      subMenu.forEach((subItem) => {
        const { key: subKey } = subItem;

        if (subKey && pagesObj[subKey]) {
          newSubMenu.push(subItem);
        }
      });

      if (newSubMenu.length > 0) {
        item.items = newSubMenu;
        newItems.push(item);
      }
    }
  });

  return newItems;
};

export default function Sidebar({ collapsed, setCollapsed, authData, pages }) {
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    if (collapsed) {
      localStorage.setItem("menu-collapsed", true);
    } else {
      localStorage.removeItem("menu-collapsed");
    }
  };

  const getActiveMenu = () => {
    const url = new URL(window.location);
    const paths = url.pathname
      .substring(1)
      ?.split("/")
      .map((i) => "/" + i);
    if (Array.isArray(paths) && paths.length) {
      return paths[0];
    } else {
      return "/";
    }
  };

  const getActiveKey = () => {
    const url = new URL(window.location);
    const paths = url.pathname
      .substring(1)
      ?.split("/")
      .map((i) => "/" + i);
    if (Array.isArray(paths) && paths.length) {
      return paths.join("");
    } else {
      return "/";
    }
  };

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="customScroll"
      theme="light"
    >
      <MenuTop collapsed={collapsed} />
      <Menu
        mode="inline"
        theme="light"
        defaultSelectedKeys={[getActiveKey()]}
        defaultOpenKeys={[getActiveMenu()]}
        style={{
          fontSize: "16px",
          letterSpacing: ".5px",
          margin: 0,
          padding: 0,
          paddingBottom: "48px",
        }}
      >
        {accessControl(menu, pages, authData.type).map((menuItem) =>
          menuItem.items ? (
            <SubMenu
              key={menuItem.base}
              title={menuItem.title}
              icon={menuItem.icon}
            >
              {menuItem.items.map((subMenuItem) => (
                <Item
                  style={{ margin: 0 }}
                  key={menuItem.base + subMenuItem.link}
                >
                  <Link to={menuItem.base + subMenuItem.link}>
                    {subMenuItem.title}
                  </Link>
                </Item>
              ))}
            </SubMenu>
          ) : (
            <Item key={menuItem.link} icon={menuItem.icon}>
              <Link to={menuItem.link}>{menuItem.title}</Link>
            </Item>
          )
        )}
      </Menu>
    </Sider>
  );
}
