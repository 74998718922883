import { useContext } from "react";
import { Col, Row, Table, Divider } from "antd";
import { RidersActivity } from "../../../pages/Rider/Attendance";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Statistics = () => {
  const { data, activeDate, dayWiseOrders } = useContext(RidersActivity);

  const Item = ({ title, value }) => {
    return (
      <Col span={24} md={8} lg={6}>
        <div className="s-item">
          <h4 className="title">{title}</h4>
          <p className="value">{value}</p>
        </div>
      </Col>
    );
  };

  const getCollection = (orders) => {
    let collection = 0;
    orders?.forEach((order) => {
      if (order.get("payment_method") === "cod") {
        collection += order.get("charge").total;
      }
    });

    return collection;
  };

  const orders = activeDate ? dayWiseOrders(activeDate) : data?.orders;

  return (
    <StatisticsWrapper gutter={[16, 16]}>
      {activeDate && (
        <Item title="Date" value={new Date(activeDate).toLocaleDateString()} />
      )}
      <Item title="Cash Collection" value={getCollection(orders) + " tk"} />
      <Item title="Total Delivered" value={orders.length} />
    </StatisticsWrapper>
  );
};

const StatisticsWrapper = styled(Row)`
  margin-bottom: 20px;

  .s-item {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .title,
    .value {
      margin-bottom: 0;
    }
  }
`;

export default function Orders() {
  const { activeDate, dayWiseOrders, data } = useContext(RidersActivity);

  const ordersColumn = [
    {
      title: "ID",
      dataIndex: "objectId",
      key: "id",
      render: (id) => <Link to={`/order/list/${id}/details`}>{id}</Link>,
    },
    {
      title: "Total",
      dataIndex: "charge",
      key: "total",
      width: "100px",
      render: (charge) => charge.total,
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      width: "120px",
    },
    {
      title: "Delivery Charge",
      dataIndex: "charge",
      key: "delivery_charge",
      width: "120px",
      render: (charge) => charge.delivery_charge,
    },
    {
      title: "Delivery Area",
      dataIndex: "customer_area",
      key: "customer_area",
    },
    {
      title: "Delivered at",
      dataIndex: "completedAt",
      key: "completed_at",
      render: (date) => new Date(date.iso).toLocaleString(),
    },
  ];

  return data ? (
    <Col span={24} lg={14}>
      <Statistics />
      <Table
        columns={ordersColumn}
        dataSource={
          activeDate
            ? dayWiseOrders(activeDate).map((order) => order.toJSON())
            : data.orders.map((order) => order.toJSON())
        }
        pagination={false}
        scroll={{
          x: "max-content",
          y: "600PX",
        }}
      />
    </Col>
  ) : null;
}
