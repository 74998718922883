import { Button, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { saveFile } from "../../../store/rider/riderCrudSlices";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";

export default function AddImage() {
  const dispatch = useDispatch();
  const { file } = useSelector((state) => state.riderCrud.addRider);

  const uploadOnChange = async ({ fileList }) => {
    if (fileList[0]?.originFileObj) {
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileList[0]?.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

      const file = fileList[0];
      file.thumbUrl = src;
      dispatch(saveFile(file));
    }
  };

  return (
    <div>
      <Upload.Dragger
        beforeUpload={() => false}
        style={{
          marginBottom: "10px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          background: "#fff",
          display: file ? "none" : "block",
        }}
        name="files"
        listType="picture"
        multiple={false}
        type="file"
        showUploadList={false}
        onChange={uploadOnChange}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      {file && (
        <div className="profile-picture">
          <img src={file.thumbUrl} alt="" />
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            className="close-btn"
            onClick={() => {
              saveFile(null);
            }}
          ></Button>
        </div>
      )}
    </div>
  );
}
