import { useContext, useEffect, useState } from "react";
import { UserListContext } from "../../pages/User/UserList";
import { Table, Switch, DatePicker, Button } from "antd";
import useSearch from "../inventory/utils/useSearch";
import UserLinks from "../Common/UserLinks";
import {
  SettingOutlined,
  CalendarOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Header from "./Header";

const TextWrapper = ({ children, style = {}, ...rest }) => (
  <span
    style={{
      fontSize: "16px",
      letterSpacing: ".5px",
      color: "#3c3c3c",
      fontWeight: "500",
      ...style,
    }}
    {...rest}
  >
    {children}
  </span>
);

export default function UsersTable() {
  const {
    fetchUsers,
    users,
    activeHandler,
    selectedRowKeys,
    onSelectChange,
    downloadUsers,
  } = useContext(UserListContext);
  const { loading, data } = users;
  const [, getColumnSearchProps] = useSearch();
  const userSelect = [
    "objectId",
    "username",
    "name",
    "phone",
    "email",
    "type",
    "active",
    "verified",
    "order_count",
    "device_info",
    "gender",
    "date_of_birth",
  ];
  const [params, setParams] = useState({ limit: 100, select: userSelect });

  useEffect(() => {
    fetchUsers(params);
  }, [params]);

  const typeColors = {
    admin: { color: "black", background: "rgb(255 203 221 / 63%)" },
    customer: { color: "black", background: "rgb(33 137 41 / 22%)" },
    csr: { color: "white", background: "#b71c1c73" },
    manager: { color: "white", background: "#8921094a" },
    rider: { color: "white", background: "#00acc157" },
    partner: { color: "black", background: "rgb(255 165 0 / 32%)" },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
  });

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "160px",
      ...getColumnSearchProps("username"),
      render: (text, { id, type }) => {
        return (
          <UserLinks id={id} type={type}>
            {text}
          </UserLinks>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "160px",
      ...dateSearchProps("createdAt"),
      render: (date) => {
        return new Date(date).toLocaleString();
      },
    },
    {
      title: "Platform",
      dataIndex: "device_info",
      key: "device_info",
      width: "160px",
      filters: [
        { text: "Android", value: "Android" },
        { text: "iOS", value: "iOS" },
        { text: "Website", value: "web" },
      ],
      render: (data) => data?.type || "Website",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "160px",
      filters: [
        { text: "Customer", value: "customer" },
        { text: "Admin", value: "admin" },
        { text: "Manager", value: "manager" },
        { text: "Rider", value: "rider" },
        { text: "Partner", value: "partner" },
        { text: "CSR", value: "csr" },
        { text: "Product Manager", value: "product manager" },
      ],
      render: (text) => (
        <TextWrapper style={{ textTransform: "capitalize", color: "black" }}>
          {text}
        </TextWrapper>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "200px",
      ...getColumnSearchProps("name"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "160px",
      ...getColumnSearchProps("phone"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "180px",
      ...getColumnSearchProps("email"),
      render: (text) => <TextWrapper>{text}</TextWrapper>,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "100px",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
    },
    {
      title: "Date of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: "180px",
      ...dateSearchProps("date_of_birth"),
      render: (date) => (date ? new Date(date).toLocaleString() : "N/A"),
    },
    {
      title: "Order Count",
      dataIndex: "order_count",
      key: "order_count",
      width: "160px",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      width: 100,
      render: (active, { id }) => (
        <div>
          <Switch
            onChange={(active) => activeHandler({ id, active })}
            checked={active}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (_, { id, type }) => (
        <span>
          <UserLinks id={id} type={type}>
            <SettingOutlined />
          </UserLinks>
        </span>
      ),
    },
  ];

  const onChange = (pagination, filters) => {
    setParams({
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      type: filters.type ? filters.type : "",
      name: filters.name ? filters.name[0] : "",
      phone: filters.phone ? filters.phone[0] : "",
      email: filters.email ? filters.email[0] : "",
      username: filters.username ? filters.username[0] : "",
      active: filters.active ? filters.active[0] : "",
      verified: filters.verified ? filters.verified[0] : "",
      createdAt: filters.createdAt?.[0],
      select: userSelect,
      platform: filters.device_info,
      gender: filters.gender,
      date_of_birth: filters.date_of_birth?.[0],
    });
  };

  const mapUsers = (users) => {
    return users.map((user) => ({
      key: user.id,
      id: user.id,
      ...user,
    }));
  };

  return (
    <div>
      <Header />
      <div>
        <Button
          onClick={() => {
            downloadUsers(params);
          }}
          icon={<DownloadOutlined />}
          type="primary"
        >
          Download
        </Button>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        loading={loading}
        onRow={({ type }) => {
          return {
            style: {
              background: typeColors[type]?.background ?? "#fff",
            },
          };
        }}
        dataSource={mapUsers(data.results)}
        pagination={{
          total: data.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "50", "100", "500", "1000", "5000"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          position: ["topLeft", "topRight"],
        }}
        scroll={{ x: 1000, y: 1000 }}
        onChange={onChange}
      />
    </div>
  );
}
