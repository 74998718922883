import {
  Button,
  DatePicker,
  Form,
  Modal,
  Row,
  Col,
  Select,
  message,
  Space,
  Tag,
} from "antd";
import { useState, useContext } from "react";
import Parse from "parse";
import { SchedulingContext } from "./Scheduling";
import { days, hours, slots } from "./Scheduling";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

function NewForm({ form, sections }) {
  return (
    <Form.List name="items">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index, arr) => {
              return (
                <div className="section" key={index}>
                  <div className="counter">{index + 1}</div>
                  <Row gutter={[16, 0]} className="body">
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        label="Select Section"
                        name={[name, "section"]}
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select placeholder="Select Section">
                          {sections.map((section) => (
                            <Select.Option key={section.id} value={section.id}>
                              {section.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        label="Select Slot"
                        name={[name, "position"]}
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select placeholder="Select Slot">
                          {slots.map((slot) => (
                            <Select.Option key={slot} value={slot}>
                              {slot}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...restField}
                        label={
                          <div>
                            Days{" "}
                            <Tag
                              onClick={() => {
                                form.setFieldsValue({
                                  items: arr.map((item) => {
                                    return {
                                      ...item,
                                      days: days.map((day) => day.value),
                                    };
                                  }),
                                });
                              }}
                            >
                              All
                            </Tag>
                          </div>
                        }
                        name={[name, "days"]}
                        rules={[
                          {
                            required: true,
                            message: "Required",
                          },
                        ]}
                      >
                        <Select mode="multiple" placeholder="Select Slot">
                          {days.map(({ value, label }) => (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        {...restField}
                        label={
                          <div>
                            Hours{" "}
                            <Tag
                              onClick={() => {
                                const values = form.getFieldsValue();
                                values.items[index].hours = hours.map(
                                  (hour) => hour.value
                                );
                                form.setFieldsValue({
                                  items: values.items,
                                });
                              }}
                            >
                              All
                            </Tag>
                          </div>
                        }
                        name={[name, "hours"]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select Hours"
                          rules={[
                            {
                              required: true,
                              message: "Required",
                            },
                          ]}
                        >
                          {hours.map(({ value, label }) => (
                            <Select.Option key={value} value={value}>
                              {label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    icon={<MinusOutlined />}
                    onClick={() => remove(name)}
                    shape="circle"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              );
            })}

            <div style={{ textAlign: "center" }}>
              <Button
                size="large"
                icon={<PlusOutlined />}
                onClick={() => add()}
                shape="circle"
                type="primary"
              />
            </div>
          </>
        );
      }}
    </Form.List>
  );
}

export default function AddNewSlot() {
  const { sections, restaurants, fetchRestaurants } =
    useContext(SchedulingContext);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  function checkConflict(items) {
    return items.find((item, index) => {
      const { section, days, hours, position } = item;
      const conflict = items.find(
        (item, i) =>
          i !== index &&
          item.section === section &&
          item.position === position &&
          item.days.some((day) => days.includes(day)) &&
          item.hours.some((hour) => hours.includes(hour))
      );
      if (conflict) {
        message.error(
          `Conflict between ${index + 1} and ${items.indexOf(conflict) + 1}`
        );
      }

      return conflict;
    });
  }

  const onFinish = async ({ start_date, end_date, restaurant, items }) => {
    start_date = new Date(start_date).toISOString();
    end_date = new Date(end_date).toISOString();

    if (checkConflict(items)) {
      return;
    }

    if (!items || !items.length) {
      message.error("No items added");
      return;
    }

    const requests = items.map((item) => ({
      start_date,
      end_date,
      restaurant,
      ...item,
    }));

    async function create(index) {
      const payload = requests[index];
      if (!payload) {
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        await Parse.Cloud.run("addNewSchedule", payload);
        delete payload.start_date;
        delete payload.end_date;
        delete payload.restaurant;

        const id = JSON.stringify(payload);
        const items = form.getFieldsValue().items;
        const idIndex = items.findIndex((item) => {
          return JSON.stringify(item) === id;
        });

        if (idIndex !== -1) {
          items.splice(idIndex, 1);
          form.setFieldsValue({ items });
        }

        create(index + 1);
      } catch (err) {
        message.error(err.message);
        setLoading(false);
      }
    }

    create(0);
  };

  return (
    <div>
      <Button onClick={() => setVisible(true)} icon={<PlusOutlined />}>
        Add New
      </Button>
      <Modal
        title="Add New Slot"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1000}
      >
        <Styles>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={[16]} style={{ marginBottom: "30px" }}>
              <Col span={8}>
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="End Date"
                  name="end_date"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Select Restaurant"
                  name="restaurant"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Restaurant"
                    onSearch={(value) => {
                      fetchRestaurants({ name: value });
                    }}
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {restaurants.map((rest, i) => (
                      <Select.Option key={i} value={rest.id}>{`${
                        rest.name
                      } - ${rest.hub?.get("name")}`}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <NewForm form={form} sections={sections} />
            <Form.Item style={{ textAlign: "right" }}>
              <Space>
                <Button onClick={() => setVisible(false)} type="danger">
                  Cancel
                </Button>
                <Button loading={loading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Styles>
      </Modal>
    </div>
  );
}

const Styles = styled.div`
  .section {
    display: flex;
    align-items: center;
    background: #f0f2f5;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 10px;

    .body {
      width: calc(100% - 70px);
    }

    .counter {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #1890ff;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }
  }
`;
