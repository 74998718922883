import { addDays } from "date-fns";
import Parse from "parse";
import { parser } from "../utils";

const startTime = (date) => {
  const d = new Date(date);
  const hour = d.getHours();
  if (hour >= 0 && hour <= 7) {
    d.setDate(d.getDate() - 1);
  }
  d.setHours(8, 0, 0, 0);
  return d;
};

const dayStartHour = (date) => {
  const time = new Date(date);
  if (time.toString() === "Invalid Date") {
    throw new Parse.Error("Invalid date");
  }
  time.setHours(9, 0, 0, 0);
  return time;
};

const isValidDate = (date) => {
  if (Array.isArray(date) && date.length === 0) return false;
  else if (Array.isArray(date) && date.length > 1) {
    const [start, end] = date;
    return (
      new Date(start).toString() !== "Invalid Date" &&
      new Date(end).toString() !== "Invalid Date"
    );
  } else if (Array.isArray(date) && date.length === 1) {
    return new Date(date[0]).toString() !== "Invalid Date";
  } else {
    return new Date(date).toString() !== "Invalid Date";
  }
};

const addDay = (date, days) => {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
};

export default function useOrders() {
  const repoName = "order";
  const defaultFunc = () => {};

  const getById = async (id, callback = defaultFunc) => {
    try {
      if (!id) {
        callback("Id required!", null);
        return;
      }

      const order = await new Parse.Query(repoName)
        .includeAll()
        .equalTo("objectId", id)
        .first();
      if (!order) {
        callback("Order not found", null);
        return;
      }

      callback(null, order);
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getOrders = async (
    {
      limit,
      skip,
      createdAt,
      completedAt,
      restaurant,
      hub,
      status,
      rider,
      promo,
      payment_status,
      customer_name,
      customer_phone,
      order_numbers,
      customer_area,
      user,
      platform,
      payment_method,
      select,
      ids,
      exclude,
      restaurantType,
      sortBy,
      sortOrder,
      pickupIds,
    } = {},
    callback = defaultFunc
  ) => {
    try {
      const restaurantTypes = ["store", "restaurant"];

      const query = new Parse.Query(repoName);
      if (limit && typeof limit === "number") query.limit(limit);
      if (skip && typeof skip === "number") query.skip(skip);
      query.includeAll();
      if (ids) {
        query.containedIn("objectId", ids);
      }
      if (Array.isArray(exclude)) {
        query.exclude(...exclude);
      }
      if (Array.isArray(hub)) {
        query.containedIn(
          "hub",
          hub.map((id) => ({
            __type: "Pointer",
            className: "hub",
            objectId: id,
          }))
        );
      } else if (hub && typeof hub === "string") {
        query.equalTo("hub", {
          __type: "Pointer",
          className: "hub",
          objectId: hub,
        });
      }
      if (select) {
        query.select(select);
      }
      if (user && typeof user === "string") {
        query.equalTo("user", {
          __type: "Pointer",
          className: "_User",
          objectId: user,
        });
      }
      if (order_numbers && Array.isArray(order_numbers)) {
        query.containedIn("pickups.order_number", order_numbers);
      }
      if (restaurantType?.length) {
        if (restaurantType.length === 1 && restaurantType[0] === "store") {
          query.notContainedIn("order_items.restaurant.type", ["restaurant"]);
        } else if (
          restaurantType.length === 1 &&
          restaurantType[0] === "restaurant"
        ) {
          query.notContainedIn("order_items.restaurant.type", [
            "store",
            "sub_store",
          ]);
        } else {
          query.containsAll("order_items.restaurant.type", restaurantTypes);
        }
      }

      if (createdAt) {
        if (Array.isArray(createdAt)) {
          if (isValidDate(createdAt[0]))
            query.greaterThanOrEqualTo("createdAt", dayStartHour(createdAt[0]));
          if (isValidDate(createdAt[1]))
            query.lessThan("createdAt", addDays(dayStartHour(createdAt[1]), 1));
        } else if (isValidDate(createdAt) && typeof createdAt === "string") {
          query.greaterThanOrEqualTo("createdAt", startTime(createdAt));
          query.lessThanOrEqualTo("createdAt", addDay(startTime(createdAt), 1));
        }
      }
      if (completedAt) {
        if (Array.isArray(completedAt)) {
          if (isValidDate(completedAt[0]))
            query.greaterThanOrEqualTo(
              "completedAt",
              dayStartHour(completedAt[0])
            );
          if (isValidDate(completedAt[1]))
            query.lessThan(
              "completedAt",
              addDays(dayStartHour(completedAt[1]), 1)
            );
        } else if (
          isValidDate(completedAt) &&
          typeof completedAt === "string"
        ) {
          query.greaterThanOrEqualTo("completedAt", startTime(completedAt));
          query.lessThanOrEqualTo(
            "completedAt",
            addDay(startTime(completedAt), 1)
          );
        }
      }
      if (restaurant) {
        query.equalTo("restaurant", {
          __type: "Pointer",
          className: "restaurants",
          objectId: restaurant,
        });
      }
      if (status) {
        if (
          status === "restaurant_reject" ||
          status.includes("restaurant_reject")
        ) {
          query.equalTo("pickups.status", "rejected");
          query.equalTo("status", "rejected");
        } else {
          query.containedIn(
            "status",
            Array.isArray(status) ? status : [status]
          );
        }
      }

      if (payment_method)
        query.containedIn(
          "payment_method",
          Array.isArray(payment_method) ? payment_method : [payment_method]
        );
      if (platform)
        query.containedIn(
          "platform",
          Array.isArray(platform) ? platform : [platform]
        );
      if (rider) {
        query.equalTo("rider", {
          __type: "Pointer",
          className: "_User",
          objectId: rider,
        });
      }
      if (pickupIds?.length) {
        query.containedIn("pickups.id", pickupIds);
      }
      if (promo) query.matches("promo.promo_code", promo, "i");
      if (payment_status) query.equalTo("payment_status", payment_status);
      if (customer_name) query.matches("customer_name", customer_name, "i");
      if (customer_phone) query.matches("customer_phone", customer_phone, "i");
      if (customer_area) query.matches("customer_area", customer_area, "i");
      if (sortBy) {
        if (sortOrder === "ascend") {
          query.ascending(sortBy);
        } else {
          query.descending(sortBy);
        }
      } else {
        query.descending("createdAt");
      }
      query.withCount();
      const result = await query.find();
      if (result) {
        callback(null, result);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const getRestaurantOrders = async (params, callback = defaultFunc) => {
    try {
      const data = await Parse.Cloud.run("getRestaurantOrders", params);
      callback(null, data);
    } catch (err) {
      callback(err.message, null);
    }
  };

  const updateOrder = async ({ id, params = {} }, callback = defaultFunc) => {
    getById(id, async (err, order) => {
      try {
        if (err) {
          return callback(err, null);
        }

        const { status } = params;
        if (status) {
          order.set("status", status);
          const saved = await order.save();
          callback(null, parser(saved));

          // if (status === "confirmed") {
          //   const confirmOrder = await Parse.Cloud.run("orderConfirm", {
          //     orderId: order.id,
          //   });
          //   callback(null, parser(confirmOrder));
          // } else {
          //   order.set("status", status);
          //   const saved = await order.save();
          //   callback(null, parser(saved));
          // }
        }
      } catch (err) {
        callback(err.message, null);
      }
    });
  };

  const updateOrderItems = async (params, callback = defaultFunc) => {
    try {
      const order = await Parse.Cloud.run("updateOrderItems", params);
      if (order) {
        callback(null, order);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const assignRider = async (data, callback = defaultFunc) => {
    try {
      const result = await Parse.Cloud.run("assignRiderHandler", data);
      if (result) {
        callback(null, result);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const deleteOrders = async ({ ids }, callback = defaultFunc) => {
    if (!ids || !Array.isArray(ids)) callback("Ids required!", null);

    try {
      const orders = await new Parse.Query(repoName)
        .select(["objectId"])
        .containedIn("objectId", ids)
        .find({ sessionToken: Parse.User.current().getSessionToken() });
      if (orders) {
        orders.forEach((order) => {
          order.set("delete", true);
        });
        const result = await Parse.Object.saveAll(orders, {
          sessionToken: Parse.User.current().getSessionToken(),
        });
        callback(null, result);
      } else {
        callback("Orders not found!", null);
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const productReport = async (
    { date, productId } = {},
    callback = defaultFunc
  ) => {
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("status", "delivered");
      query.select([
        "objectid",
        "createdAt",
        "order_items",
        "inventory",
        "customer_name",
        "customer_phone",
        "user.name",
      ]);
      if (isValidDate(date)) {
        if (typeof date === "string") {
          query.greaterThanOrEqualTo("createdAt", new Date(date));
          query.lessThanOrEqualTo("createdAt", addDay(date, 1));
        } else if (Array.isArray(date)) {
          query.greaterThan("createdAt", new Date(date[0]));
          if (date[1]) query.lessThanOrEqualTo("createdAt", addDay(date[1], 1));
        }
      }

      query.equalTo("order_items.id", productId);
      query.descending("createdAt");
      let result = await query.find();
      if (result) {
        result = result.reduce((acc, cur) => {
          let {
            order_items,
            createdAt,
            inventory,
            customer_phone,
            customer_name,
          } = cur.toJSON();
          let profitAmount = inventory?.reduce((acc, cur) => {
            if (cur.id === productId) {
              acc += cur.amount;
            }
            return acc;
          }, 0);

          order_items?.forEach((item) => {
            if (item.id === productId) {
              const { discount, quantity, sale_unit, total, promoDiscount } =
                item;
              acc.push({
                id: cur.id,
                userId: cur.get("user")?.id,
                createdAt: startTime(createdAt),
                date: createdAt,
                discount: discount ?? 0,
                quantity,
                sale_unit,
                total,
                promoDiscount: promoDiscount ?? 0,
                profit: profitAmount ?? 0,
                customer_name,
                customer_phone,
              });
            }
          });

          return acc;
        }, []);

        callback(null, result);
      } else {
        callback("No data found", null);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  const updateStatus = async (params, callback) => {
    try {
      const res = await Parse.Cloud.run("updateStatus", params);
      callback(null, res);
    } catch (err) {
      callback(err.message, null);
    }
  };

  return {
    getById,
    getOrders,
    updateOrder,
    assignRider,
    getRestaurantOrders,
    updateOrderItems,
    productReport,
    addDay,
    startTime,
    isValidDate,
    deleteOrders,
    updateStatus,
    dayStartHour,
  };
}
