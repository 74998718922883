import Sections from "../../components/sections";
import SectionsContextProvider from "../../components/sections/SectionsContext";

export default function SectionsDashboard() {
  return (
    <SectionsContextProvider>
      <Sections />
    </SectionsContextProvider>
  );
}
