import { Form, Row, Col, Select, Input, InputNumber } from "antd";
import { Box } from "../../Common/Boxer";
import AddImage from "./AddImage";
import UpdateImage from "./UpdateImage";

const { Item } = Form;

export default function BehavioralItems({
  update,
  jobType,
  setJobType,
  ...rest
}) {
  return (
    <Box title="Behavioral Items">
      {update ? <UpdateImage {...rest} /> : <AddImage />}
      <Item
        name="nidNumber"
        label="Nid Number"
        rules={[
          {
            required: true,
            message: "Please input nid number!",
          },
        ]}
      >
        <Input placeholder="Nid number" />
      </Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            name="jobType"
            label="Job Type"
            rules={[
              {
                required: true,
                message: "Please select job type!",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setJobType(e);
              }}
              placeholder="Select Job Type"
            >
              <Select.Option value="commission">Commission</Select.Option>
              <Select.Option value="salary">Salary</Select.Option>
            </Select>
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="vehicleType"
            label="Vehicle Type"
            rules={[
              {
                required: true,
                message: "Please select vehicle type!",
              },
            ]}
          >
            <Select placeholder="Select Vehicle Type">
              <Select.Option value="bike">Bike</Select.Option>
              <Select.Option value="cycle">Cycle</Select.Option>
            </Select>
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            name="employeeType"
            label="Employee Type"
            rules={[
              {
                required: true,
                message: "Please select employee type!",
              },
            ]}
          >
            <Select placeholder="Select Employee Type">
              <Select.Option value="fulltime">Full Time</Select.Option>
              <Select.Option value="part-time">Part Time</Select.Option>
            </Select>
          </Item>
        </Col>
        {jobType === "salary" && (
          <Col span={12}>
            <Item name="riderBasic" label="Rider Basic">
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Rider Basic"
              />
            </Item>
          </Col>
        )}
        <Col span={12}>
          <Item name="riderDutyHour" label="Rider Duty Hour">
            <Input style={{ width: "100%" }} placeholder="hh:mm-hh:mm" />
          </Item>
        </Col>
      </Row>
    </Box>
  );
}
