import { useState, useEffect, useContext, useCallback } from "react";
import { CreateContext } from "../../../pages/Product/Create";
import { Form, Row, Col, Divider, Space, Button, message } from "antd";
import ProductName from "./form/ProductName";
import Category from "./form/Category";
import Description from "./form/Description";
import styled from "styled-components";
import Pricing from "./form/Pricing";
import Variants from "./form/Variants";
import Addons from "./form/Addons";
import Parse from "parse";
import moment from "moment";
import { randomId } from "../../../utils";

const { Item } = Form;

export default function ProductChangeRequest() {
  const { product, setProduct, categories } = useContext(CreateContext);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState("");

  useEffect(() => {
    const { change_request = {} } = product.data;

    if (change_request.price?.discount?.validity) {
      change_request.price.discount.validity = moment(
        change_request.price.discount.validity,
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    change_request.price?.variants?.forEach((variant) => {
      variant.items?.forEach((item) => {
        item.total = item.price + change_request.price.amount;
        if (!item.id) {
          item.id = randomId();
        }
      });
    });

    const newFormData = { ...change_request };
    setFormData(newFormData);
    form.setFieldsValue(newFormData);
  }, [product.data]);

  /**
   * @param { string[] } a
   * @param { string[] } b
   */
  const common = (a, b) => {
    return b.some((i) => a.includes(i));
  };

  const deleteHandler = async () => {
    if (product.data.ref instanceof Parse.Object) {
      setLoading("delete");
      try {
        product.data.ref.set("change_request", {});
        await product.data.ref.save();
        product.data.change_request = {};
        setProduct(product);
        setLoading("");
        message.success("Change request deleted successfully");
        form.resetFields();
      } catch (err) {
        message.error(err.message);
        setLoading("");
      }
    }
  };

  const onFinish = useCallback(
    async (values) => {
      try {
        let { name, category, details, addons, price } = values;

        if (price?.discount?.validity) {
          price.discount.validity = new Date(
            values.price.discount.validity
          ).toISOString();
        }

        price?.variants?.forEach((variant) => {
          variant.items?.forEach((item) => {
            if (item.total) {
              item.price = item.total - price.amount;
            }
            if (!item.id) {
              item.id = randomId();
            }
          });
        });

        if (addons) {
          if (addons.items && Object.keys(addons.items).length === 0) {
            addons = {};
          } else if (
            addons.items?.length > 0 &&
            (addons.min === undefined || addons.max === undefined)
          ) {
            message.error("Please fill the addons min and max");
            return;
          }
        }

        if (
          price &&
          Array.isArray(price.variants) &&
          price.variants.length > 0
        ) {
          const notMinMax = values.price.variants.find((v) => {
            return v.min === undefined || v.max === undefined;
          });

          if (notMinMax) {
            message.error("Please fill the variants min and max field!");
            return;
          }
        }

        if (product.data.ref instanceof Parse.Object) {
          setLoading("update");
          const object = product.data.ref;
          if (name) {
            product.data.name = name;
            object.set("name", name);
          }
          if (formData.images) {
            product.data.images = formData.images;
            object.set("images", formData.images);
          }
          if (category) {
            product.data.category.objectId = category;
            object.set("category", {
              __type: "Pointer",
              className: "category",
              objectId: category,
            });
          }
          if (details) {
            product.data.details = details;
            object.set("details", details);
          }
          if (addons) {
            product.data.addons = addons;
            object.set("addons", addons);
          }
          if (price) {
            product.data.price = price;
            object.set("price", price);
          }
          object.set("change_request", {});
          await object.save();
          product.data.change_request = {};
          setProduct(product.data);
          setFormData({});
          form.resetFields();
          setLoading("");
        }
      } catch (err) {
        message.error(err.message);
        setLoading("");
      }
    },
    [product.data, formData]
  );

  return (
    Object.keys(formData).length > 0 && (
      <Wrapper>
        <Form
          form={form}
          layout="vertical"
          wrapperCol={{ span: 24 }}
          size="large"
          onFinish={onFinish}
        >
          <Divider>Requested For Changes</Divider>
          <Row gutter={[16, 16]} className="parent-row">
            {common(Object.keys(formData), [
              "images",
              "name",
              "category",
              "details",
            ]) && (
              <Col xs={24} sm={24} md={12} xl={8}>
                <div className="section">
                  {formData.images && (
                    <img
                      width="100%"
                      src={formData.images[0]}
                      alt="product"
                      className="image"
                    />
                  )}
                  {formData.name && <ProductName />}
                  {formData.category && <Category categories={categories} />}
                  {formData.details && <Description />}
                </div>
              </Col>
            )}
            {formData.price && (
              <>
                <Pricing formData={formData} form={form} />
                <Variants form={form} formData={formData} />
              </>
            )}
            {formData.addons && <Addons />}
            <Col span={24}>
              <Item style={{ marginBottom: "20px", textAlign: "right" }}>
                <Space>
                  <Button
                    loading={loading === "delete"}
                    onClick={deleteHandler}
                    disabled={loading}
                    type="danger"
                    style={{ width: "100px" }}
                  >
                    Delete
                  </Button>
                  <Button
                    loading={loading === "update"}
                    disabled={loading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100px" }}
                  >
                    Accept
                  </Button>
                </Space>
              </Item>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    )
  );
}

export const Wrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .section {
    padding: 20px;
    border: 1px solid #e6e6e6;
    background: #fff;
    border-radius: 5px;
    min-height: 400px;
  }

  .header {
    height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .form-title {
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 700;
  }
`;
