import { Space, Table } from "antd";
import { Box } from "../../Common/Boxer";
import useSearch from "../../inventory/utils/useSearch";

export default function LeaderBoard({ data = [], title }) {
  const [state, getSearchProps] = useSearch();
  const columns = [
    {
      title: "#",
      dataIndex: "position",
      key: "position",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "riderName",
      ...getSearchProps("riderName"),
      render: (name, { imageUrl }) => (
        <Space className="rider">
          <img
            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            src={imageUrl}
            alt=""
          />
          <span>{name}</span>
        </Space>
      ),
    },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
    },
    {
      title: "Employee Type",
      dataIndex: "employeeType",
      key: "employeeType",
    },
    {
      title: "Order Delivered",
      dataIndex: "orderCount",
      key: "orderCount",
      render: (count, { rejectedCount, cancelledCount }) => (
        <span>{count - rejectedCount - cancelledCount}</span>
      ),
    },
    {
      title: "Order Rejected",
      dataIndex: "rejectedCount",
      key: "rejectedCount",
    },
    {
      title: "Order Cancelled",
      dataIndex: "cancelledCount",
      key: "cancelledCount",
    },
    {
      title: "Traveled",
      dataIndex: "travelled",
      key: "traveled",
      render: (km) => `${km} km`,
    },
  ];

  return (
    <Box title={title}>
      <Table
        columns={columns}
        dataSource={data.map((item, i) => ({
          ...item,
          key: i,
          position: i + 1,
        }))}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Box>
  );
}
