import Styled from "styled-components";
import { Link } from "react-router-dom";
import { compressedImage } from "../../../common/utils";

const Taka = () => (
  <span style={{ fontSize: "12px", fontWeight: "900" }}>৳</span>
);

export default function OrderItem({
  id,
  name,
  sale_unit,
  promoDiscount,
  discount,
  image,
  variant,
  addons,
  quantity,
  total,
}) {
  return (
    <Item className="item">
      <div className="item-header">
        <div className="image">
          {image ? (
            <img
              width="100%"
              height="100%"
              src={compressedImage(image)}
              alt="product"
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className="name">
          <Link target="_blank" to={`/product/add?id=${id}`} className="pro">
            {name}
          </Link>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ fontWeight: "500", fontSize: "12px" }}
              className="item-charges"
            >
              Quantity: {quantity}
            </div>
          </div>
          <div style={{ fontWeight: "500", color: "green", fontSize: "12px" }}>
            {sale_unit} x {quantity} = {total} Tk
          </div>
        </div>
      </div>
      {variant && Array.isArray(variant) && variant.length !== 0 && (
        <>
          <label>Variants</label>
          <div className="variants">
            {variant.map(
              (variation) =>
                Array.isArray(variation.items) &&
                variation.items.map((item, i) => (
                  <div key={i} className="variants-item">
                    <div>{item.name}</div>
                    <div>+{item.price}</div>
                  </div>
                ))
            )}
          </div>
        </>
      )}
      {addons && Array.isArray(addons) && addons.length !== 0 && (
        <>
          <label>Addons</label>
          <div className="addons">
            {addons.map((item, i) => (
              <div key={i} className="addons-item">
                <div>{item.name}</div>
                <div>+{item.price}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </Item>
  );
}

const Item = Styled.div`
    padding: 10px 16px;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;

    &:last-child{
        border-bottom: none;
    }

    .item-header{
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .image{
            width: 40px;
            height: 40px;
            border-radius: 100%;
            margin-right: 10px;
            background: #F0F2F5;
            overflow: hidden;
        }

        .name{
            .pro, .res{
                color: #000;
                font-weight: 500;
                margin-bottom: 0;
                font-size: 16px;
            }
        }
    }

    .variants, .addons{
        background: #f7f7f7;
        padding: 10px;
        margin-bottom: 10px;

        &-item{
            display: flex;
            justify-content: space-between;
            padding: 3px 0;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .name, .qty, .price{
        margin-bottom: 5px;
    }

    .name{
        font-size: 16px;
    }
    .qty, .price{
        font-size: 12px;
        font-weight: 500;
        color: #575757;
    }

    .item-charges{
        
    }
`;
