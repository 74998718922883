import React from 'react'
import { Skeleton, Row, Col } from 'antd'

export default function FormSkeleton() {
    const input = <Skeleton.Input style={{ width: 300 }} active={true} size="large" />
    const button = <Skeleton.Button style={{ width: 100 }} active={true} size="large" />

    return (
        <div>
            <Row gutter={[16,24]}>
                <Col span={24}>
                    <Skeleton.Input style={{ width: 150 }} active={true} size="large" />
                </Col>
                <Col span={8}>
                    {input}
                </Col>
                <Col span={8}>
                    {input}
                </Col>
                <Col span={8}>
                    {input}
                </Col>
                <Col span={24}>
                    {input}
                </Col>
                <Col span={24}>
                    {button}
                    <div style={{margin: '10px 0'}}>{input}</div>
                </Col>
                <Col span={24}>
                    {button}
                </Col>
                <Col span={24}>
                    {button}
                </Col>
                <Col span={24} style={{textAlign: 'right'}}>
                    {button}
                    <div style={{width: '30px', display: 'inline-block'}}></div>
                    {button}
                </Col>
            </Row>
        </div>
    )
}
