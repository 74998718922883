import Parse from "parse";
import axios from "axios";
import { message } from "antd";

const defaultFunc = (err, data) => {};

export default function useMedia() {
  const getSignedUrl = (type) => {
    if (!type) return null;
    return Parse.Cloud.run("getSignedUrl", {
      ContentType: type,
    });
  };

  async function multipleFileUpload(files, callback) {
    try {
      const promisses = files.map(async (file, index) => {
        const { url } = await getSignedUrl(file.type);
        if (url) {
          await axios({
            url,
            method: "put",
            data: file.originFileObj,
            headers: { "Content-Type": file.type },
            maxContentLength: 1024 * 1024 * 5, // 5 mb allowed
            timeout: 10 * 60 * 1000, // 10 mins
            onUploadProgress: (pevt) => {
              file.callback(
                index,
                Math.round((pevt.loaded / pevt.total) * 100),
                url
              );
            },
          });
        }
      });

      const result = await Promise.all(promisses);
      callback(null, result);
    } catch (err) {
      callback(err.message, null);
    }
  }

  async function uploadFile({ url, file }, callback = defaultFunc) {
    try {
      if (!url || !file) {
        callback("Url of file missing!", null);
        return;
      }

      await axios({
        url,
        method: "put",
        data: file.originFileObj,
        headers: { "Content-Type": file.type },
        maxContentLength: 1024 * 1024 * 10, // 10 mb allowed
        timeout: 30 * 60 * 1000, // 30 mins
        onUploadProgress: (pevt) => {
          callback(null, Math.round((pevt.loaded / pevt.total) * 100));
        },
      });
    } catch (err) {
      callback(err.message, null);
    }
  }

  const deleteFile = async (key, callback = defaultFunc) => {
    try {
      const result = await Parse.Cloud.run("deleteFile", {
        key,
      });
      if (result) {
        callback(null, true);
      }
    } catch (err) {
      callback(err.message, null);
    }
  };

  return { getSignedUrl, uploadFile, deleteFile, multipleFileUpload };
}
