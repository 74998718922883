import React from "react";
import DashboardContents from "../../components/dashboard";
import DashboardContextProvider from "./DashboardContext";

const Dashboard = () => {
  return (
    <DashboardContextProvider>
      <DashboardContents />
    </DashboardContextProvider>
  );
};

export default Dashboard;
