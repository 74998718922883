import Parse from "parse";
import axios from "axios";

export const getSignedUrl = (type, prefix) => {
  if (!type) return null;
  return Parse.Cloud.run("getSignedUrl", {
    ContentType: type,
    prefix,
  });
};

export async function multipleFileUpload(files) {
  const promises = files.map(async (file, index) => {
    const { url } = await getSignedUrl(file.type);
    if (url) {
      await axios({
        url,
        method: "put",
        data: file.originFileObj,
        headers: { "Content-Type": file.type },
        maxContentLength: 1024 * 1024 * 1.5, // 1.5 mb allowed
        timeout: 10 * 60 * 1000, // 10 mins
        onUploadProgress: (progress) => {
          file.callback(
            index,
            Math.round((progress.loaded / progress.total) * 100),
            url
          );
        },
      });
    }
  });

  return Promise.all(promises);
}

export async function uploadFile({ url, file }) {
  console.log(file.originFileObj);
  return axios({
    url,
    method: "put",
    data: file.originFileObj,
    headers: { "Content-Type": file.type },
    maxContentLength: 1024 * 1024 * 10, // 10 mb allowed
    timeout: 30 * 60 * 1000, // 30 mins
  });
}

export const deleteFile = async (key) => {
  return Parse.Cloud.run("deleteFile", {
    key,
  });
};
