import { CheckOutlined, ExpandOutlined } from "@ant-design/icons";
import { Space, Button, Table, Row, Col, message, Drawer } from "antd";
import { useState, useEffect } from "react";
import useProduct from "../../hooks/useProduct";
import { parser } from "../../utils";
import Parse from "parse";
import ProductDetails from "./Create";

export default function UnApprovedProductList() {
  const { getProducts } = useProduct();
  const [products, setProducts] = useState({
    loading: true,
    data: [],
    count: 0,
  });
  const [productId, setProductId] = useState(null);

  useEffect(() => {
    getProducts(
      {
        limit: 100,
        skip: 0,
        approval_status: "pending",
      },
      (err, data) => {
        if (err) {
          message.error(err);
        } else {
          setProducts({
            loading: false,
            data: parser(data.results),
            count: data.count,
          });
        }
      }
    );
  }, []);

  const approved = async (ref) => {
    if (ref instanceof Parse.Object) {
      try {
        ref.set("approval_status", "approved");
        const res = await ref.save();

        if (res) {
          message.success(`Product approved successfully!`);
          const index = products.data.findIndex((i) => i.id === ref.id);
          if (index !== -1) {
            products.data.splice(index, 1);
            products.count--;

            setProducts({ ...products });
          }
        }
      } catch (err) {
        message.error(err.message);
      }
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { id }) => {
        return (
          <Space>
            <div>{name}</div>
            <Button
              style={{ border: "none" }}
              icon={<ExpandOutlined />}
              onClick={() => setProductId(id)}
            ></Button>
          </Space>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      key: "action",
      render: (text, { ref }) => (
        <Space>
          <Button
            onClick={() => approved(ref)}
            type="primary"
            shape="circle"
            icon={<CheckOutlined />}
          ></Button>
        </Space>
      ),
    },
  ];

  const mapDataSource = (products) => {
    return products.map((product, i) => ({
      key: i,
      ...product,
      category: product?.category?.get("name"),
      restaurant: product.restaurant?.get("name"),
      price: product?.price?.amount,
    }));
  };

  return (
    <div>
      <Row justify="center">
        <Col span={24} lg={20}>
          <Table columns={columns} dataSource={mapDataSource(products.data)} />
        </Col>
        <Drawer
          title="Product Details"
          placement="right"
          width={1000}
          visible={productId}
          onClose={() => setProductId(null)}
        >
          <ProductDetails productId={productId} />
        </Drawer>
      </Row>
    </div>
  );
}
