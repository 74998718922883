import { menu } from "../../components/layout/menu";
import { Row, Col } from "antd";
import OrdersReport from "./Report";
import { useReducer, useEffect, useState } from "react";
import moment from "moment";
import DateHandler from "../../components/dashboard/DateHandler";
import useOrders from "../../hooks/useOrders";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
};

export default function Order() {
  const [state, dispatch] = useReducer(reducer, {
    orders: [],
  });
  const { addDay, startTime, getOrders } = useOrders();

  const [date, setDate] = useState([
    moment(addDay(startTime(new Date()), -7), "yyyy-MM-DD"),
    moment(startTime(new Date()), "yyyy-MM-DD"),
  ]);

  useEffect(() => {
    getOrders(
      {
        createdAt: date,
        limit: 1000,
        select: [
          "charge",
          "pickups",
          "order_items",
          "timeline",
          "customer_area",
          "createdAt",
          "status",
        ],
      },
      (err, orders) => {
        if (orders) {
          const ordersJson = orders.results.map((order) => order.toJSON());
          dispatch({ type: "SET_ORDERS", payload: ordersJson });
        }
      }
    );
  }, [date]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <h3>Order Dashboard</h3>
      </div>
      <DateHandler setDate={setDate} defaultValue={date} />
      {state.orders && <OrdersReport orders={state.orders} />}
    </div>
  );
}
