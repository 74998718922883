import { Form, Button, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadMedia from "../../../media/UploadMedia";
import { Box } from "../../../Common/Boxer";

const { Item } = Form;

export default function AddImage(props) {
  const open = () => {
    props.state.visible = true;
    props.setState({ ...props.state });
  };

  return (
    <Box title="Image">
      <Item style={{ marginBottom: "5px" }}>
        <Button
          onClick={open}
          type="primary"
          htmlType="button"
          icon={<UploadOutlined />}
          size="large"
          style={{ display: "flex", alignItems: "center" }}
        >
          Add Image
        </Button>
        <UploadMedia {...props} />
      </Item>
      {props.state.images?.length > 0 && (
        <div>
          <Image
            src={props.state.images[0]}
            alt=""
            style={{ maxHeight: "280px" }}
          />
        </div>
      )}
    </Box>
  );
}
