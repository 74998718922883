import { Col, Row, Space } from "antd";
import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";

function Item({ title, value, children }) {
  return (
    <Col xs={24} sm={12} md={8} xl={6}>
      <div className="item">
        <div className="title">{title}</div>
        <div className="value">
          <div>{value}</div>
          <div>{children}</div>
        </div>
      </div>
    </Col>
  );
}

export default function Statistics() {
  const { data } = useContext(SalesReportContext);

  return (
    <div id="statistics">
      <h1>Statistics</h1>
      <Row className="statistics" gutter={[16, 24]}>
        <Item title="Total Orders" value={data.orders.length} />
        <Item title="Total Sales" value={data.sales + " tk"} />
        <Item title="Product Discount" value={(data.discount || 0) + " tk"} />
        <Item title="Average Confirm Time" value={data.averageConfirmTime} />
        <Item title="Average Prepare Time" value={data.averagePrepTime} />
        <Item title="Order Delivered" value={data.delivered} />
        <Item title="Order Rejected" value={data.rejected}>
          <Space>
            <div>
              {Math.floor(
                ((data.delivered + data.cancelled + data.rejected) / 100) *
                  data.rejected
              )}{" "}
              %
            </div>
            <div>- {data.rejectedAmount || 0} tk</div>
          </Space>
        </Item>
        <Item title="Order Cancelled" value={data.cancelled}>
          <Space>
            <div>
              {Math.floor(
                ((data.delivered + data.cancelled + data.rejected) / 100) *
                  data.cancelled
              )}{" "}
              %
            </div>
            <div>- {data.cancelledAmount || 0} tk</div>
          </Space>
        </Item>
      </Row>
    </div>
  );
}
