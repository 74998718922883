import { Form, Upload, Row, Col, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useState } from "react";
import { randomId } from "../../../utils";
import useMedia from "../../../hooks/useMedia";

export default function UploadImages({ setImages }) {
  const [loading, setLoading] = useState(false);
  const { getSignedUrl } = useMedia();

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    height: "300px",
    listType: "picture-card",
    style: {
      marginBottom: "10px",
    },
  };

  const onFinish = async ({ files }) => {
    setLoading(true);
    const list = await Promise.all(
      files.map(async (file) => {
        const src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });

        // get s3 signed url to upload image
        const { url, key } = await getSignedUrl(file.type);

        let [name, price] = file.name.split(".")[0].split("$");

        if (name) {
          file.productName = name;
        }

        if (price && Number(price)) {
          file.productPrice = Number(price);
        }

        file.uploadUrl = url;
        file.key = key;
        file.thumbUrl = src;
        return file;
      })
    );

    const newForms = list.reduce((acc, file) => {
      const id = randomId();
      acc[id] = {
        file,
        id,
        name: file.productName,
        price: file.productPrice,
      };

      console.log(acc);
      return acc;
    }, {});

    if (newForms) {
      setImages(newForms);
      setLoading(false);
    }
  };

  return (
    <Row
      gutter={[16, 24]}
      justify="center"
      align="middle"
      style={{ minHeight: "400px" }}
    >
      <Col span={24} md={20} lg={16}>
        <Form onFinish={onFinish}>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            noStyle
            rules={[
              {
                required: true,
                message: "Please upload your file!",
              },
            ]}
          >
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px" }}>
            <Button loading={loading} type="primary" htmlType="submit">
              Upload
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
