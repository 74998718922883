import { useContext, useState } from "react"
import { Modal, Input } from 'antd'
import { PlusOutlined } from "@ant-design/icons"
import { CarouselContext } from "../../pages/Carousel/Context"

export default function AddCarousel(){
    const { createCarousel } = useContext(CarouselContext)
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    const onOk = () => {
        setLoading(true)
        createCarousel({ name }, (err, data) => {
            setLoading(false)
            if(data){
                setVisible(false)
                setName('')
            }
        })
    }

    return (
        <div className="add-carousel">
            <div onClick={e => setVisible(true)} className="btn add active" style={{width: '180px'}}>
                <div className="icon"><PlusOutlined /></div>
                <div>Add Carousel</div>
            </div>
            <Modal
                title="Add Carousel"
                visible={visible}
                onOk={onOk}
                onCancel={() => setVisible(false)}
                confirmLoading={loading}
                okText="Add"
                cancelText="Cancel"
                style={{visibility: visible ? 'visible' : 'hidden'}}
            >
                <Input size="large" placeholder="Carousel Name" onChange={(e) => setName(e.target.value)} />
            </Modal>
        </div>
    )
}

