import { useCallback, useContext, useEffect, useState } from "react";
import { OrdersContext } from "../../../pages/Order/OrderProcessor";
import { Space, Input, Button, message, Typography, Affix } from "antd";
import Styled from "styled-components";
import DateHandler from "../../dashboard/DateHandler";
import useOrders from "../../../hooks/useOrders";
import moment from "moment";
import OrderCapacity from "./OrderCapacity";
import Riders from "./Riders";
import Parse from "parse";
import OrdersTab from "./OrdersTab";
// import Search from "./Search";

const { Text } = Typography;

function CustomOTP() {
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = useCallback(async () => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("customOTP", { phone });
      console.log(res);
      setOTP(res);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  }, [phone]);

  return (
    <Space>
      {!otp && (
        <>
          <Input
            style={{ width: "160px" }}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
          />
          <Button loading={loading} onClick={submit}>
            Submit
          </Button>
        </>
      )}
      {otp && (
        <>
          <div className="otp-box">
            <Text copyable>{otp}</Text>
          </div>
          <Button onClick={() => setOTP(null)} danger>
            Clear
          </Button>
        </>
      )}
    </Space>
  );
}

export default function Dashboard() {
  const { startTime, addDay } = useOrders();
  const {
    getActiveOrdersCount,
    todaysOrders: { data },
  } = useContext(OrdersContext);
  const [date, setDate] = useState([
    addDay(startTime(new Date()), -1),
    addDay(startTime(new Date()), 1),
  ]);
  const [fold, setFold] = useState(false);

  useEffect(() => {
    getActiveOrdersCount({
      createdAt: date,
    });
  }, [date]);

  return (
    <Container>
      <Affix offsetTop={64}>
        <div
          style={{
            background: "#fff",
            padding: "0px 20px",
            boxShadow: "0px 1px 2px rgba(0,0,0,.3)",
            marginRight: fold ? 0 : "250px",
          }}
        >
          <Space>
            <DateHandler
              setDate={setDate}
              defaultValue={[
                moment(addDay(startTime(new Date()), -1), "yyyy-MM-DD"),
                moment(addDay(startTime(new Date()), 1), "yyyy-MM-DD"),
              ]}
            />
            <OrderCapacity />
            <CustomOTP />
            {/* <Search /> */}
          </Space>
        </div>
      </Affix>
      <div className="panel">
        <div
          className={`orders-panel`}
          style={{ marginRight: fold ? 0 : "250px" }}
        >
          {data && <OrdersTab date={date} />}
        </div>
        <Riders fold={fold} setFold={setFold} />
      </div>
    </Container>
  );
}

const Container = Styled.div`
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    postion: relative;

    .otp-box {
      width: 182.4px;
      border: 1px solid #ccc;
      padding: 3px 10px;
      background: #fff;
    }

    .panel {
      .fold.riders-panel{
        position: fixed;
        width: 90px;
        height: 46px;
        right: 30px;
        top: 95px;
        padding: 10px;
        border: 2px solid #ff7875;
        border-radius: 10px;
        z-index: 100000;
      }
  
      .riders-panel {
        position: fixed;
        right: 0;
        height: calc(100vh - 64px);
        width: 250px;
        top: 64px;
        background: #fff;
        padding: 20px;
        border-left: 2px solid #b7b6b6;

        .rider {
            padding: 10px 0;
            
            &: not(:last-child) {
              border-bottom: 1px solid #e8e8e8;
            }

            .avail{
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: #fff; 
              &.active {
                background: #3cc53c;
                border: 2px solid #91df91;
              }
              &.deActive {
                background: #e7525a;
                border: 1px solid #ff8f94;
              }
            }

            
        }
      }
    }


`;
