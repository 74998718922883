import { Col } from "antd";

export default function Layout({ title, children, card, ...rest }) {
  const component = (
    <div className={`${card ? "card" : "layout"}`} {...rest}>
      {title && <h2 className="title">{title}</h2>}
      {children}
    </div>
  );

  if (card) {
    return (
      <Col span={24} md={12} lg={8} xl={6}>
        {component}
      </Col>
    );
  }

  return component;
}
