import { useState, useEffect } from "react"
import useProduct from "../../hooks/useProduct"
import { useParams } from "react-router-dom"
import { message, Timeline, Row, Col, Space, Tag } from "antd"
import { parser } from "../../utils"
import { formatRelative } from 'date-fns'
import styled from "styled-components"
import ProductLinks from "../../components/Common/ProductLinks"

const { Item } = Timeline


export default function ProductTimeline(){
    const { id } = useParams()
    const { getById } = useProduct()
    const [product, setProduct] = useState(null)

    useEffect(() => {
        getById({ id, select: ["timeline", "name", "images"] }, (err, data) => {
            if(err){
                message.error(err)
            }else{
                const newData = parser(data)
                if(!newData.timeline) {
                    newData.timeline = []
                }
                setProduct(newData)
            }
        })
    },[])

    return (
        product ? <div>
            <Row justify="center">
                <Wrapper span={24} lg={22} xl={20}>
                    <div className="header">
                        <ProductLinks id={product.id}>
                            <h1 className="title">{product.name}</h1>
                        </ProductLinks>
                    </div>

                    <Timeline className="timeline" reverse={true} mode="left" style={{marginTop: '20px'}}>
                        {product.timeline.map(({ msg, time, type }, i, arr) => {
                            return (
                                <Item label={
                                    <div style={{fontSize: '14px'}}>
                                        <Space direction="horizontal">
                                            {formatRelative(new Date(time), Date.now())}
                                            <Tag>{type}</Tag>
                                        </Space>
                                    </div>
                                } key={i} style={{fontSize: '16px', minHeight: '80px'}}>
                                    <div>{msg}</div>
                                </Item>
                            )
                        })}
                    </Timeline>
                </Wrapper>
            </Row>
        </div>: null
    )
}

const Wrapper = styled(Col)`
    .header{
        padding: 10px 20px;
        margin-bottom: 20px;
        .title{
            margin-bottom: 0;
            font-size: 20px;
        }
    }

    .timeline{
        background-color: #fff;
        padding: 20px;
        min-height: calc(100vh - 64px - 100px - 60px);

        .ant-timeline-item-label {
            width: calc(20% - 12px)!important;
          }
        
          .ant-timeline-item-head {
            left: 20%!important;
          }
        
          .ant-timeline-item-tail {
            left: 20%!important;
          }
        
          .ant-timeline-item-content {
            left: calc(20% - 4px)!important;
            width: calc(80% - 14px)!important;
          }


    }
`