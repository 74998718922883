import { useCallback, useContext } from "react";
import { Select } from "antd";
import { BulkProductUploadContext } from "../../../pages/Restaurant/BulkProductUpload";

export default function SelectCategories({ setForms, forms }) {
  const { categories } = useContext(BulkProductUploadContext);

  const categoryChangeHandler = useCallback(
    (id) => {
      const category = categories.data.find((category) => category.id === id);
      if (category) {
        const newForms = { ...forms };
        Object.entries(newForms).forEach(([key, { form }]) => {
          if (newForms[key]["checked"]) {
            newForms[key]["category"] = category;
            newForms[key]["checked"] = false;

            form.setFieldsValue({ category: category.id });
          }
        });
        setForms(newForms);
      }
    },
    [forms, categories]
  );

  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
      loading={categories.loading}
      onChange={categoryChangeHandler}
    >
      {categories.data.map(({ id, name }, idx) => {
        return (
          <Select.Option key={idx} value={id}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
}
