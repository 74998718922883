import styled from "styled-components";

export default function BoxLayout({ title, children, ...rest }) {
  return (
    <Wrapper {...rest}>
      {title && (
        <div className="header">
          <h3 className="title">{title}</h3>
        </div>
      )}
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  min-height: 120px;
  border-radius: 10px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 16px;

  .header {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 0px;
      font-size: 18px;
    }
  }
`;
