import { useState, useEffect, useCallback } from "react";
import { message, Button, Popconfirm } from "antd";
import styled from "styled-components";
import Parse from "parse";

const InfoItem = ({ label, value, ...rest }) => (
  <div className="info-item" {...rest}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

export default function BkashPaymentDetails({ orderId, issue }) {
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    customerMsisdn,
    statusMessage,
    paymentID,
    amount,
    transactionStatus,
    paymentExecuteTime,
    refund,
    trxID,
  } = payment || {};

  const fetchBkashPayment = async (orderId) => {
    try {
      const payment = await new Parse.Query("bkash")
        .equalTo("order", {
          __type: "Pointer",
          className: "order",
          objectId: orderId,
        })
        .first();
      if (payment) {
        setPayment(payment.toJSON());
      } else {
        message.error("Payment not found");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchBkashPayment(orderId);
  }, [orderId]);

  const refundHandler = useCallback(async () => {
    try {
      if (payment) {
        setLoading(true);
        const res = await Parse.Cloud.run("bkashRefund", {
          issueId: issue.objectId,
        });

        if (!payment.refund) {
          payment.refund = [];
        }

        payment.refund.push(res);
        message.success("Refund successful!");
        setLoading(false);
      }
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  }, [payment, issue]);

  return (
    payment && (
      <Wrapper className="bkash-refund">
        {issue && (
          <InfoItem
            label={<div className="title">Bkash Payment Info</div>}
            value={
              issue.refund_status !== "complete" &&
              (!refund || refund.length === 0) && (
                <div>
                  <Popconfirm
                    title={`Refund ${issue.refund_amount} BDT from Bkash!`}
                    onConfirm={() => {
                      refundHandler();
                    }}
                    okText="Ok"
                    cancelText="Cancel"
                  >
                    <Button loading={loading} type="primary">
                      Refund
                    </Button>
                  </Popconfirm>
                </div>
              )
            }
          />
        )}
        {refund?.length > 0 &&
          refund.map((refund, i) => {
            return (
              <InfoItem
                key={i}
                label={
                  <div>
                    <div>Refund {refund.transactionStatus}</div>
                    <div>
                      at <small>{refund.completedTime}</small>
                    </div>
                  </div>
                }
                value={
                  <div>
                    {refund.amount} {refund.currency}
                  </div>
                }
                style={{
                  backgroundColor: "#fff",
                  padding: "10px",
                  border: "2px solid #52c41a",
                  borderRadius: "10px",
                }}
              />
            );
          })}

        <InfoItem label="Customer MSISDN" value={customerMsisdn} />
        <InfoItem label="Status Message" value={statusMessage} />
        <InfoItem label="Payment ID" value={paymentID} />
        <InfoItem label="Transaction ID" value={trxID} />
        <InfoItem label="Amount" value={amount} />
        <InfoItem label="Transaction Status" value={transactionStatus} />
        <InfoItem label="Time" value={paymentExecuteTime} />
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background: #f7f7f7;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 10px;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #eb2f96;
    letter-spacing: 1px;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }
`;
