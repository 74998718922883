import { Row, Col } from "antd";
import HubsProvider from "./HubsProvider";
import HubList from "./HubList";

export default function HubDashboard() {
  return (
    <HubsProvider>
      <Row justify="center" gutter={[16, 24]}>
        <Col span={24}>
          <h3 style={{ marginBottom: 0 }}>Hub list</h3>
        </Col>
        <HubList />
      </Row>
    </HubsProvider>
  );
}
