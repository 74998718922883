import { useContext, useState } from "react";
import { DetailsContext } from "../../../../pages/Order/Details";
import { Button, Form, Input, Row, Col, message, Space } from "antd";
import styled from "styled-components";
import Parse from "parse";

export default function AddPromo({ close }) {
  const { order, updateOrderItems, necessaryOrderItems } =
    useContext(DetailsContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async ({ promo, promoAuth }) => {
    setLoading(true);
    try {
      const availPromo = await Parse.Cloud.run("availPromo", {
        promo_code: promo,
        order_items: necessaryOrderItems(order.data.order_items),
        customer_area: order.data.customer_area,
        platform: order.data.platform,
      });

      if (availPromo) {
        updateOrderItems(
          {
            orderId: order.data.id,
            orderItems: necessaryOrderItems(order.data.order_items),
            promo,
            promoAuth,
          },
          () => {
            setLoading(false);
          }
        );
      } else {
        message.error("Promo code is not valid!");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      message.error(err.message);
    }
  };

  return (
    <Wrapper form={form} onFinish={onFinish} size="large" layout="vertical">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} lg={10}>
          <Form.Item
            name="promo"
            rules={[
              {
                required: true,
                message: "Please enter promo code",
              },
            ]}
          >
            <Input placeholder="Promo code" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={9}>
          <Form.Item
            name="promoAuth"
            rules={[
              {
                required: true,
                message: "Please enter promo auth",
              },
            ]}
          >
            <Input placeholder="Promo auth" />
          </Form.Item>
        </Col>
        <Col span={24} lg={5}>
          <Form.Item style={{ textAlign: "right" }}>
            <Space>
              <Button onClick={close} type="danger">
                Close
              </Button>
              <Button loading={loading} type="primary" htmlType="submit">
                Apply
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled(Form)`
  @keyframes fadeIn {
    from {
      opacity: 0;
      margin-top: -10px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  animation: fadeIn 0.3s ease-in-out;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    line-height: 1.5;
  }
`;
