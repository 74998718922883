import { Menu, Dropdown, Space, Popover } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, ExpandOutlined } from "@ant-design/icons";
import {
  BiBox,
  BiCategory,
  BiCloudUpload,
  BiDollarCircle,
  BiFile,
} from "react-icons/bi";
import { IoMdAnalytics } from "react-icons/io";
import styled from "styled-components";

const CenterText = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

const menu = [
  { name: "Details", icon: <BiFile />, link: `/restaurant/:id/details` },
  { name: "Update", icon: <EditOutlined />, link: `/restaurant/add?id=:id` },
  {
    name: "Report",
    icon: <IoMdAnalytics />,
    link: `/restaurant/:id/sales-report`,
  },
  {
    name: "Categories",
    icon: <BiCategory />,
    link: `/restaurant/:id/categories`,
  },
  { name: "Products", icon: <BiBox />, link: `/product/list?restaurant=:id` },
  {
    name: "Product Upload",
    icon: <BiCloudUpload />,
    link: `/restaurant/:id/product-upload`,
  },
  { name: "Sales", icon: <BiDollarCircle />, link: `/restaurant/:id/payments` },
];

export const RestaurantTopMenu = ({ id, ...rest }) => {
  return (
    <TopMenu {...rest}>
      {menu.map((item) => (
        <Popover key={item.name} content={item.name}>
          <div className="item">
            <Link to={item.link.replace(":id", id)}>{item.icon}</Link>
          </div>
        </Popover>
      ))}
    </TopMenu>
  );
};

const TopMenu = styled(Space)`
  margin-bottom: 10px;
  font-size: 20px;
  .item {
    width: 40px;
    height: 40px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    border-radius: 5px;
    border: 1px solid #e8e8e8;

    a {
      color: inherit;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const MenuItems = ({ id }) => (
  <>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/${id}/details`}
      >
        <CenterText>
          <ExpandOutlined /> <span style={{ marginLeft: "10px" }}>Details</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/${id}/sales-report`}
      >
        <CenterText>
          <IoMdAnalytics />
          <span style={{ marginLeft: "10px" }}>Sales Report</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/${id}/categories`}
      >
        <CenterText>
          <BiCategory />
          <span style={{ marginLeft: "10px" }}>Categories</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/product/list?restaurant=${id}`}
      >
        <CenterText>
          <BiBox />
          <span style={{ marginLeft: "10px" }}>Products</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/${id}/product-upload`}
      >
        <CenterText>
          <BiCloudUpload />
          <span style={{ marginLeft: "10px" }}>Products Upload</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/add?id=${id}`}
      >
        <CenterText>
          <EditOutlined /> <span style={{ marginLeft: "10px" }}>Update</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/restaurant/${id}/payments`}
      >
        <CenterText>
          <BiDollarCircle />
          <span style={{ marginLeft: "10px" }}>Sales</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item>
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/payments/history?restaurant=${id}`}
      >
        <CenterText>
          <BiFile />
          <span style={{ marginLeft: "10px" }}>Payments</span>
        </CenterText>
      </Link>
    </Menu.Item>
  </>
);

export default function RestaurantLinks({ children, id, ...props }) {
  return (
    <Dropdown
      placement="bottom"
      arrow
      trigger={["click"]}
      overlay={() => (
        <Menu theme="light" style={{ width: "160px" }}>
          <MenuItems id={id} />
        </Menu>
      )}
      {...props}
    >
      <div style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>
        {children}
      </div>
    </Dropdown>
  );
}
