import { useCallback, useEffect, useState } from "react";
import { Modal, Space, Button } from "antd";

const newScript = ({ src }) => {
  const script = document.createElement("script");
  script.src = src;
  document.head.appendChild(script);
};

const newLink = ({ href, rel }) => {
  const link = document.createElement("link");
  link.href = href;
  link.rel = rel;
  document.head.appendChild(link);
};

const initiateBMap = ({ lat, lng, setGeo }) => {
  const bkoigl = window.bkoigl;
  if (bkoigl) {
    bkoigl.accessToken = process.env.REACT_APP_BARIKOI_API_KEY;

    const map = new bkoigl.Map({
      container: "bMap",
      center: [lng, lat],
      zoom: 16,
    });

    map.on("load", () => {
      map.addControl(new bkoigl.FullscreenControl());
      map.addControl(new bkoigl.ScaleControl());
      map.addControl(new bkoigl.NavigationControl());

      const marker = new bkoigl.Marker({ draggable: true })
        .setLngLat([lng, lat])
        .addTo(map);

      marker.on("dragend", (e) => {
        const geo = e.target.getLngLat();
        setGeo({
          lat: geo.lat,
          lng: geo.lng,
        });
      });
    });
  }
};

export default function Map({ open, close, setAddress, lat, lng }) {
  const [geo, setGeo] = useState({ lat, lng });
  const [loading, setLoading] = useState(false);
  const [isInitiated, setIsInitiated] = useState(false);

  useEffect(() => {
    if (lat && lng) {
      newLink({
        href: "https://cdn.barikoi.com/bkoi-gl-js/dist/bkoi-gl.css",
        rel: "stylesheet",
      });

      newScript({
        src: "https://cdn.barikoi.com/bkoi-gl-js/dist/bkoi-gl.js",
      });
    }
  }, [lat, lng]);

  useEffect(() => {
    if (open && lat && lng && !isInitiated) {
      setGeo({ lat, lng });
      initiateBMap({ lat, lng, setGeo });
      setIsInitiated(true);
    }
  }, [open, lat, lng]);

  const submit = useCallback(() => {
    setLoading(true);
    setAddress(geo.lat, geo.lng, () => setLoading(false));
  }, [geo, loading]);

  return (
    <Modal
      title="Map"
      visible={open}
      onCancel={close}
      footer={null}
      width={700}
    >
      <div
        style={{
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          width: "calc(700px - 50px)",
          height: "400px",
          overflow: "hidden",
        }}
        id="bMap"
      ></div>
      <div style={{ marginTop: "20px" }}>
        <Space>
          <Button onClick={close}>Cancel</Button>
          <Button loading={loading} onClick={submit}>
            Done
          </Button>
        </Space>
      </div>
    </Modal>
  );
}
