import { useState, useContext, useEffect, useCallback } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import { Popover, Space, Tag, message, Button, Popconfirm, Switch } from "antd";
import styled from "styled-components";
import Parse from "parse";

const InfoItem = ({ label, value, ...rest }) => (
  <div className="info-item" {...rest}>
    <span>{label}</span>
    <span>{value}</span>
  </div>
);

export default function OnlinePaymentDetails({ orderId }) {
  const [payment, setPayment] = useState(null);

  const fetchOnlinePayment = async (orderId) => {
    try {
      const payment = await new Parse.Query("aamarpay")
        .equalTo("order", {
          __type: "Pointer",
          className: "order",
          objectId: orderId,
        })
        .first();
      if (payment) {
        setPayment(payment.toJSON());
      } else {
        message.error("Payment not found");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchOnlinePayment(orderId);
  }, [orderId]);

  const { pay_status, amount, bank_txn, card_type, trx_id } = payment || {};

  return (
    <Wrapper className="payment-details">
      <InfoItem label="Pay Status" value={pay_status} />
      <InfoItem label="Amount" value={amount} />
      <InfoItem label="Bank Txn" value={bank_txn} />
      <InfoItem label="Card Type" value={card_type} />
      <InfoItem label="Transaction ID" value={trx_id} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: #f7f7f7;
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 10px;

  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #eb2f96;
    letter-spacing: 1px;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }
`;
