import { message, Space, Table } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";

export default function OrderIssues() {
  const [issues, setIssues] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchIssues = async ({ limit, skip }) => {
    try {
      setLoading(true);
      const issues = await new Parse.Query("order_issue")
        .limit(limit)
        .skip(skip)
        .withCount()
        .exists("order")
        .select([
          "order.status",
          "order.payment_method",
          "refund_status",
          "type",
          "refund_amount",
          "restaurant_penalty",
          "accountable",
          "rider_penalty",
          "status",
          "approved",
          "added_by.name",
        ])
        .descending("createdAt")
        .find();

      issues.results = issues.results.map((issue) => {
        const { order, ...rest } = issue.toJSON();

        return {
          ...rest,
          key: issue.id,
          orderId: order?.objectId,
          orderStatus: order?.status,
          paymentMethod: order?.payment_method,
        };
      });
      setIssues(issues);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssues({ limit: 10, skip: 0 });
  }, []);

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "order_id",
      render: (id, { createdAt }) => (
        <Link
          style={{ color: "gray", fontWeight: 600, letterSpacing: "1px" }}
          to={`/order/list/${id}`}
        >
          <div>#{id}</div>
          <small>{new Date(createdAt).toLocaleString()}</small>
        </Link>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Approved",
      dataIndex: "approved",
      key: "approved",
      render: (is) => (is ? "Yes" : "No"),
    },
    {
      title: "Issue Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Refund Status",
      dataIndex: "refund_status",
      key: "refund_status",
    },
    {
      title: "Refund Amount",
      dataIndex: "refund_amount",
      key: "refund_amount",
    },
    {
      title: "Accountable",
      dataIndex: "accountable",
      key: "accountable",
    },
    {
      title: "Restaurant Penalty",
      dataIndex: "restaurant_penalty",
      key: "restaurant_penalty",
      width: 160,
      render: (rests) =>
        Array.isArray(rests) ? (
          <div>
            {rests.map((rest, i) => (
              <div key={i}>
                {rest.name || rest.id}: {rest.amount} tk
              </div>
            ))}
          </div>
        ) : null,
    },
    {
      title: "Rider Penalty",
      dataIndex: "rider_penalty",
      key: "rider_penalty",
    },
    {
      title: "Raised By",
      dataIndex: "added_by",
      key: "addedBy",
      render: (addedBy) => addedBy?.name,
    },
  ];

  return (
    <div>
      <h1>Order Issues</h1>
      <Table
        loading={loading}
        columns={columns}
        dataSource={issues.results ?? []}
      />
    </div>
  );
}
