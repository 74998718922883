import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Chart } from "react-chartjs-2";

const getChartsData = ({ labels = [], data = [] }) => {
  return {
    labels,
    datasets: [
      {
        label: `Sales Per Day`,
        data,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        tension: 0.7,
        fill: true,
        showLine: true,
      },
    ],
  };
};

export default function SalesPerDate() {
  const { data } = useContext(SalesReportContext);
  return (
    <div id="sales-per-day">
      <h2>Sales Per Day</h2>
      <Chart
        type="bar"
        datasetIdKey="label"
        data={getChartsData({
          ...Object.entries(data?.salesPerDate || {}).reduce(
            (acc, [label, order]) => {
              let date = new Date(label)
                .toDateString()
                .split(" ")
                .slice(1, 3)
                .join(" ");
              acc.labels.push(date);
              acc.data.push(order);
              return acc;
            },
            { labels: [], data: [] }
          ),
        })}
      />
    </div>
  );
}
