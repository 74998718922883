import { Form, Row, Col, Input, Select, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { Box } from "../../Common/Boxer";

const { Item } = Form;

export default function PrimaryItems({ isUpdate, updatePassword, children }) {
  const {
    hubs: {
      loading,
      data: { results },
    },
  } = useSelector((state) => state.hub);

  return (
    <Box title="Primary Items">
      <Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Please input your name!" }]}
      >
        <Input size="large" placeholder="Full name" />
      </Item>
      <Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            message: "Please input riders username!",
          },
        ]}
      >
        <Input placeholder="Username" />
      </Item>
      <Item name="email" label="Email">
        <Input placeholder="Email" />
      </Item>
      <Row>
        <Col span={12}>
          <Item name="date_of_birth" label="Date of Birth">
            <DatePicker />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            name="riderHub"
            label="Rider Hub"
            rules={[
              {
                required: true,
                message: "Please select a hub!",
              },
            ]}
          >
            <Select loading={loading} placeholder="Select Riders Hub">
              {results.map((hub) => (
                <Select.Option key={hub.objectId} value={hub.id}>
                  {hub.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Item
        name="phone"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: "Please input riders phone!",
          },
        ]}
      >
        <Input placeholder="Phone" />
      </Item>
      {(!isUpdate || updatePassword) && (
        <Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Item>
      )}
      {children}
    </Box>
  );
}
