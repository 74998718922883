import { Menu, Dropdown, Typography } from "antd";
import { Link } from "react-router-dom";
import { IssuesCloseOutlined } from "@ant-design/icons";
import { MenuItems as RestaurantMenu } from "./RestaurantLinks";
import { BiBarChartAlt2, BiEdit, BiChart } from "react-icons/bi";

const { SubMenu } = Menu;
const { Paragraph } = Typography;

const CenterText = ({ children }) => (
  <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
);

export const MenuItems = ({ id, restaurantId, inventoryId }) => (
  <>
    <Menu.Item key="p-sales">
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/product/${id}/sales`}
      >
        <CenterText>
          <BiBarChartAlt2 />
          <span style={{ marginLeft: "10px" }}>Sales</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item key="p-details">
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/product/add?id=${id}`}
      >
        <CenterText>
          <BiEdit />
          <span style={{ marginLeft: "10px" }}>Details</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item key="p-details">
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/product/${id}/timeline`}
      >
        <CenterText>
          <IssuesCloseOutlined />
          <span style={{ marginLeft: "10px" }}>Timeline</span>
        </CenterText>
      </Link>
    </Menu.Item>
    <Menu.Item key="p-records">
      <Link
        style={{ fontSize: "14px", fontWeight: "500" }}
        to={`/inventory/list?product=${id}`}
      >
        <CenterText>
          <BiChart />
          <span style={{ marginLeft: "10px" }}>Inventory</span>
        </CenterText>
      </Link>
    </Menu.Item>
    {restaurantId && (
      <SubMenu title="Restaurant">
        <RestaurantMenu id={restaurantId} />
      </SubMenu>
    )}
    <Menu.Item key="p-sales">
      <Paragraph style={{ color: "#fff", margin: 0 }} copyable>
        {id}
      </Paragraph>
    </Menu.Item>
  </>
);

export default function ProductLinks({
  children,
  id,
  restaurantId,
  inventoryId,
  ...props
}) {
  return (
    <Dropdown
      placement="bottom"
      arrow
      trigger={["click"]}
      overlay={() => (
        <Menu style={{ width: "260px" }} theme="dark">
          <MenuItems
            id={id}
            restaurantId={restaurantId}
            inventoryId={inventoryId}
          />
        </Menu>
      )}
      {...props}
    >
      <div style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer" }}>
        {children}
      </div>
    </Dropdown>
  );
}
