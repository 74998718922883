import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, message, Select, Form, Switch } from "antd";
import { useState, useEffect, useCallback } from "react";
import useRestaurants from "../../hooks/useRestaurants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const { Item } = Form;

export default function GroupRestaurants({ group, setGroup, hub, id }) {
  const [restaurants, setRestaurants] = useState({
    loading: false,
    data: [],
    count: 0,
  });
  const [restaurant, setRestaurant] = useState(null);
  const { getRestaurants } = useRestaurants();

  useEffect(() => {
    getRestaurants(
      {
        limit: 200,
        select: ["name", "banner_image", "hub.name"],
        hub,
        type: ["store", "restaurant"],
      },
      (err, res) => {
        if (err) {
          message.error(err);
        } else {
          setRestaurants({
            loading: false,
            data: res.results?.reduce((acc, r) => {
              if (r.id !== id) {
                acc.push(r.toJSON());
              }
              return acc;
            }, []),
            count: res.count,
          });
        }
      }
    );
  }, []);

  const filterExisting = useCallback((restaurants, data = []) => {
    return restaurants.filter((r) => !data.includes(r.objectId));
  }, []);

  const onChange = (value) => {
    setRestaurant(value);
  };

  const onSubmit = () => {
    if (restaurant) {
      if (!group.includes(restaurant)) {
        group.unshift(restaurant);
        setGroup([...group]);
      }
    }
  };

  const deleteItem = (id) => {
    group.splice(group.indexOf(id), 1);
    setGroup([...group]);
  };

  const pickRestaurants = useCallback(
    (restaurants, group = []) => {
      if (!Array.isArray(restaurants)) return [];
      const restGroup = restaurants.reduce((acc, res) => {
        acc[res.objectId] = res;
        return acc;
      }, {});
      return group
        .map((r) => restGroup[r])
        .filter((r) => typeof r === "object");
    },
    [group]
  );

  const onDragEnd = ({ source, destination }) => {
    let temp = group[source.index];
    group.splice(source.index, 1);
    group.splice(destination.index, 0, temp);
    setGroup([...group]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="droppable">
          {(provided) => {
            return (
              <div
                className="section"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <h3 className="section-title">Group restaurants</h3>
                <Item
                  name="show_groups"
                  label="Show groups"
                  valuePropName="checked"
                  style={{ marginBottom: "0px" }}
                >
                  <Switch />
                </Item>
                <div className="add-restaurant">
                  <Select
                    onChange={onChange}
                    placeholder="Select restaurant"
                    className="select"
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    loading={restaurants.loading}
                  >
                    <Select.Option value="">Select restaurant</Select.Option>
                    {filterExisting(restaurants.data, group).map((r) => (
                      <Select.Option value={r.objectId}>{r.name}</Select.Option>
                    ))}
                  </Select>
                  <Button onClick={onSubmit}>Add</Button>
                </div>
                <div className="group-items">
                  {pickRestaurants(restaurants.data, group).map((r, i) => {
                    return (
                      <Draggable draggableId={`droppable-${i}`} index={i}>
                        {(provided) => {
                          return (
                            <div
                              key={i}
                              className="item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img src={r.banner_image} alt="restaurant" />
                              <div>
                                <h3 className="title">{r.name}</h3>
                                <p className="subtitle">{r.hub?.name}</p>
                              </div>
                              <Button
                                size="small"
                                icon={<CloseOutlined />}
                                danger
                                shape="circle"
                                onClick={() => deleteItem(r.objectId)}
                              ></Button>
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                </div>

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </DragDropContext>
  );
}
