import { useCallback, useContext } from "react";
import { Button, Col, Space, Popconfirm } from "antd";
import { BulkProductUploadContext } from "../../../pages/Restaurant/BulkProductUpload";
import useMedia from "../../../hooks/useMedia";
import SelectCategories from "./SelectCategories";

export default function TopBar({ forms, loading, submitHandler, setForms }) {
  const { restaurant } = useContext(BulkProductUploadContext);
  const { deleteFile } = useMedia();

  const selectAll = useCallback(() => {
    const newForms = { ...forms };

    const isAllSelected = Object.values(newForms).every(
      (form) => form.checked === true
    );

    for (const key in newForms) {
      newForms[key]["checked"] = !isAllSelected;
    }

    setForms(newForms);
  }, [forms]);

  const deleteAll = () => {
    const newForms = { ...forms };
    for (const id in newForms) {
      const {
        file: { key },
      } = newForms[id];
      deleteFile(key);
    }
    setForms({});
  };

  return (
    <div className="topbar">
      <h2>{restaurant.data?.name}</h2>
      <Space>
        <Popconfirm
          title="Are you sure you want to delete all items?"
          onConfirm={deleteAll}
        >
          <Button>Delete All</Button>
        </Popconfirm>
        <Button onClick={selectAll}>Select All</Button>
        <SelectCategories forms={forms} setForms={(forms) => setForms(forms)} />
        <Button type="primary" loading={loading} onClick={submitHandler}>
          Submit
        </Button>
      </Space>
    </div>
  );
}
