import { message } from "antd";
import Parse from "parse";
import { useEffect, useState, createContext } from "react";
import InvoiceDetails from "../../components/payments/Invoice";
import PaymentsInfo from "../../components/payments/PaymentsInfo";
import { useHistory, useParams } from "react-router-dom";
import { downloadCsv } from "../../utils";

export const InvoiceContext = createContext();

export const downloadDetails = (details) => {
  const {
    restaurant,
    payment_period,
    success_order,
    failed_order,
    vat_collection,
    vat_included,
    revenue,
    commission,
    product_discount,
    promo_discount,
    failed_order_amount,
    penalty,
    refund,
    payable,
    total_sales,
    orders,
    note,
  } = details ?? {};

  const ordersData =
    orders?.reduce((acc, order, i) => {
      const {
        status,
        orderId,
        orderNumber,
        promo,
        items,
        time,
        issue,
        commission_rate,
        penalty,
      } = order;

      items.forEach((item, i) => {
        const {
          name,
          quantity,
          sale_unit,
          variant,
          total,
          addons,
          refund,
          totalDiscount,
          vat,
          commission,
          totalSale,
          revenue,
          payable,
        } = item;

        let { total: vTotal, name: vName } = variant?.reduce(
          (acc, curr) => {
            const { items } = curr;
            items.forEach((item) => {
              acc.total += item.price;
              acc.name += item.name + " ";
            });

            return acc;
          },
          { total: 0, name: "" }
        ) ?? { total: 0, name: "" };

        let addonsTotal =
          addons?.reduce((acc, curr) => {
            acc += curr.price;
            return acc;
          }, 0) ?? 0;

        let obj = {
          name: `${name} ${vName ? `(${vName}) '` : ""}`,
          sale_unit: sale_unit + vTotal,
          quantity,
          total,
          totalSale,
          vat,
          commission,
          commission_rate,
          revenue,
          discount: totalDiscount,
          addons: addonsTotal,
          promo,
          time,
          refundItemType:
            refund && refund?.quantity
              ? "Product Item"
              : refund
              ? "Addons"
              : "",
          refundQuantity: refund?.quantity ?? 0,
          refundTotal: refund?.total ?? 0,
          payable: payable ?? 0,
        };

        if (i === 0) {
          obj.status = status;
          obj.orderId = orderId;
          obj.orderNumber = orderNumber;
          if (penalty) obj.penalty = penalty;
          if (issue) {
            obj.issueType = issue?.type ?? "";
            obj.issueDetails = issue?.details ?? "";
          }

          if (status !== "delivered" && total) {
            obj.total = 0;
            obj.failedAmount = total * 0.5;
          }

          obj.netSales = total - totalDiscount;
        }

        acc.push(obj);
      });

      return acc;
    }, []) ?? [];

  const periodStr = payment_period
    .map((t) => {
      const d = new Date(t.iso ?? t);
      return d.toDateString();
    })
    .join(" to ");

  downloadCsv(
    [
      {
        title: `Invoice Details for ${restaurant?.name}`,
        header: [
          { key: "info", title: "" },
          { key: "details", title: "Payment Details" },
        ],
        data: [
          {
            info: "Payment Period",
            details:
              payment_period?.length > 1
                ? payment_period
                    .map((i) => new Date(i.iso ?? i).toLocaleDateString())
                    .join(" to ")
                : new Date(
                    payment_period?.[0].iso ?? payment_period?.[0]
                  ).toLocaleDateString(),
          },
          { info: "Success Order", details: success_order },
          { info: "Failed Order", details: failed_order },
          { info: "Total Sales", details: total_sales },
          { info: "VAT Amount", details: restaurant.vat },
          { info: "VAT Collection", details: vat_collection },
          { info: "VAT Included", details: vat_included },
          { info: "Revenue", details: revenue },
          { info: "Commission", details: commission },
          { info: "Product Discount", details: product_discount },
          { info: "Promo Discount", details: promo_discount },
          { info: "Failed Order Amount", details: failed_order_amount },
          { info: "Penalty", details: penalty },
          { info: "Refund", details: refund },
          { info: "Payable", details: payable },
          { info: "Note", details: note },
        ],
      },
      {
        title: "Payment Details",
        header: [
          { key: "time", title: "Date" },
          { key: "orderId", title: "Order ID" },
          { key: "orderNumber", title: "Order Number" },
          { key: "status", title: "Status" },
          { key: "name", title: "Item Name" },
          { key: "sale_unit", title: "Sale Unit" },
          { key: "quantity", title: "Quantity" },
          { key: "addons", title: "Addons" },
          { key: "total", title: "Total" },
          { key: "discount", title: "Total Discount" },
          { key: "totalSale", title: "Total Sale" },
          { key: "vat", title: `VAT ${restaurant.vat}%` },
          { key: "revenue", title: "Store Items Revenue" },
          { key: "commission_rate", title: `Commission Rate` },
          { key: "commission", title: `Commission` },
          { key: "failedAmount", title: "Failed Order Amount 50%" },
          { key: "promo", title: "Promo" },
          { key: "penalty", title: "Penalty" },
          { key: "refundTotal", title: "Refund Item Total" },
          { key: "payable", title: "Net Payable" },
          { key: "refundQuantity", title: "Refund Quantity" },
          { key: "refundItemType", title: "Refund Item Type" },
          { key: "issueType", title: "Refund & Penalty Cause" },
          { key: "issueDetails", title: "Refund & Penalty Details" },
        ],
        data: ordersData.map((order) => {
          order.time = new Date(order.time).toLocaleString().replace(", ", " ");
          if (order.issue) {
            order.issueType = order.issue.type;
            order.issueDetails = order.issue.details;
          }
          return order;
        }),
      },
    ],
    `${restaurant.name}-${periodStr}-invoice-details`
  );
};

export default function Invoice() {
  const searchParams = new URLSearchParams(window.location.search);
  const { invoiceId } = useParams();
  const ids = searchParams.get("ids")?.split(",");
  const restaurantId = searchParams.get("restaurant");
  const [details, setDetails] = useState(null);
  const [reqPayloads, setReqPayloads] = useState(null);
  const [visible, setVisible] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const history = useHistory();

  const fetchInvoiceDetails = async ({
    paymentIds,
    note,
    vat_included,
    restaurant,
  }) => {
    message.loading("Loading...", 0);
    try {
      const res = await Parse.Cloud.run("invoiceDetails", {
        paymentIds,
        restaurant,
        note,
        vat_included,
      });

      setDetails(res);
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  const paymentHandler = async (values) => {
    try {
      setPaymentLoading(true);
      const payment = await Parse.Cloud.run("makePayment", {
        paymentIds: ids,
        restaurant: restaurantId,
        ...values,
      });
      if (payment) {
        setDetails(payment);
        setVisible(false);
        message.success("Payment Successful");
        history.push(`/invoice/${payment.objectId}`);
      }
      setPaymentLoading(false);
    } catch (err) {
      message.error(err.message);
      setPaymentLoading(false);
    }
  };

  const fetchInvoice = async (id) => {
    message.loading("Loading...", 0);
    try {
      const invoice = await new Parse.Query("invoice")
        .equalTo("objectId", id)
        .includeAll()
        .first();
      if (invoice) {
        setDetails(invoice.toJSON());
      } else {
        message.error("Invoice not found");
      }
      message.destroy();
    } catch (err) {
      message.destroy();
      message.error(err.message);
    }
  };

  useEffect(() => {
    if (invoiceId === "new") {
      if (ids && restaurantId) {
        fetchInvoiceDetails({
          paymentIds: ids,
          restaurant: restaurantId,
          note: "",
          paymentsInfo: {
            method: "Cash",
          },
          vat_included: false,
        });
      } else {
        message.error("Invalid Invoice");
      }
    } else {
      fetchInvoice(invoiceId);
    }
  }, []);

  return (
    <InvoiceContext.Provider
      value={{
        details,
        paymentHandler,
        reqPayloads,
        setReqPayloads,
        ids,
        restaurantId,
        visible,
        setVisible,
        paymentLoading,
        setPaymentLoading,
        invoiceId,
        downloadDetails: () => downloadDetails(details),
      }}
    >
      {details && <InvoiceDetails />}
      {details && <PaymentsInfo />}
    </InvoiceContext.Provider>
  );
}
