import { createContext, useReducer, useEffect, useState } from "react";
import { Button, Drawer, message, Space } from "antd";
import PaymentsTable from "../../../components/restaurant/payments/Table";
import useRestaurants from "../../../hooks/useRestaurants";
import { parser } from "../../../utils";
import { Link, useParams } from "react-router-dom";
import Parse from "parse";
import SalesDetails from "./Details";
import useOrders from "../../../hooks/useOrders";

const Types = {
  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAILURE: "GET_PAYMENTS_FAILURE",
  GET_RESTAURANT_REQUEST: "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS: "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_FAILURE: "GET_RESTAURANT_FAILURE",
  GET_INVOICE_REQUEST: "GET_INVOICE_REQUEST",
  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_FAILURE: "GET_INVOICE_FAILURE",
  CLEAR_ALL: "CLEAR_ALL",
};

export const PaymentsContext = createContext();

const initialState = {
  payments: { loading: false, data: { count: 0, results: [] } },
  payment: { loading: false, data: {} },
  restaurant: { loading: false, data: null },
  invoice: { loading: false, data: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_PAYMENTS_REQUEST:
      state.payments.loading = true;
      return { ...state };
    case Types.GET_PAYMENTS_SUCCESS:
      state.payments.loading = false;
      state.payments.data = action.payload;
      return { ...state };
    case Types.GET_PAYMENTS_FAILURE:
      state.payments.loading = false;
      state.payments.data = initialState.payments.data;
      return { ...state };
    case Types.GET_RESTAURANT_REQUEST:
      state.restaurant.loading = true;
      return { ...state };
    case Types.GET_RESTAURANT_SUCCESS:
      state.restaurant.loading = false;
      state.restaurant.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANT_FAILURE:
      state.restaurant.loading = false;
      state.restaurant.data = null;
      return { ...state };
    case Types.GET_INVOICE_REQUEST:
      state.invoice.loading = true;
      return { ...state };
    case Types.GET_INVOICE_SUCCESS:
      state.invoice.loading = false;
      state.invoice.data = action.payload;
      return { ...state };
    case Types.GET_INVOICE_FAILURE:
      state.invoice.loading = false;
      state.invoice.data = null;
      return { ...state };
    case Types.CLEAR_ALL:
      return { ...initialState };
    default:
      return state;
  }
};

export default function RestaurantPayments({ id }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getById: getRestaurantById } = useRestaurants();
  const { restaurantId = id } = useParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [salesId, setSalesId] = useState(null);
  const { dayStartHour, addDay } = useOrders();

  const getPaymentsFunc = async ({
    restaurantId,
    date,
    limit = 100,
    skip = 0,
  }) => {
    dispatch({ type: Types.GET_PAYMENTS_REQUEST });
    try {
      const query = new Parse.Query("restaurant_payment");
      query.equalTo("restaurant", {
        __type: "Pointer",
        className: "restaurant",
        objectId: restaurantId,
      });
      query.select([
        "payment_date",
        "total_collected",
        "vat",
        "commission_rate",
        "penalty",
        "failed_order_amount",
        "promo_discount",
        "product_discount",
        "revenue",
        "paid",
        "restaurant.type",
        "restaurant.commission_type",
        "vat_included",
        "refund",
      ]);
      if (Array.isArray(date)) {
        const [startDate, endDate] = date;
        if (startDate)
          query.greaterThanOrEqualTo("payment_date", dayStartHour(startDate));
        if (endDate)
          query.lessThan("payment_date", addDay(dayStartHour(endDate), 1));
      }
      query.descending("payment_date");
      query.limit(limit);
      query.skip(skip);
      query.withCount();
      const result = await query.find({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      dispatch({
        type: Types.GET_PAYMENTS_SUCCESS,
        payload: {
          count: result.count,
          results: parser(result.results),
        },
      });
    } catch (err) {
      console.log(err);
      message.error(err.message);
      dispatch({
        type: Types.GET_PAYMENTS_FAILURE,
      });
    }
  };

  const getRestaurantFunc = (id) => {
    dispatch({ type: Types.GET_RESTAURANT_REQUEST });
    getRestaurantById(
      { id, select: ["objectId", "name", "banner_image", "payments_info"] },
      (err, res) => {
        if (err) {
          message.error(err);
          dispatch({ type: Types.GET_RESTAURANT_FAILURE });
        } else {
          dispatch({
            type: Types.GET_RESTAURANT_SUCCESS,
            payload: parser(res),
          });
        }
      }
    );
  };

  useEffect(() => {
    getRestaurantFunc(restaurantId);

    let url = new URL(window.location).searchParams;
    let date = url.get("date");
    if (date) {
      date = date.split("to");
      if (date.length !== 2) {
        date = null;
      }
    }

    getPaymentsFunc({
      restaurantId,
      date,
    });
  }, []);

  return (
    <PaymentsContext.Provider
      value={{
        ...state,
        dispatch,
        getPayments: getPaymentsFunc,
        getRestaurant: getRestaurantFunc,
        selectedItems,
        setSelectedItems,
        selectedRowKeys,
        setSelectedRowKeys,
        restaurantId,
        setSalesId,
      }}
    >
      <Space>
        {selectedItems?.length > 0 && (
          <Button type="primary">
            <Link
              to={`/invoice/new?restaurant=${restaurantId}&ids=${selectedItems
                .map((i) => i.id)
                .join(",")}`}
            >
              Invoice Details
            </Link>
          </Button>
        )}
      </Space>
      <PaymentsTable />
      <Drawer
        title="Sales Details"
        width={1000}
        onClose={() => setSalesId(null)}
        visible={salesId}
      >
        {salesId && <SalesDetails id={salesId} />}
      </Drawer>
    </PaymentsContext.Provider>
  );
}
