import { Button, InputNumber, message, Space } from "antd";
import { useEffect, useState } from "react";
import Parse from "parse";
import { CheckOutlined } from "@ant-design/icons";

export default function OrderCapacity() {
  const [capacity, setCapacity] = useState();
  const [object, setObject] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCapacity = async () => {
    try {
      setLoading(true);
      const dispatchHours = await new Parse.Query("config")
        .equalTo("key", "dispatch_hours")
        .first();
      if (dispatchHours) {
        setObject(dispatchHours);
        setCapacity(dispatchHours.get("value")?.capacity);
      }
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    try {
      if (object) {
        setLoading(true);
        const value = object.get("value");
        if (value) {
          value.capacity = capacity;
          object.set("value", value);
          const res = await object.save(null, {
            sessionToken: Parse.User.current()?.getSessionToken(),
          });
          if (res) {
            message.success(`Order capacity updated to ${capacity}`);
          }
        }
        setLoading(false);
      }
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCapacity();
  }, []);

  return (
    <Space>
      <InputNumber
        size="large"
        value={capacity}
        onChange={(e) => setCapacity(e)}
      />
      <Button
        size="large"
        loading={loading}
        disabled={loading}
        onClick={submitHandler}
        type="primary"
        icon={<CheckOutlined />}
        shape="circle"
      ></Button>
    </Space>
  );
}
