import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import React, {
  Form,
  Row,
  Col,
  Input,
  Radio,
  InputNumber,
  Button,
  Space,
} from "antd";
import styled from "styled-components";

const { Item, List } = Form;

export default function CreateSurvey() {
  const [form] = Form.useForm();

  return (
    <Wrapper>
      <Row justify="center">
        <Col span={24} md={20} xl={16}>
          <div className="content-wrapper">
            <div className="content-header">
              <h1>Create Survey</h1>
            </div>
            <Form form={form} layout="vertical" name="survey" size="large">
              <Item name="title" label="Title" className="section">
                <Input />
              </Item>
              <List name="questions">
                {(fields, { add, remove }) => {
                  return (
                    <div style={{ padding: 0 }}>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <div key={i} className="section">
                          <Button
                            className="delete-qn"
                            size="middle"
                            danger
                            shape="circle"
                            onClick={() => remove(name)}
                            icon={<DeleteOutlined />}
                          ></Button>
                          <Item
                            name={[name, "text"]}
                            fieldKey={[key, "text"]}
                            label="Question"
                            {...restField}
                          >
                            <Input />
                          </Item>
                          <Item
                            name={[name, "type"]}
                            fieldKey={[key, "type"]}
                            label="Question Type"
                            {...restField}
                          >
                            <Radio.Group>
                              <Radio value="text">Text</Radio>
                              <Radio value="checkbox">Checkbox</Radio>
                              <Radio value="radio">Radio</Radio>
                            </Radio.Group>
                          </Item>
                          <label>Options</label>
                          <List name={[name, "options"]}>
                            {(optionFields, { add, remove }) => {
                              return (
                                <>
                                  {optionFields.map(
                                    ({ key, name, ...restField }, i) => (
                                      <div key={i}>
                                        <Space>
                                          <div className="round-counter">
                                            {i + 1}
                                          </div>
                                          <Item
                                            name={[name, "value"]}
                                            fieldKey={[key, "value"]}
                                            {...restField}
                                            style={{
                                              width: "300px",
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <Input size="middle" />
                                          </Item>
                                        </Space>
                                      </div>
                                    )
                                  )}
                                  <Item>
                                    <Button
                                      style={{ marginTop: "10px" }}
                                      size="middle"
                                      onClick={() => add()}
                                    >
                                      Add Option
                                    </Button>
                                  </Item>
                                </>
                              );
                            }}
                          </List>
                        </div>
                      ))}

                      <div>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                        >
                          Add Question
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </List>
            </Form>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .content-wrapper {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-form-item-label {
      padding: 0;
    }
    .section {
      background: #fff;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      padding: 10px 24px 24px 24px;
      margin-bottom: 24px;
      position: relative;

      .delete-qn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;

        &:hover {
          background: #ffc0cb6e;
          color: red;
        }
      }

      .round-counter {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #f5f5f5;
        color: #333;
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
`;
