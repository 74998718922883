import CarouselContextProvider from "./Context";
import Body from "../../components/carousel/CarouselsV2";

export default function Carousel() {
  return (
    <CarouselContextProvider>
      <Body />
    </CarouselContextProvider>
  );
}
