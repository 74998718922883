import { useSelector } from "react-redux";
import { Box } from "../../Common/Boxer";
import styled from "styled-components";

export default function RestaurantDetailsInfo() {
  const {
    restaurant: { loading, data },
  } = useSelector((state) => state.restaurantDetails);

  const {
    name,
    phone,
    cuisines,
    managedBy,
    hub,
    type,
    commission,
    commission_type,
    availability,
    vat,
    operating_hours,
    address,
    meta_tags,
    banner_image,
    group,
  } = data || {};

  const Item = ({ title, value, className, ...rest }) => {
    return (
      <div className={`item ${className}`} {...rest}>
        <div className="title">{title}</div>
        <div className="value">{value}</div>
      </div>
    );
  };

  return (
    <Box
      title={loading ? "Loading Restaurant ..." : name}
      minHeight="300px"
      min={8}
    >
      {data && (
        <Wrapper>
          <div className="top">
            <img className="banner" src={banner_image} alt="" />
            <div className="info">
              <Item
                title="Manged By:"
                value={managedBy?.name}
                className="j-start"
              />
              <Item
                title="Availability:"
                value={availability ? "Available" : "Not Available"}
                className="j-start"
              />
              <Item title="Cuisines" value={cuisines} className="block" />
            </div>
          </div>
          <div className="properties">
            <Item title="Open Hours" value={operating_hours?.join(" to ")} />
            <Item title="Phone" value={phone} />
            <Item title="Hub" value={hub?.name} />
            <Item title="Type" value={type} />
            <Item title="Commission" value={commission} />
            <Item title="Commission Type" value={commission_type} />
            <Item title="VAT" value={vat} />
            <Item title="Meta Tags" value={meta_tags?.join(", ")} />
          </div>
        </Wrapper>
      )}
    </Box>
  );
}

const Wrapper = styled.div`
  .top {
    width: 100%;
    display: flex;

    .info {
      width: calc(100% - 100px);
      padding-left: 15px;
    }

    .banner {
      width: 100px;
      min-height: 75px;
      height: auto;
      border-radius: 10px;
      background: #f2f2f2;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 13px;
      font-weight: 500;
      color: #000;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
      color: #6d6767;
    }

    &.block {
      display: block;
    }

    &.j-start {
      justify-content: flex-start;
      .title {
        margin-right: 10px;
      }
    }
  }

  .properties {
    padding-top: 15px;

    .item {
      padding: 3px 10px;
      &:not(:last-child) {
        border-bottom: 1px solid #e0e0e0;
      }
    }
  }
`;
