import StatisticsItem from "../../Common/StatisticsItem";
import { Row, Col } from "antd";

export default function Summery({ data }) {
  const { name, quantity, total, discount, restaurant, stock, count } =
    data || {};

  return (
    <Col span={24} style={{ marginBottom: "20px" }}>
      <Row gutter={[16, 16]}>
        <StatisticsItem
          title={name}
          value={`@${restaurant?.name}`}
          isAmount={false}
        />
        <StatisticsItem title="Total Order" value={count} isAmount={false} />
        <StatisticsItem title="Total sales" value={Math.round(total)} />
        <StatisticsItem title="Sold items" value={quantity} isAmount={false} />
        <StatisticsItem title="Discount" value={discount} />
        <StatisticsItem title="Current Stock" isAmount={false} value={stock} />
      </Row>
    </Col>
  );
}
