import InventoryTable from '../../components/inventory/Inventories';
import InventoryContextProvider from '../../components/inventory/InventoryContext';
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


export default function Inventories() {
    return (
        <InventoryContextProvider>
            <div style={{padding: '20px', marginBottom: '10px'}} >
                <Button size="large" style={{display: 'flex', alignItems: 'center'}} type="primary" icon={<PlusOutlined /> }>
                    <Link style={{color: '#fff'}} to="/inventory/add"> Add Record</Link>
                </Button>
            </div>
            <InventoryTable />
        </InventoryContextProvider>
    )
}
