import { useContext, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Select, Form } from "antd";
import { SectionsContext } from "./SectionsContext";
const { Option } = Select;

export default function AddSectionItem({ close, sectionId, items, hubs }) {
  const { addNewItemToSection, getRestaurants, restaurants } =
    useContext(SectionsContext);
  const { loading, data } = restaurants;
  const [form] = Form.useForm();

  const onFinish = ({ items }) => {
    addNewItemToSection(sectionId, items, () => {
      form.resetFields();
      close();
    });
  };

  const onChange = (e) => {
    getRestaurants({ search: e.target.value, limit: 10 });
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  const filterAvailableItems = (data) => {
    if (data && Array.isArray(data)) {
      const itemsGroup = items.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});
      return data.filter((i) => {
        return !itemsGroup[i.id];
      });
    } else {
      return [];
    }
  };

  return (
    <div className="add-item">
      <div className="header">
        <h3 className="title">Add New Item</h3>
        <div onClick={close} className="close-x">
          <CloseOutlined />
        </div>
      </div>
      <Form
        form={form}
        style={{ width: "100%" }}
        layout="vertical"
        name="add-restaurant"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="items"
          rules={[{ required: true, message: "Please select a restaurant" }]}
          style={{ marginBottom: "10px", width: "100%" }}
          onChange={onChange}
        >
          <Select
            showSearch
            placeholder="Select a restaurant"
            style={{ width: "100%" }}
            optionFilterProp="children"
            loading={loading}
            size="large"
            autoFocus
            allowClear
            mode="multiple"
          >
            {data &&
              filterAvailableItems(data).map((restaurant, i) => (
                <Option key={i} value={restaurant.id}>
                  {restaurant.get("name")}{" "}
                  {restaurant.get("hub") &&
                    `(${restaurant.get("hub").get("name")})`}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Button
          size="large"
          style={{ width: "100%" }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}
