import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Boxer, { Box } from "../../components/Common/Boxer";
import Groups from "../../components/restaurant/Details/Groups";
import RestaurantDetailsInfo from "../../components/restaurant/Details/Info";
import { getRestaurant } from "../../store/restaurant/restaurantDetails";
import RestaurantCategories from "./Categories";
import ProductList from "../Product/ProductList";
import BulkProductUpload from "./BulkProductUpload";
import RestaurantPayments from "./Payments";

export default function RestaurantDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getRestaurant(id));
  }, [id]);

  return (
    <div>
      <Boxer>
        <RestaurantDetailsInfo />
        <Box
          title="Product Upload"
          meta="restaurant-page"
          min={18}
          maxHeight="700px"
        >
          <BulkProductUpload id={id} />
        </Box>
        <Box title="Sales" meta="restaurant-page" min={18} maxHeight="700px">
          <RestaurantPayments id={id} />
        </Box>
        <Groups />
        <Box title="Categories" min={18}>
          <RestaurantCategories restaurantId={id} />
        </Box>
        <Box title="Products" min={12}>
          <ProductList restaurantId={id} />
        </Box>
      </Boxer>
    </div>
  );
}
