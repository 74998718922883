import { Form, InputNumber } from "antd";
import { Box } from "../../Common/Boxer";
import Layout from "./Layout";

const { Item } = Form;

function VehicleType({ title, type, riderType }) {
  return (
    <Layout title={title} style={{ paddingLeft: "20px" }}>
      <Item
        label="Consider Late Entry (Min)"
        name={["penalty", riderType, "fullTime", type, "considerLateEntry"]}
      >
        <InputNumber />
      </Item>
      <Item
        label="Fixed Amount"
        name={["penalty", riderType, "fullTime", type, "fixedAmount"]}
      >
        <InputNumber />
      </Item>
    </Layout>
  );
}

function RiderType({ type, title }) {
  return (
    <Layout title={title}>
      <VehicleType type="bike" title="Bike" riderType={type} />
      <VehicleType type="cycle" title="Cycle" riderType={type} />
    </Layout>
  );
}

export default function Penalty() {
  return (
    <Box title="Penalty">
      <RiderType type="commission" title="Commission Rider" />
      <RiderType type="salary" title="Salary Rider" />
    </Box>
  );
}
