import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getRider } from "../../store/rider/riderCrudSlices";
import ProfileInfo from "../../components/rider/profile/ProfileInfo";
import TravelLogs from "../../components/rider/profile/TravelLogs";
import Boxer, { Box } from "../../components/Common/Boxer";

export default function RiderProfile() {
  const {
    rider: { loading, data },
  } = useSelector((state) => state.riderCrud);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getRider({
        id,
        cb: (query) => {
          query.select([
            "name",
            "username",
            "email",
            "phone",
            "image",
            "riderHub.name",
            "jobType",
            "vehicleType",
            "employeeType",
            "nidNumber",
            "riderBasic",
            "date_of_birth",
            "riderDutyHour",
          ]);
        },
      })
    );
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <Boxer>
        <Box title="Profile">
          <ProfileInfo data={data} />
        </Box>
        <Box title="Travel Logs" min={12}>
          <TravelLogs />
        </Box>
      </Boxer>
    </div>
  );
}
