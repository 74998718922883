import { Col, Row } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DateHandler from "../dashboard/DateHandler";
import Item from "../../components/Common/StatisticsItem";
import styles from "./PaymentHistoryDashboard.module.css";
import Parse from "parse";
import { PaymentHistoryContext } from "../../pages/Payments/PaymentHistoryPage";


export default function PaymentHistoryDashboard() {
    const params = useParams();
    const { date, setDate } = useContext(PaymentHistoryContext);

    const [dashboardData, setDashboardData] = useState({
        failedOrderAmount: 0,
        penaltyAmount: 0,
        rejectedOrders: 0,
        successfullyDelivered: 0,
        totalNetPayable: 0,
        totalPaid: 0,
        totalPlacedOrder: 0,
        totalSales: 0,
        vendorDiscount: 0,
        totalVatAmount: 0,
        commission: 0
    })

    const fetchDashboardData = () => {
        const startDateStr = moment(date[0]).format('YYYY-MM-DD');
        const endDateStr = moment(date[1]).format('YYYY-MM-DD');
        const restaurantId = params.resturentId;
        
        Parse.Cloud.run("getPaymentDetailDashboard",
            { startDateStr, endDateStr, restaurantId }
        ).then(res => setDashboardData(res))
    }

    useEffect(() => fetchDashboardData(), []);

    useEffect(() => fetchDashboardData(), [date]);

    return (
        <>
            <Row justify="space-between" align="center">
                <Col>
                    <span className={styles.historyHeader}> Payment Details <span className={styles.historyResturentId}>-  {dashboardData.restaurant_name}({params.resturentId})</span></span>
                </Col>
                <Col>
                    <DateHandler setDate={setDate} defaultValue={date} margin="0" />
                </Col>

            </Row>
            <Row gutter={[24, 24]} className={styles.itemHolder}>
                <Item title="Total Placed Order" value={dashboardData.totalPlacedOrder} isAmount={false} />
                <Item title="Successfully Delivered" value={dashboardData.successfullyDelivered} isAmount={false} />
                <Item title="Canceled/Rejected orders" value={dashboardData.rejectedOrders} isAmount={false} />
                <Item title="Total sales" value={dashboardData.totalSales.toFixed(0).toString()} />
                <Item title="Vendor Discount" value={dashboardData.vendorDiscount.toFixed(0).toString()} />
                <Item title="Penalty Amount" value={dashboardData.penaltyAmount.toFixed(0).toString()} />
                <Item title="Failed Order Amount" value={dashboardData.failedOrderAmount.toFixed(0).toString()} />
                <Item title="Total Paid " value={dashboardData.totalPaid.toFixed(0).toString()} />
                <Item title="Total Net Payable" value={dashboardData.totalNetPayable.toFixed(0).toString()} />
                <Item title="Total Vat Amount" value={dashboardData.totalVatAmount.toFixed(0).toString()} />
                <Item title="Commission" value={dashboardData.commission.toFixed(0).toString()} />
            </Row>
        </>
    )
}