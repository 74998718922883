import {
  EditOutlined,
  DeleteOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Row,
  Col,
  Select,
  message,
  Space,
  Table,
  Tag,
  Drawer,
  Popconfirm,
} from "antd";
import {
  useState,
  useReducer,
  useEffect,
  createContext,
  useContext,
} from "react";
import Parse from "parse";
import { parser } from "../../utils";
import useRestaurants from "../../hooks/useRestaurants";
import moment from "moment";
import useSelect from "../../components/inventory/utils/useSelect";
import AddNewSlot from "./NewSchedule";

export const SchedulingContext = createContext();

export const slots = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const days = [
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
];
export const hours = [
  { value: 0, label: "12:00 AM" },
  { value: 1, label: "1:00 AM" },
  { value: 2, label: "2:00 AM" },
  { value: 3, label: "3:00 AM" },
  { value: 4, label: "4:00 AM" },
  { value: 5, label: "5:00 AM" },
  { value: 6, label: "6:00 AM" },
  { value: 7, label: "7:00 AM" },
  { value: 8, label: "8:00 AM" },
  { value: 9, label: "9:00 AM" },
  { value: 10, label: "10:00 AM" },
  { value: 11, label: "11:00 AM" },
  { value: 12, label: "12:00 PM" },
  { value: 13, label: "1:00 PM" },
  { value: 14, label: "2:00 PM" },
  { value: 15, label: "3:00 PM" },
  { value: 16, label: "4:00 PM" },
  { value: 17, label: "5:00 PM" },
  { value: 18, label: "6:00 PM" },
  { value: 19, label: "7:00 PM" },
  { value: 20, label: "8:00 PM" },
  { value: 21, label: "9:00 PM" },
  { value: 22, label: "10:00 PM" },
  { value: 23, label: "11:00 PM" },
];

function UpdateSlot({ id, ...props }) {
  const { sections, restaurants, fetchRestaurants } =
    useContext(SchedulingContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.start_date = new Date(values.start_date).toISOString();
      values.end_date = new Date(values.end_date).toISOString();
      await Parse.Cloud.run("updateSectionSchedule", { ...values, id });
      message.success("New slot added successfully");
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      start_date: moment(props.start_date),
      end_date: moment(props.end_date),
      days: props.days,
      hours: props.hours,
      position: props.position,
      section: props.section.id,
      restaurant: props.restaurant.id,
    });
    fetchRestaurants({ ids: [props.restaurant.id] });
  }, []);

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={[16]}>
          <Col span={12}>
            <Form.Item label="Start Date" name="start_date">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Date" name="end_date">
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Select Section" name="section">
              <Select placeholder="Select Section">
                {sections.map((section) => (
                  <Select.Option key={section.id} value={section.id}>
                    {section.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Select Restaurant" name="restaurant">
              <Select
                showSearch
                placeholder="Select Restaurant"
                onSearch={(value) => {
                  fetchRestaurants({ name: value });
                }}
                optionFilterProp="children"
                filterOption={true}
              >
                {restaurants.map((rest, i) => (
                  <Select.Option key={i} value={rest.id}>{`${
                    rest.name
                  } - ${rest.hub?.get("name")}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Days" name="days">
              <Select mode="multiple" placeholder="Select Slot">
                {days.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Hours" name="hours">
              <Select mode="multiple" placeholder="Select Hours">
                {hours.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Select Slot" name="position">
              <Select placeholder="Select Slot">
                {slots.map((slot) => (
                  <Select.Option key={slot} value={slot}>
                    {slot}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "right" }}>
          <Space>
            <Button loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

const initialState = {
  list: {
    results: [],
    count: 0,
  },
  sections: [],
  restaurants: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setList":
      return { ...state, list: action.payload };
    case "deleteItem":
      const index = state.list.results.findIndex(
        (item) => item.id === action.payload
      );
      if (index !== -1) {
        state.list.results.splice(index, 1);
      }
      return { ...state, list: { ...state.list } };
    case "setSections":
      return { ...state, sections: action.payload };
    case "setRestaurants":
      return { ...state, restaurants: action.payload };
    default:
      return state;
  }
};

export default function SectionScheduling() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getRestaurants } = useRestaurants();
  const [update, setUpdate] = useState(null);
  const [, getSelectProps] = useSelect({
    items: state.restaurants,
    onSearch: (v) =>
      fetchRestaurants({ limit: 5, search: v, select: ["name", "hub.name"] }),
    getName: (v) => `${v.name} - ${v.hub?.get("name")}`,
    getValue: (v) => v.id,
  });

  const fetchSections = async () => {
    try {
      const res = await new Parse.Query("section").select(["name"]).find();
      dispatch({ type: "setSections", payload: parser(res) });
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchRestaurants = async (props) => {
    try {
      getRestaurants(
        { limit: 10, select: ["name", "hub.name"], ...props },
        (err, res) => {
          if (err) {
            message.error(err.message);
          } else {
            dispatch({ type: "setRestaurants", payload: parser(res.results) });
          }
        }
      );
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchData = async ({
    limit,
    skip,
    section,
    restaurant,
    start_date,
    end_date,
    days,
    hours,
  }) => {
    try {
      message.loading("Loading...", 0);
      const query = new Parse.Query("section_schedule")
        .select([
          "section.name",
          "restaurant.name",
          "days",
          "hours",
          "start_date",
          "end_date",
          "position",
          "api_call",
        ])
        .limit(limit)
        .skip(skip)
        .withCount()
        .descending("createdAt");

      if (Array.isArray(section)) {
        query.containedIn(
          "section",
          section.map((id) => Parse.Object.createWithoutData("section", id))
        );
      }
      if (Array.isArray(restaurant)) {
        query.containedIn(
          "restaurant",
          restaurant.map((id) =>
            Parse.Object.createWithoutData("restaurant", id)
          )
        );
      }

      if (Array.isArray(start_date)) {
        const [start, end] = start_date.map((date) => new Date(date));
        if (
          start.toString() !== "Invalid Date" &&
          end.toString() !== "Invalid Date"
        ) {
          end.setHours(23, 59, 59, 999);
          query.greaterThanOrEqualTo("start_date", start);
          query.lessThanOrEqualTo("start_date", end);
        }
      }

      if (Array.isArray(end_date)) {
        const [start, end] = end_date.map((date) => new Date(date));
        if (
          start.toString() !== "Invalid Date" &&
          end.toString() !== "Invalid Date"
        ) {
          end.setHours(23, 59, 59, 999);
          query.greaterThanOrEqualTo("end_date", start);
          query.lessThanOrEqualTo("end_date", end);
        }
      }

      if (Array.isArray(days)) {
        query.containsAll("days", days);
      }

      if (Array.isArray(hours)) {
        query.containsAll("hours", hours);
      }

      const res = await query.find();
      dispatch({
        type: "setList",
        payload: {
          results: parser(res.results),
          count: res.count,
        },
      });
      message.destroy(0);
    } catch (err) {
      message.destroy(0);
      message.error(err.message);
    }
  };

  const deleteItem = async (object) => {
    try {
      if (object instanceof Parse.Object) {
        await object.destroy();
        dispatch({
          type: "deleteItem",
          payload: object.id,
        });
        message.success("Deleted successfully");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchSections();
    fetchData({ limit: 100, skip: 0 });
  }, []);

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    sorter: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
  });

  const columns = [
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "120px",
      ...dateSearchProps("start_date"),
      render: (start_date) => moment(start_date).format("YYYY-MM-DD"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "120px",
      ...dateSearchProps("end_date"),
      render: (end_date) => moment(end_date).format("YYYY-MM-DD"),
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      width: "180px",
      filters: state.sections.map((section) => ({
        text: section.name,
        value: section.id,
      })),
      render: (section) => section?.get("name"),
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      width: "160px",
      ...getSelectProps("restaurant"),
      render: (restaurant) => restaurant?.get("name") || "",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      filters: days.map((day) => ({
        text: day.label,
        value: day.value,
      })),
      render: (arr) =>
        arr.map((day) => (
          <Tag>{days.find(({ value }) => value === day)?.label}</Tag>
        )),
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      filters: hours.map((hour) => ({
        text: hour.label,
        value: hour.value,
      })),
      render: (arr) =>
        arr.map((hour) => (
          <Tag>{hours.find(({ value }) => value === hour)?.label}</Tag>
        )),
    },
    {
      title: "API Call",
      dataIndex: "api_call",
      key: "api_call",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "120px",
      render: (id, params) => (
        <Space>
          <Button
            onClick={() => setUpdate(params)}
            icon={<EditOutlined />}
            type="primary"
            shape="circle"
          />
          <Popconfirm
            title="Are you sure to delete this item?"
            onConfirm={() => deleteItem(params.ref)}
          >
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              type="danger"
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <SchedulingContext.Provider
      value={{ ...state, dispatch, fetchRestaurants }}
    >
      <div className="scheduling">
        <h2>Section Scheduling</h2>
        <Space>
          <AddNewSlot />
        </Space>
        <div style={{ marginTop: "20px" }}>
          <Table
            columns={columns}
            dataSource={Array.from(state.list.results)}
            scroll={{
              x: 1000,
            }}
            pagination={{
              total: state.list.count,
              defaultPageSize: 100,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "50", "100", "200", "500"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              position: ["topLeft", "topRight"],
            }}
            onChange={(pagination, filter) => {
              fetchData({
                limit: pagination.pageSize,
                skip: (pagination.current - 1) * pagination.pageSize,
                start_date: filter.start_date?.[0],
                end_date: filter.end_date?.[0],
                section: filter.section,
                restaurant: filter.restaurant,
                days: filter.days,
                hours: filter.hours,
              });
            }}
          />
          <Drawer
            title="Update Slot"
            width={720}
            onClose={() => setUpdate(null)}
            visible={update}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {update && (
              <UpdateSlot
                {...update}
                object={update.ref}
                fetchRestaurants={fetchRestaurants}
              />
            )}
          </Drawer>
        </div>
      </div>
    </SchedulingContext.Provider>
  );
}
