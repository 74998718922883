import styled from "styled-components";
import { Col } from "antd";
import { useHistory } from "react-router-dom";
import { FC } from "react";

const StatisticsItem: FC<any> = ({
  title,
  value,
  isAmount,
  value2,
  link,
  htmlTitle = "",
}) => {
  const history = useHistory();

  return (
    <Col xs={24} sm={12} lg={8} xl={6}>
      <Wrapper
        className="statistics-item"
        onClick={() => {
          if (link) {
            history.push(link);
          }
        }}
        style={{ cursor: link ? "pointer" : "default" }}
        title={htmlTitle}
      >
        <h3 className="title">{title}</h3>
        <div className="value">
          {value ?? 0} {isAmount ? "tk" : ""}
        </div>
        <div className="value2">{value2}</div>
      </Wrapper>
    </Col>
  );
};

export default StatisticsItem;

StatisticsItem.defaultProps = {
  isAmount: true,
};

const Wrapper = styled.div`
  padding: 28px;
  background: linear-gradient(45deg, #455a64, #607d8b);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;

  .title {
    font-size: 14px;
    letter-spacing: 1px;
    margin-bottom: 0px;
    color: #fff;
  }

  .value {
    font-size: 24px;
    letter-spacing: 1px;
    color: #fff389;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .value2 {
    position: absolute;
    font-size: 16px;
    right: 16px;
    bottom: 16px;
    font-weight: 500;
    color: #fff389;
    letter-spacing: 1px;

    .title {
      color: #fff;
    }
  }
`;
