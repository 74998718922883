import { useContext } from "react";
import { DetailsContext } from "../../../../pages/Order/Details";
import { Row, Col, Button, Popconfirm, Space, Tag } from "antd";
import styled from "styled-components";
import UpdateItems from "./UpdateItems";
import OrderItemVariant from "./OrderItemVariant";
import OrderItemAddons from "./OrderItemAddons";
import OrderItemCharge from "./OrderItemCharge";
import { DeleteOutlined } from "@ant-design/icons";
import { compressedImage } from "../../../../common/utils";
import ProductLinks from "../../../Common/ProductLinks";
import RestaurantLinks from "../../../Common/RestaurantLinks";

export default function OrderItem(props) {
  const { order, editMode, getOrderCharges } = useContext(DetailsContext);
  const { id, name, image, restaurant, variant, addons, pickup, setLoading } =
    props;

  const deleteItem = (id) => {
    const index = order.data.order_items.findIndex((item) => item.id === id);
    if (index !== -1) {
      order.data.order_items.splice(index, 1);
      setLoading(true);
      getOrderCharges(order, () => {
        setLoading(false);
      });
    }
  };

  return (
    <OrderItemWrapper>
      <Row>
        <Col span={16}>
          <div className="heading">
            <img className="image" src={compressedImage(image)} alt="" />
            <div className="idty">
              <ProductLinks id={id}>
                <h3 className="name">{name}</h3>
              </ProductLinks>
              <div className="restaurant">
                <span style={{ marginRight: "10px" }}>from </span>
                <RestaurantLinks id={restaurant?.id}>
                  <span style={{ fontSize: "14px" }}>{restaurant?.name}</span>
                </RestaurantLinks>
              </div>
              {pickup && (
                <Space style={{ marginTop: "10px" }}>
                  <Tag color={pickup.confirmed ? "volcano" : ""}>Confirmed</Tag>
                  <Tag color={pickup.ready ? "volcano" : ""}>Ready</Tag>
                  <Tag color={pickup.picked ? "volcano" : ""}>Picked</Tag>
                </Space>
              )}
            </div>
            {editMode && (
              <div className="item-action">
                <UpdateItems {...props} />
                <Popconfirm
                  title="Are you sure delete this item?"
                  onConfirm={() => deleteItem(id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    style={{ display: "flex", alignItems: "center" }}
                    size="middle"
                    danger
                  >
                    <DeleteOutlined /> Delete{" "}
                  </Button>
                </Popconfirm>
              </div>
            )}
          </div>
          <OrderItemVariant variant={variant} />
          <OrderItemAddons addons={addons} />
        </Col>
        <OrderItemCharge {...props} />
      </Row>
    </OrderItemWrapper>
  );
}

const OrderItemWrapper = styled.div`
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6;

  .charges {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    .item {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      padding: 3px 10px 2px 10px;
    }
  }

  .heading {
    width: 100%;
    display: flex;

    .image {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 50%;
      background: #eee;
    }

    .idty {
      .name,
      .restaurant {
        margin: 0;
      }
      .name {
        font-size: 16px;
      }
      .restaurant {
        font-size: 14px;
        display: flex;
        align-items: center;
      }
    }

    .item-action {
      display: flex;
      margin-left: auto;
      display: flex;
      margin-right: 10px;

      .edit-btn {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      button {
        border-radius: 30px;
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }

  .variant {
    margin-top: 0px;
    padding: 10px 20px;
  }
  .variations {
    width: 100%;
    .variation {
      &-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        padding: 0 10px;
      }
    }
  }

  .addons {
    padding: 10px 20px;
    .item {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      font-weight: 500;
      width: 100%;
      font-size: 16px;
    }
  }
`;
