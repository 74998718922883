import { useState, useContext } from 'react';
import { Modal } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined  } from '@ant-design/icons'
import CustomButton from "./CustomButton"
import { CategoriesContext } from '../../pages/Categories/CategoriesContext'

export default function DeleteCategory({ id, name }) {
    const { deleteCategory } = useContext(CategoriesContext)
    const [loading, setLoading] = useState(false)

    function confirm() {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: <span>Are you sure you want to delete <strong>{name}</strong> category?</span>,
          okText: 'Yes',
          cancelText: 'No',
          onOk(){
            setLoading(true)
            deleteCategory(id, () => {
                setLoading(false)
                Modal.destroyAll()
            })
          }
        });
      }

    return(
        <CustomButton onClick={confirm} loading={loading} icon={<DeleteOutlined />} danger>Delete</CustomButton>
    )
}