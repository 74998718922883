import { useContext } from "react";
import { ProductListContext } from "../../../pages/Product/ProductList";
import { Input, Select, Form, Button, message, Space } from "antd";
import Parse from "parse";

const { Option } = Select;

export default function AvailabilityHandler({
  selectedProducts,
  setSelectedProducts,
  clearSelectedRows,
}) {
  const { updateProducts } = useContext(ProductListContext);

  const onFinish = async ({ availability }) => {
    const newRefs = selectedProducts.reduce((acc, { refs }) => {
      if (refs) {
        refs.set("availability", availability);
        acc.push(refs);
      }
      return acc;
    }, []);

    try {
      const res = await Parse.Object.saveAll(newRefs);
      if (res) {
        message.success("Availability updated successfully");
        clearSelectedRows([]);
        setSelectedProducts([]);

        updateProducts((products) => {
          const refsGroup = newRefs.reduce((acc, ref) => {
            acc[ref.id] = ref;
            return acc;
          }, {});

          products.results.forEach((item) => {
            if (refsGroup[item.objectId]) {
              item.ref = refsGroup[item.objectId];
              item.availability = availability;
            }
          });

          return products;
        });
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return selectedProducts.length > 0 ? (
    <div>
      <Form name="basic" onFinish={onFinish} layout="horizontal">
        <Space>
          <label>Availability: </label>
          <Input.Group compact>
            <Form.Item name="availability" style={{ marginBottom: 0 }}>
              <Select style={{ width: "160px" }} placeholder="Select Status">
                <Option value="available">Available</Option>
                <Option value="unavailable">Unavailable</Option>
                <Option value="stockout">Stockout</Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button htmlType="submit" type="primary">
                Ok
              </Button>
            </Form.Item>
          </Input.Group>
        </Space>
      </Form>
    </div>
  ) : null;
}
