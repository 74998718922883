import { message } from "antd";
import { createContext, useEffect, useReducer, useState } from "react";
import useCarousel from "../../hooks/useCarousel";
import { parser } from "../../utils";
import useRestaurants from "../../hooks/useRestaurants";
import Parse from "parse";

export const CarouselContext = createContext();

const Types = {
  GET_CAROUSELS_REQUEST: "GET_CAROUSELS_REQUEST",
  GET_CAROUSELS_SUCCESS: "GET_CAROUSELS_SUCCESS",
  GET_CAROUSELS_FAILURE: "GET_CAROUSELS_FAILURE",
  UPDATE_BANNER: "UPDATE_BANNER",
};

const initialState = {
  carousels: {
    loading: false,
    data: {
      count: 0,
      results: [],
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_CAROUSELS_REQUEST:
      state.carousels.loading = true;
      return { ...state };
    case Types.GET_CAROUSELS_SUCCESS:
      state.carousels.loading = false;
      state.carousels.data = action.payload;
      return { ...state };
    case Types.GET_CAROUSELS_FAILURE:
      state.carousels.loading = false;
      return { ...state };
    case Types.UPDATE_BANNER:
      const carousel = state.carousels.data.results.find(
        (i) => i.id === action.payload?.id
      );
      if (carousel) {
        carousel.banner = action.payload?.banner;
        if (carousel.ref instanceof Parse.Object) {
          carousel.ref.set("banner", action.payload?.banner);
        }
      }
      return { ...state };
    default:
      return state;
  }
};

export default function CarouselContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [restaurants, setRestaurants] = useState([]);
  const { getCarousels, createNew, updateCarousel, deleteCarousel } =
    useCarousel();
  const { getRestaurants } = useRestaurants();
  const [editBanner, setEditBanner] = useState(null);

  const fetchCarousels = (params) => {
    dispatch({ type: Types.GET_CAROUSELS_REQUEST });
    message.loading("Loading...", 0);
    getCarousels(params, (err, data) => {
      if (err) {
        dispatch({
          type: Types.GET_CAROUSELS_FAILURE,
        });
        return message.error(err);
      }

      dispatch({
        type: Types.GET_CAROUSELS_SUCCESS,
        payload: {
          count: data.count,
          results: parser(data.results),
        },
      });
      message.destroy();
    });
  };

  const createCarousel = (data, callback) => {
    createNew(data, (err, data) => {
      if (err) {
        message.error(err);
        if (typeof callback === "function") callback(err, null);
        return;
      }

      if (data) {
        message.success("Carousel created successfully!");
        if (typeof callback === "function") callback(null, data);
        // add data to state
        state.carousels.data.count++;
        state.carousels.data.results.unshift(parser(data));
        dispatch({
          type: Types.GET_CAROUSELS_SUCCESS,
          payload: state.carousels.data,
        });
      }
    });
  };

  const updateHandler = (params) => {
    updateCarousel(params, (err, data) => {
      if (err) {
        message.error(err);
      }

      if (data) {
        const index = state.carousels.data.results.findIndex(
          (item) => item.id === data.id
        );
        if (index !== -1) {
          state.carousels.data.results[index] = parser(data);
          dispatch({
            type: Types.GET_CAROUSELS_SUCCESS,
            payload: state.carousels.data,
          });
          message.success("Carousel updated successfully!");
        }
      }
    });
  };

  const deleteHandler = (id, callback) => {
    deleteCarousel(id, (err, data) => {
      if (err) {
        message.error(err);
        if (typeof callback === "function") {
          callback(false);
        }
        return;
      }

      const index = state.carousels.data.results.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        state.carousels.data.results.splice(index, 1);
        state.carousels.data.count--;
        dispatch({
          type: Types.GET_CAROUSELS_SUCCESS,
          payload: state.carousels.data,
        });
        message.success("Carousel deleted successfully!");
        if (typeof callback === "function") {
          callback(true);
        }
      }
    });
  };

  useEffect(() => {
    getRestaurants(
      { select: ["name", "hub.name", "slug"], limit: 10 },
      (err, data) => {
        if (data) {
          setRestaurants(data.results?.map((i) => i.toJSON()));
        }

        if (err) {
          message.error(err);
        }
      }
    );
  }, []);

  const searchRestaurants = (query, ids) => {
    getRestaurants(
      { select: ["name", "hub.name", "slug"], name: query, limit: 5, ids },
      (err, data) => {
        if (data) {
          setRestaurants(data.results?.map((i) => i.toJSON()));
        }

        if (err) {
          message.error(err);
        }
      }
    );
  };

  return (
    <CarouselContext.Provider
      value={{
        ...state,
        dispatch,
        fetchCarousels,
        createCarousel,
        updateHandler,
        deleteHandler,
        restaurants,
        searchRestaurants,
        editBanner,
        setEditBanner,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
}
