import Parse from "parse";

const repoName = "hub";
const getUserToken = () => Parse.User.current().getSessionToken();

export const getHubs = ({ limit = 100, skip = 0, select } = {}) => {
  const query = new Parse.Query(repoName);
  query.limit(limit);
  query.skip(skip);
  query.withCount();
  if (select) query.select(select);

  return query.find({
    sessionToken: getUserToken(),
  });
};

export const getById = ({ id, select } = {}) => {
  const query = new Parse.Query(repoName);
  query.equalTo("objectId", id);
  if (select) query.select(select);
  return query.first({
    sessionToken: getUserToken(),
  });
};

export const createNew = ({ name, region, areas, address }) => {
  const hub = new Parse.Object(repoName);
  hub.set("name", name);
  hub.set("region", region);
  hub.set("areas", areas);
  hub.set("address", address);
  return hub.save(null, {
    sessionToken: getUserToken(),
  });
};
