import { useContext } from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { SectionsContext } from "./SectionsContext";

export default function DeleteSection({ sectionId }) {
  const { deleteSectionFunc } = useContext(SectionsContext);

  function confirm(e) {
    deleteSectionFunc(sectionId);
  }

  return (
    <Popconfirm
      title="Are you sure to delete this section?"
      onConfirm={confirm}
      okText="Yes"
      cancelText="No"
    >
      <Button shape="circle" icon={<DeleteOutlined />} danger />
    </Popconfirm>
  );
}
