import { Table } from "antd";
import { useContext, useState, useEffect } from "react";
import { HubsContext } from "./HubsProvider";
import CreateHubArea from "./CreateHubArea";
import UpdateHubArea from "./UpdateHubArea";
import useSearch from "../../components/inventory/utils/useSearch";

function Area({ area, hub, name }) {
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [charge, setCharge] = useState(area?.delivery_charge);

  return (
    <div>
      {charge && !create && !update && (
        <div onDoubleClick={() => setUpdate(true)}>{charge}</div>
      )}
      {update && (
        <UpdateHubArea
          charge={charge}
          setCharge={setCharge}
          refs={area?.refs}
          setUpdate={setUpdate}
        />
      )}
      {!charge && !create && (
        <div
          onDoubleClick={() => setCreate(true)}
          style={{ fontSize: "12px", color: "gray" }}
        >
          No charge
        </div>
      )}
      {create && (
        <CreateHubArea
          charge={charge}
          setCharge={setCharge}
          area={name}
          hub={hub}
          setCreate={setCreate}
        />
      )}
    </div>
  );
}

export default function HubAreas() {
  const {
    areas,
    hubs: { data },
    fetchHubAreas,
    fetchHubs,
  } = useContext(HubsContext);
  const [, getSearchProps] = useSearch();

  useEffect(() => {
    if (data.results.length === 0) {
      fetchHubs({}, false);
    }
  }, []);

  useEffect(() => {
    if (data.results.length) {
      fetchHubAreas();
    }
  }, [data.results]);

  const columns = [
    {
      title: "Area",
      dataIndex: "name",
      key: "name",
      width: "160px",
      fixed: "left",
      ...getSearchProps("name"),
    },
  ];

  if (Array.isArray(data.results)) {
    data.results.forEach((hub) => {
      columns.push({
        title: hub.name,
        dataIndex: hub.id,
        key: hub.id,
        width: "140px",
        render: (area, record) => {
          // console.log(area, record);
          return <Area area={area} hub={hub.id} name={record.name} />;
        },
      });
    });
  }

  console.log(areas);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={areas}
        pagination={{
          pageSize: areas.length,
        }}
        scroll={{ x: true }}
      />
    </div>
  );
}
