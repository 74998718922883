import { Skeleton, Row, Col } from 'antd'

const Item = (props) => (
    <Col xs={12} sm={12} md={8} lg={6}>
        <Skeleton.Input style={{ width: 200, height: '100px', borderRadius: '10px' }} active size="large" />
    </Col>
)

export default function DashboardSkeleton() {
    return (
        <Row gutter={[16, 24]} style={{padding: '30px 20px'}}>
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
            <Col span={24}>
                <Skeleton paragraph={4} />
            </Col>
        </Row>
    )
}
