import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const Authmiddleware = ({
	component: Component,
	isAuthProtected,
	data,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthProtected && !data) {
					return (
						<Redirect
							to={{ pathname: '/login', state: { from: props.location } }}
						/>
					);
				}

				if(rest.path === '/' && data && ['admin', 'superadmin'].includes(data?.type)){
					return (
						<Redirect
							to={{ pathname: '/dashboard' }}
						/>
					);
				}

				return <Component {...props} />;
			}}
		/>
	);
};

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object
};

export default Authmiddleware;
