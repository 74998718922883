import { useState, useContext } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import ContentHeader from "./ContentHeader";
import Styled from "styled-components";
import { AuthContext } from "../../AuthProvider";

const { Content, Footer } = Layout;

export default function Structure({ children }) {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("menu-collapsed")
  );
  const {
    login: { data },
    role,
  } = useContext(AuthContext);

  return role ? (
    <Layout>
      <Sidebar
        authData={data}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        pages={role.access?.pages ?? []}
      />
      <Layout style={{ marginLeft: collapsed ? "80px" : "200px" }}>
        <ContentHeader />
        <ContentWrapper>{children}</ContentWrapper>
        <FooterWrapper>
          <small>mad.com.bd</small>
        </FooterWrapper>
      </Layout>
    </Layout>
  ) : null;
}

const ContentWrapper = Styled(Content)`
    min-height: calc(100vh - 64px - 40px);
    padding: 20px;
`;

const FooterWrapper = Styled(Footer)`
    height: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
