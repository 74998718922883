import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";
import { Chart } from "react-chartjs-2";

const getChartsData = ({ labels = [], data = [] }) => {
  return {
    labels,
    datasets: [
      {
        label: `Orders Per Hour (%)`,
        data,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        tension: 0.7,
        fill: true,
        // showLine: true,
      },
    ],
  };
};

export default function OrdersPerHour() {
  const { data } = useContext(SalesReportContext);

  return (
    <div id="orders-per-hour">
      <h2>Orders Per Hour</h2>
      <Chart
        type="bar"
        datasetIdKey="label"
        data={getChartsData({
          labels: data?.ordersPerHour?.map(
            (item) =>
              (item.hour % 12 === 0 ? "12" : item.hour % 12) +
              (item.hour >= 12 ? " PM" : " AM")
          ),
          data: data?.ordersPerHour?.map((item, i) =>
            Number(item.percentage.replace("%", ""))
          ),
        })}
      />
    </div>
  );
}
