import { BiLeftArrowAlt } from "react-icons/bi";
import styled from "styled-components";

export default function GoBack() {
  return (
    <Container onClick={() => window.history.back()}>
      <BiLeftArrowAlt />{" "}
    </Container>
  );
}

const Container = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 22px;
  color: #000;
  margin-right: 10px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #d3d3d3;
    color: black;
  }
`;
