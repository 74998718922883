export const getAvilColor = (text) => {
  switch (text) {
    case "available":
      return "green";
    case "unavailable":
      return "pink";
    case "stockout":
      return "red";
    default:
      return "gray";
  }
};

export const mapProducts = (data) => {
  if (!Array.isArray(data)) return [];

  return data.map(
    (
      {
        objectId: id,
        sorting_order,
        name,
        images,
        price,
        restaurant,
        inventory,
        category,
        availability,
        ref,
        ...rest
      },
      i
    ) => {
      return {
        key: i,
        id,
        sorting_order,
        image: images[0] ?? "",
        category: category?.name,
        categoryId: category?.objectId,
        name,
        restaurant: restaurant?.name,
        hub: restaurant?.hub?.name,
        restaurantId: restaurant?.objectId,
        price: price?.amount,
        vat: price?.vat ?? 0,
        discount: price?.discount?.amount,
        discountType: price?.discount?.type,
        discountValidity: price?.discount?.validity,
        availability,
        inventory,
        refs: ref,
        ...rest,
      };
    }
  );
};
