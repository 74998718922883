import { useContext } from "react";
import { SalesReportContext } from "../../../pages/Restaurant/SalesReport";

function ProgressText({ value }) {
  if (value === undefined) return <div></div>;

  return (
    <span className={`progress ${value < 0 ? "up" : "down"}`}>{value} %</span>
  );
}

function Item({
  idx,
  name,
  orderCount,
  quantity,
  total,
  discount,
  variants,
  orderProgress,
  quantityProgress,
  totalProgress,
  showProgress = true,
  ...rest
}) {
  return (
    <>
      <div className="item" {...rest}>
        <div className="idx">{idx}</div>
        <div className="name">{name}</div>
        <div className="order">
          {orderCount} {showProgress && <ProgressText value={orderProgress} />}
        </div>
        <div className="quantity">
          {quantity} {showProgress && <ProgressText value={quantityProgress} />}
        </div>
        <div className="total">
          {total} {totalProgress && <ProgressText value={totalProgress} />}
        </div>
        <div className="discount">{discount}</div>
        {variants?.length > 0 && (
          <div className="variants">
            {variants?.map(({ id, name, quantity }, i) => {
              return (
                <div key={id} className="variant">
                  <div className="idx">#</div>
                  <div className="name">{name}</div>
                  <div className="quantity">{quantity}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default function SoldItems() {
  const { data } = useContext(SalesReportContext);

  return (
    <div id="sold-items">
      <h1>Sold Items</h1>
      <div className="sold-items">
        <Item
          name="Product Name"
          orderCount="Order Count"
          quantity="Quantity"
          total="Total"
          discount="Discount"
          style={{
            background: "#bfbfbf",
          }}
          showProgress={false}
        />
        {data?.soldItems.map((item, i) => (
          <Item key={i} idx={i + 1} {...item} />
        ))}
      </div>
    </div>
  );
}
