import { Button } from 'antd'

export default function CustomButton({ children, ...rest }){
    return (
        <Button  
            type="dashed" 
            style={{margin: '0 10px', display: 'flex', alignItems: 'center'}} 
            {...rest} 
        >
            {children}
        </Button>
    )
}