import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { getHubs } from "../../api/hub";

const initialState = {
  hubs: {
    loading: false,
    data: {
      count: 0,
      results: [],
    },
  },
};

export const fetchHubs = createAsyncThunk("order/fetchHubs", async (params) => {
  const hubs = await getHubs(params);
  return hubs;
});

const hubSlices = createSlice({
  name: "hub",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHubs.pending, (state) => {
      state.hubs.loading = true;
    });
    builder.addCase(fetchHubs.fulfilled, (state, action) => {
      state.hubs.loading = false;
      action.payload.results = action.payload.results.map((hub) => {
        return { ...hub.toJSON(), refs: hub };
      });
      state.hubs.data = action.payload;
    });
    builder.addCase(fetchHubs.rejected, (state, action) => {
      state.hubs.loading = false;
      message.error(action.error.message);
    });
  },
});

export default hubSlices.reducer;
