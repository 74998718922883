import { useContext } from 'react';
import { Layout, Row, Col, Form, Input, Button } from 'antd'
import { AuthContext } from '../../AuthProvider'
import Styled from 'styled-components'

const { Content } = Layout

export default function Login(){
    const { login:{ loading }, loginHandler } = useContext(AuthContext)

    const onSubmit = ({ username, password }) => {
        loginHandler(username, password)
    }

    return (
        <Layout>
            <Wrapper>
                <div className="nav-bar">
                    <div className="brand-logo">
                        <img width="100%" src="/munchies.webp" />
                    </div>
                </div>
                <Row align="middle" justify="center" style={{minHeight: '100vh'}}>
                    <Col xs={22} sm={18} md={12} lg={10} xl={8}>
                        <section className="form-wrapper">
                            <h1 className="title">Login</h1>
                            <Form
                                onFinish={onSubmit}
                                layout="vertical"
                            >
                                <Form.Item 
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input placeholder="Username" size="large" />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password placeholder="Password" size="large" />
                                </Form.Item>
                                <Form.Item>
                                    <Button className="submit-btn" size="large" loading={loading} disabled={loading} htmlType="submit">
                                        Log in
                                    </Button>
                                </Form.Item>
                            </Form>
                        </section>
                    </Col>
                </Row>
            </Wrapper>
        </Layout>
    )
}

const Wrapper = Styled(Content)`
    width: 100%;
    min-height: 100vh;
    position: relative;
    background: black;

    .nav-bar{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        padding: 0px 24px;
        display: flex;
        align-items: center;

        .brand-logo{
            width: 100px;
            img{
                background: black;
                border-radius: 10px;
            }
        }
    }

    .form-wrapper {
        background: #080808;
        padding: 30px 20px;
        border-radius: 50px;
        border: 5px solid #00bcd4;
        box-shadow: 0px 0px 15px -4px #e1f5fe;

        .title {
            font-size: 40px;
            text-align: center;
            color: #e26ee1;
            letter-spacing: 2px;
            font-family: monospace;
            font-style: italic;
        }

        label{
            font-size: 18px;
            color: #e784e6;
            font-family: monospace;
        }

        input{
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1px;
        }

        .submit-btn{
            width: 100%;
            background: #00bcd4;
            border: none;
            border-radius: 2px;
            font-size: 18px;
            font-family: monospace;
            color: white;
            &:hover{
                background: #4adff2;
            }
        }
    }
`