import { useContext } from "react";
import { Button, Col, Space, Table } from "antd";
import { RidersActivity } from "../../../pages/Rider/Attendance";

export default function Attendance() {
  const { data, isLate, setActiveDate, dayWiseOrders } =
    useContext(RidersActivity);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "date",
      width: "130px",
      render: (time) =>
        new Date(time) != "Invalid Date"
          ? new Date(time).toLocaleString().split(",")[0]
          : "- - -",
    },
    {
      title: "Attendance",
      dataIndex: "createdAt",
      key: "time",
      width: "130px",
      render: (time) =>
        new Date(time) != "Invalid Date"
          ? new Date(time).toLocaleString().split(" ")[1]
          : "- - -",
    },
    {
      title: "Delivered",
      key: "time",
      dataIndex: "createdAt",
      width: "100px",
      render: (date) => dayWiseOrders(date).length,
    },
    {
      title: "Actions",
      key: "actions",
      render: (action, data) => {
        return (
          <Space>
            <Button
              onClick={() => {
                setActiveDate(data.createdAt);
              }}
            >
              Orders
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Col span={24} lg={10}>
      {data && (
        <Table
          columns={columns}
          dataSource={data.attendance}
          rowClassName={(record) => {
            return isLate(record.createdAt) ? "late" : "";
          }}
          pagination={{
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100", "500"],
            total: data.count,
            position: ["topRight"],
          }}
          scroll={{ y: "400px" }}
        />
      )}
    </Col>
  );
}
