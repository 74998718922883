import { createContext, useReducer, useEffect } from "react";
import { message } from "antd";
import useRestaurants from "../../hooks/useRestaurants";
import Parse from "parse";
import { useParams } from "react-router-dom";
import Categories from "../../components/restaurant/categories/index";

export const RestaurantCategories = createContext();

const Types = {
  SET_RESTAURANTS: "SET_RESTAURANTS",
  SET_CATEGORIES: "SET_CATEGORIES",
};

const initialState = {
  restaurant: null,
  categories: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.SET_RESTAURANTS:
      return { ...state, restaurant: action.payload };
    case Types.SET_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export default function RestaurantCategoriesProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getById } = useRestaurants();
  const { id } = useParams();

  // FETCH RESTAURANT
  const fetchRestaurant = async (id) => {
    getById(
      {
        id,
        select: ["name", "banner_image"],
        exclude: [
          "categories",
          "managedBy",
          "sub_stores",
          "updatedAt",
          "hub",
          "group",
          "meta_tags",
          "operating_hours",
        ],
      },
      (err, data) => {
        if (data) {
          dispatch({
            type: Types.SET_RESTAURANTS,
            payload: data.toJSON(),
          });
        } else if (err) {
          message.error(err);
        }
      }
    );
  };

  // FETCH CATEGORIES
  const fetchCategories = async (id) => {
    try {
      message.loading("Loading categories...", 0);
      const categories = await Parse.Cloud.run("getRestaurantCategories", {
        id,
      });
      dispatch({
        type: Types.SET_CATEGORIES,
        payload: categories,
      });
      message.destroy(0);
    } catch (err) {
      message.error(err.message);
      message.destroy(0);
    }
  };

  const sortingUpdate = async (params) => {
    try {
      await Parse.Cloud.run("restaurantCategoriesSortingUpdate", params);
    } catch (err) {
      message.error(err.message);
    }
  };

  const updateCategory = async (params, done = () => {}) => {
    try {
      await Parse.Cloud.run("updateRestaurantCategory", params);
      done();
    } catch (err) {
      message.error(err.message);
      done();
    }
  };

  useEffect(() => {
    fetchRestaurant(id);
    fetchCategories(id);
  }, []);

  const updateCategories = (categories) => {
    dispatch({
      type: Types.SET_CATEGORIES,
      payload: categories,
    });
  };

  return (
    <RestaurantCategories.Provider
      value={{ ...state, updateCategories, updateCategory, sortingUpdate, id }}
    >
      <Categories />
    </RestaurantCategories.Provider>
  );
}
