import ProfileMenu from "./ProfileMenu";
import { Layout, Space } from "antd";
import GoBack from "../Common/GoBack";

const { Header } = Layout;

export default function ContentHeader(props) {
  return (
    <Header
      style={{
        position: "sticky",
        top: 0,
        zIndex: "100",
        display: "flex",
        alignItems: "center",
        height: "64px",
        background: "#fff",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Space>
        <GoBack />
      </Space>
      <ProfileMenu />
    </Header>
  );
}
