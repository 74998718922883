import HubsProvider from "./HubsProvider";
import { Switch, Route } from "react-router-dom";
import HubDashboard from "./Dashboard";
import HubAreas from "./HubAreas";
import CreateNewHub from "./CreateNew";

export default function Hub() {
  return (
    <HubsProvider>
      <Switch>
        <Route path="/hub/list" exact component={HubDashboard} />
        <Route path="/hub/areas" exact component={HubAreas} />
        <Route path="/hub/create-new" exact component={CreateNewHub} />
      </Switch>
    </HubsProvider>
  );
}
