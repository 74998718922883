import { useReducer, useEffect, createContext } from "react";
import useInvoice from "../../hooks/useInvoice";
import useRestaurants from "../../hooks/useRestaurants";
import { parser } from "../../utils";
import InvoiceTable from "../../components/invoice/InvoiceTable";
import GoBack from "../../components/Common/GoBack";
import RestaurantLinks from "../../components/Common/RestaurantLinks";
import { message, Space } from "antd";

export const InvoiceContext = createContext();

const Types = {
  GET_INVOICES_REQUEST: "GET_INVOICES_REQUEST",
  GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
  GET_INVOICES_FAILURE: "GET_INVOICES_FAILURE",
  GET_RESTAURANT_REQUEST: "GET_RESTAURANT_REQUEST",
  GET_RESTAURANT_SUCCESS: "GET_RESTAURANT_SUCCESS",
  GET_RESTAURANT_FAILURE: "GET_RESTAURANT_FAILURE",
};

const initialState = {
  invoices: { loading: false, data: { count: 0, results: [] } },
  restaurant: { loading: false, data: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.GET_INVOICES_REQUEST:
      state.invoices.loading = true;
      return { ...state };
    case Types.GET_INVOICES_SUCCESS:
      state.invoices.loading = false;
      state.invoices.data = action.payload;
      return { ...state };
    case Types.GET_INVOICES_FAILURE:
      state.invoices.loading = false;
      state.invoices.data = initialState.invoices.data;
      return { ...state };
    case Types.GET_RESTAURANT_REQUEST:
      state.restaurant.loading = true;
      return { ...state };
    case Types.GET_RESTAURANT_SUCCESS:
      state.restaurant.loading = false;
      state.restaurant.data = action.payload;
      return { ...state };
    case Types.GET_RESTAURANT_FAILURE:
      state.restaurant.loading = false;
      state.restaurant.data = initialState.restaurant.data;
      return { ...state };
    default:
      return state;
  }
};

function Header({ restaurant }) {
  return (
    <div
      style={{
        height: "50px",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <GoBack />
      <h3 style={{ marginLeft: "10px", marginBottom: 0 }}>
        <Space>
          <span>Invoice's </span>
          {restaurant && (
            <Space>
              of{" "}
              <RestaurantLinks id={restaurant.id}>
                <span style={{ fontSize: "22px", color: "#1387e3" }}>
                  {restaurant.name}
                </span>
              </RestaurantLinks>
            </Space>
          )}
        </Space>
      </h3>
    </div>
  );
}

export default function InvoiceHistory() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getInvoices } = useInvoice();
  const { getById: getRestaurantById } = useRestaurants();
  const url = new URL(window.location);
  const restaurantId = url.searchParams.get("restaurant");

  const fetchInvoices = (params) => {
    message.loading({ content: "Loading invoices...", key: "invoice" });
    getInvoices(params, (err, res) => {
      if (err) {
        dispatch({ type: Types.GET_INVOICES_FAILURE });
        message.error(err);
      } else {
        dispatch({
          type: Types.GET_INVOICES_SUCCESS,
          payload: {
            count: res.count,
            results: res.results.map((invoice) => invoice.toJSON()),
          },
        });
      }
      message.destroy("invoice");
    });
  };

  const fetchRestaurant = (id) => {
    getRestaurantById({ id, select: ["name", "banner_image"] }, (err, res) => {
      if (err) {
        dispatch({ type: Types.GET_RESTAURANT_FAILURE });
        message.error(err);
      } else {
        dispatch({ type: Types.GET_RESTAURANT_SUCCESS, payload: parser(res) });
      }
    });
  };

  useEffect(() => {
    const params = {
      limit: 100,
      select: ["payment_period", "payable", "restaurant.name"],
    };
    if (restaurantId) {
      params["restaurant"] = restaurantId;
      fetchRestaurant(restaurantId);
    }

    fetchInvoices(params);

    return () => {
      dispatch({ type: Types.GET_RESTAURANT_SUCCESS, payload: null });
      dispatch({
        type: Types.GET_INVOICES_SUCCESS,
        payload: initialState.invoices.data,
      });
    };
  }, [restaurantId]);

  return (
    <InvoiceContext.Provider
      value={{
        ...state,
        fetchInvoices,
        restaurantId,
        fetchRestaurant,
      }}
    >
      <Header restaurant={state.restaurant.data} />
      <InvoiceTable />
    </InvoiceContext.Provider>
  );
}
