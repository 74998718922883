import { memo, useEffect, useCallback, createContext, useReducer } from 'react'
import Parse from 'parse'
import { message } from 'antd'
import usePurchaseRecord from '../../hooks/usePurchaseRecord'
import { parser } from '../../utils'

const Types = {
    INVENTORIES_REQUEST: 'INVENTORIES_REQUEST',
    INVENTORIES_SUCCESS: 'INVENTORIES_SUCCESS',
    INVENTORIES_FAILURE: 'INVENTORIES_FAILURE',
    NEW_IMPORT_REQUEST: 'NEW_IMPORT_REQUEST',
    NEW_IMPORT_SUCCESS: 'NEW_IMPORT_SUCCESS',
    NEW_IMPORT_FAILURE: 'NEW_IMPORT_FAILURE',
}

const initialState = {
    records: { loading: false, data: { count: 0, results: []}, error: null },
    newRecord: { loading: false, data: null, error: null },
}

const reducer = (state, action) => {
    switch(action.type){
        case Types.INVENTORIES_REQUEST:
            state.records.loading = true
            return { ...state }
        case Types.INVENTORIES_SUCCESS:
            state.records.loading = false
            state.records.data = action.payload
            return { ...state }
        case Types.INVENTORIES_FAILURE: 
            state.records.loading = false
            state.records.error = action.payload
            return { ...state }
        case Types.NEW_IMPORT_REQUEST: 
            state.newRecord.loading = true
            return {...state }
        case Types.NEW_IMPORT_SUCCESS: 
            state.newRecord.loading = false
            state.newRecord.data = action.payload
            return { ...state }
        case Types.NEW_IMPORT_FAILURE: 
            state.newRecord.loading = false
            state.newRecord.error = action.payload
            return { ...state }
        default:
            return state
    }
}

export const InventoryContext = createContext()

function InventoryContextProvider({ children }) {
    const [state,dispatch] = useReducer(reducer, initialState)
    const { addNewPurchaseRecord, getPurchaseRecords, deletePurchaseRecord, updatePurchaseRecord } = usePurchaseRecord()
    const defaultFunc = () => {}

    const addNewPurchaseRecordFunc = async ({ clearForm, ...data }) => {
        dispatch({ type: Types.NEW_IMPORT_REQUEST })
        addNewPurchaseRecord(data, (err, res) => {
            if(res){
                dispatch({ type: Types.NEW_IMPORT_SUCCESS, payload: parser(res) })
                clearForm()
                message.success('Purchase record created!')
            }

            if(err){
                message.error(err)
                dispatch({ type: Types.NEW_IMPORT_FAILURE, payload: err })
            }
        })
    }

    const getPurchaseRecordsFunc = async (payloads={}) => {
        dispatch({ type: Types.INVENTORIES_REQUEST })
        getPurchaseRecords(payloads, (err, res) => {
            if(res){
                dispatch({ type: Types.INVENTORIES_SUCCESS, payload: {
                    count: res.count,
                    results: parser(res.results)
                } })
                return 
            }

            dispatch({ type: Types.INVENTORIES_FAILURE, payload: err })
            message.error(err)
        })
    }

    const deletePurchaseRecordHandler = async (id, callback=defaultFunc) => {
        if(!id) {
            callback()
            return message.error('Invalid purchase record id')
        }

        deletePurchaseRecord(id, (err, res) => {
            if(err){
                message.error(err)
                return
            }

            const index = state.records.data.results.findIndex(item => item.id === res.id)
            if(index !== -1){
                state.records.data.results.splice(index, 1)
                state.records.data.count -= 1
                dispatch({ type: Types.INVENTORIES_SUCCESS, payload: state.records.data })
            }

            message.success('Purchase record deleted!')
            callback()
        })
    }

    const updatePurchaseRecordHandler = async (params, callback=defaultFunc) => {
        updatePurchaseRecord(params, (err, res) => {
            if(err){
                callback()
                return message.error(err)
            }

            if(res){
                const index = state.records.data.results.findIndex(item => item.id === res.id)
                if(index !== -1){
                    state.records.data.results[index] = parser(res)
                    dispatch({ type: Types.INVENTORIES_SUCCESS, payload: state.records.data })
                }

                message.success('Purchase record updated!')
            }
        })
    }

    return (
        <InventoryContext.Provider value={{
            ...state, 
            dispatch,
            Types,
            getPurchaseRecords: getPurchaseRecordsFunc, 
            addNewPurchaseRecord: addNewPurchaseRecordFunc, 
            deletePurchaseRecord: deletePurchaseRecordHandler,
            updatePurchaseRecordHandler
        }}>
            { children }
        </InventoryContext.Provider>
    )
    
}

export default memo(InventoryContextProvider)