import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { getById, getRestaurants } from "../../api/restaurant";

const initialState = {
  restaurant: {
    loading: false,
    data: null,
    group: {
      loading: false,
      data: [],
    },
  },
};

export const getRestaurant = createAsyncThunk(
  "restaurantDetails/getRestaurant",
  async (id) => {
    return await getById({
      id,
      select: [
        "name",
        "phone",
        "cuisines",
        "managedBy.name",
        "hub.name",
        "type",
        "commission",
        "commission_type",
        "availability",
        "vat",
        "operating_hours",
        "address",
        "meta_tags",
        "banner_image",
        "group",
      ],
    });
  }
);

export const getGroupRestaurants = createAsyncThunk(
  "restaurantDetails/getGroupRestaurants",
  async (params) => {
    return await getRestaurants(params);
  }
);

const restaurantDetailsSlice = createSlice({
  name: "restaurantDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [getRestaurant.pending]: (state, action) => {
      state.restaurant.loading = true;
    },
    [getRestaurant.fulfilled]: (state, action) => {
      state.restaurant.loading = false;
      if (!action.payload) {
        message.error("Restaurant not found");
        return state;
      }
      state.restaurant.data = action.payload.toJSON();
    },
    [getRestaurant.rejected]: (state, action) => {
      state.restaurant.loading = false;
      message.error(action.error.message);
    },
    [getGroupRestaurants.pending]: (state) => {
      state.restaurant.group.loading = true;
    },
    [getGroupRestaurants.fulfilled]: (state, action) => {
      state.restaurant.group.loading = false;
      if (!action.payload) {
        message.error("Restaurant not found");
        return state;
      }
      state.restaurant.group.data = action.payload.results.map((r) =>
        r.toJSON()
      );
    },
    [getGroupRestaurants.rejected]: (state, action) => {
      state.restaurant.group.loading = false;
      message.error(action.error.message);
    },
  },
});

export default restaurantDetailsSlice.reducer;
