import React, { useState, createRef } from "react";
import { Space, Select, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const useSelect = ({
  items,
  onSearch = () => {},
  getValue = (v) => v?.id || "",
  getName = (v) => v?.name || "n/a",
} = {}) => {
  const [state, setState] = useState({
    values: [],
    searchedColumn: "",
  });
  const searchInput = createRef();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      values: selectedKeys,
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters, callback) => {
    clearFilters();
    setState({ values: [] });
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const getColumnSearchProps = (dataIndex, { onResetTrigger } = {}) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <Select
            ref={searchInput}
            style={{ width: "300px" }}
            mode="multiple"
            showSearch
            placeholder="Select"
            value={selectedKeys}
            onChange={(v) => {
              setSelectedKeys(v);
            }}
            onSearch={(v) => {
              onSearch(v);
            }}
            filterOption={false}
          >
            {items?.map((res) => (
              <Select.Option value={getValue(res)}>
                {getName(res)}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([]);
              confirm({ closeDropdown: true });
              handleReset(clearFilters, onResetTrigger);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return true;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput?.current?.select();
        }, 100);
      }
    },
  });

  return [state, getColumnSearchProps];
};

export default useSelect;
