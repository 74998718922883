import { Form, InputNumber } from "antd";
import Layout from "./Layout";

const { Item } = Form;

export default function Basic({ employeeType }) {
  return (
    <>
      <Layout title="Basic (Bike) ">
        <Item
          label="Weekday (hourly)"
          name={[
            "earning",
            "commission",
            employeeType,
            "basic",
            "bike",
            "weekday",
          ]}
        >
          <InputNumber />
        </Item>
        <Item
          label="Weekend (hourly)"
          name={[
            "earning",
            "commission",
            employeeType,
            "basic",
            "bike",
            "weekend",
          ]}
        >
          <InputNumber />
        </Item>
      </Layout>
      <Layout title="Basic ( Cycle )">
        <Item
          label="Weekday (hourly)"
          name={[
            "earning",
            "commission",
            employeeType,
            "basic",
            "cycle",
            "weekday",
          ]}
        >
          <InputNumber />
        </Item>
        <Item
          label="Weekend (hourly)"
          name={[
            "earning",
            "commission",
            employeeType,
            "basic",
            "cycle",
            "weekend",
          ]}
        >
          <InputNumber />
        </Item>
      </Layout>
    </>
  );
}
