import { Divider } from "antd"

export default function OrderItemAddons({ addons }){
    return (
        addons?.length > 0 ? <div className="addons">
            <Divider orientation="left" style={{marginBottom: '0px'}}>Addons</Divider>
            <div className="addons-items">
                {addons.map(({name, price}={}, index) => (
                    <div key={index} className="item">
                        <div>{name}</div>
                        <div>{price} tk</div>
                    </div>
                ))}
            </div>
        </div>: null
    )
}