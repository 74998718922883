import React from 'react'
import { Skeleton, Space, Row, Col } from 'antd'

const Item = () => (
    <Col md={12} lg={8} style={{minHeight: '300px'}}>
        <Skeleton.Input active style={{width: '300px', height: '300px', borderRadius: '10px'}} />
    </Col>
)

export default function SectionsSkeleton() {
    return (
        <div>
            <Row style={{padding: '30px'}} gutter={[16, 24]}>
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
            </Row>
        </div>
    )
}
