import styled from "styled-components";
import { message, Popover, Select, Space, Button } from "antd";
import { useEffect, useState } from "react";
import { hours } from "./Scheduling";
import DateHandler from "../../components/dashboard/DateHandler";
import { addDays } from "date-fns";
import moment from "moment";
import Parse from "parse";
import { parser } from "../../utils";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function Hour({ hour, slots }) {
  return (
    <div className="hour">
      <div className="hour-label">
        {(hour % 12 === 0 ? "12" : hour % 12) + (hour >= 12 ? " PM" : " AM")}
      </div>
      <div className="hour-slots">
        {Object.entries(slots).map(([slot, isBooked], i) => {
          return (
            <div key={i} className="slot">
              <div className="slot-label">
                {isBooked ? (
                  <Popover content={isBooked.name}>
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "100%",
                      }}
                      src={isBooked.banner_image}
                      alt=""
                    />
                  </Popover>
                ) : (
                  parseInt(slot) + 1
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function DateItem({ date, hours, day }) {
  return (
    <div className={`date `}>
      <div className="date-label">
        <div>{date}</div>
        <div>{day}</div>
      </div>
      <div className="date-hours">
        {Object.entries(hours).map(([hour, slots], i) => (
          <Hour key={hour} hour={hour} slots={slots} />
        ))}
      </div>
    </div>
  );
}

const sortDays = (obj, hours) => {
  const dayList = [6, 0, 1, 2, 3, 4, 5];

  return dayList.reduce((acc, d) => {
    const dates = obj[d];

    if (hours) {
      dates?.forEach((date) => {
        hours.forEach((hour) => {
          delete date.hours[hour];
        });
      });
    }

    if (dates) {
      acc.push({ day: d, title: days[d], dates });
    }

    return acc;
  }, []);
};

export default function SlotsMap() {
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem("muted-hours-list")) || []
  );
  const [date, setDate] = useState([
    moment(new Date(), "yyyy-MM-DD"),
    moment(addDays(new Date(), 30), "yyyy-MM-DD"),
  ]);
  const [slots, setSlots] = useState(null);
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSlots = async (params) => {
    try {
      setLoading(true);
      const res = await Parse.Cloud.run("getScheduledSlots", params);
      setSlots(res);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };

  const fetchSections = async () => {
    try {
      const res = await new Parse.Query("section").select(["name"]).find();
      setSections(parser(res));
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    const mutedHours = localStorage.getItem("muted-hours-list");
    if (mutedHours) {
      setFilter(JSON.parse(mutedHours));
    }
    fetchSections();
  }, []);

  return (
    <Wrapper>
      <div>
        <Space>
          <DateHandler setDate={setDate} defaultValue={date} />
          <Select
            style={{ width: "200px" }}
            placeholder="Select section"
            value={section}
            size="large"
            onChange={(value) => setSection(value)}
          >
            {sections.map((section) => (
              <Select.Option key={section.id} value={section.id}>
                {section.name}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            size="large"
            loading={loading}
            onClick={() => {
              fetchSlots({
                start_date: new Date(date[0]).toISOString(),
                end_date: new Date(date[1]).toISOString(),
                section,
              });
            }}
          >
            Fetch
          </Button>
        </Space>
        <div>
          <Space>
            <strong>Remove Hour</strong>
            <Select
              style={{ minWidth: "200px" }}
              mode="multiple"
              onChange={(value) => {
                setFilter(value);
                localStorage.setItem("muted-hours-list", JSON.stringify(value));
              }}
              placeholder="Mute hours"
              value={filter}
              clearIcon
              allowClear
            >
              {hours.map((h) => {
                return (
                  <Select.Option key={h.value} value={h.value}>
                    {h.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Space>
        </div>
      </div>
      {slots && (
        <div className="days">
          {sortDays(slots, filter).map(({ title, dates, day }, i) => {
            return (
              <div key={day} className="day">
                <h3>{title}</h3>

                {dates.map((item, i) => (
                  <DateItem key={item.date} {...item} day={title} />
                ))}
              </div>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .days {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .day {
    min-width: 782px;
    padding: 10px;

    .date {
      background: #fff;
      padding: 10px 16px;
      border-radius: 10px;
      margin-bottom: 10px;

      &-label {
        font-weight: 500;
        display: flex;
        justify-content: space-between;
      }

      &-hours {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;

        .hour {
          padding: 5px 10px;
          background: #ff7875a8;
          width: 240px;
          border-radius: 10px;

          &-label {
            font-weight: 500;
            width: 200px;
            margin-bottom: 5px;
          }

          &-slots {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 5px;
            font-size: 16px;

            .slot {
              padding: 3px 10px;
              background: #fff;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;
