import Parse from 'parse'


export default function useCarousel(){
    const repoName = 'carousel'
    const defaultFunc = (err, data) => {}

    const createNew = async ({ name }={}, callback=defaultFunc) => {
        if(!name) {
            return callback('Name is required')
        }
        const Carousel = Parse.Object.extend(repoName)
        const carousel = new Carousel()
        carousel.set('name', name)
        try {
            // check existance
            const exists = await new Parse.Query(repoName).equalTo('name', name).first()
            if(exists){
                return callback(`${name} already exists`, null)
            }

            const res = await carousel.save()
            if(res){
                callback(null, carousel)
            }
        } catch(err) {
            callback(err.message)
        }
    }

    const getCarousels = async ({ limit=100, skip=0, name, search }={}, callback=defaultFunc) => {
        try {
            const query = new Parse.Query(repoName)
            if(name) query.equalTo('name', name)
            if(search) query.contains('name', search, 'i')
            if(limit !== undefined || limit !== null) query.limit(limit)
            if(skip !== undefined || skip !== null) query.skip(skip)
            query.withCount()
            query.descending('createdAt')
            const data = await query.find()
            callback(null, data)
        } catch(err){
            callback(err.message, null)
        }
    }

    const getById = async (id, callback=defaultFunc) => {
        if(!id) {
            return callback('Id is required')
        }
        try {
            const data = await new Parse.Query(repoName).equalTo('objectId', id).first()
            if(data){
                callback(null, data)
            }else{
                callback('Not found', null)
            }
        } catch(err){
            callback(err.message, null)
        }
    }

    
    const updateCarousel = ({ id, params }={}, callback=defaultFunc) => {
        getById(id, async (err, carousel) => {
            if(err){
                return callback(err)
            }

            if(carousel){
                try{
                    if(params?.name) carousel.set('name', params.name)
                    if(params?.images) carousel.set('images', params.images)
                    const res = await carousel.save()
                    if(res){
                        callback(null, carousel)
                    }
                }catch(err){
                    callback(err.message)
                }
            }
        })
    }

    const deleteCarousel = (id, callback=defaultFunc) => {
        getById(id, async (err, carousel) => {
            if(err){
                return callback(err)
            }

            if(carousel){
                try{
                    const res = await carousel.destroy()
                    if(res){
                        callback(null, res)
                    }
                }catch(err){
                    callback(err.message)
                }
            }
        })
    }

    return {
        getCarousels,
        getById,
        createNew,
        updateCarousel,
        deleteCarousel
    }
}