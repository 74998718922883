import { Form, Input, Select } from "antd";
import { Box } from "../../../Common/Boxer";

const { Item } = Form;

export default function MetaInfo() {
  return (
    <Box title="Meta Data">
      <Item label="Meta Title" name="meta_title" style={{ marginBottom: "0" }}>
        <Input size="large" type="text" placeholder="Meta title" />
      </Item>
      <Item label="Meta Tags" name="meta_tags" style={{ marginBottom: "0" }}>
        <Select
          size="large"
          placeholder="tag one, tag two..."
          mode="tags"
          style={{ width: "100%" }}
          tokenSeparators={[","]}
        ></Select>
      </Item>
      <Item label="Meta Description" name="meta_description">
        <Input.TextArea size="large" placeholder="Description" />
      </Item>
    </Box>
  );
}
