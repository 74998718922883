import React, { useEffect, useContext, useCallback, memo } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Section from "./Section";
import { SectionsContext, Types } from "./SectionsContext";
import CreateNewSection from "./CreateNewSection";
import Skeleton from "./Skeleton";
import "./styles.scss";
import Boxer from "../Common/Boxer";
import SectionsSorting from "./SectionsSorting";

function Sections() {
  const {
    getSections,
    sections,
    dispatch,
    updateSection,
    parseSectionForDB,
    dragDrop,
    deleteSectionItem,
  } = useContext(SectionsContext);
  const { data, loading } = sections;

  const onDragEnd = useCallback(
    ({ source, destination }) => {
      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        source.index === destination.index
      )
        return;

      const newData = [...data];

      // find source section
      const sourceSectionIndex = newData.findIndex(
        (item) => item.id === source.droppableId
      );
      const sourceSection = newData[sourceSectionIndex];
      const sourceItem = sourceSection.items[source.index];
      sourceSection.items.splice(source.index, 1);

      let desSection;
      if (source.droppableId !== destination.droppableId) {
        const idx = newData.findIndex(
          (item) => item.id === destination.droppableId
        );
        desSection = newData[idx];
        const exists = desSection.items.find(
          (item) => item.id === sourceItem.id
        );
        if (!exists) {
          desSection.items.splice(destination.index, 0, sourceItem);
        }
      } else {
        sourceSection.items.splice(destination.index, 0, sourceItem);
      }

      updateSection(parseSectionForDB(sourceSection));

      if (desSection) {
        updateSection(parseSectionForDB(desSection));
      }

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: newData });
    },
    [data]
  );

  useEffect(() => {
    getSections();
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CreateNewSection />
      {!loading && data ? (
        <Boxer style={{ marginTop: "20px" }}>
          <SectionsSorting />
          {data.map((section, i) => {
            return (
              <Section
                key={i}
                {...section}
                dragDrop={dragDrop}
                index={i}
                deleteItem={deleteSectionItem}
              />
            );
          })}
        </Boxer>
      ) : (
        <Skeleton />
      )}
    </DragDropContext>
  );
}

export default memo(Sections);
