import { Col } from "antd";
import { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantCategories } from "../../../pages/Restaurant/Categories";
import Styled from "styled-components";
import Products from "./Products";
import Boxer, { Box } from "../../Common/Boxer";

function CategoryList() {
  const { categories, updateCategories, sortingUpdate, id } =
    useContext(RestaurantCategories);

  const onDragend = ({ source, destination }) => {
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const newCategories = Array.from(categories);
    const [removed] = newCategories.splice(source.index, 1);
    newCategories.splice(destination.index, 0, removed);
    updateCategories(newCategories);

    sortingUpdate({
      restaurantId: id,
      ids: newCategories.map((cat) => cat.objectId),
    });
  };

  return (
    <DragDropContext onDragEnd={onDragend}>
      <Droppable droppableId="droppable">
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="droppable list"
            >
              <div className="group-items">
                {categories?.map((item, i) => (
                  <Draggable draggableId={`draggable-${i}`} index={i}>
                    {(provided) => {
                      return (
                        <div
                          key={i}
                          className="item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img
                            src={item.category.get("image")}
                            alt="restaurant"
                          />
                          <div>
                            <h3 className="title">
                              {item.category.get("name")}
                            </h3>
                          </div>
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}

export default function Categories() {
  const { id } = useContext(RestaurantCategories);
  const [showList, setShowList] = useState(
    localStorage.getItem(`res-cat-list-${id}`)
  );

  return (
    <Wrapper>
      <Boxer>
        <Box title="Category List" maxHeight="400px">
          <CategoryList />
        </Box>
        <Products showList={showList} />
      </Boxer>
    </Wrapper>
  );
}

const Wrapper = Styled.div`

  .group-items {
      margin-top: 10px;
      padding-right: 5px;

      .item {
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        background: #546e7a;
        border-radius: 5px;
        position: relative;
        animation: fadeIn 0.5s ease-in-out;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        &:hover {
            opacity: .9;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
        }

        .avail {
          position: absolute;
          top: 10px;
          right: 10px;
          background: #fff;
          width: 20px;
          height: 10px;
          border-radius: 10px;
        }

        .available{
          background: #afffaf;
        }
        .unavailable{
          background: #ff7373;
        }
        .stockout{
          background: #f7c766;
        }

        .title,
        .subtitle {
          color: #fff;
          margin-bottom: 0px;
          letter-spacing: 1px;
        }

        button {
          position: absolute;
          top: 0;
          right: 0;
          margin: 16px;
          border: none;
          transition: all 0.3s ease;

          &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .list {
      .group-items {
        background: inherit;

        .item {
          background: #bfbfbf61;

          .title {
            color: black;
          }
        }
      }
    }

`;
