import { useContext, useState } from "react"
import { ProductListContext } from "../../../pages/Product/ProductList"
import styled from "styled-components"
import { Button, Popconfirm } from 'antd'

const Wrapper = styled.div`
    
`

export default function DeleteProducts({ selectedProducts, setSelectedProducts, clearSelectedRows, reqPayloads }) {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const { deleteProducts, getProducts  } = useContext(ProductListContext)

    const handleOk = async () => {
        setLoading(true)
        deleteProducts(selectedProducts.map(i => i.id), (done) => {
            setLoading(false)
            if(done){
                setVisible(false)
                clearSelectedRows([])
                setSelectedProducts([])
                getProducts(reqPayloads)
            }
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    return (
        selectedProducts.length > 0 ? 
            <Wrapper>
                <Popconfirm
                    title="Are you sure you want to delete selected products?"
                    visible={visible}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: loading }}
                    onCancel={handleCancel}
                    placement="top"
                >
                    <Button onClick={e => setVisible(true)} danger>Delete Items</Button>
                </Popconfirm>
            </Wrapper>
        : null
    )
}