import PricingType from "./PricingType";
import PriceAmount from "./PriceAmount";
import { Box } from "../../../Common/Boxer";

export default function Pricing({ formData, form }) {
  return (
    <Box title="Pricing">
      <PricingType form={form} />
      <PriceAmount formData={formData} form={form} />
    </Box>
  );
}
