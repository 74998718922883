import { useState, useEffect } from "react";
import styled from "styled-components";
import { message, Table } from "antd";
import Parse from "parse";
import useSearch from "../../components/inventory/utils/useSearch";

export default function LogsList() {
  const [logs, setLogs] = useState({
    count: 0,
    results: [],
  });
  const [, getSearchProps] = useSearch();

  const columns = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      render: (time) => new Date(time).toLocaleString(),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      width: "160px",
      render: (user) => user?.name,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      ...getSearchProps("type"),
    },
    {
      title: "Message",
      dataIndex: "msg",
      key: "message",
    },
  ];

  const fetchLogs = async ({ limit = 100, type }) => {
    try {
      message.loading("Loading logs...", 0);
      const query = new Parse.Query("logs");
      query.limit(limit);
      if (type) {
        query.matches("type", type, "i");
      }
      query.select(["target", "type", "user.name", "msg"]);
      query.withCount();
      const data = await query.find();
      if (data) {
        data.results = data.results.map((log) => log.toJSON());
        setLogs(data);
        message.destroy(0);
      }
    } catch (err) {
      console.log(err);
      message.destroy(0);
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchLogs({ limit: 100 });
  }, []);

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={logs.results}
        pagination={{
          total: logs.count,
          defaultPageSize: 100,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "200", "300", "500"],
        }}
        onChange={(pagination, filters) => {
          fetchLogs({
            limit: pagination.pageSize,
            type: filters.type?.length > 0 ? filters.type[0] : undefined,
          });
        }}
        scroll={{ x: "1200px" }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
