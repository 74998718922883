import { Space, Switch, Tag } from "antd";
import { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RestaurantCategories } from "../../../pages/Restaurant/Categories";
import Boxer, { Box } from "../../Common/Boxer";

function ProductItem({ name, availability, images = [], idx, category }) {
  return (
    <Draggable draggableId={`${category}-${idx}`} index={idx}>
      {(provided) => {
        return (
          <div
            key={idx}
            className="item"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <img src={images[0]} alt="restaurant" />
            <div>
              <h3 className="title">{name}</h3>
            </div>
            <div className={`avail ${availability}`}></div>
            {provided.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
}

function CategoryAvailability({ id, availability }) {
  const { updateCategory } = useContext(RestaurantCategories);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(availability);

  return (
    <Switch
      size="small"
      checked={value}
      loading={loading}
      onClick={(value) => {
        setLoading(true);
        setValue(value);
        updateCategory({ id, availability: value }, () => {
          setLoading(false);
        });
      }}
    />
  );
}

function CategoryItem({ objectId, category, products, availability }) {
  return (
    <Box
      title={
        <Space>
          {category?.get("name")}{" "}
          <Tag color="green">{products?.length ?? 0}</Tag>{" "}
        </Space>
      }
      rightMenu={
        <CategoryAvailability id={objectId} availability={availability} />
      }
      maxHeight="400px"
      meta={`${category?.get("name")}-r-cat`}
    >
      <Droppable droppableId={objectId}>
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="droppable"
            >
              <div className="group-items customScroll">
                {products?.map((product, i) => (
                  <ProductItem
                    key={i}
                    category={objectId}
                    idx={i}
                    {...product}
                  />
                ))}
              </div>
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </Box>
  );
}

export default function Products() {
  const { categories, updateCategories, updateCategory } =
    useContext(RestaurantCategories);

  const onDragEnd = ({ source, destination }) => {
    const droppableId = source.droppableId;
    if (droppableId !== destination?.droppableId) return;
    else if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const category = categories.find((cat) => cat.objectId === droppableId);
    if (category) {
      const products = Array.from(category.products);
      const item = products[source.index];
      products.splice(source.index, 1);
      products.splice(destination.index, 0, item);
      category.products = products;
      updateCategories(categories);
      updateCategory({
        id: category.objectId,
        ids: products.map((p) => p.objectId),
      });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Boxer>
        {categories.map((item, i) => (
          <CategoryItem key={item.objectId} {...item} />
        ))}
      </Boxer>
    </DragDropContext>
  );
}
