import { useState, useEffect } from "react";
import { Row, Col, message, Input, Button, Modal, Form, Space } from "antd";
import useConfig from "../../hooks/useConfig";
import { parser } from "../../utils";
import styled from "styled-components";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";

function CreateNew({ configs, setConfigs }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { createNew } = useConfig();
  const [loading, setLoading] = useState(false);

  const onFinish = ({ key, value }) => {
    setLoading(true);
    createNew(
      {
        key,
        value: JSON.parse(value),
      },
      (err, res) => {
        setLoading(false);
        if (err) {
          message.error(err);
        } else {
          configs.data.unshift(parser(res));
          configs.ref.unshift(res);
          setConfigs({ ...configs });
          setVisible(false);
          form.resetFields();
        }
      }
    );
    setVisible(false);
  };

  return (
    <Col span={24} lg={16}>
      <div className="create-new">
        <Button
          onClick={() => setVisible(true)}
          className="create-btn"
          type="primary"
        >
          <PlusOutlined />
          <span>Create new</span>
        </Button>
        <Modal
          title="Create new"
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <Form onFinish={onFinish} form={form} layout="vertical">
            <Form.Item
              label="Name"
              name="key"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
            <Form.Item
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  validator: (rule, value) => {
                    try {
                      const v = JSON.parse(value);
                      return Promise.resolve(true);
                    } catch (err) {
                      return Promise.reject("Invalid JSON");
                    }
                  },
                },
              ]}
            >
              <Input.TextArea rows={5} placeholder="Value" />
            </Form.Item>
            <Form.Item style={{ textAlign: "right" }}>
              <Button onClick={() => setVisible(false)} size="large" danger>
                Cancel
              </Button>
              <Button
                loading={loading}
                size="large"
                style={{ margin: "0 10px" }}
                type="primary"
                htmlType="submit"
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Col>
  );
}

function ConfigsItem({ config, configs, setConfigs }) {
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const { updateItem } = useConfig();
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ value }) => {
    const ref = configs.ref.find((i) => i.id === config.id);
    if (ref) {
      setLoading(true);
      updateItem(ref, { value: JSON.parse(value) }, (err, res) => {
        setLoading(false);
        if (err) {
          message.error(err);
        } else {
          const index = configs.data.findIndex((item) => item.id === config.id);
          configs.data.splice(index, 1, parser(res));
          setConfigs({ ...configs });
          form.setFieldsValue({
            value: JSON.stringify(parser(res)?.value, undefined, 4),
          });
          setEdit(false);
        }
      });
    }
  };

  return (
    <Col span={24} lg={16}>
      <Form
        form={form}
        initialValues={{
          value: JSON.stringify(config.value, undefined, 4),
        }}
        onFinish={onSubmit}
      >
        <div className="config-wrapper">
          <div className="header">
            <h2 className="title">{config.key}</h2>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  value: JSON.stringify(config.value),
                });
                setEdit(true);
              }}
              className="edit-btn"
            >
              <EditOutlined />
              <span>Edit</span>
            </Button>
          </div>
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
                validator: (rule, value) => {
                  try {
                    const v = JSON.parse(value);
                    return Promise.resolve(true);
                  } catch (err) {
                    return Promise.reject("Invalid JSON");
                  }
                },
              },
            ]}
          >
            <Input.TextArea disabled={!edit} rows={5} />
          </Form.Item>
          {edit && (
            <Form.Item style={{ textAlign: "right" }}>
              <Space className="foot">
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      value: JSON.stringify(config.value, undefined, 4),
                    });
                    setEdit(false);
                  }}
                  danger
                >
                  Cancel
                </Button>
                <Button loading={loading} htmlType="submit" type="primary">
                  Update
                </Button>
              </Space>
            </Form.Item>
          )}
        </div>
      </Form>
    </Col>
  );
}

export default function Config() {
  const [configs, setConfigs] = useState({
    loading: false,
    data: [],
    ref: [],
  });

  const { getConfigs } = useConfig();

  const fetchConfigs = async (params) => {
    setConfigs({ ...configs, loading: true });
    getConfigs(params, (err, res) => {
      if (err) {
        setConfigs({
          loading: false,
          data: [],
        });
        return message.error(err);
      } else {
        setConfigs({
          loading: false,
          data: res.map((i) => parser(i)),
          ref: res,
        });
      }
    });
  };

  useEffect(() => {
    fetchConfigs({});
  }, []);

  return (
    <ConfigsWrapper>
      <Row gutter={[16, 24]} justify="center">
        <CreateNew configs={configs} setConfigs={setConfigs} />
        {configs.data.map((config, idx) => {
          return (
            <ConfigsItem
              key={idx + config.key}
              config={config}
              configs={configs}
              setConfigs={setConfigs}
            />
          );
        })}
      </Row>
    </ConfigsWrapper>
  );
}

const ConfigsWrapper = styled.div`
  .create-new {
    background: #fff;
    padding: 20px;

    .create-btn {
      width: 120px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }

  .config-wrapper {
    padding: 20px;
    background: #fff;

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 20px;
        margin-bottom: 20px;
      }
      .edit-btn {
        display: flex;
        align-items: center;
      }
    }

    .ant-input-disabled {
      color: black;
    }

    .foot {
      margin-top: 10px;
    }
  }
`;
