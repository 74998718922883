import styled from "styled-components"
import { useContext } from 'react'
import { OrderListContext } from '../../../pages/Order/OrderList'
import GoBack from "../../Common/GoBack"
import UserLinks from "../../Common/UserLinks"

export default function Header() {
    const { user, rider } = useContext(OrderListContext)
   
    return (
        <HeaderContainer>
            <div className="top">
                <h2 className="title">
                        <span>Order list</span>
                        {user.data && <span className="user">of <UserLinks id={user.data.id} type={user.data.type}><span className="text">{user.data.name ?? user.data.username} ({user.data?.type}) </span></UserLinks></span>}
                        {rider.data && <span className="user"> {user.data? 'assigned to': 'of'} <UserLinks id={rider.data.id} type={rider.data.type}><span className="text">{rider.data.name ?? rider.data.username} ({rider.data.type}) </span></UserLinks></span>}
                </h2>
            </div>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.div`
    margin-bottom: 20px;
    .top{
        display: flex;
        align-items: center;

        .title{
            font-size: 22px;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            .user{
                margin-left: 10px;
                display: flex;
                align-items: center;

                .text{
                    font-size: 22px;
                    margin-left: 10px;
                    color: #000;
                }
            }
        }
    }
`