import { InputNumber, message, Space } from "antd";
import { useState } from "react";
import Parse from "parse";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";

export default function CreateHubArea({
  area,
  charge,
  setCharge,
  hub,
  setCreate,
}) {
  const [loading, setLoading] = useState(false);

  const onChange = async (e) => {
    setCharge(e.target.value);
    if (e.keyCode === 13) {
      setLoading(true);
      try {
        const hubPointer = {
          __type: "Pointer",
          className: "hub",
          objectId: hub,
        };

        const exists = await new Parse.Query("hub_area")
          .equalTo("name", area)
          .equalTo("hub", hubPointer)
          .first();
        if (exists) {
          message.error("Area already exists");
          setLoading(false);
          setCreate(false);
        }
        let hubArea = new Parse.Object("hub_area");
        hubArea.set("name", area);
        hubArea.set("hub", hubPointer);
        hubArea.set("delivery_charge", Number(e.target.value));
        hubArea = await hubArea.save();
        if (hubArea) {
          setCreate(false);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        message.error(err.message);
      }
    }
  };

  return (
    <Space>
      <InputNumber
        value={charge}
        onKeyDown={onChange}
        placeholder="charge"
        style={{ width: "100px" }}
      />
      {loading ? (
        <div style={{ fontSize: "14px" }}>
          <LoadingOutlined />
        </div>
      ) : (
        <CloseOutlined
          style={{ color: "red" }}
          onClick={() => {
            setCharge(null);
            setCreate(false);
          }}
        />
      )}
    </Space>
  );
}
