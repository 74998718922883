import { Button, Form, Input, Checkbox, Modal, Row, Col } from "antd";
import { InvoiceContext } from "../../pages/Payments/Invoice";
import { useContext } from "react";
import { MinusOutlined } from "@ant-design/icons";

export default function PaymentsInfo() {
  const { paymentHandler, paymentLoading, visible, setVisible } =
    useContext(InvoiceContext);
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Payment"
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Form form={form} onFinish={paymentHandler} layout="vertical">
        <Form.Item name="vat_included" valuePropName="checked">
          <Checkbox>Vat included</Checkbox>
        </Form.Item>
        <h3>Appendix</h3>
        <Form.List name="appendix">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[16, 0]} align="middle">
                    <Col span={13}>
                      <Form.Item {...restField} name={[name, "title"]}>
                        <Input placeholder="Title" />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item {...restField} name={[name, "amount"]}>
                        <Input placeholder="Amount" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item>
                        <Button
                          onClick={() => remove(name)}
                          icon={<MinusOutlined />}
                        ></Button>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button onClick={() => add()}>Add Appendix</Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Form.Item name="note">
          <Input.TextArea placeholder="Note" rows={4} />
        </Form.Item>
        <Form.Item>
          <Button loading={paymentLoading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
