import { useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import DateHandler from "../../dashboard/DateHandler";
import moment from "moment";
import useOrders from "../../../hooks/useOrders";
import Summery from "./Summery";
import { Link } from "react-router-dom";

export default function ProductSales({ loading, data, id, fetchData }) {
  const { addDay, startTime } = useOrders();
  const currentTime = startTime(Date.now());
  const [date, setDate] = useState([
    moment(addDay(currentTime, -30), "DD-MM-YYYY"),
    moment(currentTime, "DD-MM-YYYY"),
  ]);

  useEffect(() => {
    fetchData({
      id,
      date: [date[0].toString(), date[1].toString()],
    });
  }, [id, date]);

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (time, { id }) => (
        <Link to={`/order/list/${id}`}>
          {moment(time).format("DD-MM-YYYY hh:mm A")}
        </Link>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Customer name",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text, { user }) => (
        <Link style={{ color: "black" }} to={`/user/add?id=${user}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Customer area",
      dataIndex: "customer_area",
      key: "customer_area",
    },
    {
      title: "Sale Unit",
      dataIndex: "sale_unit",
      key: "unit",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
    },
  ];

  return (
    <div>
      <Row>
        <Col span={24}>
          <DateHandler
            setDate={setDate}
            defaultValue={[
              moment(addDay(currentTime, -30), "DD-MM-YYYY"),
              moment(currentTime, "DD-MM-YYYY"),
            ]}
          />
        </Col>
        {data && <Summery data={data} />}
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data?.orders || []}
            pagination={false}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </div>
  );
}
