export const getErrorMessage = (error) =>
  error.response ? error.response.data.error : error.message;

export const parser = (data) => {
  if (Array.isArray(data)) {
    return data.map((i, x) => ({
      ...i.attributes,
      id: i.id,
      ref: i,
      key: x,
    }));
  } else if (typeof data === "object") {
    return {
      ...data.attributes,
      id: data.id,
      ref: data,
    };
  } else {
    return null;
  }
};

export function randomId() {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

/**
 * Download csv file
 * @param { Array } segments
 */

export function downloadCsv(segments, filename = "export") {
  let a = document.createElement("a");

  const text = segments
    .map((segment) => {
      const { header = [], data = [], title = "" } = segment;

      const keys = header.map((s) => s.key);
      let segHeader = header.map((s, i) => s.title).join(",") + "\n";

      const segBody = data
        .map((item) => {
          return keys.map((k) => item[k] ?? "").join(",");
        })
        .join("\n");

      return title + "\n " + segHeader + segBody;
    })
    .join("\n \n");

  a.href = "data:application/octet-stream, " + encodeURIComponent(text);
  a.download = `${filename}.csv`;
  a.click();
}
