import { useContext, useCallback, useState } from "react";
import { Button, Space, Switch, Tag } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box } from "../Common/Boxer";
import { SectionsContext, Types } from "./SectionsContext";
import styled from "styled-components";
import Parse from "parse";
import UpdateSection from "./UpdateSection";
import DeleteSection from "./DeleteSection";
import { EditOutlined } from "@ant-design/icons";

function SectionItem({ section, idx }) {
  const [edit, setEdit] = useState(false);
  const { publishSectionFunc } = useContext(SectionsContext);
  return (
    <div key={section.id} className="droppable-item">
      <Draggable draggableId={section.id} index={idx}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="droppable-item"
          >
            <Item>
              <div>
                <Space>
                  <div className="title">{section.name}</div>
                  <Tag color="blue" title="Restaurants">
                    {section.items?.length}
                  </Tag>
                </Space>
                <div className="hubs">
                  {section.hubs?.map((hub) => (
                    <div key={hub.id} className="hubs-item">
                      {hub?.get ? hub?.get("name") : hub?.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="pub">
                <Space>
                  <Switch
                    checked={section.published}
                    onChange={(checked) =>
                      publishSectionFunc(section.id, checked)
                    }
                  />
                  <Button
                    onClick={() => setEdit(true)}
                    icon={<EditOutlined />}
                    shape="circle"
                  />
                  <DeleteSection sectionId={section.id} />
                </Space>

                {edit && (
                  <UpdateSection
                    sectionId={section.id}
                    {...section}
                    close={(e) => setEdit(false)}
                  />
                )}
              </div>
            </Item>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </div>
  );
}

export default function SectionsSorting() {
  const { sections, dispatch } = useContext(SectionsContext);

  const onDragEnd = useCallback(
    async (result) => {
      const { destination, source } = result;

      if (!destination || source.index === destination.index) return;

      const sourceSection = sections.data[source.index];
      sections.data.splice(source.index, 1);
      sections.data.splice(destination.index, 0, sourceSection);

      sections.data.forEach((item, idx) => {
        item.ref.set("sort_order", idx);
        item.sort_order = idx;
      });

      dispatch({ type: Types.SECTIONS_SUCCESS, payload: sections.data });

      await Parse.Object.saveAll(sections.data.map((i) => i.ref));
    },
    [sections]
  );

  return (
    <Box title="Sections" maxHeight={500}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="droppable"
              >
                {sections.data.map((section, idx) => (
                  <SectionItem key={section.id} idx={idx} section={section} />
                ))}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f3f3f3;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #e8e8e8;

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .hubs {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    &-item {
      background: #000000b5;
      font-size: 14px;
      color: #fff;
      padding: 3px 10px;
      margin-right: 10px;
      border-radius: 5px;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }
  }

  &:hover {
    background: #e3e3e3;
  }

  .more-icon {
    font-size: 24px;
    min-width: 35px;
    height: 35px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    color: green;
    &:hover {
      box-shadow: 0 11px 32px rgb(0 0 0 / 25%), 0 4px 15px rgb(0 0 0 / 22%);
      // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  }

  .add-item,
  .update-section {
    position: absolute;
    padding: 16px;
    padding-top: 10px;
    top: 0;
    left: 0;
    min-height: 150px;
    background-color: #fff;
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgb(0 0 0 / 20%);
    z-index: 1000;

    .header {
      margin-bottom: 0px;
      display: flex;
      height: 30px;

      .title {
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }

  .close-x {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin: 7px;
    cursor: pointer;
    border-radius: 50%;
    color: gray;

    &:hover {
      color: #e54242;
    }
  }
`;
