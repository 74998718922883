import { useCallback } from "react";
import { Col, Row, Form, Input, Space, Button, message } from "antd";
import styled from "styled-components";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState, useContext } from "react";
import useHub from "../../hooks/useHub";
import { HubsContext } from "./HubsProvider";

const { Item } = Form;

function Address({ form }) {
  const [places, setPlaces] = useState([]);
  const [counter, setCounter] = useState(0);

  const onChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.length === 0 && places.length) {
        setPlaces([]);
      }

      setCounter(counter + 1);

      if (value.length < 10 || counter < 4) {
        return;
      }
      setCounter(0);

      const link = `https://barikoi.xyz/v1/api/search/verify/autocomplete/${process.env.REACT_APP_BARIKOI_API_KEY}/place?q=${e.target.value}`;

      fetch(link)
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data.places)) {
            setPlaces(data.places);
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    },
    [counter]
  );

  const setAddress = useCallback((place) => {
    form.setFieldsValue({
      address: place,
    });

    setPlaces([]);
  }, []);

  return (
    <Item>
      <div className="address">
        <Item name={["address", "address"]} label="Address">
          <Input onChange={onChange} />
        </Item>
        {places.length > 0 && (
          <div className="places customScroll">
            {places.map((place) => (
              <div
                onClick={() => setAddress(place)}
                className="place"
                key={place.id}
              >
                {place.address}
              </div>
            ))}
          </div>
        )}
      </div>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Item name={["address", "area"]} label="Area">
            <Input disabled />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={["address", "city"]} label="City">
            <Input disabled />
          </Item>
        </Col>
        <Col span={12}>
          <Item name={["address", "postCode"]} label="Post Code">
            <Input disabled />
          </Item>
        </Col>
        <Col span={12} style={{ display: "none" }}>
          <Item name={["address", "latitude"]} label="Latitude">
            <Input disabled />
          </Item>
        </Col>
        <Col span={12} style={{ display: "none" }}>
          <Item name={["address", "longitude"]} label="Longitude">
            <Input disabled />
          </Item>
        </Col>
        <Col span={12} style={{ display: "none" }}>
          <Item name={["address", "pType"]} label="PType">
            <Input disabled />
          </Item>
        </Col>
      </Row>
    </Item>
  );
}

export default function CreateNewHub() {
  const { fetchHubs } = useContext(HubsContext);
  const { createNew, updateItem, getById } = useHub();
  const [form] = Form.useForm();
  const url = new URL(window.location.href);
  const hubId = url.searchParams.get("id");
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    if (Array.isArray(values.areas)) {
      values.areas.forEach((item) => {
        item.name = item.name.toLowerCase().trim();
      });
    }

    if (values.address) {
      if (typeof values.address.latitude === "number") {
        values.address.latitude = values.address.latitude.toString();
      }
      if (typeof values.address.longitude === "number") {
        values.address.longitude = values.address.longitude.toString();
      }
    }

    setLoading(true);
    if (hubId) {
      updateItem({ id: hubId, ...values }, (err, res) => {
        if (err) {
          message.error(err);
        } else {
          message.success("Updated successfully!");
          fetchHubs();
        }
        setLoading(false);
      });
    } else {
      createNew(values, (err, res) => {
        if (err) {
          message.error(err);
        } else {
          message.success("Created successfully!");
          form.resetFields();
          fetchHubs();
        }

        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (hubId) {
      message.loading("Loading...", 0);
      getById({ id: hubId }, (err, res) => {
        message.destroy();
        if (res) {
          const { name, areas, region, address } = res.toJSON();
          form.setFieldsValue({
            name,
            areas,
            region,
            address,
          });
        } else {
          message.error(err);
        }
      });
    }

    return () => {
      form.resetFields();
    };
  }, [hubId]);

  return (
    <Row justify="center">
      <Wrapper span={24} md={24} lg={20} xl={16}>
        <div className="heading">
          <h1 className="title">Create New Hub</h1>
        </div>
        <Form form={form} onFinish={onFinish} layout="vertical" size="large">
          <Row gutter={[16, 16]}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Col>
            <Col span={24} lg={12}>
              <div className="form-group">
                <Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="Region"
                  name="region"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Address form={form} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="form-group">
                <Item label="Areas">
                  <Form.List name="areas">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map(({ key, name, ...rest }) => (
                            <div>
                              <Space
                                style={{ marginBottom: "10px" }}
                                direction="horizontal"
                              >
                                <Item
                                  key={key}
                                  {...rest}
                                  name={[name, "name"]}
                                  fieldKey={[key, "name"]}
                                  style={{ marginBottom: "0px" }}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Name can't be empty!`,
                                    },
                                  ]}
                                >
                                  <Input placeholder="Name" />
                                </Item>
                                <Item>
                                  <Button
                                    type="link"
                                    onClick={() => remove(name)}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    danger
                                    icon={<DeleteOutlined />}
                                  >
                                    delete
                                  </Button>
                                </Item>
                              </Space>
                            </div>
                          ))}

                          <Item style={{ margin: "10px 0" }}>
                            <Button
                              type="dashed"
                              size="large"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Add Area
                            </Button>
                          </Item>
                        </>
                      );
                    }}
                  </Form.List>
                </Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Wrapper>
    </Row>
  );
}

const Wrapper = styled(Col)`
  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 20px;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .form-group {
    padding: 20px;
    background: #fff;
    border: 1px solid #e8e8e8;
  }

  .address {
    position: relative;

    .places {
      position: absolute;
      top: 32px;
      left: 0px;
      width: 100%;
      min-height: 200px;
      max-height: 300px;
      background: #fff;
      border: 1px solid #e8e8e8;
      z-index: 1000;
      padding: 10px 0px;
      border-radius: 10px;
      margin-top: 40px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

      .place {
        padding: 3px 10px;
        cursor: pointer;
        letter-spacing: 0.5px;

        &:not(:last-child) {
          border-bottom: 1px solid #e8e8e8;
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
`;
