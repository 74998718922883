import { Button, Drawer, Table } from "antd";
import { RidersContext } from "../../../pages/Rider/Riders";
import { useContext, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";

export default function ArchivedRiders() {
  const { archivedRiders, fetchArchivedRiders, deleteRider } =
    useContext(RidersContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible && !archivedRiders) {
      fetchArchivedRiders();
    }
  }, [visible, archivedRiders]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      key: "action",
      render: (text, { objectId }) => (
        <Button onClick={() => deleteRider({ id: objectId, restore: true })}>
          Restore
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Button
        size="large"
        icon={<DeleteOutlined />}
        onClick={() => setVisible(true)}
      >
        Archived Riders
      </Button>
      <Drawer
        title="Archived Rider"
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
        width={500}
      >
        <Table
          loading={!archivedRiders}
          columns={columns}
          dataSource={archivedRiders || []}
        />
      </Drawer>
    </div>
  );
}
