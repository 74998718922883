import { useState, useEffect, useCallback } from "react";
import {
  CloseOutlined,
  ExpandOutlined,
  GlobalOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { formatRelative, intervalToDuration } from "date-fns";
import Styled from "styled-components";
import { Divider, Button, Space, Tag, message } from "antd";
import { Link } from "react-router-dom";
import { BiDollar, BiMobile, BiTargetLock, BiUser } from "react-icons/bi";
import { FaUserCheck } from "react-icons/fa";
import RaiseIssue from "../Details/RaiseIssue";
import Parse from "parse";

const UserIcon = (props) => (
  <div {...props} className={`user-icon ${props.verified ? "verified" : ""}`}>
    {props.verified ? <FaUserCheck /> : <BiUser />}
  </div>
);

function Charge(props) {
  const { charge, payment_method } = props;
  const { delivery_charge, discount, items_total, vat, total } = charge;
  const [open, setOpen] = useState(false);

  return (
    <div className="charge">
      <div onClick={(e) => setOpen(true)} className="charge-icon">
        <BiDollar />
        <span
          className="tx"
          style={{
            background: payment_method !== "cod" ? "#40A9FF" : "",
          }}
        >
          {total}
        </span>
      </div>
      {open && (
        <div className="charge-content">
          <div className="item">
            <span>Delivery charge:</span>{" "}
            <span className="value">+{delivery_charge} tk</span>
          </div>
          <div className="item">
            <span>Discount:</span> <span className="value">-{discount} tk</span>
          </div>
          <div className="item">
            <span>Items total:</span>{" "}
            <span className="value">{items_total} tk</span>
          </div>
          <div className="item">
            <span>Vat:</span> <span className="value">+{vat} tk</span>
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <div className="item">
            <span>Total:</span> <span className="value">{total} tk</span>
          </div>
          <div class="charge-footer">
            <Button
              size="middle"
              onClick={(e) => setOpen(false)}
              type="dashed"
              danger
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function UserInfo({
  customer_name,
  customer_phone,
  customer_address,
  close,
  user,
}) {
  return (
    <div className="user-info">
      <div className="content-wrapper">
        <div onClick={close} className="close-icon">
          <CloseOutlined />
        </div>
        <div className="top">
          <UserIcon />
          <div className="info">
            <h3 className="name">
              <Link target="_blank" to={`/user/add?id=${user.id}`}>
                {user?.get("name") ?? customer_name}
              </Link>
              {user.get("verified") && <Tag color="green">Verified</Tag>}
            </h3>

            <div className="sec">
              <span className="icon">
                <BiMobile />
              </span>
              <span className="text">{customer_phone}</span>
            </div>
            <div className="sec">
              <span className="icon">
                <BiTargetLock />
              </span>
              <span className="text">{customer_address}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CardHeader(props) {
  const {
    id,
    createdAt,
    charge,
    platform,
    status,
    delivery_time,
    promo,
    hub,
    note,
    rider_note,
    dispatch_hour,
    setOrderId,
    ...rest
  } = props;
  const [userInfoOpen, setUserInfoOpen] = useState(false);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const { name: hubName, objectId: hubId } = hub?.toJSON() ?? {};
  const [orderCount, setOrderCount] = useState(null);

  const fetchUserOrderCount = async (id, createdAt) => {
    try {
      const count = await new Parse.Query("order")
        .equalTo("user", {
          __type: "Pointer",
          className: "_User",
          objectId: id,
        })
        .lessThanOrEqualTo("createdAt", new Date(createdAt))
        .count();
      setOrderCount(count);
    } catch (err) {
      message.error(err);
    }
  };

  useEffect(() => {
    fetchUserOrderCount(props.user?.id, props.createdAt);
  }, []);

  const setTimer = useCallback(() => {
    return setInterval(() => {
      const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(createdAt),
        end: Date.now(),
      });
      setTime({ hours, minutes, seconds });
    }, 2000);
  }, [createdAt]);

  useEffect(() => {
    const interval = setTimer();
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <HeaderWrapper className="header">
      <div className="left-side">
        <Space>
          <div style={{ cursor: "pointer", color: "#fff" }}>
            <ExpandOutlined
              style={{ fontSize: "22px" }}
              onClick={() => setOrderId(id)}
            />
          </div>
          <Link to={`/order/list/${id}`} className="id">
            #{id}{" "}
            <span style={{ color: "white" }}>
              {platform === "web" ? <GlobalOutlined /> : <MobileOutlined />}
            </span>
          </Link>
          {orderCount ? (
            <Link target="_blank" to={`/order/list?user=${rest.user?.id}`}>
              <Tag title="User order number" color="red">
                {orderCount}
              </Tag>
            </Link>
          ) : null}
        </Space>
        <p className="date">
          {["delivered", "cancelled", "rejected"].includes(status) ||
          (time && time.hours >= 10)
            ? formatRelative(new Date(createdAt), Date.now())
            : `${time.hours ?? "00"}:${time.minutes ?? "00"}:${time.seconds}`}
        </p>
      </div>
      <Space className="right-side">
        {["delivered", "rejected", "cancelled"].includes(status) && (
          <RaiseIssue data={props} shape="circle" />
        )}
        <Charge {...props} />
        {userInfoOpen && (
          <UserInfo close={(e) => setUserInfoOpen(false)} {...rest} />
        )}
        <UserIcon
          verified={rest.user?.get("verified")}
          onClick={(e) => setUserInfoOpen(true)}
        />
      </Space>
      <div className="customer">
        <div>
          <Space direction="vertical">
            <div>
              <span>{rest.customer_name ?? "N/A"}</span>
              {rest.customer_area && <span> ( {rest.customer_area} )</span>}
            </div>
            <div>{rest.customer_phone}</div>
          </Space>
        </div>
        <Space>
          {hubName && (
            <Tag color="pink">
              <Link target="_blank" to={`/hub/create-new?id=${hubId}`}>
                {hubName}
              </Link>
            </Tag>
          )}
          {dispatch_hour?.text ? (
            <div className="dispatch-hour">{dispatch_hour.text}</div>
          ) : null}
          {promo && <Tag color="orange">{promo.promo_code}</Tag>}
        </Space>
      </div>
      {(note || rider_note) && (
        <div className="notes">
          {note && (
            <p className="note">
              <strong>Note: </strong>
              {note}
            </p>
          )}
          {rider_note && (
            <p className="rider-note">
              <strong>Rider note: </strong>
              {rider_note}
            </p>
          )}
        </div>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = Styled.div`
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    flex-wrap: wrap;


    .customer{
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        width: 100%;
        color: #fff!important;
        letter-spacing: 0.5px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .close-icon{
        position: absolute;
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        color: #f4256b;
        border-radius: 50%;
        margin: 5px 10px;
        cursor: pointer;
        transition: .3s;

        &:hover{
            background: #fbe3e7;
        }
    }

    .left-side{
        width: 60%;
        .id{
            font-size: 14px;
            margin-bottom: 0px;
            letter-spacing: 1px;
            font-weight: 600;
            color: #1dd9ed;
        }
        .date{
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            margin-bottom: 0px;
        }
    }

    .right-side{

        .charge{
            margin-right: 5px;
            .charge-icon{
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                font-size: 18px;
                color: #918f8e;
                cursor: pointer;
                transition: .3s;
                position: relative;

                &:hover{
                    box-shadow: 0px 3px 11px rgba(0,0,0,.2);
                }

                .tx{
                    font-size: 10px;
                    position: absolute;
                    top: -5px;
                    left: 47%;
                    font-weight: bold;
                    background: #ff9800;
                    padding: 1px 6px;
                    border-radius: 5px;
                    letter-spacing: 1px;
                    color: #000000;
                }
            }

            .charge-content{
                position: absolute;
                top: 0px;
                left: 0;
                width: 100%;
                min-height: 120px;
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0px 7px 28px rgb(0 0 0 / 20%);
                font-weight: 600;
                animation: fadeIn .2s ease-in;

                .item{
                    display: flex;
                    justify-content: space-between;

                    .value{
                        font-weight: bold;
                    }
                }

                .charge-footer{
                    margin-top: 20px;
                    text-align: right;

                    button{
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    .user-info{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: #fff;
        min-height: 200px;
        max-height: 100%;
        overflow-y: auto;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 7px 28px rgba(0,0,0,.2);
        animation: fadeIn .3s ease-in;
    }

    .verified {
      color: green!important;
    }

    .user-icon{
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        background: #fff;
        justify-content: center;
        border-radius: 50%;
        font-size: 19px;
        cursor: pointer;
        transition: .3s;
        
        &:hover{
            box-shadow: 0px 3px 11px rgba(0,0,0,.2);
        }
    }

    .dispatch-hour{
      background: yellow;
      padding: 3px 10px;
      border-radius: 10px;
      color: black;
      letter-spacing: 1px;
    }


    .content-wrapper{
        .top{
            display: flex;

            .user-icon{
                background: #c8dfc9;
            }

            .info{
                padding: 0px 10px;
                width: calc(100% - 35px);
                .name{
                    font-size: 18px;
                    margin-bottom: 7px;
                }
                .sec{
                    font-size: 13px;
                    color: #171717;
                    font-weight: 600;
                    letter-spacing: 1px;
                    .icon{
                        margin-right: 5px;
                        font-size: 15px;
                    }
                }
            }
        }

      }
      .notes{
        background: #fff;
        width: 100%;
        margin-top: 10px;
        padding: 5px 10px;
        border-radius: 10px;

        .note, .rider-note{
          color: #000;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 0;
          word-break: break-word;
        }
      }
`;
