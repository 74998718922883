import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Item = ({ title, value }) => {
  return (
    <div className="profile-info-item">
      <div className="profile-info-item-title">{title}</div>
      <div className="profile-info-item-value">{value ?? "N/A"}</div>
    </div>
  );
};

export default function ProfileInfo({ data }) {
  if (!data) return <div>Not Found</div>;

  const {
    objectId,
    name,
    image,
    riderHub,
    username,
    jobType,
    vehicleType,
    phone,
    employeeType,
    nidNumber,
    riderBasic,
    date_of_birth,
    riderDutyHour,
  } = data || {};

  return (
    <Wrapper className="profile-info">
      <div className="profile-header">
        <div className="left">
          <div className="image">{image && <img src={image} alt="" />}</div>
          <div className="info">
            <h1 className="name">{name}</h1>
            <h2 className="username">{username}</h2>
            <p className="phone">{phone}</p>
          </div>
        </div>
        <div className="right">
          <Link to={`/rider/update/${objectId}`}>
            <EditOutlined /> Edit
          </Link>
        </div>
      </div>
      <div className="profile-body">
        <Item title="Hub" value={riderHub?.name} />
        <Item title="Job Type" value={jobType} />
        <Item title="Vehicle Type" value={vehicleType} />
        <Item title="Employee Type" value={employeeType} />
        <Item title="NID Number" value={nidNumber} />
        <Item title="Rider Basic" value={riderBasic} />
        {date_of_birth && (
          <Item
            title="Date of Birth"
            value={new Date(date_of_birth.iso).toLocaleDateString()}
          />
        )}
        <Item title="Rider duty hour" value={riderDutyHour} />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .profile-header {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;

      .image {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        background: #eee;
        border-radius: 10px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .info {
        .name,
        .username,
        .phone {
          margin-bottom: 0;
        }
        .name {
          font-size: 20px;
          font-weight: bold;
        }
        .username {
          font-size: 14px;
          color: #999;
        }
        .phone {
          font-size: 14px;
          color: #999;
        }
      }
    }
  }

  .profile-body {
    margin-top: 20px;
    .profile-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;
