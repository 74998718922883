import { useContext, useState, useEffect, Fragment } from "react";
import { DetailsContext } from "../../../../pages/Order/Details";
import {
  Button,
  Modal,
  message,
  Form,
  Radio,
  Space,
  Checkbox,
  InputNumber,
} from "antd";
import useProduct from "../../../../hooks/useProduct";
import { EditOutlined } from "@ant-design/icons";

export default function UpdateItems({ id, addons, variant, setLoading }) {
  const { getById } = useProduct();
  const { order, getOrderCharges } = useContext(DetailsContext);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({
    loading: false,
    data: null,
  });
  const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && !product.data) {
      setProduct({
        ...product,
        loading: true,
      });

      getById({ id }, (err, product) => {
        if (err) message.error(err);
        if (product) {
          setProduct({
            ...product,
            loading: false,
            data: product.toJSON(),
          });

          if (variant && Array.isArray(variant)) {
            const productOrderitems =
              product.toJSON().price?.variants?.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
              }, {}) ?? {};

            const variantsMap = variant.reduce((acc, item) => {
              const original = productOrderitems[item.variantId];
              acc[item.variantId] = {
                items:
                  original.max <= 1
                    ? item.items[0].id
                    : item.items.map((i) => i.id),
              };
              return acc;
            }, {});

            form.setFieldsValue({
              variants: variantsMap,
            });
          }

          if (addons && Array.isArray(addons)) {
            const addonsMap = addons.map((i) => i.id);
            form.setFieldsValue({
              addons: addonsMap,
            });
          }
        }
      });
    }

    const orderItem = order.data.order_items.find((item) => item.id === id);
    if (orderItem) {
      form.setFieldsValue({
        quantity: orderItem.quantity,
      });
    }
  }, [open]);

  const onFinish = (values) => {
    const { variants, addons, quantity } = values;
    const orderItem = order.data.order_items.find((item) => item.id === id);
    if (orderItem) {
      orderItem.quantity = quantity;

      if (variants && Object.keys(variants).length > 0) {
        orderItem.variant = Object.keys(variants).reduce((acc, key) => {
          const obj = {};
          obj["variantId"] = key;
          if (variants[key] && Array.isArray(variants[key].items)) {
            obj.items = variants[key].items.map((i) => ({ id: i }));
          } else if (typeof variants[key]?.items === "string") {
            obj.items = [{ id: variants[key].items }];
          }
          if (obj.items) {
            acc.push(obj);
          }
          return acc;
        }, []);
      }

      if (addons && Array.isArray(addons) && addons.length > 0) {
        orderItem.addons = addons.map((i) => ({ id: i }));
      }

      setLoading(true);
      getOrderCharges(order, () => {
        setLoading(false);
      });
      handleCancel();
    }
  };

  return (
    <>
      <Button onClick={showModal} className="edit-btn" type="primary">
        <EditOutlined className="icon" /> edit
      </Button>
      <Modal
        visible={open}
        title="Update Order Item"
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        forceRender
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Quantity" name="quantity">
            <InputNumber min={1} style={{ width: "200px" }} />
          </Form.Item>
          {product.data?.price?.variants?.map((variant, index) => {
            return (
              <Fragment key={index}>
                <Form.Item
                  label={variant.title}
                  name={["variants", variant.id, "items"]}
                  rules={[
                    {
                      required: variant.min > 0 ? true : false,
                      message: `Please select at least ${variant.min} item`,
                    },
                  ]}
                >
                  {variant.max <= 1 ? (
                    <Radio.Group>
                      <Space direction="vertical">
                        {variant.items.map((item, i) => (
                          <Radio key={i} value={item.id}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "250px",
                              }}
                            >
                              <div>{item.name}</div>
                              <div>{item.price} tk</div>
                            </div>
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  ) : (
                    <Checkbox.Group>
                      <Space direction="vertical">
                        {variant.items.map((item, i) => (
                          <Checkbox key={i} value={item.id}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "250px",
                              }}
                            >
                              <div>{item.name}</div>
                              <div>{item.price} tk</div>
                            </div>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              </Fragment>
            );
          })}
          {product.data?.addons && (
            <Form.Item name="addons" label={product.data.addons?.title}>
              <Checkbox.Group>
                <Space direction="vertical">
                  {product.data.addons?.items?.map((item, i) => (
                    <Checkbox key={i} value={item.id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "250px",
                        }}
                      >
                        <div>{item.name}</div>
                        <div>{item.price} tk</div>
                      </div>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          )}
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              style={{ marginRight: "10px" }}
              onClick={handleCancel}
              type="primary"
              danger
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Ok
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
