import { useContext } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { SectionsContext } from "./SectionsContext";

export default function UpdateSection({
  close,
  name,
  sectionId,
  hubs: hubItems,
}) {
  const [form] = Form.useForm();
  const {
    updateSectionNameFunc,
    updateSectionName: { loading },
    hubs,
  } = useContext(SectionsContext);

  const onFinish = (values) => {
    updateSectionNameFunc(sectionId, values, () => {
      form.resetFields();
      close();
    });
  };

  return (
    <div className="update-section">
      <div className="header">
        <h3 className="title">Update Section Name</h3>
        <div onClick={close} className="close-x">
          <CloseOutlined />
        </div>
      </div>

      <Form
        form={form}
        style={{ width: "100%" }}
        layout="vertical"
        name="update-section"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        initialValues={{
          name,
          hubs: hubItems.map((h) => h.id),
        }}
        size="large"
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input section name!" }]}
          style={{ marginBottom: "10px" }}
        >
          <Input placeholder="Section Name" />
        </Form.Item>
        <Form.Item
          label="Select Hubs"
          name="hubs"
          style={{ marginBottom: "0px" }}
        >
          <Select placeholder="Select hub" mode="multiple">
            {hubs.data.map((hub) => {
              return (
                <Select.Option key={hub.id} value={hub.id}>
                  {hub.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginTop: "10px" }}>
          <Button
            loading={loading}
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
