import { Row, Col } from "antd";
import React, { useState, createContext, useContext, useEffect } from "react";
import styled from "styled-components";

const BoxerContext = createContext();

export function Box({
  children,
  title = "",
  header,
  bg,
  min = 8,
  minHeight = "150px",
  maxHeight,
  overflowY = "auto",
  overflowX = "hidden",
  rightMenu,
  metaTitle = "",
  ...rest
}) {
  const ctx = useContext(BoxerContext);
  const funcName = `${
    typeof title === "string" ? title.replaceAll(" ", "-") : ""
  }-${metaTitle}-${bg}${min}${minHeight}${maxHeight}${overflowY}${overflowX}`;
  const [size, setSize] = useState(!ctx?.open ? "close" : "");

  useEffect(() => {
    setSize(localStorage.getItem(`box-${funcName}`));
  }, []);

  function ExpandBar() {
    const onClick = (e) => {
      const attrName = e.target.attributes.getNamedItem("name").value;
      setSize(attrName);
      if (funcName) {
        localStorage.setItem(`box-${funcName}`, attrName);
      }
    };

    return (
      <div className="expand-bar">
        <div name="min" onClick={onClick} className="min"></div>
        <div name="mid" onClick={onClick} className="mid"></div>
        <div name="large" onClick={onClick} className="large"></div>
        <div name="max" onClick={onClick} className="max"></div>
        <div name="close" onClick={onClick} className="close"></div>
      </div>
    );
  }

  const sizes = {
    576: {
      min: 12,
      mid: 24,
      large: 24,
      max: 24,
    },
    992: {
      min: 8,
      mid: 12,
      large: 16,
      max: 24,
    },
    768: {
      min: 12,
      mid: 16,
      large: 20,
      max: 24,
    },
  };

  const getSize = (size) => {
    let width = window.innerWidth;
    if (width < 576) {
      return sizes["768"][size];
    }
    if (width < 768) {
      return sizes["768"][size];
    } else {
      return sizes["992"][size];
    }
  };

  const getSpan = (size, min) => {
    let res = getSize(size) || 12;
    return Math.max(res, min);
  };

  return (
    <>
      {size === "close" && (
        <Col>
          <ComponentStyle>
            <div className="initial">
              <div className="box-header">
                <div className="box-right">{rightMenu && rightMenu}</div>
              </div>
              <ExpandBar />
              <div className="initial-title">{title}</div>
            </div>
          </ComponentStyle>
        </Col>
      )}
      <Col
        style={{ display: size === "close" ? "none" : "" }}
        span={getSpan(size, min)}
      >
        <ComponentStyle
          style={{
            background: bg ? bg : "#ffffff",
          }}
        >
          {header ? (
            header
          ) : (
            <div className="box-header">
              <h2 className="box-header-title">{title}</h2>
              <div className="box-right">{rightMenu && rightMenu}</div>
            </div>
          )}
          <ExpandBar />
          <div
            className="body customScroll"
            style={{
              minHeight,
              maxHeight: maxHeight || "auto",
              overflowX,
              overflowY,
            }}
            {...rest}
          >
            {typeof children === "function" ? children({ size }) : children}
          </div>
        </ComponentStyle>
      </Col>
    </>
  );
}

const ComponentStyle = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  padding: 35px 0px 20px 0px;

  .box-header {
    width: 100%;
    height: 30px;
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 4px 10px;
    padding-right: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      margin-top: 10px;
      font-size: 15px;
      color: #535353;
      font-family: monospace;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .box-right {
      font-size: 16px;
    }
  }

  .initial {
    adding: 20px;
    width: 250px;
    height: 100px;
    background: #fff;
    border-radius: 10px;

    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .expand-bar {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 110px;
    height: 24px;
    background: #e8e8e8;
    display: flex;
    justify-content: space-around;
    padding: 4px;
    border-radius: 0 10px 0px 10px;

    .min,
    .mid,
    .max,
    .large,
    .close {
      width: 15px;
      height: 15px;
      background: #bfbfbf;
      border-radius: 100%;
      cursor: pointer;
      transition: 0.4s;

      &:hover {
        transform: scale(1.2);
      }
    }

    .mid {
      background: #a1a1a1;
    }

    .max {
      background: #0000009c;
    }

    .large {
      background: #6f6f6f;
    }

    .close {
      background: #ff7875;
    }
  }

  .body {
    padding: 0 20px;
  }
`;

export default function Boxer({ children, open = false, ...rest }) {
  return (
    <BoxerContext.Provider value={{ open, ...rest }}>
      <Row gutter={[24, 24]} {...rest}>
        {children}
      </Row>
    </BoxerContext.Provider>
  );
}
