import { useEffect, useState } from "react";
import { Form, Button } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addRiderHandler } from "../../store/rider/riderCrudSlices";
import { fetchHubs } from "../../store/hub/hubSlices";
import PrimaryItems from "../../components/rider/registration/PrimaryItems";
import Boxer from "../../components/Common/Boxer";
import BehavioralItems from "../../components/rider/registration/BehavioralItems";

export default function RiderRegistration() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    addRider: { loading, file },
  } = useSelector((state) => state.riderCrud);
  const [jobType, setJobType] = useState("");

  const onFinish = (values) => {
    values.file = file;
    if (values.date_of_birth) {
      values.date_of_birth = values.date_of_birth.format("YYYY-MM-DD");
    }
    values.cb = () => {
      form.resetFields();
    };
    dispatch(addRiderHandler(values));
  };

  useEffect(() => {
    dispatch(fetchHubs({ select: ["name"] }));
  }, []);

  return (
    <Wrapper md={18} lg={16} xl={12}>
      <div className="header">
        <div className="left-section">
          <h3 className="title">Add New Rider</h3>
        </div>
      </div>
      <Form
        form={form}
        size="large"
        layout="vertical"
        name="addUser"
        onFinish={onFinish}
      >
        <Boxer open={true}>
          <PrimaryItems />
          <BehavioralItems jobType={jobType} setJobType={setJobType} />
        </Boxer>
        <div className="footer">
          <Button
            loading={loading}
            htmlType="submit"
            type="primary"
            size="large"
          >
            Submit
          </Button>
        </div>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .section {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    min-height: 450px;
    border-radius: 10px;
  }

  .header {
    padding: 20px 0;
    .left-section {
      display: flex;
      align-items: center;

      .title {
        font-size: 22px;
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-label {
    line-height: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .profile-picture {
    position: relative;

    img {
      height: 150px;
      width: 150px;
      border-radius: 10px;
    }
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      color: red;
    }
  }

  .footer {
    padding: 20px 0;
    margin-top: 10px;
  }
`;
