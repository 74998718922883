import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Upload,
  message,
  Space,
  Switch,
  Popconfirm,
  Row,
  Col,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  fetchCuisines,
  createNewCuisine,
  deleteCuisine,
  updateCuisine,
  updateList,
  updateListAsync,
} from "../../store/cuisines";
import {
  InboxOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { getSignedUrl, uploadFile } from "../../api/media";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function Show({ show, data }) {
  const [active, setActive] = useState(show);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Switch
      loading={loading}
      checked={active}
      onClick={(value) => {
        setLoading(true);
        setActive(value);
        dispatch(
          updateCuisine({
            object: data.object,
            show: value,
            cb: () => {
              setLoading(false);
            },
          })
        );
      }}
    />
  );
}

function NewCuisine() {
  const { loading } = useSelector((state) => state.cuisines.createNew);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Item } = Form;
  const [file, saveFile] = useState(null);
  const [icon, saveIcon] = useState(null);

  const uploadOnChange = async ({ fileList, name }) => {
    const file = fileList[fileList.length - 1];
    if (file?.originFileObj) {
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

      file.thumbUrl = src;
      if (name === "image") {
        saveFile(file);
      } else if (name === "icon") {
        saveIcon(file);
      }
    }
  };

  const onSubmit = async ({ name }) => {
    try {
      const images = await Promise.all(
        Object.entries({ image: file, icon }).map(async ([name, file]) => {
          const { url } = await getSignedUrl(file.type);
          await uploadFile({ url, file });
          return {
            name,
            url: url.split("?")[0],
          };
        })
      );

      const image = images.find(({ name }) => name === "image")?.url;
      const iconImage = images.find(({ name }) => name === "icon")?.url;

      dispatch(
        createNewCuisine({
          name,
          image,
          icon: iconImage,
          cb: () => {
            form.resetFields();
            setOpen(false);
            saveFile(null);
          },
        })
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
      >
        Add New
      </Button>
      <Modal
        title="Add New Cuisine"
        visible={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        footer={null}
        width={500}
      >
        <Form form={form} onFinish={onSubmit}>
          <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
            <Col span={12}>
              <Upload.Dragger
                beforeUpload={() => false}
                style={{
                  marginBottom: "10px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  background: "#fff",
                  display: file ? "none" : "block",
                }}
                listType="picture"
                multiple={false}
                type="file"
                showUploadList={false}
                onChange={(props) =>
                  uploadOnChange({ ...props, name: "image" })
                }
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Image Upload</p>
              </Upload.Dragger>
              {file && (
                <Picture>
                  <img src={file.thumbUrl} alt="" className="image" />
                  <Button
                    icon={<CloseOutlined />}
                    shape="circle"
                    className="close-btn"
                    onClick={() => {
                      saveFile(null);
                    }}
                  ></Button>
                </Picture>
              )}
            </Col>
            <Col span={12}>
              <Upload.Dragger
                beforeUpload={() => false}
                style={{
                  marginBottom: "10px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  background: "#fff",
                  display: icon ? "none" : "block",
                }}
                listType="picture"
                multiple={false}
                type="file"
                showUploadList={false}
                onChange={(props) => uploadOnChange({ ...props, name: "icon" })}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Icon Upload</p>
              </Upload.Dragger>
              {icon && (
                <Picture>
                  <img src={icon.thumbUrl} alt="" className="image" />
                  <Button
                    icon={<CloseOutlined />}
                    shape="circle"
                    className="close-btn"
                    onClick={() => {
                      saveIcon(null);
                    }}
                  ></Button>
                </Picture>
              )}
            </Col>
          </Row>

          <Item name="name" label="Name">
            <Input placeholder="Cuisine Name" />
          </Item>
          <Item>
            <Space>
              <Button loading={loading} htmlType="submit" type="primary">
                Submit
              </Button>
            </Space>
          </Item>
        </Form>
      </Modal>
    </div>
  );
}

const Picture = styled.div`
  position: relative;
  margin-bottom: 20px;

  .image {
    width: 100%;
    max-height: 260px;
    border-radius: 10px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

function CuisinesItem(props) {
  const { name, show, icon, image, objectId, idx, object } = props;
  const dispatch = useDispatch();

  return (
    <Draggable draggableId={objectId} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="cuisines-item"
        >
          <Space>
            <img src={icon} alt="" className="icon" />
            <img src={image} alt="" className="image" />
            <h3 className="title">{name}</h3>
          </Space>
          <Space>
            <Show show={show} data={props} />
            <Popconfirm
              title="Are you sure you want to delete this item?"
              onConfirm={() => {
                dispatch(deleteCuisine(object));
              }}
            >
              <Button type="danger" icon={<DeleteOutlined />} shape="circle" />
            </Popconfirm>
          </Space>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
}

export default function Cuisines() {
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.cuisines.list);

  const onDragEnd = ({ source, destination }) => {
    if (!destination) return;

    const { index: startIndex } = source;
    const { index: endIndex } = destination;

    const newOrder = [...data];
    const [removed] = newOrder.splice(startIndex, 1);
    newOrder.splice(endIndex, 0, removed);

    newOrder.forEach((item, index) => {
      if (item.object) {
        item.object.set("sort_order", index);
      }
    });

    dispatch(updateList(newOrder));
    dispatch(updateListAsync(newOrder.map((item) => item.object)));
  };

  useEffect(() => {
    dispatch(fetchCuisines({ limit: 100, skip: 0 }));
  }, []);

  return (
    <Wrapper>
      <NewCuisine />
      {!loading && (
        <DragDropContext onDragEnd={onDragEnd}>
          <h1>Cuisines</h1>
          <Row gutter={[24, 24]}>
            <Col lg={8}>
              <Droppable droppableId="droppable">
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="cuisines customScroll"
                    >
                      {data.map((cuisine, i) => (
                        <CuisinesItem
                          key={cuisine.objectId}
                          idx={i}
                          {...cuisine}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </Col>
          </Row>
        </DragDropContext>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .cuisines {
    height: calc(100vh - 230px);
    padding: 5px 10px;

    &-item {
      height: 80px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid lightgray;
      padding: 10px;
      cursor: grab;
      margin-bottom: 10px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .image,
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: black;
      }

      .title {
        margin-bottom: 0;
      }
    }
  }
`;
