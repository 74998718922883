import { Table } from "antd";

export default function RidersReportTable({ data }) {
  const columns = [
    {
      title: "Rider name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Order count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Average Delivery Time",
      dataIndex: "average",
      key: "average",
    },
  ];

  return (
    <section style={{ padding: "20px", marginTop: "20px" }}>
      <div>last 1000 orders</div>
      <h1>Riders Report</h1>
      <Table columns={columns} dataSource={data} pagination={false} />
    </section>
  );
}
