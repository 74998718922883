import { useEffect } from "react";
import { Col, Form, Button } from "antd";
import styled from "styled-components";
import Bonus from "../../components/rider/settings/Bonus";
import FulltimeRidersCommission from "../../components/rider/settings/FulltimeRidersCommission";
import PartTimeRidersCommission from "../../components/rider/settings/PartTimeRidersCommission";
import Salary from "../../components/rider/settings/Salary";
import Penalty from "../../components/rider/settings/Penalty";
import { useSelector, useDispatch } from "react-redux";
import { fetchSettings, updateSettings } from "../../store/rider/riderSlices";
import Boxer from "../../components/Common/Boxer";

export default function RiderSettings() {
  const [form] = Form.useForm();
  const {
    settings: { loading, data, updateLoading },
  } = useSelector((state) => state.rider);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data.value);
      form.setFieldsValue(data.value);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;

  const onFinish = (values) => {
    values.object = data.refs;
    dispatch(updateSettings(values));
  };

  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <h2>Rider Settings</h2>
        <Boxer open={true}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button loading={updateLoading} type="primary" htmlType="submit">
              Update
            </Button>
          </Col>
          <Bonus />
          <FulltimeRidersCommission />
          <PartTimeRidersCommission />
          <Salary />
          <Penalty />
        </Boxer>
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .card {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgba(26, 26, 26, 0.1);
    min-height: 400px;

    .title {
      text-align: right;
      font-size: 18px;
    }
  }
  .layout {
    .title {
      font-size: 16px;
      text-align: left;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    .suffix {
      margin-left: 10px;
    }
  }

  .ant-form-item {
    margin-bottom: 8px;
  }
`;
