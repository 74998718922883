const [startHour, endHour] = [20, 9];

const betweenOperatingHour = (start, end, now) => {
  if (start < end) {
    return start <= now && end >= now;
  } else {
    return start <= now || end >= now;
  }
};

const startTime = (date) => {
  const d = new Date(date);

  if (d.getHours() >= 0 && d.getHours() <= endHour) {
    d.setDate(d.getDate() - 1);
  }
  d.setHours(startHour, 0, 0, 0);
  return d;
};

const ordersReport = (orders) => {
  if (!orders || orders.length === 0) {
    return {
      length: 0,
      confirm: { count: 0, time: 0 },
      ready: { count: 0, time: 0 },
      delivery: { count: 0, time: 0 },
      delivered: 0,
      rejected: 0,
      cancelled: 0,
      hours: {},
      ordersPerDate: {},
      orderItems: {},
      restaurant: {},
      area: {},
    };
  }

  const result = orders.reduce(
    (acc, order) => {
      let {
        timeline,
        customer_area,
        createdAt,
        status,
        charge,
        order_items,
        pickups,
      } = order;
      console.log(acc);
      acc.length += 1;

      // confirmation time
      const confirmedAt = timeline.find(
        (item) => item.type === "status" && item.current === "confirmed"
      );
      let createdTime;
      if (confirmedAt) {
        createdTime = new Date(createdAt);
        if (
          !betweenOperatingHour(
            startHour,
            endHour,
            createdTime.getHours(),
            createdTime.getMinutes()
          )
        ) {
          createdTime = startTime(createdAt);
        }
        acc.confirm.count += 1;
        acc.confirm.time +=
          (new Date(confirmedAt.time) - new Date(createdTime)) / 1000 / 60;
      }

      // ready time
      const readyAt = timeline.find(
        (item) => item.type === "status" && item.current === "ready"
      );
      if (readyAt) {
        acc.ready.count += 1;
        acc.ready.time +=
          (new Date(readyAt.time) - new Date(createdTime)) / 1000 / 60;
      }

      // delivery time
      const deliveryAt = timeline.find(
        (item) => item.type === "status" && item.current === "delivered"
      );
      if (deliveryAt) {
        acc.delivery.count += 1;
        acc.delivery.time +=
          (new Date(deliveryAt.time) - new Date(createdTime)) / 1000 / 60;
      }

      // status report
      if (status === "delivered") acc.delivered += 1;
      if (status === "cancelled") acc.cancelled += 1;
      if (status === "rejected") acc.rejected += 1;

      const createdHour = new Date(createdAt).getHours();
      if (Number.isInteger(createdHour)) {
        if (acc.hours[createdHour]) {
          acc.hours[createdHour] += 1;
        } else {
          acc.hours[createdHour] = 1;
        }
      }

      if (status === "delivered") {
        let orderDate = startTime(createdAt).getDate();
        if (acc.ordersPerDate[orderDate]) {
          acc.ordersPerDate[orderDate].count += 1;
          acc.ordersPerDate[orderDate].total += charge.total;
          acc.ordersPerDate[orderDate].delivery_charge +=
            charge.delivery_charge;
          acc.ordersPerDate[orderDate].discount += charge.discount;
          acc.ordersPerDate[orderDate].promo_discount += charge.promo_discount;
          acc.ordersPerDate[orderDate].vat += charge.vat;
        } else {
          acc.ordersPerDate[orderDate] = {
            count: 1,
            ...charge,
          };
        }
      }

      // order items report
      order_items.forEach((item) => {
        if (acc.orderItems[item.id]) {
          acc.orderItems[item.id].count += 1;
          acc.orderItems[item.id].total += item.total;
          acc.orderItems[item.id].quantity += item.quantity;
        } else {
          acc.orderItems[item.id] = {
            count: 1,
            total: item.total,
            name: item.name,
            quantity: item.quantity,
          };
        }
      });

      // restaurant report
      pickups.forEach((pickup) => {
        const charges = pickup.items.reduce(
          (acc, item) => {
            acc.total += item.total;
            acc.quantity += item.quantity;
            acc.discount += item.discount;
            return acc;
          },
          { total: 0, quantity: 0, discount: 0 }
        );

        if (acc.restaurant[pickup.id]) {
          acc.restaurant[pickup.id].count += 1;

          acc.restaurant[pickup.id] = {
            ...acc.restaurant[pickup.id],
            ...charges,
          };
        } else {
          acc.restaurant[pickup.id] = {
            id: pickup.id,
            name: pickup.name,
            count: 1,
            ...charges,
          };
        }
      });

      if (acc.area[customer_area]) {
        acc.area[customer_area] += 1;
      } else {
        acc.area[customer_area] = 1;
      }

      return acc;
    },
    {
      length: 0,
      confirm: { count: 0, time: 0 },
      ready: { count: 0, time: 0 },
      delivery: { count: 0, time: 0 },
      delivered: 0,
      rejected: 0,
      cancelled: 0,
      hours: {},
      ordersPerDate: {},
      orderItems: {},
      restaurant: {},
      area: {},
    }
  );

  return { ...result };
};

export default ordersReport;
