import { Button, message, Upload } from "antd";
import { InboxOutlined, CloseOutlined } from "@ant-design/icons";
import useMedia from "../../../hooks/useMedia";

export default function AddImage({ banner, setBanner }) {
  const { getSignedUrl, uploadFile } = useMedia();

  const uploadOnChange = async ({ fileList }) => {
    if (fileList[0]?.originFileObj) {
      setBanner({
        loading: true,
      });
      const src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileList[0]?.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

      const file = fileList[fileList.length - 1];

      setBanner({
        thumbUrl: src,
      });

      const { url } = await getSignedUrl(file.type);

      uploadFile({ url, file }, (err, progress) => {
        if (err) {
          setBanner({ loading: false });
          message.error(err);
          return;
        }

        setBanner({ progress });

        if (progress === 100) {
          setBanner({
            loading: false,
            image: url.split("?")[0],
            update: false,
            progress: null,
          });
        }
      });
    }
  };

  const showImage = (banner) => {
    if (banner.thumbUrl) {
      return banner.thumbUrl;
    } else if (banner.image && !banner.update) {
      return banner.image;
    } else {
      return null;
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Upload.Dragger
        beforeUpload={() => false}
        style={{
          marginBottom: "10px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          background: "#fff",
          display: showImage(banner) ? "none" : "block",
        }}
        name="files"
        listType="picture"
        multiple={false}
        type="file"
        showUploadList={false}
        onChange={uploadOnChange}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Upload.Dragger>
      {showImage(banner) && (
        <div className="profile-picture">
          <img src={banner.image || banner.thumbUrl} alt="" />
          {banner.progress && <div className="progress">{banner.progress}</div>}
          <Button
            icon={<CloseOutlined />}
            shape="circle"
            className="close-btn"
            onClick={() => {
              setBanner({
                thumbUrl: null,
                update: true,
              });
            }}
          ></Button>
        </div>
      )}
    </div>
  );
}
