import { configureStore } from "@reduxjs/toolkit";
import riderCrudSlices from "./rider/riderCrudSlices";
import riderSlices from "./rider/riderSlices";
import hubSlices from "./hub/hubSlices";
import restaurantDetailsSlice from "./restaurant/restaurantDetails";
import cuisines from "./cuisines";
import promotionalBanner from "./promotional_banner";

export default configureStore({
  reducer: {
    riderCrud: riderCrudSlices,
    rider: riderSlices,
    hub: hubSlices,
    restaurantDetails: restaurantDetailsSlice,
    cuisines,
    promotionalBanner,
  },
});
