import { useContext, useState } from "react";
import { DetailsContext } from "../../../../pages/Order/Details";
import BoxLayout from "../BoxLayout";
import OrderItem from "./OrderItem";
import AddOrderItem from "./AddOrderItem";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import AddPromo from "./AddPromo";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const PromoWrapper = Styled(BoxLayout)`
    .item{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 20px 3px 20px;

        .code, .value, .title{
            font-size: 16px;
            font-weight: 500;
            color: black;
            color: #656565;
        }
        .title{
            font-size: 16px;
        }
    }

    .item:last-child{
        border-bottom: none;
    }
`;

function PromoInfo({
  objectId,
  apply_on,
  promo_code,
  promo_amount,
  promo_type,
  min_order_amount,
  max_discount_amount,
}) {
  function Item({ title, value }) {
    return (
      <div className="item">
        <div className="title">{title}</div>
        <div className="value">{value}</div>
      </div>
    );
  }

  return (
    <div>
      <Item
        title="Promo Code"
        value={
          <Link className="code" to={`/promo/list/${objectId}`}>
            {promo_code}
          </Link>
        }
      />
      <Item title="Promo applied on" value={apply_on} />
      <Item title={`Promo amount (${promo_type})`} value={promo_amount} />
      <Item title={`Min order amount`} value={min_order_amount} />
      <Item title={`Max discount amount`} value={max_discount_amount} />
    </div>
  );
}

function PromoSection() {
  const { order } = useContext(DetailsContext);
  const { promo } = order.data;
  const [open, setOpen] = useState(false);

  return (
    <>
      {promo && (
        <PromoWrapper title="Promo info">
          <PromoInfo {...promo} />
        </PromoWrapper>
      )}

      {!promo &&
        !["delivered", "cancelled", "rejected"].includes(order.data.status) && (
          <>
            {!open && (
              <div style={{ margin: "10px 0", textAlign: "right" }}>
                <Button icon={<PlusOutlined />} onClick={() => setOpen(true)}>
                  Add promo
                </Button>
              </div>
            )}
            {open && (
              <PromoWrapper title="Add promo">
                <AddPromo close={() => setOpen(false)} />
              </PromoWrapper>
            )}
          </>
        )}
    </>
  );
}

export default function OrderItems() {
  const { order } = useContext(DetailsContext);
  const { order_items, pickups } = order.data;
  const [loading, setLoading] = useState(false);

  const pickupItem = (id) => {
    return pickups.find((item) => {
      return item.items.find((i) => i.id === id);
    });
  };

  return (
    <>
      <PromoSection />
      <BoxLayout>
        {order_items.map((item, index) => (
          <OrderItem
            key={index}
            setLoading={setLoading}
            loading={loading}
            {...item}
            pickup={pickupItem(item.id)}
          />
        ))}
        <AddOrderItem setLoading={setLoading} loading={loading} />
      </BoxLayout>
    </>
  );
}
