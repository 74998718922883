import { useContext } from "react"
import { DetailsContext } from '../../../../pages/Order/Details'
import { Divider } from "antd"
import VariantItem from "./VariantItem"


export default function OrderItemVariant({ variant }){
    const { order, editMode, getOrderCharges } = useContext(DetailsContext)

    return (
        variant?.length > 0 && Array.isArray(variant) ? <div className="variant">
            <Divider orientation="left" style={{marginBottom: '8px'}}>Variants</Divider>
            <div style={{display: 'flex'}}>
                <div className="variations">
                    {variant.map((item, index) => {
                        return (
                            <div className="variation" key={index}>
                                {item.items.map((v, i) => <VariantItem key={i} {...v} variantId={item.variantId} />)}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>: null
    )
}