import { Form, Input } from "antd";
const { Item } = Form;

export default function ProductName() {
  return (
    <Item
      name="name"
      label="Product Name"
      rules={[
        {
          required: true,
          message: "Please input product name",
        },
      ]}
      style={{ marginBottom: "5px" }}
    >
      <Input size="large" placeholder="Product name" />
    </Item>
  );
}
