import { InputNumber, message, Space } from "antd";
import { useState } from "react";
import Parse from "parse";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";

export default function UpdateHubArea({ charge, setCharge, setUpdate, refs }) {
  const [loading, setLoading] = useState(false);

  const onChange = async (e) => {
    setCharge(e.target.value);
    if (e.keyCode === 13) {
      setLoading(true);
      try {
        if (refs instanceof Parse.Object) {
          refs.set("delivery_charge", Number(e.target.value));
          const res = await refs.save();
          if (res) {
            setUpdate(false);
            setLoading(false);
          }
        } else {
          setLoading(false);
          message.error("Invalid reference");
        }
      } catch (err) {
        setLoading(false);
        message.error(err.message);
      }
    }
  };

  return (
    <Space>
      <InputNumber
        value={charge}
        onKeyDown={onChange}
        placeholder="charge"
        style={{ width: "100px" }}
      />
      {loading ? (
        <div style={{ fontSize: "14px" }}>
          <LoadingOutlined />
        </div>
      ) : (
        <CloseOutlined
          style={{ color: "red" }}
          onClick={() => {
            setCharge(null);
            setUpdate(false);
          }}
        />
      )}
    </Space>
  );
}
