import { useContext, useState } from "react";
import { Table, DatePicker, Modal, Button, Space } from "antd";
import { InvoiceContext } from "../../pages/Payments/History";
import RestaurantLinks from "../Common/RestaurantLinks";
import { isSameDay, isBefore, isAfter } from "date-fns";
import { CalendarOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function InvoiceTable(props) {
  const { invoices, restaurantId, fetchInvoices } = useContext(InvoiceContext);
  const { loading, data } = invoices;

  const dateSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <DatePicker.RangePicker
          style={{ width: "250px" }}
          disabledDate={(current) => current.isAfter(Date.now(), current)}
          format="YYYY-MM-DD"
          size="large"
          value={setSelectedKeys[0]}
          onChange={(date, dateString) => {
            setSelectedKeys(dateString ? [dateString] : []);
            confirm();
          }}
        />
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <div
          style={{
            fontSize: "20px",
            padding: "7px 10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CalendarOutlined
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        </div>
      );
    },
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (value?.filter((i) => i).length > 0) {
          return (
            (isAfter(new Date(record[dataIndex]), new Date(value[0])) ||
              isSameDay(new Date(record[dataIndex]), new Date(value[0]))) &&
            (isBefore(new Date(record[dataIndex]), new Date(value[1])) ||
              isSameDay(new Date(record[dataIndex]), new Date(value[1])))
          );
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
  });

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "objectId",
      key: "id",
    },
    {
      title: "Restaurant",
      dataIndex: "restaurant",
      key: "restaurant",
      render: (res) => {
        return res?.objectId ? (
          <RestaurantLinks id={res?.objectId}>
            <span style={{ fontSize: "14px" }}>{res?.name}</span>
          </RestaurantLinks>
        ) : (
          <span style={{ color: "red" }}>Not Found</span>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "200px",
      ...dateSearchProps("createdAt"),
      render: (date) => {
        return <span>{new Date(date).toLocaleDateString()}</span>;
      },
    },
    {
      title: "Invoice Period",
      dataIndex: "payment_period",
      key: "payment_period",
      render: (dates) => {
        return dates
          .map((i) => new Date(i.iso).toLocaleDateString())
          .join(" - ");
      },
    },
    {
      title: "Invoice Amount",
      dataIndex: "payable",
      key: "payable",
      sorter: (a, b) => a.payable - b.payable,
    },
    {
      title: "Actions",
      key: "action",
      render: (text, { objectId }) => (
        <Space>
          <Button type="primary">
            <Link to={`/invoice/${objectId}`}>Details</Link>
          </Button>
        </Space>
      ),
    },
  ];

  if (restaurantId) {
    const resIndex = columns.findIndex(
      ({ dataIndex }) => dataIndex === "restaurant"
    );
    if (resIndex > -1) {
      columns.splice(resIndex, 1);
    }
  }

  const mapData = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((invoice) => {
      return {
        ...invoice,
        key: invoice.id,
      };
    });
  };

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={mapData(data.results)}
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          total: data.count,
          position: ["topLeft", "topRight"],
        }}
        scroll={{
          x: "max-content",
        }}
        onChange={(pagination, filters, sorter) => {
          fetchInvoices({
            limit: pagination.pageSize,
            skip: (pagination.current - 1) * pagination.pageSize,
            restaurant: filters.restaurant,
            createdAt:
              filters.createdAt &&
              filters.createdAt[0]?.filter((i) => i).length > 0
                ? filters.createdAt[0]
                : undefined,
          });
        }}
      />
    </div>
  );
}
