import { useContext, useEffect, useState, useCallback } from "react";
import { DetailsContext } from "../../../pages/Order/Details";
import BoxLayout from "./BoxLayout";
import styled from "styled-components";
import { EditTwoTone } from "@ant-design/icons";
import { Button, Form, Input, Select, message, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";

function Item({ title, value, name, edit, required, zone, ...rest }) {
  return !edit ? (
    <div
      className="item"
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 20px",
        borderBottom: "1px solid #dedede",
      }}
      {...rest}
    >
      <strong className="title">{title}</strong>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          width: "70%",
          textAlign: "right",
        }}
      >
        {value}
      </div>
    </div>
  ) : (
    <Form.Item
      rules={[{ required }]}
      name={name}
      style={{ marginBottom: "5px" }}
      label={title}
    >
      {name !== "customer_area" ? (
        <Input placeholder={name} {...rest} />
      ) : (
        <Select
          placeholder="Select Zone"
          style={{ width: "100%" }}
          showSearch
          {...rest}
        >
          {zone?.map((area, index) => {
            return (
              <Select.Option key={index} value={area.name}>
                {area.name}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </Form.Item>
  );
}

export default function CustomerInfo(props) {
  const { order, zone, updateCustomerInfo } = useContext(DetailsContext);
  const {
    status,
    customer_name,
    customer_phone,
    customer_area,
    customer_address,
    note,
    rider_note,
    user,
  } = order.data;
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState([]);
  const [counter, setCounter] = useState(0);
  const [updateGeo, setUpdateGeo] = useState(false);

  const onFinish = useCallback(
    (values) => {
      setLoading(true);

      updateCustomerInfo({ ...values, geo: updateGeo }, (saved) => {
        setLoading(false);
        if (saved) {
          setEdit(false);
          setPlaces([]);
          setUpdateGeo(false);
        }
      });
    },
    [updateGeo]
  );

  const onAddressChange = (e) => {
    const value = e.target.value;
    if (value.length === 0 && places.length) {
      setPlaces([]);
    }

    setCounter((counter) => counter + 1);

    if (value.length < 10 || counter < 3) {
      return;
    }
    setCounter(0);

    const link = `https://barikoi.xyz/v1/api/search/verify/autocomplete/${process.env.REACT_APP_BARIKOI_API_KEY}/place?q=${value}`;

    fetch(link)
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data.places)) {
          setPlaces(data.places);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      customer_name,
      customer_phone,
      customer_area,
      customer_address,
      note,
      rider_note,
    });

    return () => {
      form.resetFields();
    };
  }, [order.data]);

  return (
    <BoxLayout
      title={<Link to={`/user/add?id=${user?.id}`}>Customer Info</Link>}
    >
      <Wrapper>
        <Form onFinish={onFinish} form={form} layout="vertical" size="large">
          {!edit && !["delivered", "cancelled", "rejected"].includes(status) && (
            <div className="edit">
              <Button onClick={(e) => setEdit(true)} type="primary">
                <EditTwoTone /> Edit
              </Button>
            </div>
          )}
          <Item
            edit={edit}
            required={true}
            name="customer_name"
            title="Name"
            value={customer_name}
          />
          <Item
            edit={edit}
            required={true}
            name="customer_phone"
            title="Phone"
            value={customer_phone}
          />
          <Dropdown
            overlay={
              places.length > 0 ? (
                <Menu style={{ maxHeight: "300px" }} className="customScroll">
                  {places.map((place, index) => {
                    return (
                      <Menu.Item
                        key={index}
                        onClick={() => {
                          form.setFieldsValue({
                            customer_address: place.address,
                            customer_area: place.area.toLowerCase(),
                          });

                          setUpdateGeo({
                            latitude: place.latitude,
                            longitude: place.longitude,
                          });
                        }}
                      >
                        {`${place.area}: ${place.address}`}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              ) : (
                <div></div>
              )
            }
            trigger={["click", "hover"]}
          >
            <Item
              edit={edit}
              required={true}
              name="customer_address"
              title="Address"
              value={customer_address}
              onChange={onAddressChange}
            />
          </Dropdown>
          <Item
            edit={edit}
            required={true}
            name="customer_area"
            title="Area"
            value={customer_area}
            zone={zone}
          />
          <Item edit={edit} name="note" title="Note" value={note} />
          <Item
            edit={edit}
            name="rider_note"
            title="Rider Note"
            value={rider_note}
          />
          {edit && (
            <Form.Item className="form-footer">
              <Button
                onClick={() => setEdit(false)}
                danger
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                disabled={loading}
                htmlType="submit"
                type="primary"
              >
                Update
              </Button>
            </Form.Item>
          )}
        </Form>
      </Wrapper>
    </BoxLayout>
  );
}

const Wrapper = styled.div`
  position: relative;

  .edit {
    position: absolute;
    right: 0;
    top: -35px;
    width: 100px button {
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .item {
    .value {
      font-size: 16px;
      font-weight: 500;
      width: 70%;
      text-align: right;
    }
  }

  .item:last-child {
    border-bottom: none;
  }

  .form-footer {
    text-align: right;
    padding: 10px 20px;
  }
`;
