import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import SectionItem from "./SectionItem";
import AddSectionItem from "./AddSectionItem";
import { Box } from "../Common/Boxer";
import { PlusOutlined } from "@ant-design/icons";

export default function Section(props) {
  const { id: objectId, items, name, sort_order, hubs } = props;

  const [addNew, setAddNew] = useState(false);

  return (
    <Box
      title={`[${sort_order + 1}] ${name}`}
      metaTitle="section"
      maxHeight="500px"
      rightMenu={<PlusOutlined onClick={() => setAddNew(true)} />}
    >
      <div className="section">
        {addNew && (
          <AddSectionItem
            sectionId={objectId}
            {...props}
            close={(e) => setAddNew(false)}
          />
        )}

        <div className="section-header">
          <div className="hubs">
            {hubs &&
              hubs.map((hub) => (
                <div key={hub.id} className="hubs-item">
                  {hub?.get ? hub?.get("name") : hub?.name}
                </div>
              ))}
          </div>
        </div>

        <Droppable droppableId={objectId}>
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="droppable"
              >
                {items.map((item, i) => {
                  return (
                    <SectionItem
                      key={i}
                      section={objectId}
                      {...item}
                      index={i}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </Box>
  );
}
