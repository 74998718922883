import { createContext, useReducer } from "react";
import Parse from "parse";
import { message } from "antd";

export const DashboardContext = createContext();

const Types = {
  SALES_ANALYTICS_REQUEST: "SALES_ANALYTICS_REQUEST",
  SALES_ANALYTICS_SUCCESS: "SALES_ANALYTICS_SUCCESS",
  SALES_ANALYTICS_FAILURE: "SALES_ANALYTICS_FAILURE",
};

const initialState = {
  salesAnalytics: { loading: false, data: null, error: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case Types.SALES_ANALYTICS_REQUEST:
      state.salesAnalytics.loading = true;
      return { ...state };
    case Types.SALES_ANALYTICS_SUCCESS:
      state.salesAnalytics.loading = false;
      state.salesAnalytics.error = null;
      state.salesAnalytics.data = action.payload;
      return { ...state };
    case Types.SALES_ANALYTICS_FAILURE:
      state.salesAnalytics.loading = false;
      state.salesAnalytics.data = null;
      state.salesAnalytics.error = action.payload;
      return { ...state };
    default:
      return state;
  }
};

export default function DashboardContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getSalesAnalytics = async (date) => {
    const newDate = [...date];
    if (typeof newDate[0] === "object")
      newDate[0] = new Date(newDate[0]).toISOString();
    if (typeof newDate[1] === "object")
      newDate[1] = new Date(newDate[1]).toISOString();

    try {
      dispatch({ type: Types.SALES_ANALYTICS_REQUEST });
      let item = await Parse.Cloud.run("getSalesReports", { date: newDate });
      if (item) {
        dispatch({
          type: Types.SALES_ANALYTICS_SUCCESS,
          payload: item.id ? item.toJSON() : item,
        });
      } else {
        dispatch({
          type: Types.SALES_ANALYTICS_FAILURE,
          payload: "No data found",
        });
      }
    } catch (err) {
      message.error(err.message);
      dispatch({ type: Types.SALES_ANALYTICS_FAILURE, payload: err });
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        getSalesAnalytics,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
