import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import Parse from "parse";
import { deleteFile } from "../api/media";
import { getImageKey } from "../common/utils";

const initialState = {
  list: {
    loading: false,
    data: [],
    count: 0,
  },
  createNew: {
    loading: false,
  },
};

export const updateCuisine = createAsyncThunk(
  "cuisines/updateCuisine",
  async ({ object, show, cb }) => {
    try {
      if (object instanceof Parse.Object) {
        if (typeof show !== "boolean") {
          throw new Error("Show must be boolean!");
        }

        object.set("show", show);
        const res = await object.save();
        if (typeof cb === "function") {
          cb();
        }
        return res;
      } else {
        throw new Error("Something wrong!");
      }
    } catch (err) {
      if (typeof cb === "function") {
        cb();
      }
      throw new Error(err.message);
    }
  }
);

export const deleteCuisine = createAsyncThunk(
  "cuisines/deleteCuisine",
  async (object) => {
    if (object instanceof Parse.Object) {
      const icon = object.get("icon") || "";
      const image = object.get("image") || "";
      const res = await object.destroy();
      if (getImageKey(icon)) {
        await deleteFile(getImageKey(icon));
      }
      if (getImageKey(image)) {
        await deleteFile(getImageKey(image));
      }
      return res;
    } else {
      throw new Error("Something wrong!");
    }
  }
);

export const createNewCuisine = createAsyncThunk(
  "cuisines/createNewCuisine",
  async ({ name, image, icon, cb }) => {
    const exists = await new Parse.Query("cuisines")
      .equalTo("name", name)
      .first();
    if (exists) {
      throw new Error("This name already exists");
    }
    const Cuisine = new Parse.Object("cuisines");
    Cuisine.set("name", name);
    if (image) Cuisine.set("image", image);
    if (icon) Cuisine.set("icon", icon);
    const cuisine = await Cuisine.save();
    if (typeof cb === "function") {
      cb();
    }
    return cuisine;
  }
);

export const fetchCuisines = createAsyncThunk(
  "cuisines/fetchCuisines",
  async ({ limit, skip, search } = {}) => {
    const query = new Parse.Query("cuisines").withCount();
    query.limit(limit);
    query.skip(skip);
    if (search) {
      query.matches("name", search, "i");
    }
    query.ascending("sort_order");
    return await query.find();
  }
);

export const updateListAsync = async (data) => {
  try {
    await Parse.Object.saveAll(data);
  } catch (err) {
    message.error(err.message);
  }
};

const cuisines = createSlice({
  name: "cuisines",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list.data = action.payload;
    },
  },
  extraReducers: {
    [fetchCuisines.pending]: (state, action) => {
      state.list.loading = true;
    },
    [fetchCuisines.fulfilled]: (state, action) => {
      state.list.loading = false;
      state.list.data = action.payload.results.map((item) => ({
        ...item.toJSON(),
        object: item,
      }));
      state.list.count = action.payload.count;
    },
    [fetchCuisines.rejected]: (state, action) => {
      state.list.loading = false;
      message.error(action.error.message);
    },
    [createNewCuisine.pending]: (state) => {
      state.createNew.loading = true;
    },
    [createNewCuisine.fulfilled]: (state, action) => {
      state.createNew.loading = false;
      state.list.data.unshift(action.payload.toJSON());
      state.list.count++;
    },
    [createNewCuisine.rejected]: (state, action) => {
      state.createNew.loading = false;
      message.error(action.error.message);
    },
    [deleteCuisine.fulfilled]: (state, { payload }) => {
      const index = state.list.data.findIndex(
        (item) => item.objectId === payload.id
      );

      if (index > -1) {
        state.list.data.splice(index, 1);
        state.list.count--;
      }
    },
    [deleteCuisine.rejected]: (state, { error }) => {
      message.error(error.message);
    },
    [updateCuisine.fulfilled]: (state, { payload }) => {
      const cuisine = state.list.data.find(
        (item) => item.objectId === payload.id
      );
      if (cuisine) {
        cuisine.show = payload.get("show");
        cuisine.object = payload;
      }
    },
    [updateCuisine.rejected]: (state, { error }) => {
      message.error(error.message);
    },
  },
});

export default cuisines.reducer;

export const { updateList } = cuisines.actions;
