import { addDays } from "date-fns";
import Parse from "parse";

export default function useUser() {
  const repoName = "User";
  const defaultFunc = (err, data) => {};

  const getUsers = async (params = {}, callback = defaultFunc) => {
    try {
      const {
        limit = 100,
        skip = 0,
        ids,
        verified,
        type,
        search,
        username,
        name,
        phone,
        email,
        select,
        createdAt,
        platform,
        gender,
        date_of_birth,
      } = params;
      const query = new Parse.Query(repoName);
      query.limit(limit);
      query.skip(skip);
      if (search) {
        query.matches("name", search, "i");
      }
      if (ids) {
        query.containedIn("objectId", ids);
      }
      if (name) query.matches("name", name, "i");
      if (phone) query.matches("phone", phone, "i");
      if (email) query.matches("email", email, "i");
      if (username) query.matches("username", username, "i");
      if (typeof verified === "boolean") query.equalTo("verified", verified);
      if (select && Array.isArray(select)) query.select(...select);

      if (Array.isArray(createdAt) && createdAt.length === 2) {
        const [start, end] = createdAt.map((d) => new Date(d));

        if (start) {
          query.greaterThanOrEqualTo("createdAt", start);
        }
        if (end) {
          query.lessThan("createdAt", addDays(end, 1));
        }
      }
      if (Array.isArray(date_of_birth) && date_of_birth.length === 2) {
        const [start, end] = date_of_birth.map((d) => new Date(d));

        if (start) {
          query.greaterThanOrEqualTo("date_of_birth", start);
        }
        if (end) {
          query.lessThan("date_of_birth", addDays(end, 1));
        }
      }
      if (Array.isArray(platform) && platform.length) {
        if (platform[0] === "web") {
          query.doesNotExist("device_info");
        } else {
          query.containedIn("device_info.type", platform);
        }
      }
      if (Array.isArray(gender)) {
        query.containedIn("gender", gender);
      }

      query.withCount();
      query.descending("createdAt");
      if (type) {
        if (Array.isArray(type)) {
          query.containedIn("type", type);
        } else {
          query.equalTo("type", type);
        }
      }
      const users = await query.find({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      callback(null, users);
    } catch (err) {
      console.log(err);
      callback(err.message);
    }
  };

  const getById = async ({ id, type, select } = {}, callback = defaultFunc) => {
    if (!id) return callback("User id is required");
    try {
      const query = new Parse.Query(repoName);
      query.equalTo("objectId", id);
      if (select) query.select(select);
      if (type) query.equalTo("type", type);
      // query.select('active', 'username', 'email', 'phone', 'name', 'type', 'objectId')
      const user = await query.first({
        sessionToken: Parse.User.current().getSessionToken(),
      });
      if (user) {
        callback(null, user);
      } else {
        callback("User not found");
      }
    } catch (err) {
      callback(err.message);
    }
  };

  const createUser = async (
    { name, type, username, email, phone, password },
    callback = defaultFunc
  ) => {
    try {
      const user = await Parse.Cloud.run("createNewUser", {
        name,
        type,
        username,
        email,
        phone,
        password,
      });
      callback(null, user);
    } catch (err) {
      callback(err.message);
    }
  };

  const updateUser = async (params, callback = defaultFunc) => {
    try {
      const update = await Parse.Cloud.run("updateUser", params);
      callback(null, update);
    } catch (err) {
      callback(err.message);
    }
  };

  const deleteUsers = async (params, callback = defaultFunc) => {
    try {
      const res = await Parse.Cloud.run("deleteUsers", params);
      callback(null, res);
    } catch (err) {
      callback(err.message);
    }
  };

  const fetchRoles = async ({}, callback = defaultFunc) => {
    try {
      const roles = await new Parse.Query("_Role").find();
      callback(null, roles);
    } catch (err) {
      callback(err.message);
    }
  };

  return {
    getUsers,
    getById,
    createUser,
    updateUser,
    deleteUsers,
    fetchRoles,
  };
}
